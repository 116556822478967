import {Component, Input, NgModule, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delconf-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title"> You are going to return back to the offer {{offerTitle}} without applying calculations</h4>
    </div>
    <div class="modal-body">
      <p>Are you sure you want to continue?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="activeModal.close('Accept')">Yes</button>
      <button type="button" class="btn btn-secondary" (click)="activeModal.close('Cancel')">No</button>
    </div> 
  `
})
export class UnSaveConfModalComponent implements OnInit {
  @Input() offerTitle: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
  }

}

@NgModule({
  declarations: [UnSaveConfModalComponent],
  exports: [UnSaveConfModalComponent]
})
export class UnSaveConfModalModule { }
