import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {UserService} from '../user.service';
import {GroupService} from '../../group/group.service';
import {User} from '../user';
import {Group} from '../../group/group';
import {Person} from '../../person/person';
import {PersonService} from '../../person/person.service';
import {NgForm} from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import {CompanyService} from '../../company/company.service';
import {Office} from '../../company/office/office';
import {ProductCategory} from '../../../scs/constitution/productCategory';
import { CityService } from '../../../shared/city.service';

import { TranslateService } from '@ngx-translate/core';
import {LoginService} from '../../login/login.service';

import {CreatePersonModalComponent} from '../../person/create-person/create-person-modal.component';
import { ConstitutionService } from '../../../scs/constitution/constitution.service';

import { ErrorBoxService } from '../../../shared/error-box/error-box.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  user: any;
  notFound = false;
  groups: Group[];

  extraBtns = [
    {
      html: `<i class="fa fa-plus-circle"></i> Add New`,
      fn: 'test'
    }
  ];

  @ViewChild('editUserForm')
  public editUserForm: NgForm;
  
  officesByCompany: Office[];

  public editUserData = {
    person: null,
    username: null,
    password: null,
    email: null,
    group: null,
    roles: null,
    father: null,
    discount: null,
    commission: null,
    agent_number: null,
    flagMaxDiscountDefault: null,
    enabled: true,
    name: null,
    surname:null,
    company: null,
    office: null,
    city: null,
    zipCode: null,
    address: null,
    phone: null
   
  };

  switchPerson: boolean = false;
  userData: any;

  quotedByUsers: any;
  cityPlaceholder = 'Utebo Aragón';
  selectedCountry: any;


  categoryProducts: ProductCategory;
  categoryProductsSilo: ProductCategory;
  categorySilos: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private groupService: GroupService,
    private modalService: NgbModal,
    private personService: PersonService,
    private loginService: LoginService,
    private translate: TranslateService,
    private companyService: CompanyService,
    private cityService: CityService,
    private constitutionService: ConstitutionService,
    private errorBox: ErrorBoxService
  ) { }

  ngOnInit() {
     
    this.getUserEdit(); 
    this.getGroups();
    this.getUserData();
    this.getSimezaUsers();

  }

  getUserEdit(){
    this.route.params
      .switchMap((params: Params) => this.userService.getUserById(params['id']))
      .subscribe(
        user => {

          this.postActionsGetUserEdit(user);
        },
        error => {
          if (error.status === 404) {
            this.notFound = true;
          }
        }
      );
  }

  postActionsGetUserEdit(user){

    delete user.password;

    this.user =  this.disserailizeUser(_.cloneDeep(user));
    this.editUserData = this.disserailizeUser(_.cloneDeep(user));


       
    // this.userData.group = this.userData.group.id;
    this.getOffices(this.editUserData.person.office.company);
    this.getCategories();
    this.getCategoriesSilo();
  }

  getGroups(): void {
    this.groupService.getGroups()
      .then(groups => this.groups = groups);
  }

  reatingUser:boolean = false; 
  onSubmit(empForm: any, event: Event): void {

    event.preventDefault();
    this.reatingUser = true;
    let newUser = { ...this.editUserData };

    newUser = this.serializeUser(newUser);


    this.userService.updateUser(newUser).then(user =>{ 

      this.reatingUser = false;
    
      this.postActionsGetUserEdit(user);
      this.errorBox.success('User updated succesfully.');

    }).catch(error => {
      
      this.reatingUser = false;
    });
  }

  disserailizeUser(user){
  
    if (user.person.city){
      this.selectedCountry = user.person.city.country;
    }

    return user;

  }

  serializeUser(user){

    if(!_.isNull( user.person)){
       //user.person = user.person.id;
    }

    if(!_.isNull( user.father)) {
        user.father = user.father.id;
    }

    return user;
  }

  compareData(): boolean {
    return _.isEqual(this.user, this.userData);
  }


  getUserData() {
    this.loginService.getUser()
      .then(user => this.userData = user);
  }

  search = (text$: Observable<string>) =>
    text$
      .debounceTime(300)
      .distinctUntilChanged()
      .switchMap(term =>
        Observable.fromPromise(this.personService.getPersons(term)))
      .catch(() => Observable.of([]));

  formatter = (x: {name: string, surname: string}) => x.name + ' ' + x.surname;

  extBtnFn(extBtn: any) {
    this[extBtn.fn]();
  }


  isUserGroup(group: Group): boolean {

    if (group.name === 'Customer') {
      return true;
    }
    return false;
  }

  searchCompany = (text$: Observable<string>) =>
    text$
      .debounceTime(300)
      .distinctUntilChanged()
      .switchMap(term =>
        Observable.fromPromise(this.companyService.getCompanies(term)))
      .catch(() => Observable.of([]));

  formatterCompany = (x: {name: string}) => x.name;

  companySelected(event) {
    if (event.item && event.item.id) {
       this.getOffices(event.item);
    }
  }

  getOffices(company){
   this.companyService.getOffices(company.id)
        .then(offices => {
          this.officesByCompany = offices;

          for (let office of offices){

             if(office.isDefault)
             {

               this.editUserData.office = office.id;
             }

          }

          if( _.isNull(this.editUserData.discount)) {

            this.editUserData.discount = company.discount;
          }

        });
  }

  searchCountry = (text$: Observable<string>) =>
    text$
      .debounceTime(300)
      .distinctUntilChanged()
      .switchMap(term => {
        return term.length <= 2 ? [] : this.cityService.searchCountries(term)
          .catch(() => Observable.of([]));
  })

  formatterCountry = (x: {name: string}) => x.name;

  searchSite = (text$: Observable<string>) =>
    text$
      .debounceTime(300)
      .distinctUntilChanged()
      .switchMap(term => {
        return Observable.fromPromise(this.cityService.getCitiesByCountry(this.selectedCountry.id, term));
      })
      .catch(() => Observable.of([]));

  formatterSiteOffice = (x: { nameUtf8: string, state: { nameUtf8: string } }) => { return x.nameUtf8 + (x.state ? ', ' + x.state.nameUtf8 : '') };

  selectedCountryTrigger($country){

    this.cityPlaceholder = $country.item.capital;

  }

  userDisscountChanged(value){

    this.editUserData.discount = value/100;
   
  }

  userComissionChanged(value){
      this.editUserData.commission = value/100;
  }

  getSimezaUsers(){

     this.userService.getUsers(null,null,"1")
          .then(users => {
            this.quotedByUsers = users;
             
          });

  }


  selectFather(user) {
    this.editUserData.father = user;
  }

  enableDisableUser() {
    this.editUserData.enabled = !this.editUserData.enabled;
    this.userService.updateEnableUser(this.editUserData)
      .then(user =>{ 
       this.postActionsGetUserEdit(user);

    });
  

  }

  
  activeTab: number=1;

  isTabActive(tabNumber){
    return tabNumber == this.activeTab;
  }

  setActiveTab(number){

    if(number != this.activeTab ){
      this.activeTab = number;
    }
    
  }

  getTranslationText(object,property){
    
    let translation =  object.translations.find(x => x.locale == this.translate.currentLang);
    if(_.isNil(translation) || _.isUndefined(translation)){
      translation =  object.translations.find(x => x.locale == "en");
    }

    return translation[property];

  }

  getCategories(){

    this.constitutionService.getProductCategories().then(categoryProducts => {
       
      this.categoryProducts = categoryProducts;
      this.orderCategories(this.categoryProducts.childNodes);
      
    });
  
  }

  getCategoriesSilo(){

    this.constitutionService.getProductCategoriesSilo().then(categoryProducts => {
       

      this.categoryProductsSilo = categoryProducts;
 
      this.orderCategoriesSilo(this.categoryProductsSilo.childNodes);
      
    });
  
  }

  orderCategories(categoryNodes){

    for(let categoryNode of categoryNodes){
      categoryNode.loading = true;

      this.constitutionService.getCategoryUserPermission(this.user,categoryNode).then(categoryUserPermission => {
        categoryNode.loading = false;
       
        categoryNode.read = categoryUserPermission.readPermission;

      }).catch(error => {
        categoryNode.loading = false;
       
      }); 

      if(_.has(categoryNode, 'childNodes') &&  !_.isEmpty(categoryNode.childNodes) ){
          this.orderCategories(categoryNode.childNodes)
      }
    }

    if(!_.isNull(categoryNodes[0]['categoryOrder']))
      categoryNodes.sort(this.compareCategories);
   

  }

  orderCategoriesSilo(categoryNodes){

    for(let categoryNode of categoryNodes){
      categoryNode.loading = true;

      this.constitutionService.getCategorySiloUserPermission(this.user,categoryNode).then(categoryUserPermission => {
        categoryNode.loading = false;

        categoryNode.read = categoryUserPermission.readPermission;

      }).catch(error => {
        categoryNode.loading = false;
      
      }); 

      if(_.has(categoryNode, 'childNodes') &&  !_.isEmpty(categoryNode.childNodes) ){
          this.orderCategoriesSilo(categoryNode.childNodes)
      }
    }

    if(!_.isNull(categoryNodes[0]['categoryOrder']))
      categoryNodes.sort(this.compareCategories);
   

  }

    compareCategories(a,b){

   
      if (a.categoryOrder < b.categoryOrder)
        return -1;
      if (a.categoryOrder > b.categoryOrder)
        return 1;
      return 0;
    }


  saveCategotyUserPermission(value,secondLevelCategory){

      secondLevelCategory.loading = true;
      this.constitutionService.updateCategoryUserPermission(this.user,secondLevelCategory, value).then(response => {

          secondLevelCategory.loading = false;

      }).catch(error => {

         secondLevelCategory.loading = false;
          secondLevelCategory.read = !value;
      });

  }

  saveCategotySiloUserPermission(value,secondLevelCategory){

      secondLevelCategory.loading = true;
      this.constitutionService.updateCategorySiloUserPermission(this.user,secondLevelCategory, value).then(response => {

          secondLevelCategory.loading = false;

      }).catch(error => {
       
         secondLevelCategory.loading = false;
         secondLevelCategory.read = !value;
      });

  }

}
