import { Injectable } from '@angular/core';
import { Http, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';

import * as _ from 'lodash';

const endpoint = '/cis';
const endpointRecalculatedSilo = '/recalculatedsilos';
const endPointStoredCalculations = '/stored-calculation';

@Injectable()
export class CISService {

  public inputFromOffer: {
    offerTitle: string,
    offerId: number,
    offerRevision: number,
    offerSilo: any,
    offerClient: string,
    offerSiteOfInstallation:string, 
    offerShipmentSite: string,
    enableSaveAttachToOffer: boolean
    

  };

  public inputFromCis: {
    attachSiloToOffer: boolean,
    recalculatedSilo: any;
    silosQuantity: number,
    originalOfferSilo: any
  }

  constructor(
    private http: Http,
    private router: Router
  ) { }


  goToCIS(params){
    
    this.inputFromOffer = params;
    this.inputFromCis = undefined;
    this.router.navigate(['/dashboard', 'cis']);
  }

  goToOffer(params){
  
    this.inputFromCis = params;

    this.router.navigate(['/dashboard', 'offers', 'edit', this.inputFromOffer.offerId, 'revision',  this.inputFromOffer.offerRevision]);
  }


  isInputFromOfferAvailable(){

    if(!_.isUndefined(this.inputFromOffer) && this.inputFromOffer.enableSaveAttachToOffer){
      return true;
    }
    return false;
  }

  isInputFromCisAvailable(){
    if(!_.isUndefined(this.inputFromCis) && this.inputFromCis.attachSiloToOffer){
      return true;
    }
    return false;
  }

  calculate(input: any): Promise<any> {

    input = this.clean(input);

    console.log("input single calculation", input);
 
    return this.http.post(endpoint + '/calculate', this.normalizeInput(input))
      .toPromise()
      .then(response => {
         return response.json() as any
        
        })
      .catch(this.handleError);
  }

  calculateWithConstitutionSelection(input: any, calculateConstitutionSelection: any){
    input = this.clean(input);

    input["constitutionSelectionRingsCalculated"] = calculateConstitutionSelection;
    input = this.normalizeInput(input);

    console.log("input check selection", input);
 
    return this.http.post(endpoint + '/calculate', input)
      .toPromise()
      .then(response => {
         return response.json() as any
        
        })
      .catch(this.handleError);
  }


  initialConstitutionSelection(input: any): Promise<any> {

    input = this.clean(input);

 
 
    return this.http.post(endpoint + '/constitution-selection', this.normalizeInput(input))
      .toPromise()
      .then(response => {
         return response.json() as any
        
        })
      .catch(this.handleError);
  }

  calculatePeakLoad(wallSheetsPerRing: any): Promise<any> {
 
    return this.http.post(endPointStoredCalculations + '/peak-load', {
      "wallSheetsPerRing": wallSheetsPerRing
    })
      .toPromise()
      .then(response => {
         return response.json() as any
        
        })
      .catch(this.handleError);
  }


  calculateProbeLoad(wallSheetsPerRing: any, wallRings: any): Promise<any> {

 
    return this.http.post(endPointStoredCalculations + '/probe-load', {
      "wallSheetsPerRing": wallSheetsPerRing,
      "wallRings": wallRings


    })
      .toPromise()
      .then(response => {
         return response.json() as any
        
        })
      .catch(this.handleError);
  }


  calculateRunner(input: any): Promise<any> {


    return this.http.post(endpoint + '/calculate/runner', this.normalizeInput(input))
      .toPromise()
      .then(response => {
         return response.json() as any
        
        })
      .catch(this.handleError);
  }

  calculatePartList(input: any){

    input = this.clean(input);
   
  
    return this.http.post(endpoint + '/part-list/excel', 
                          this.normalizeInput(input), 
                          { responseType: ResponseContentType.Blob })
                    .catch(this.handleError);
    
  }

  createCalculatedSilo(completeInput: any){
    
    let input  = completeInput.inputSentToCIS;
    let inputForCalculation = completeInput.inputForCalculation;

    const silo = {...completeInput};
    delete silo.inputForCalculation;
    delete silo.inputSentToCIS;

    input["jobId"] = silo.jobId;
    input = this.clean(input);
    
    input = {
      input
    };

    const inputObject = {
      input,
      silo,
      inputForCalculation
    }

    console.log("save silo input", inputObject);

    return this.http.post(endpointRecalculatedSilo ,inputObject)
    .toPromise()
    .then(response => {
      
       return response.json().data as any
      
      })
    .catch(this.handleError);


  }

  getSiloCalculation(siloId: number){
    return this.http.get(endpointRecalculatedSilo + '/' + siloId + '/calculation')
    .toPromise()
    .then(response => {
      
       return response.json().data as any
      
      })
    .catch(this.handleError);
  }

  downloadPartList(input: any){

    const calcInput = {
      input
 
    };

    return this.http.post(endpoint + '/part-list/excel', 
    calcInput, 
                          { responseType: ResponseContentType.Blob })
    .catch(this.handleError);
  }

  private clean(obj) {
    for (var propName in obj) { 

      if(_.isObject(obj[propName])){
            this.clean(obj[propName]);
      }

      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }

    return obj;
  }

  private handleError(error: any): Promise<any> {
 
    return Promise.reject(error.message || error);
  }


  private normalizeInput(input){
    const newInput = {
        input
    };
     
    return newInput;
  }

  private normalizeSimpleInput(simpleinput){
    const newInput = {
      simpleinput
    };
    

    return newInput;
  }

}
