import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mts',
  templateUrl: './mts.component.html',
  styleUrls: ['./mts.component.scss']
})
export class MTSComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
