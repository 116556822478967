/**
 * Created by juan on 27/06/17.
 */

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'mySimpleFilter',
  pure: false
})
export class SimpleFilter implements PipeTransform {
  transform(items: any[], filter: String, field: String): any {
    if (!items || !filter) {
      return items;
    }

    return items.filter(item => _.includes(item[field.toString()], filter));
  }
}
