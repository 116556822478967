export interface ComponentKeyValue<T = any> {
    key: string;
    value: T;
}

export type ComponentsRelation<K extends string = string, T extends ComponentKeyValue[] = ComponentKeyValue[]> = Partial<Record<K, T>>

export function generateRelations(...args: {value: ComponentKeyValue, relations: ComponentKeyValue[]}[]): ComponentsRelation {
    const result = {};

    for (const arg of args) {
        result[arg.value.key] = [];

        for (const relation of arg.relations) {
            result[arg.value.key].push(relation);
        }
    }

    return result;
}
