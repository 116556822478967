import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ConstitutionService } from './constitution.service';
import { Silo } from './silo';
import { SiloFilter } from '../../pss/offer/silo-filter';
import { MiscService } from '../../shared/misc.service';
import { UserService } from '../../mts/user/user.service';
import { User } from '../../mts/user/user';
import { LoginService } from '../../mts/login/login.service';
import { TranslateService } from '@ngx-translate/core';
import { LocaleCustomUnity } from '../../pipes/custom-locale.pipe';


import * as _ from 'lodash';
import { InfiniteScrollerDirective } from 'app/shared/infinite-scroller.directive';

@Component({
  selector: 'app-constitutions',
  templateUrl: './constitution.component.html',
  styleUrls: ['./constitution.component.scss']
})
export class ConstitutionComponent implements OnInit {
  private page;

  private state = {
    selectedCategory: null

  }

  commissionRate: number = 0;
  @ViewChild(InfiniteScrollerDirective) infiniteScrollerDirective: InfiniteScrollerDirective;
  activeParentCategory: any = {};
  activeSecondCategory: any = {};
  activeThirdCategory: any = {};
  activeForthCategory: any = {};

  activeCategoryName: string = '';

  activeSubViewSwitch: boolean = true;

  switchSiloFilterView: boolean = true;
  switchFocusFullProductListView: boolean = true;

  categoryProducts: any = [];
  loadingConstitutions: boolean = false;

  productLoadingRestOperationInCourse: boolean = false;
  loadingInfiniteProducts: boolean = false;

	silos: Array<Silo> = [];
  siloFilter: SiloFilter;
  userData: any;
  swtichSilo: boolean;
  managmentData: any = {
    kprice: 0,
    kweight: 0,
    disscount: 0,
  };

  noSilos: boolean = false;

  scrollCallback; //TODO refactor if process silo
  scrollCallbackSingle; //TODO refactor if process silo

  constructor(
  	private siloService: ConstitutionService,
    private miscService: MiscService,
    private userService: UserService,
    private loginService: LoginService,
    private translate: TranslateService,
    private localeCustomUnity: LocaleCustomUnity
  )
  { }

  ngOnInit() {
    this.getProductCategory();
    this.initManagementData();
    this.initSiloFilter();
    this.getUser();

    this.scrollCallback = this.getSilos.bind(this, false, false);
    this.scrollCallbackSingle = this.getInfiniteProducts.bind(this);
    this.swtichSilo = false;
    this.page = 1;




  }

  initManagementData(){

    this.managmentData = {
      kprice: 0,
      ksection: 0,
      kWeightAnsi: this.siloService.getKSectionWeightAnsi(),
      kWeightEuro: this.siloService.getKSectionWeightEuro(),
      discount: this.siloService.getDefaultDisscount()

    }

    this.siloService.getLastKPriceCoeffcient().then(coefficient => {

      this.managmentData.kprice = coefficient.value;
    });
  }

  initSiloFilter(){

    this.siloFilter = new SiloFilter();

  }

  compareCategories(a,b){
    if (a.category.categoryOrder < b.category.categoryOrder)
      return -1;
    if (a.category.categoryOrder > b.category.categoryOrder)
      return 1;
    return 0;
  }


  compareStoredProducts(a,b) {
    if (a.priorityOrder < b.priorityOrder)
      return -1;
    if (a.priorityOrder > b.priorityOrder)
      return 1;
    return 0;
  }

  userSiloModel: any;
  getUser() {

    this.loginService.getUser()
      .then(user =>{
          this.userData = user;

          if(this.userData.user.flagMaxDiscountDefault){
            this.managmentData.discount = this.userData.user.discount;
          }

          const today = new Date();
          this.siloService.getCategoriesSiloUserPermissionByUser(this.userData.user, today.toDateString()).then(models => {

            models.sort(this.compareCategories);

            let finalModels = [];
            for(let model of models){
              if(model.readPermission){
                finalModels.push({
                  "value": model.category.name,
                  "kprice": model.category.effectiveCoefficient
                });
              }
            }

            this.userSiloModel = finalModels;

            this.setFiltervalues();


          });

      });
  }

  setFiltervalues(){

    this.siloFilter.siloModels =  this.userSiloModel;

    if(!_.isEmpty(this.siloFilter.siloModels)){
      this.siloFilter.filter.model = this.siloFilter.siloModels[0].value;
      this.siloFilter.setDefaultData(this.siloFilter.filter.model, this.translate.currentLang);
      this.siloFilter.selectedSiloModel = this.siloFilter.getSelectedSiloModelObject();
      this.managmentData.ksection =  this.siloFilter.selectedSiloModel.kprice;
    }

    this.siloFilter.siloStandards = this.siloService.getStandards();
    this.siloFilter.stiffenersSheets  = this.siloService.getStiffenersSheets(this.siloFilter.filter.model);
    this.siloFilter.wallRings  = this.siloService.getWallRings(this.siloFilter.filter.model);
    this.siloFilter.sheetsRing  = this.siloService.getSheetsRingNoAdmin(this.siloFilter.filter.model);

    this.siloFilter.snowLoads  = this.siloService.getSnowLoads();
    this.siloFilter.seismics  = this.siloService.getSeismics();
    this.siloFilter.diameters  = this.siloService.getDiametersNotAdmin(this.siloFilter.filter.model);
    this.siloFilter.distancesStiffeners  = this.siloService.getDistances(this.siloFilter.filter.model);
   // this.siloFilter.wallSheetsGalvanizations  = this.siloService.getFinishes(this.siloFilter.filter.model, this.translate.currentLang);
    this.siloFilter.page=0;

    this.siloFilter.setDefaultData(this.siloFilter.filter.model, this.translate.currentLang);
    this.siloFilter.wallSheetsGalvanizations  = this.siloService.getFinishes(this.siloFilter.filter.model, this.translate.currentLang);

    if(this.userData.user.isAdmin){
        this.siloFilter.diameters  = this.siloService.getDiameters(this.siloFilter.filter.model);
        this.siloFilter.sheetsRing  = this.siloService.getSheetsRing(this.siloFilter.filter.model);
    }

    this.siloService.getStoredProducts().then(data => {

      this.siloFilter.storedProducts = data;
      this.siloFilter.storedProducts.sort(this.compareStoredProducts);

      this.siloFilter.filter.storedProduct = this.siloFilter.storedProducts[0].id;
      this.siloFilter.filter.productDensity = this.siloFilter.storedProducts[0].density;

    });

  }

  getSilos(fromFilter?, clear?) {

    if(!_.isNull(fromFilter) && !_.isUndefined(fromFilter) && fromFilter== true){
        this.siloFilter.updateHelperFilters();
        this.siloFilter.selectedSiloModel = this.siloFilter.getSelectedSiloModelObject();
        this.managmentData.ksection =  this.siloFilter.selectedSiloModel.kprice;
    }

    return this.siloService.getSilos(this.siloFilter.toString())

      .then((silos) => {

       this.checkSilosQuantity(silos);

        for (let silo of silos) {
           silo.selected = false;
        }


            if (clear) {
              this.silos = silos;
            } else {
              this.silos = this.silos.concat(silos);
            }

        this.loadingConstitutions = false;

    })
      .catch(error =>{
        if(error.status = 404 && this.siloFilter.isFirstPage() ){
          this.silos = [];
          this.checkSilosQuantity([]);

        }
        this.loadingConstitutions = false;


      } );
  }

  getInfiniteSilos(){


    return this.siloService.getSilos(this.siloFilter.toString())
    .then(this.processSilos)
    .catch();

  }

  getWeight(item){

      return item.weight;

  }

  getListWeight(item){

    if(item.grainLoadsInAccordanceWith == "ANSI/ASAE EP433 (1988 R2011)"){


      return this.getWeight(item)*this.managmentData.kWeightAnsi;


    }
    else if(item.grainLoadsInAccordanceWith == "EN 1991-4:2006"){

      return this.getWeight(item)*this.managmentData.kWeightEuro;
    }

  }

  getKWeight(item){

     if(item.grainLoadsInAccordanceWith == "ANSI/ASAE EP433 (1988 R2011)"){


      return this.managmentData.kWeightAnsi;


    }
    else if(item.grainLoadsInAccordanceWith == "EN 1991-4:2006"){

      return this.managmentData.kWeightEuro;
    }

  }

  private checkSilosQuantity(newSilos){
    if(newSilos.length > 0){

      this.siloFilter.incrementPage();

    }

    if(this.silos.length > 0){
      this.noSilos = false;
    }
    else{
      this.noSilos = false;
    }


  }

  private processSilos = (silos) => {
      if(!this.siloFilter.hasCapacity() && !this.siloFilter.hasVolume() ){

          for (let silo of silos) {
             silo.selected = false;
          }

          this.silos = this.silos.concat(silos);
          this.checkSilosQuantity(silos);
      }

  };


  private postProcessSilos(){

  }

   compactionChanged(value){

    this.siloFilter.filter.compaction = value/100;

  }


  // funcion del botton buscar en el listado de constitution
  searchSilo(){
    //console.log("Search");
    this.loadingConstitutions = true;
    this.siloFilter.page=0;
    this.getSilos(true, true);
    this.scrollCallback = this.getSilos.bind(this, true, false);
  }

  // Borrar todos los filtros que tiene activos
  resetSiloFilter(){
    this.initSiloFilter();
    this.setFiltervalues();
    this.scrollCallback = this.getSilos.bind(this, true, false);
    this.getSilos(true, true);
  }

  syncModel(value){

    this.siloFilter.setDefaultData(value, this.translate.currentLang);

    if(this.userData.user.isAdmin){

      this.siloFilter.diameters  = this.siloService.getDiameters(this.siloFilter.filter.model);
      this.siloFilter.sheetsRing  = this.siloService.getSheetsRing(this.siloFilter.filter.model);
    }else{
      this.siloFilter.sheetsRing  = this.siloService.getSheetsRingNoAdmin(value);
      this.siloFilter.diameters  = this.siloService.getDiametersNotAdmin(value);
    }
    this.siloFilter.stiffenersSheets  = this.siloService.getStiffenersSheets(this.siloFilter.filter.model);
    this.siloFilter.distancesStiffeners  = this.siloService.getDistances(this.siloFilter.filter.model);
    this.siloFilter.wallRings  = this.siloService.getWallRings(this.siloFilter.filter.model);

    this.siloFilter.filter.distanceBetweenStiffeners = null;
    this.siloFilter.filter.verticalStiffenersPerWallSheet = null;
    this.siloFilter.filter.diameter = null;
    this.siloFilter.filter.wallSheetsPerRing = null;
  }

  syncStoredProductDensity(value){

    let storedProduct =  this.siloFilter.storedProducts.find(x => x.id == value);
    this.siloFilter.filter.productDensity = storedProduct.density;
  }

  syncDiameter(value){

    if(!this.siloFilter.isSGSelected()){

      let sheetsRings =  this.siloService.getSheetsRingNoAdmin(this.siloFilter.filter.model);

      if(this.userData.user.isAdmin){

        sheetsRings =  this.siloService.getSheetsRing(this.siloFilter.filter.model);
      }


      let sheetsRing = this.miscService.findByMatchingProperties(sheetsRings,{"value": value})[0];

      this.siloFilter.filter.diameter = sheetsRing.valueDiameter;

    }
  }

  syncSheetsRing(value){

    if(!this.siloFilter.isSGSelected()){

      let sheetsRings =  this.siloService.getSheetsRingNoAdmin(this.siloFilter.filter.model);

      if(this.userData.user.isAdmin){

        sheetsRings =  this.siloService.getSheetsRing(this.siloFilter.filter.model);
      }

      let diameter = this.miscService.findByMatchingProperties(sheetsRings,{"valueDiameter": value})[0];


      this.siloFilter.filter.wallSheetsPerRing = diameter.value;
    }

  }


 syncDistance(value){
    let stiffenersSheets =  this.siloService.getStiffenersSheets(this.siloFilter.filter.model);

    let stiffeners = this.miscService.findByMatchingProperties(stiffenersSheets,{"value": value})[0];

    this.siloFilter.filter.distanceBetweenStiffeners = stiffeners.valueDistance;


  }

  syncStiffenersSheet(value){

    let stiffenersSheets =  this.siloService.getStiffenersSheets(this.siloFilter.filter.model);

    let distance = this.miscService.findByMatchingProperties(stiffenersSheets,{"valueDistance": value})[0];


    this.siloFilter.filter.verticalStiffenersPerWallSheet = distance.value;

  }

  syncSeism(value){

    if(value!=60){
      this.siloFilter.filter.seismicZone = 0.00;
    }

  }

  syncSnow(value){

    if(value>0){
      this.siloFilter.filter.snowLoad = 60;
    }


  }

  focusSwitchAddItems(){
    this.swtichSilo = !this.swtichSilo;
  }

  flagDiscount: boolean = false;
  managementDisscountChanged(value){

    const realValue = value/100;

    if(!this.userData.user.isAdmin){



        if(realValue > this.userData.user.discount ){

           this.managmentData.discount = this.userData.user.discount;
           this.flagDiscount = true;

        }else{
          this.managmentData.discount = value/100;
           this.flagDiscount = false;
        }
    }

    else{
      this.managmentData.discount = value/100;
    }

  }

  selectedHBSSilo: any;
  displayHBSDeatil: boolean = false;


  rowSelected(item){

   if(this.siloFilter.isHBS()){

    if(_.has( this.selectedHBSSilo ,'id'  ) && item.id != this.selectedHBSSilo.id ){
       this.disseelcteSilo(this.selectedHBSSilo);
    }


    if(  !_.has( this.selectedHBSSilo ,'id'  )  ||  item.id != this.selectedHBSSilo.id ){

       item.selected = true;
       this.selectedHBSSilo = item;
       this.displayHBSDeatil = true;

     }

   }

  }

  hideHBSDetail(){

    this.displayHBSDeatil = false;
    this.disseelcteSilo(this.selectedHBSSilo);

  }

  disseelcteSilo(selectedHBSSilo){
     const itemIdx = _.findIndex(this.silos, function(i) {

        return i.id == selectedHBSSilo.id;

    });

    try{
      this.silos[itemIdx].selected = false;
    }
    catch(error){
      console.log("error diselecting", error);
    }

  }


  getListWeightWith(weight){

    if(this.selectedHBSSilo.grainLoadsInAccordanceWith == "ANSI/ASAE EP433 (1988 R2011)"){


      return weight*this.managmentData.kWeightAnsi;


    }
    else if(this.selectedHBSSilo.grainLoadsInAccordanceWith  == "EN 1991-4:2006"){

      return weight*this.managmentData.kWeightEuro;
    }
  }

  getSiloPriceDiscounted(item){
     return (item.cost*this.managmentData.kprice)*(1-this.managmentData.discount)
  }

  switchStorageSystemView() {

    this.activeSubViewSwitch = true;
  }

  switchSingleProductView() {

    this.activeSubViewSwitch = false;
    // this.getInfiniteProducts();
  }

  isStorageSystemView() {
    return this.activeSubViewSwitch === true;
  }

  isSingleProductView() {
    return this.activeSubViewSwitch === false;
  }

  hasChilds(category) {
    if (!_.isEmpty(category.childNodes)) {
      return true;
    } else {
      return false;
    }
  }

  getChildNodes() {

    return this.activeSecondCategory.childNodes;

  }

  getActiveCategories() {

    if (_.isUndefined(this.activeSecondCategory.id)) {
      return [];
    }

    let categories = [];
    let finalCategories = [];


    if (!_.isUndefined(this.activeThirdCategory.childNodes) && !_.isNull(this.activeThirdCategory.childNodes) && !_.isEmpty(this.activeThirdCategory.childNodes)) {
      categories = this.activeThirdCategory.childNodes;
    } else if (!_.isUndefined(this.activeThirdCategory.id)) {
      categories = [this.activeThirdCategory];
    } else {
      categories = [this.activeSecondCategory];
    }


    if (_.isUndefined(categories) || _.isNull(categories)) {
      return [];
    }



    return categories;
  }

  getActiveCategory() {
    let activeCategory;

    if (!_.isUndefined(this.activeForthCategory.id) && !_.isEmpty(this.activeForthCategory)) {
      activeCategory = this.activeForthCategory;
    } else if (!_.isUndefined(this.activeThirdCategory.id) && !_.isEmpty(this.activeThirdCategory)) {
      activeCategory = this.activeThirdCategory;
    } else {
      activeCategory = this.activeSecondCategory;
    }
    return activeCategory;
  }

  swtich2dCategory(parentCategory, category) {

    this.cleanProducts();

    let lastCategory = _.cloneDeep(this.activeSecondCategory);

    this.activeSecondCategory = category;
    this.activeParentCategory = parentCategory;
    this.setActiveCategoryName();
    this.set3dCategory();
    this.set4dCategory();


    this.page = 1;

    if (!this.isRegularListing()) {

      if (!_.isUndefined(this.infiniteScrollerDirective)) {
        this.infiniteScrollerDirective.resetScroll();
      }
      this.getInfiniteProducts();

    } else {

    }


  }

  getProductMargin(product) {
    return (this.getPriceListDiscounted(product) - product.cost) / this.getPriceListDiscounted(product);
  }

  canSeeProductList() {
    return !this.userData.user.isInternalTechnical && !this.userData.user.isInternalProduction && !this.userData.user.isInternalAdministrative;
  }

  isCategoryThirdActive(category, parentCategory) {

    if (!category.active) {

      return false;
    }

    /*if(_.isUndefined( parentCategory.read ) || !parentCategory.read){
      return false;
    }*/

    return true;

  }

  isSelected3dCategory(category_id) {

    return this.activeThirdCategory.id == category_id;

  }

  isSelected4dCategory(category_id) {

    return this.activeForthCategory.id == category_id;

  }

  isCategoryActive(category) {

    if (!category.active) {

      return false;
    }

    return true;

  }

  isCategoryInUploadProcess(category) {


    let hiddenCategoriesForModel = this.siloService.getCategoriesInUploadingProcess();

    const itemIdx = _.findIndex(hiddenCategoriesForModel, function (hiddedCategoryId) {

      return hiddedCategoryId == category.id;

    });

    if (itemIdx === -1) {
      return false
    } else {

      return true;
    }
  }

  setDefaults() {

    if (this.categoryProducts.childNodes[0]) {

      this.activeParentCategory = this.categoryProducts.childNodes[0];
      this.activeSecondCategory = this.activeParentCategory.childNodes[0];
      this.activeThirdCategory = this.activeSecondCategory.childNodes[0];
      this.activeForthCategory = this.activeThirdCategory.childNodes[0];


      this.setActiveCategoryName();

      this.getInfiniteProducts();
    }

  }

  setDefaultsSingleCat() {

    if (this.categoryProducts.childNodes[0]) {

      this.siloService.getProductCategoryChilds(this.categoryProducts.childNodes[0], new Date().toISOString()).then(childNodes => {

        this.categoryProducts.childNodes[0].childNodes = childNodes;
        this.activeParentCategory = this.categoryProducts.childNodes[0];
        this.activeSecondCategory = this.activeParentCategory.childNodes[0];

        this.set3dCategory();
        this.set4dCategory();

        this.orderCategories(this.categoryProducts.childNodes);

        this.setActiveCategoryName();

        this.getInfiniteProducts();
      });

    }

  }

  getInfiniteProducts() {

    let category = this.getActiveCategory();
    if (this.loadingInfiniteProducts || this.productLoadingRestOperationInCourse || _.isUndefined(category) || _.isEmpty(category)) {
      return [];
    }


    this.loadingInfiniteProducts = true;
    return this.siloService.getProdcutsOnlyByCategory(category.id, new Date().toISOString(), this.page).then(this.postprocessProducts).catch(error => {

      this.loadingInfiniteProducts = false;
    });

  }

  set3dCategory() {
    this.activeThirdCategory = null;
    if (this.activeSecondCategory.childNodes[0]) {

      for (const category of this.activeSecondCategory.childNodes) {
        if (category.active) {
          this.activeThirdCategory = category;
          break;
        }
      }

    } else {
      this.activeThirdCategory = {};
    }
  }

  set4dCategory() {

    this.activeForthCategory = null;
    if (this.activeThirdCategory.childNodes && this.activeThirdCategory.childNodes[0]) {

      this.activeForthCategory = this.activeThirdCategory.childNodes[0];

    } else {
      this.activeForthCategory = {};


    }

  }

  setActiveCategoryName() {
    this.activeCategoryName = this.getTranslationText(this.activeParentCategory, 'name');
  }

  isSelected2dCategory(category_id) {
    return this.activeSecondCategory.id === category_id;
  }

  getTranslationText(object, property) {

    let translation = object.translations.find(x => x.locale == this.translate.currentLang);

    if (_.isNil(translation) || _.isUndefined(translation)) {
      translation = object.translations.find(x => x.locale == 'en');
    }

    return translation[property];

  }

  userCanSwitchFocusView() {
    return this.userData.user.isInternal || this.userData.user.isAdmin;
  }

  isRegularListing() {

    return false;
  }

  focusSwitchAddProductItems() {

    this.switchFocusFullProductListView = !this.switchFocusFullProductListView;
  }

  getProductCategory() {

    if (!_.isEmpty(this.categoryProducts)) {

      this.setDefaultsSingleCat();

    } else {
      this.siloService.getProductCategories(new Date().toISOString()).then(categoryProducts => {

        this.categoryProducts = categoryProducts;


        this.orderCategories(this.categoryProducts.childNodes);

        this.setDefaults();

      }).catch(error => {

      });
    }

  }

  orderCategories(categoryNodes) {

    for (let categoryNode of categoryNodes) {

      if (_.has(categoryNode, 'childNodes') && !_.isEmpty(categoryNode.childNodes)) {

        this.orderCategories(categoryNode.childNodes)
      }

    }
    if (!_.isNull(categoryNodes[0]['categoryOrder']))
      categoryNodes.sort(this.compareCategoriesSingle);
  }

  compareCategoriesSingle(a, b) {


    if (a.categoryOrder < b.categoryOrder)
      return -1;
    if (a.categoryOrder > b.categoryOrder)
      return 1;
    return 0;
  }

  private postprocessProducts = (products) => {

    if (!_.isEmpty(products[0])) {
      this.page = this.page + 1;

    }
    this.loadingInfiniteProducts = false;
    this.processSubscribeProcesInfinite(products);

  }

  processSubscribeProcesInfinite(arrayOfProducts) {

    let category = this.getActiveCategory();

    let counter = 0
    let displayedProducts = [];

    for (let product of arrayOfProducts) {

      product['quantity'] = 1;
      product['cost'] = product['cost'] * 1;

      displayedProducts.push(product);

    }


    if (!_.isUndefined(category)) {

      if (_.has(category, 'products') && !_.isEmpty(category['products'])) {

        category['products'] = category['products'].concat(displayedProducts);
      } else {

        category['products'] = displayedProducts;
      }

    }

  }

  cleanProducts() {

    let category = this.getActiveCategory();

    if (!_.isUndefined(category.products)) {
      category.products = [];

    }

  }

  isHelperTextSet(item) {

    let translation = item.translations.find(x => x.locale == this.translate.currentLang);


    if (_.isUndefined(translation) || _.isNull(translation)) {
      return false;
    }

    return !_.isNull(translation.descriptionHelperText) && !_.isUndefined(translation.descriptionHelperText) && !_.isEmpty(translation.descriptionHelperText) && translation.descriptionHelperText != ''
  }

  getPriceListDiscounted(product) {

    return this.getPriceList(product) * (1 - this.managmentData.discount);
  }

  getPriceList(product) {

    let coefficientsvalue = this.getCoefficientValue(product);

    return product.cost * coefficientsvalue * this.managmentData.kprice;
  }

  getCoefficientValue(product) {
    let coefficientsvalue = 1;

    if (this.categoryHasPriceCoefficients(this.activeSecondCategory)) {
      coefficientsvalue *= this.activeSecondCategory.effectiveCoefficient;
    } else if (this.categoryHasPriceCoefficients(this.activeParentCategory)) {
      coefficientsvalue *= this.activeParentCategory.effectiveCoefficient;
    }


    return coefficientsvalue;
  }

  categoryHasPriceCoefficients(category) {

    if (!_.isUndefined(category) && category.effectiveCoefficient != 1) {
      return true;
    } else {
      return false;
    }

  }

  getTotlMargin(product) {

    if (this.getTotalProductPrice(product) == 0) {
      return 0;
    }

    return (this.getTotalProductPrice(product) - this.getTotalCost(product)) / this.getTotalProductPrice(product);
  }

  getTotalProductPrice(product) {

    return this.getPriceListDiscounted(product) * product.quantity;
  }

  getTotalProductPriceWithCommissionRate(product) {
    return this.getTotalProductPrice(product) * (1 + this.commissionRate);
  }

  getTotalProductCommission(product) {
    return this.getTotalProductPriceWithCommissionRate(product) - this.getTotalProductPrice(product);
  }

  getTotalCost(product) {
    return product.cost * product.quantity;
  }

  getTotalProductWeight(product) {
    return this.getWeightList(product) * product.quantity;
  }

  getWeightList(product) {
    return product.weight * this.getKWeightSingle();
  }

  getKWeightSingle() {
    return 1.15;
  }

  swtich3dCategory(category) {

    this.cleanProducts();
    this.activeThirdCategory = category;


    this.set4dCategory();

    this.page = 1;

    if (!this.isRegularListing()) {

      this.getInfiniteProducts();
      if (!_.isUndefined(this.infiniteScrollerDirective)) {
        this.infiniteScrollerDirective.resetScroll();
      }

    } else {
      //this.getProducts();
    }
  }

  switch4dCategory(category) {

    this.cleanProducts();
    this.activeForthCategory = category;

    this.page = 1;

    if (!this.isRegularListing()) {


      this.getInfiniteProducts();
      if (!_.isUndefined(this.infiniteScrollerDirective)) {
        this.infiniteScrollerDirective.resetScroll();
      }
    }

  }

  switchProductsView() {

    this.cleanSelected();
    this.page = 1;


    this.getInfiniteProducts();
    if (!_.isUndefined(this.infiniteScrollerDirective)) {
      this.infiniteScrollerDirective.resetScroll();
    }

  }

  cleanSelected() {

    let category = this.getActiveCategory();

    if (!_.isUndefined(category.products)) {
      for (let product of category.products) {
        product.selected = false;
      }
    }

  }

  toggleSiloFilterView(e) {
    this.switchSiloFilterView = !this.switchSiloFilterView;
    this.siloFilter.switchBasicAdvanced(this.switchSiloFilterView);
    e.stopPropagation();
  }
}
