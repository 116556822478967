import { Component, OnInit, Input, Output, EventEmitter, Optional, Inject, ViewChild } from '@angular/core';

import {
  NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS,
} from '@angular/forms';
import { ElementBase } from '../../shared/element-base';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

const MAX_ITEMS_PER_PAGE = 6;

@Component({
  selector: 'app-year-selector',
  templateUrl: './year-selector.component.html',
  styleUrls: ['./year-selector.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: YearSelectorComponent, multi: true}
  ],
})
export class YearSelectorComponent  extends ElementBase<number> implements OnInit {
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;
  @ViewChild(NgModel) model: NgModel;
  public identifier = `form-text-${identifier++}`;
  @Output() public onYearSelected: EventEmitter<any> = new EventEmitter();

  @Input() minYear: number;
  @Input() maxYear: number;

  private _disabledRanges: {min: number, max: number}[];
  get disabledRanges(): {min: number, max: number}[] {
    return this._disabledRanges;
  }
  @Input()
  set disabledRanges(ranges: {min: number, max: number}[]) {
    this._disabledRanges = ranges;
    if (ranges.length > 0) {
      this.disabledYears = [].concat(...ranges.map(x => Array(x.max - x.min + 1).fill(null).map((_, idx) => x.min + idx)));
    }
  }
  disabledYears: number[];

  private years: number[];

  private pages: number;
  public pagesArray: number[];
  currentPage = 0;

  selectedYear: number;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
  ) {
    super(validators, asyncValidators); // ValueAccessor base
  }

  ngOnInit() {
    this.pages = Math.ceil((this.maxYear - this.minYear + 1) / MAX_ITEMS_PER_PAGE);
    this.pagesArray = Array(5).fill(0).map((x, i) => i);
    this.years = Array(this.maxYear - this.minYear + 1).fill(null).map((_, idx) => this.minYear + idx);
  }

  nextPage() {
    if (this.isNextAvailable()) {
      this.currentPage++;
    }
  }

  prevPage() {
    if (this.isPrevAvailable()) {
      this.currentPage--;
    }
  }

  isNextAvailable() {
    return this.currentPage + 1 < this.pages;
  }

  isPrevAvailable() {
    return this.currentPage > 0;
  }

  selectYear(year: number) {
    this.selectedYear = year;
    this.value = year;
    this.onYearSelected.emit(this.selectedYear);
    this.dropdown.close();
  }

  canSelectYear(year: number) {
    if (!this.disabledYears) {
      return false;
    }

    return this.disabledYears.indexOf(year) !== -1;
  }

  writeValue(value) {
    super.writeValue(value);
    this.selectYear(value);
  }
}
let identifier = 0;
