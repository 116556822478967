import * as _ from 'lodash';
import { Offer } from '../offer/offer';
import { User } from '../../mts/user/user';

export class Order {

  title: string;
  id: number;
  revision: number;
  customer: any;
  idc: string;
  user: any;
  idu: string;
  ids: string;
  stg: string;
  sts: string;
  currency: any;
  lang_id: number;
  state: number;
  status: string;
  country: any;
  cityOfInstallation: any;
  creationDate: string;
  customerDate: any;
  finalDateAvailable: any;
  jobOrder: any;
  typeOfSupply: any;
  scopeOfSupplyOverride: string;
  scopeOfSupply: string;
  currencyChangeRate: number;
  allowChangeNextState: boolean;
  price: number;
  stateChangePending: boolean;
  capacityTotal: number;
  father: any;
  fileRef: string;
  adquired: boolean;

  offerRevision: Offer;
  writtenBy: User;
  approvedBy: User;

  historyNotes: Array<any>;
  lastShipmentDates: Array<any>;

  translations: Array<any>;

  constructor() {
    this.offerRevision = new Offer();
  }





}