import * as _ from 'lodash';

export class SiloFilter {


  queryString: string;
  page: number;

  filter: {
    wallSheetsPerRing: String,
    wallRings: number,
    diameter: String,
    verticalStiffenersPerWallSheet: number,
    volume: number,
    storageCapacity: number,
    idCategoryFamily: number,
    textProduct: String,
    model: String,
    snowLoad: number,
    windPreasure: number,
    seismicZone: number,
    grainLoadsInAccordanceWith: String,
    storedProduct: number,
    productDensity: number,
    compaction: number,
    distanceBetweenStiffeners: string,
    wallSheetsGalvanization: string
    archive: string;
  


  }

  selectedSiloModel: any;

  applyedFiltersHelper:{
    model: String,
    snowLoad: number,
    seismicZone: number,
    grainLoadsInAccordanceWith: String,
    wallSheetsGalvanization: string,
  }

  capacityVolumeSwitch: boolean;
  siloStandards: any[];
  stiffenersSheets: any[];
  distancesStiffeners: any[];
  wallRings: any[];
  sheetsRing: any[];
  siloModels: any[];
  snowLoads: any[];
  seismics: any[];
  diameters: any[];
  wallSheetsGalvanizations: any[];
  storedProducts: any[];


  public constructor() {
    this.queryString = '';
    this.page=0;
    this.filter = {

      wallSheetsPerRing : null,
      wallRings: null,
      diameter : null,
      volume : null,
      storageCapacity : null,
      verticalStiffenersPerWallSheet : 2,
      idCategoryFamily: null,
      textProduct: null,
      model: "FBS",
      snowLoad: 60,
      windPreasure: 100,
      seismicZone: 0.00,
      grainLoadsInAccordanceWith: "ANSI/ASAE EP433 (1988 R2011)",
      storedProduct: null,
      productDensity: 0.75,
      compaction: 0.06,
      distanceBetweenStiffeners: "1200",
      wallSheetsGalvanization: "Galvanized",
      archive: "silos"


    };

    this.applyedFiltersHelper = {
      model: this.filter.model,
      snowLoad: this.filter.snowLoad,
      seismicZone: this.filter.seismicZone,
      grainLoadsInAccordanceWith: this.filter.grainLoadsInAccordanceWith,
      wallSheetsGalvanization: this.filter.wallSheetsGalvanization
    };


    this.capacityVolumeSwitch = false;

{
  }  }

  public switchBasicAdvanced(basic: boolean = true) {
    this.filter = {

      wallSheetsPerRing: this.filter.wallSheetsPerRing || null,
      wallRings: this.filter.wallRings || null,
      diameter: this.filter.diameter || null,
      volume: this.filter.volume || null,
      storageCapacity: this.filter.storageCapacity || null,
      verticalStiffenersPerWallSheet: this.filter.verticalStiffenersPerWallSheet || !basic ? 2 : null,

      textProduct: this.filter.textProduct || null,
      idCategoryFamily: this.filter.idCategoryFamily || null,

      model: this.filter.model || "FBS",
      snowLoad: this.filter.snowLoad || 60,
      windPreasure: this.filter.windPreasure || !basic ? 100 : null,
      seismicZone: this.filter.seismicZone || 0.00,
      grainLoadsInAccordanceWith: this.filter.grainLoadsInAccordanceWith || "ANSI/ASAE EP433 (1988 R2011)",
      storedProduct: this.filter.storedProduct || null,
      productDensity: this.filter.productDensity ||  null,
      compaction: this.filter.compaction || !basic ? 0.06 : null,
      distanceBetweenStiffeners: this.filter.distanceBetweenStiffeners || !basic ? "1200" : null,
      wallSheetsGalvanization: this.filter.wallSheetsGalvanization || !basic ? "Galvanized" : null,
      archive: this.filter.archive || "silos"

    };

    this.applyedFiltersHelper = {
      model: this.filter.model,
      snowLoad: this.filter.snowLoad,
      seismicZone: this.filter.seismicZone,
      grainLoadsInAccordanceWith: this.filter.grainLoadsInAccordanceWith,
      wallSheetsGalvanization: this.filter.wallSheetsGalvanization
    };
  }


  public getSelectedSiloModelObject(){
    const model =  this.siloModels.find(x => x.value == this.applyedFiltersHelper.model);

    return model;
  }

  public setDefaultData(model, locale){
     switch(model){
      case "DPT":
      case "DP":
        switch(locale){
          case "en":

            this.filter.wallSheetsGalvanization= "Galvanized";
            break;
          case "es":

            this.filter.wallSheetsGalvanization= "Galvanizado";
            break;
        }

        this.capacityVolumeSwitch = true;
       // this.filter.productDensity = 1;
        //change to water v
        break;
      case "HBS 45":
      case "HBS 60":
      case "FBS":
      case "TLS 45":
      case "TLS 60":
      case "TLS-S 45":
      case "TLS-S 60":
      case "TLS-S 53-80":
        switch(locale){
          case "en":

            this.filter.wallSheetsGalvanization= "Galvanized";
            break;
          case "es":

            this.filter.wallSheetsGalvanization= "Galvanizado";
            break;
        }

        this.capacityVolumeSwitch = false;
        //this.filter.productDensity = 0.75;
           //change to wheat
        break;
      case "SGC":
      case "SGL":
      case "SGC-A":
      case "SGL-A":
        switch(locale){
          case "en":

            this.filter.wallSheetsGalvanization= "Galvanized";
            break;
          case "es":

            this.filter.wallSheetsGalvanization= "Galvanizado";
            break;
        }

        this.capacityVolumeSwitch = false;

        break;
    }


  }

  isHBS(){

    switch(this.applyedFiltersHelper.model){
        case "HBS 60":
        case "HBS 45":
        case "HBS-S 45":
        case "HBS-S 60":
          return true;

    }

    return false;


  }

  isFBS(){

    switch(this.applyedFiltersHelper.model){

        case "FBS":
          return true;

    }

    return false;
  }

  isWaterTank(){

    switch(this.applyedFiltersHelper.model){

        case "DP":
        case "DPT":
          return true;

    }

    return false;
  }

  isSG(){

    switch(this.applyedFiltersHelper.model){

        case "SGC":
        case "SGL":
        case "SGC-A":
        case "SGL-A":
          return true;

    }

    return false;
  }

  isDP(){
    switch(this.applyedFiltersHelper.model){

        case "DP":
          return true;

    }

    return false;
  }

  isDPSelected(){
    switch(this.filter.model){

        case "DP":
          return true;

    }

    return false;
  }

  isWaterTankSelected(){

    switch(this.filter.model){

        case "DP":
        case "DPT":
          return true;

    }

    return false;
  }

  isSGSelected(){

    switch(this.filter.model){

        case "SGC":
        case "SGL":
        case "SGC-A":
        case "SGL-A":
          return true;

    }

    return false;
  }


  public getQueryString(){

      this.queryString="";
      let applyedFilters = { ...this.filter };
      applyedFilters = this.checkCapacityVolumeSwitch(applyedFilters);
      const exlcudedFiltersForModel = this.getFilterExclusionsForModel();

      for (const property in applyedFilters) {
        if (applyedFilters.hasOwnProperty(property) && !_.isNull(applyedFilters[property]) && !_.isNaN(applyedFilters[property])) {

          const itemIdx = _.findIndex(exlcudedFiltersForModel, function(excludedFilter) {
            return excludedFilter == property;
          });

          if(itemIdx === -1){
            this.queryString+= this.getSeparatorQueryString() + property + '=' + this.getFinalPropertyValue(applyedFilters,property);
          }

        }
      }

      this.addPagination();
      this.addArchive();


     return  this.queryString;
  }

  private getFinalPropertyValue(filters,property){
     switch(property){
       case "wallSheetsGalvanization":
         switch(filters[property]){
           case "Galvanized":
           case "Galvanizado":
             return "Z6";
           case "Prelaquered":
           case "Prelacado":
             return "PL2";
         }

     }

     return filters[property];
  }

  private getFilterExclusionsForModel(){

    switch(this.filter.model){
      case "DP":
        return ["archive","seismicZone", "grainLoadsInAccordanceWith", "productDensity", "compaction", "storedProduct", "snowLoad", "storedProduct"];
      case "DPT":
        return ["archive","seismicZone", "grainLoadsInAccordanceWith", "productDensity", "compaction", "storedProduct"];
      case "FBS":
      case "HBS 45":
      case "HBS 60":
      case "HBS-S 45":
      case "HBS-S 60":
      case "TLS 45":
      case "TLS 60":
      case "TLS-S 45":
      case "TLS-S 60":
      case "TLS-S 53-80":
        return ["archive","wallSheetsGalvanization", "storedProduct"];
      case "SGC":
      case "SGL":
      case "SGC-A":
      case "SGL-A":
        return ["archive","wallSheetsPerRing", "verticalStiffenersPerWallSheet" , "distanceBetweenStiffeners", "snowLoad", "windPreasure", "seismicZone" ,
                "grainLoadsInAccordanceWith", "storedProduct"];


    }

    return [];

  }

  private getSeparatorQueryString(){

    if(this.queryString != '' && this.queryString){
      return "&"
    }
    else{
      return "?"
    }

  }

  public toString = () : string => {

    return this.getQueryString();
  }


  public isCapacity(){

    return this.capacityVolumeSwitch == false;

  }

  public hasCapacity(){

    if(!_.isNull(this.filter.storageCapacity) && this.filter.storageCapacity>0 ){
        return true;
    }
    return false;

  }

  public hasVolume(){
   if(!_.isNull(this.filter.volume) && this.filter.volume>0 ){
        return true;
    }
    return false;
  }




  public updateHelperFilters(){

    this.applyedFiltersHelper.model = this.filter.model;
    this.applyedFiltersHelper.snowLoad = this.filter.snowLoad;
    this.applyedFiltersHelper.seismicZone = this.filter.seismicZone;
    this.applyedFiltersHelper.grainLoadsInAccordanceWith = this.filter.grainLoadsInAccordanceWith;
    this.applyedFiltersHelper.wallSheetsGalvanization = this.filter.wallSheetsGalvanization;
 }

  private checkCapacityVolumeSwitch(applyedFilters){

    const value = applyedFilters.storageCapacity;
    if(this.isCapacity()){
      applyedFilters.storageCapacity = value;
      applyedFilters.volume = null;
    }
    else{
        applyedFilters.storageCapacity = null;
        applyedFilters.volume = value;
    }


    return applyedFilters;

  }

  private addPagination(){

    this.queryString += this.getSeparatorQueryString() + "page=" + this.page;

  }

  private addArchive(){
    this.queryString = this.filter.archive  + this.queryString;
  }

  public incrementPage(){

    if( (_.isNull(this.filter.storageCapacity) || this.filter.storageCapacity<=0 ) && (_.isNull(this.filter.volume) || this.filter.volume<=0 ) ){

     this.page++;
    }
    else{
      this.page=0;
    }

  }

  public isFirstPage(){
    return this.page == 0 ? true : false;
  }
}
