import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import {User} from './user';
import 'rxjs/add/operator/toPromise';
import * as _ from 'lodash';

const endpoint = '/users';

@Injectable()
export class UserService {
  constructor(private http: Http) { }

  createUser(user: any): Promise<User> {
    return this.http.post(endpoint + '?', user)
      .toPromise()
      .then(response => response.json() as User)
      .catch(this.handleError);
  }

  getUsers(query?: string, userRole?: string, office?: string): Promise<User[]> {
    let q = query ? '?nameSurnameCompany=' + query + (userRole ? '&role=' + userRole : '') : (userRole ? '?role=' + userRole : '');
    
    if(!q && !_.isUndefined(office)){
      q +='?officeCompany=' + office;
    }

    
    return this.http.get(endpoint + q)
      .toPromise()
      .then(response => { return response.json() as User[]})
      .catch(this.handleError);
  }

  getUserById(id: number): Promise<User> {
    return this.http.get(endpoint + '/' + id)
      .toPromise()
      .then(response => response.json() as User)
      .catch(this.handleError);
  }

  getUserChilds(id: number): Promise<User[]>{
    return this.http.get(endpoint + '/' + id + '/' +'children')
      .toPromise()
      .then(response => {return response.json() as User[]})
      .catch(this.handleError);
  }

  getOfficeUsers(){

  }

  updateUser(user: any): Promise<Response> {
    return this.http.put(endpoint + '/' + user.id, user)
      .toPromise()
      .then(response => response.json())
      .catch(this.handleError);
  }

  updateEnableUser(user: any): Promise<Response> {
    return this.http.put(endpoint + '/' + user.id + '/enable', user)
      .toPromise()
      .then(response => response.json())
      .catch(this.handleError);
  }

  deleteUser(id: number): Promise<Response> {
    return this.http.delete(endpoint + '/' + id)
      .toPromise()
      .then(response => response.json())
      .catch(this.handleError);
  }

  updateSelfUserLang(lang): Promise<Response> {

    return this.http.put(endpoint + '/self/lang', lang)
      .toPromise()
      .then(response => response.json())
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {

    return Promise.reject(error.message || error);
  }
}
