import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Company} from '../company';
import {CompanyService} from '../company.service';
import {MiscService} from '../../../shared/misc.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import 'rxjs/add/operator/switchMap';
import * as _ from 'lodash';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.scss']
})
export class EditCompanyComponent implements OnInit {
  company: Company;
  notFound = false;
  @ViewChild('companyForm')
  public companyForm: NgForm;

  public companyGroups: any[];

  public companyData = {
    id: null,
    name: null,
    address: null,
    zip: null,
    cif: null,
    web: null,
    phone: null,
    group: null,
    idNavision: null
  };
  
  constructor(
    private router: Router, 
    private route: ActivatedRoute, 
    private companyService: CompanyService,
    private miscService: MiscService
  ) { 


    this.getCompanyGroups();
  }

  ngOnInit() {
    this.route.params
      .switchMap((params: Params) => this.companyService.getCompanyById(params['id']))
      .subscribe(
        company => {
        this.company = company;
        this.companyData = {...this.company};
        
        },
        error => {
          if (error.status === 404) {
            this.notFound = true;
          }
        }
      );
  }

  getCompanyGroups(): any {
    this.miscService.getCompanyGroups()
      .then(companyGroups => {
        this.companyGroups = companyGroups; 
      });
  }  
  
  byId(item1, item2) {
    
    if(item2){
      return item1.id === item2.id;
    }

    return false;
 

  }

  onSubmit() {
    this.companyService.updateCompany(this.companyData)
      .then(company => this.router.navigate(['/dashboard/companies/detail/' + this.company.id]));
  }

  compareData(): boolean {
    return _.isEqual(this.company, this.companyData);
  }

}
