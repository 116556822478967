import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {GroupService} from '../group.service';
import {Group} from '../group';
import {NgForm} from '@angular/forms';
import * as _ from 'lodash';
import {Subject} from 'rxjs/Subject';
@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.scss']
})
export class EditGroupComponent implements OnInit {
  group: Group;
  notFound = false;
  private aclHasChanged = false;

  onUpdate: Subject<any> = new Subject();

  @ViewChild('groupForm')
  public groupForm: NgForm;

  public groupData = {
    id: null,
    name: null,
    order: null
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private groupService: GroupService
  ) { }

  ngOnInit() {
    this.route.params
      .switchMap((params: Params) => this.groupService.getGroupById(params['id']))
      .subscribe(
        group => {
          this.group = group;
          this.groupData = {...this.group};
        },
        error => {
          if (error.status === 404) {
            this.notFound = true;
          }
        }
      );
  }

  onSubmit(): void {
    this.onUpdate.next(true);
    this.aclHasChanged = false;
    if (!_.isEqual(this.group, this.groupData)) {
      this.groupService.updateGroup(this.groupData as Group)
        .then(group => this.router.navigate(['/dashboard/groups']));
    }
  }

  compareData(): boolean {
     if (!_.isEqual(this.group, this.groupData) && this.aclHasChanged) {
      return true;
    } else if (!_.isEqual(this.group, this.groupData) && !this.aclHasChanged) {
      return true;
    } if (_.isEqual(this.group, this.groupData) && this.aclHasChanged) {
      return true;
    } else if (_.isEqual(this.group, this.groupData) && !this.aclHasChanged) {
       return false;
    } else {
      return false;
    }
  }

  aclChange(event) {
    this.aclHasChanged = event;
  }

}
