import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
  NgModule
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {Silo} from '../../../scs/constitution/silo';
import {OfferRevisionSilo} from '../offer-revision-silo';

import {MiscService} from '../../../shared/misc.service';
import {InfiniteScrollerDirective} from '../../../shared/infinite-scroller.directive';
import {Product} from '../../../scs/constitution/productRevision';
import {OfferService} from '../offer.service';
import {ConstitutionService} from '../../../scs/constitution/constitution.service';
import {NgForm, EmailValidator, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {CustomPipesModule} from '../../../pipes/custom-pipes.module';
import {SiloFilter} from '../silo-filter';
import {ErrorBoxService} from '../../../shared/error-box/error-box.service';
import {InfiniteScrollerModule} from '../../../shared/infinite-scroller.directive';

import 'rxjs/add/observable/forkJoin';
import {Offer} from '../offer';
import {Order} from '../../job-order/order';
import * as _ from 'lodash';
import {saveAs} from 'file-saver';

import {mergeMap, catchError} from 'rxjs/operators';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {of} from 'rxjs/observable/of';


@Component({
  selector: 'app-add-product-modal',
  templateUrl: './add-product-modal.component.html',
  styleUrls: ['./edit-offer.component.scss']
})
export class AddProductModalComponent implements OnInit {

  @ViewChild(InfiniteScrollerDirective) infiniteScrollerDirective: InfiniteScrollerDirective;

  @Input() userData;
  @Input() offer: Offer;
  @Input() order: Order;
  @Input() discount: number;
  @Input() commissionRate: number;
  @Input() disabledCodes: boolean = true;

  @Input() isSingleEquipment;
  @Input() categoryProducts;

  @Input() managmentData: any = {
    kprice: 0,
    ksection: 0,
    kweight: 0,
    disscount: 0,
  };

  @Output() emitData = new EventEmitter();

  private page;


  private state = {
    selectedCategory: null

  }


  activeSecondCategory: any = {};
  activeThirdCategory: any = {};
  activeForthCategory: any = {};

  activeCategoryName: string = '';

  //flags
  basketRestOperationInCourse: boolean = false;
  basketSpecialRestOperationInCourse: boolean = false;
  productLoadingRestOperationInCourse: boolean = false;
  loadingInfiniteProducts: boolean = false;
  processingReqLoadSilos: boolean = false;
  displayHBSDeatil: boolean = false;
  activeSubViewSwitch: boolean = true;
  switchFocusFullProductListView: boolean = true;
  switchFocusFullSiloListView: boolean = true;
  switchSiloFilterView: boolean = true;

  silos: Array<Silo> = [];
  siloFilter: SiloFilter;


  scrollCallback; //TODO refactor if process silo
  scrollCallbackSilo; //TODO refactor if process silo

  selectedHBSSilo: any;


  siloIdx: any;

  basketDescriptionLanguage: string;

  basketSelectedItems: any[] = [];

  familyToSearch: any[] = [];

  constructor(
    private router: Router,
    private miscService: MiscService,
    private siloService: ConstitutionService,
    private offerService: OfferService,
    public activeModal: NgbActiveModal,
    private translate: TranslateService,
    private errorBox: ErrorBoxService,
  ) {
    this.basketDescriptionLanguage = this.translate.currentLang;
  }

  ngOnInit(): void {

    console.log("ngOnInit");
    this.getProductCategory();
    this.initSiloFilter();

    this.scrollCallback = this.getInfiniteProducts.bind(this);
    this.page = 1;

    this.scrollCallbackSilo = this.getSilos.bind(this, false, false);

    if (this.isSingleEquipment) {
      this.activeSubViewSwitch = false;
    }


  }


  getChildNodes() {

    return this.activeSecondCategory.childNodes;

  }

  getActiveCategories() {

    if (_.isUndefined(this.activeSecondCategory.id)) {

      return [];
    }

    let categories = [];

    if (!_.isUndefined(this.activeThirdCategory.childNodes) && !_.isNull(this.activeThirdCategory.childNodes) && !_.isEmpty(this.activeThirdCategory.childNodes)) {
      categories = this.activeThirdCategory.childNodes;
    } else if (!_.isUndefined(this.activeThirdCategory.id)) {
      categories = [this.activeThirdCategory];
    } else {
      categories = [this.activeSecondCategory];
    }



    if (_.isUndefined(categories) || _.isNull(categories)) {
      return [];
    }


    return categories;
  }

  getActiveCategory() {

    let activeCategory;

    if (!_.isUndefined(this.activeForthCategory.id) && !_.isEmpty(this.activeForthCategory)) {
      activeCategory = this.activeForthCategory;
    } else if (!_.isUndefined(this.activeThirdCategory.id) && !_.isEmpty(this.activeThirdCategory)) {
      activeCategory = this.activeThirdCategory;
    } else {
      activeCategory = this.activeSecondCategory;
    }

    return activeCategory;

  }

  isQuantityRequired(silo, product) {

    if (product.category.id == 177 || product.category.id == 134) {
      return false;
    }

    if (Silo.isSG(silo) && (product.category.id == 17 || product.category.id == 33 || product.category.id == 34)) {
      return false;
    }


    return true;
  }


  /*
    * Trae arbol de familias 
  */
  getthreeActiveCategories() {

    if (_.isUndefined(this.activeSecondCategory.id)) {

      return [];
    }
    let categories = [];
    if (!_.isUndefined(this.activeThirdCategory.childNodes) && !_.isNull(this.activeThirdCategory.childNodes) && !_.isEmpty(this.activeThirdCategory.childNodes)) {
      categories = this.activeThirdCategory.childNodes;
    } else if (!_.isUndefined(this.activeThirdCategory.id)) {
      categories = [this.activeThirdCategory];
    } else {
      categories = [this.activeSecondCategory];
    }
    if (_.isUndefined(categories) || _.isNull(categories)) {
      return [];
    }
    return categories;
    
  }





  processSubscribeProcesInfinite(arrayOfProducts) {

    let category = this.getActiveCategory();

    let counter = 0
    let displayedProducts = [];

    for (let product of arrayOfProducts) {

      product['quantity'] = 1;
      product['cost'] = product['cost'] * this.offer.currencyChangeRate;

      //product['totalQuantity'] =   product['quantity'];
      displayedProducts.push(product);

    }


    if (!_.isUndefined(category)) {

      if (_.has(category, 'products') && !_.isEmpty(category['products'])) {

        category['products'] = category['products'].concat(displayedProducts);
      } else {

        category['products'] = displayedProducts;
      }

    }

  }


  private postprocessProducts = (products) => {


    if (!_.isEmpty(products[0])) {
      this.page = this.page + 1;

    }
    this.loadingInfiniteProducts = false;
    this.processSubscribeProcesInfinite(products);

  };

  // trae los productos por search description infsinite paginacion
  getInfiniteProductsBySearch(textSearch) {

    let category = this.getActiveCategory();

    if (this.loadingInfiniteProducts || this.productLoadingRestOperationInCourse || _.isUndefined(category) || _.isEmpty(category)) {
      return [];
    }
    //var diameter = parseFloat(this.siloFilter.filter.diameter);
    this.loadingInfiniteProducts = true;
    return this.siloService.getProdcutsOnlyByCategoryBySearch(category.id, this.offer.pricesDate, textSearch, this.siloFilter.filter.diameter, this.page).then(this.postprocessProducts).catch(error => {
      this.loadingInfiniteProducts = false;
    });

  }
  // fin funcion de traer los productos by search description

  getInfiniteProducts() {

    let category = this.getActiveCategory();

    if (this.loadingInfiniteProducts || this.productLoadingRestOperationInCourse || _.isUndefined(category) || _.isEmpty(category)) {
      return [];
    }

    this.loadingInfiniteProducts = true;
    return this.siloService.getProdcutsOnlyByCategory(category.id, this.offer.pricesDate, this.page).then(this.postprocessProducts).catch(error => {

      this.loadingInfiniteProducts = false;
    });

  }

  cleanSelected() {

    let category = this.getActiveCategory();

    if (!_.isUndefined(category.products)) {
      for (let product of category.products) {
        product.selected = false;
      }
    }

  }

  cleanProducts() {

    let category = this.getActiveCategory();

    if (!_.isUndefined(category.products)) {
      category.products = [];

    }

  }

  cleanBasket() {
    this.activeParentCategory['basket'] = [];
  }

  switchProductsView() {

    this.cleanSelected();
    this.page = 1;


    this.getInfiniteProducts();
    if (!_.isUndefined(this.infiniteScrollerDirective)) {
      this.infiniteScrollerDirective.resetScroll();
    }

  }

  hasChilds(category) {

    if (!_.isEmpty(category.childNodes)) {
      return true;
    } else {
      return false;
    }

  }


  getKWeight() {
    return 1.15;
  }

  getWeightList(product) {
    return product.weight * this.getKWeight();
  }

  getPriceList(product) {

    let coefficientsvalue = this.getCoefficientValue(product);

    return product.cost * coefficientsvalue * this.managmentData.kprice;
  }

  getCoefficientValue(product) {
    let coefficientsvalue = 1;

    if (this.categoryHasPriceCoefficients(this.activeSecondCategory)) {
      coefficientsvalue *= this.activeSecondCategory.effectiveCoefficient;
    } else if (this.categoryHasPriceCoefficients(this.activeParentCategory)) {
      coefficientsvalue *= this.activeParentCategory.effectiveCoefficient;
    }


    return coefficientsvalue;
  }

  categoryHasPriceCoefficients(category) {

    if (!_.isUndefined(category) && category.effectiveCoefficient != 1) {
      return true;
    } else {
      return false;
    }

  }


  getPriceListBasket(product) {

    const coefficientsvalue = this.getCoefficientValue(product);

    return product.cost * coefficientsvalue * this.managmentData.kprice;

  }

  getPriceListDiscounted(product) {

    return this.getPriceList(product) * (1 - this.discount);
  }

  getPriceListDiscountedBasket(product) {

    return this.getPriceListBasket(product) * (1 - this.discount);
  }

  getProductPriceWithCommissionRateBasket(product) {
    return this.getPriceListDiscountedBasket(product) * (1 + this.commissionRate);
  }

  getProductMargin(product) {
    return (this.getPriceListDiscounted(product) - product.cost) / this.getPriceListDiscounted(product);
  }

  getTotalProductPrice(product) {

    return this.getPriceListDiscounted(product) * product.quantity;
  }

  getTotalProductPriceWithCommissionRate(product) {
    return this.getTotalProductPrice(product) * (1 + this.commissionRate);
  }

  getTotalProductCommission(product) {
    return this.getTotalProductPriceWithCommissionRate(product) - this.getTotalProductPrice(product);
  }


  getTotalProductPriceBasket(product) {

    return this.getPriceListDiscountedBasket(product) * product.quantity;
  }

  getTotalProductPriceWithCommissionRateBasket(product) {
    return this.getTotalProductPriceBasket(product) * (1 + this.commissionRate);
  }

  getTotalProductCommissionBasket(product) {
    return this.getTotalProductPriceWithCommissionRateBasket(product) - this.getTotalProductPriceBasket(product);
  }


  getTotalProductWeight(product) {
    return this.getWeightList(product) * product.quantity;
  }

  getTotalCost(product) {
    return product.cost * product.quantity;
  }

  getTotlMargin(product) {

    if (this.getTotalProductPrice(product) == 0) {
      return 0;
    }

    return (this.getTotalProductPrice(product) - this.getTotalCost(product)) / this.getTotalProductPrice(product);
  }


  getBasket() {

    let categories = [];

    if (_.isUndefined(this.activeParentCategory.id) && this.isSingleEquipment) {
      categories = [this.categoryProducts.childNodes[0]];

    } else {
      categories = [this.activeParentCategory];
    }

    this.setBasketSavingFlagsOn();

    const getBasketProducts = category => {
      return this.offerService.getOfferProductByCategory(this.offer, category.id)
    }

    const source = of(categories);
    const basketProducts = source.pipe(mergeMap(q => forkJoin(...q.map(getBasketProducts))), catchError(error => {


      this.setBasketSavingFlagsOff();

      return of(error);
    }));
    const subscribe = basketProducts.subscribe(allBasketProucts => {

      this.setBasketSavingFlagsOff();

      for (let basketProucts of allBasketProucts) {
        for (let item of basketProucts) {
          item.selectedTranslation = this.basketDescriptionLanguage;
        }
        this.activeParentCategory['basket'] = this.activeParentCategory['basket'].concat(basketProucts);
      }

      this.activeParentCategory['basket'].sort(this.compare);

    });

  }

  getProductCategory() {

    if (!_.isEmpty(this.categoryProducts)) {

      this.setDefaultsSingleCat();
      //this.getInfiniteProducts();
    } else {
      this.siloService.getProductCategories(this.offer.pricesDate).then(categoryProducts => {

        this.categoryProducts = categoryProducts;

        this.orderCategories(this.categoryProducts.childNodes);

        this.setDefaults();

      }).catch(error => {

      });
    }

  }

  orderCategories(categoryNodes) {


    for (let categoryNode of categoryNodes) {

      if (_.has(categoryNode, 'childNodes') && !_.isEmpty(categoryNode.childNodes)) {

        this.orderCategories(categoryNode.childNodes)
      }

    }

    if (!_.isNull(categoryNodes[0]['categoryOrder']))
      categoryNodes.sort(this.compareCategories);


  }

  isCategoryActive(category) {

    if (!category.active) {

      return false;
    }

    return true;

  }

  isCategoryThirdActive(category, parentCategory) {

    if (!category.active) {

      return false;
    }

    return true;

  }

  isCategoryInUploadProcess(category) {


    let hiddenCategoriesForModel = this.siloService.getCategoriesInUploadingProcess();

    const itemIdx = _.findIndex(hiddenCategoriesForModel, function (hiddedCategoryId) {

      return hiddedCategoryId == category.id;

    });

    if (itemIdx === -1) {
      return false
    } else {

      return true;
    }
  }

  setDefaults() {

    if (this.categoryProducts.childNodes[0]) {

      this.activeParentCategory = this.categoryProducts.childNodes[0];
      this.activeSecondCategory = this.activeParentCategory.childNodes[0];
      this.activeThirdCategory = this.activeSecondCategory.childNodes[0];
      this.activeForthCategory = this.activeThirdCategory.childNodes[0];


      this.setActiveCategoryName();
      this.setCategoryBasket();
      this.getBasket();

    }

  }

  setDefaultsSingleCat() {

    if (this.categoryProducts.childNodes[0]) {

      this.siloService.getProductCategoryChilds(this.categoryProducts.childNodes[0], this.offer.pricesDate).then(childNodes => {


        this.categoryProducts.childNodes[0].childNodes = childNodes;
        this.activeParentCategory = this.categoryProducts.childNodes[0];
        this.activeSecondCategory = this.activeParentCategory.childNodes[0];

        this.set3dCategory();
        this.set4dCategory();

        this.orderCategories(this.categoryProducts.childNodes);

        this.setActiveCategoryName();
        this.setCategoryBasket();

        this.getInfiniteProducts();
        this.getBasket();
      });

    }

  }

  setActiveCategoryName() {

    this.activeCategoryName = this.getTranslationText(this.activeParentCategory, 'name');

  }

  isSelected2dCategory(category_id) {

    return this.activeSecondCategory.id == category_id;

  }

  isHelperTextSet(item) {

    let translation = item.translations.find(x => x.locale == this.translate.currentLang);


    if (_.isUndefined(translation) || _.isNull(translation)) {
      return false;
    }

    return !_.isNull(translation.descriptionHelperText) && !_.isUndefined(translation.descriptionHelperText) && !_.isEmpty(translation.descriptionHelperText) && translation.descriptionHelperText != ''
  }


  setCategoryBasket() {

    this.activeParentCategory['basket'] = [];

  }

  activeParentCategory: any = {};


  // cambio en category
  swtich2dCategory(parentCategory, category) {

    this.cleanBasket();
    this.cleanProducts();

    let lastCategory = _.cloneDeep(this.activeSecondCategory);
    this.saveActiveBasket(lastCategory);

    this.activeSecondCategory = category;
    this.activeParentCategory = parentCategory;
    this.setActiveCategoryName();
    this.set3dCategory();
    this.set4dCategory();
    this.setCategoryBasket();
    this.getBasket();


    this.page = 1;

    if (!this.isRegularListing()) {

      if (!_.isUndefined(this.infiniteScrollerDirective)) {
        this.infiniteScrollerDirective.resetScroll();
      }
      this.getInfiniteProducts();

    } else {
      //  this.getProducts();
    }


  }

  set3dCategory() {
    this.activeThirdCategory = null;
    if (this.activeSecondCategory.childNodes[0]) {

      for (const category of this.activeSecondCategory.childNodes) {
        if (category.active) {
          this.activeThirdCategory = category;
          break;
        }
      }

    } else {
      this.activeThirdCategory = {};
    }
  }

  set4dCategory() {

    this.activeForthCategory = null;
    if (this.activeThirdCategory.childNodes && this.activeThirdCategory.childNodes[0]) {

      this.activeForthCategory = this.activeThirdCategory.childNodes[0];

    } else {
      this.activeForthCategory = {};


    }


  }

  isSelected4dCategory(category_id) {

    return this.activeForthCategory.id == category_id;

  }

  isSelected3dCategory(category_id) {

    return this.activeThirdCategory.id == category_id;

  }

  swtich3dCategory(category) {

    this.cleanProducts();
    this.activeThirdCategory = category;

    this.set4dCategory();

    this.page = 1;

    if (!this.isRegularListing()) {

      this.getInfiniteProducts();
      if (!_.isUndefined(this.infiniteScrollerDirective)) {
        this.infiniteScrollerDirective.resetScroll();
      }

    } else {
      //this.getProducts();
    }


  }

  switch4dCategory(category) {

    this.cleanProducts();
    this.activeForthCategory = category;

    this.page = 1;

    if (!this.isRegularListing()) {

      this.getInfiniteProducts();
      if (!_.isUndefined(this.infiniteScrollerDirective)) {
        this.infiniteScrollerDirective.resetScroll();
      }
    } else {
      //this.getProducts();
    }

  }


  someProductChecked() {

    let category = this.getActiveCategory();


    if (_.has(category, 'products') && (!_.isUndefined(category.products) && !_.isNull(category.products))) {

      for (let product of category.products) {
        if (product.selected) {

          return true;
        }
      }
    }

    return false;
  }


  isBasketSilosEmpty() {

    if (_.isNull(this.offer.offerRevisionSilos) || _.isUndefined(this.offer.offerRevisionSilos) || _.isEmpty(this.offer.offerRevisionSilos)) {
      return true;
    } else {
      return false;
    }

  }

  getLastElementSiloOrder() {

    this.offer.offerRevisionSilos.sort(this.compare);
    return this.offer.offerRevisionSilos[this.offer.offerRevisionSilos.length - 1].priorityOrder + 1;

  }


  isBasketEmpty() {

    if (_.isNull(this.activeParentCategory.basket) || _.isUndefined(this.activeParentCategory.basket) || _.isEmpty(this.activeParentCategory.basket)) {
      return true;
    } else {
      return false;
    }

  }

  getLastElementOrder() {

    return this.activeParentCategory.basket[this.activeParentCategory.basket.length - 1].order + 1;

  }

  compare(a, b) {
    if (a.order < b.order)
      return -1;
    if (a.order > b.order)
      return 1;
    return 0;
  }

  compareStoredProducts(a,b) {
    if (a.priorityOrder < b.priorityOrder)
      return -1;
    if (a.priorityOrder > b.priorityOrder)
      return 1;
    return 0;
  }

  compareCategories(a, b) {


    if (a.categoryOrder < b.categoryOrder)
      return -1;
    if (a.categoryOrder > b.categoryOrder)
      return 1;
    return 0;
  }

  orderCategoriesSilo(a, b) {
    if (a.category.categoryOrder < b.category.categoryOrder)
      return -1;
    if (a.category.categoryOrder > b.category.categoryOrder)
      return 1;
    return 0;
  }

  addSelectedProducts() {

    let order = this.isBasketEmpty() ? 0 : this.getLastElementOrder();

    let category = this.getActiveCategory();


    if (!_.isUndefined(category.products) && !_.isNull(category.products)) {


      this.offerService.createOfferSection(this.offer, this.activeParentCategory).then(offerSection => {


        for (let product of category.products) {

          if (product.selected) {


            product.selected = !product.selected;
            product = this.serializeProduct(product);
            product.order = order;


            this.offerService.createOfferProduct(this.offer, product).then(offerProduct => {

              offerProduct.selectedTranslation = this.basketDescriptionLanguage;
              this.activeParentCategory['basket'].push(_.cloneDeep(offerProduct));
              this.activeParentCategory['basket'].sort(this.compare);

              this.errorBox.success('Product added successfully.');

            }).catch(error => {

            });

            order++;

          }

        }

      });

    }


  }

  savebasketRestOperationInCourse: boolean = false;

  setBasketSavingFlags() {
    this.basketRestOperationInCourse = !this.basketRestOperationInCourse;
    this.savebasketRestOperationInCourse = !this.savebasketRestOperationInCourse;
  }

  setBasketSavingFlagsOn() {
    this.basketRestOperationInCourse = true;
    this.savebasketRestOperationInCourse = true;
  }


  setBasketSavingFlagsOff() {
    this.basketRestOperationInCourse = false;
    this.savebasketRestOperationInCourse = false;
  }

  isBasketOperationInCourse() {
    return this.basketRestOperationInCourse || this.savebasketRestOperationInCourse
  }

  saveActiveBasket(category?) {

    //this.setBasketSavingFlags();
    let basket = this.activeParentCategory['basket'];

    if (!_.isUndefined(category)) {
      basket = category.basket;
    }

    if (!_.isEmpty(basket)) {
      this.setBasketSavingFlags()

      const editBasketProducts = product => {

        product = this.serializeProductBasket(product);

        return this.offerService.editOfferProduct(this.offer, product)
      }


      const source = of(basket);
      const editedProducts = source.pipe(mergeMap(q => forkJoin(...q.map(editBasketProducts))), catchError(error => {

        return of(error);
      }));
      const subscribe = editedProducts.subscribe(val => {

        this.setBasketSavingFlags();



      });
    }


  }

  serializeProductBasket(productOriginal) {

    let product = _.cloneDeep(productOriginal);

    product.totalPrice = this.getTotalProductPriceBasket(product);
    product.price = this.getPriceListDiscountedBasket(product);
    product.priceList = this.getPriceListBasket(product);
    product.totalWeight = this.getTotalProductWeight(product);
    product.category = this.getOfferProductCategory(product);
    product.section = this.getOfferProductSection(product);
    product.totalQuantity = product.quantity

    delete product.offerRevisionSilo;
    delete product.newTranslations;
    delete product.priceList;
    delete product.currentLocale;
    delete product.creationDate;
    delete product.deletedAt;
    delete product.offerRevision;
    delete product.defaultLocale;
    delete product.offerRevisionCategory;

    return product;

  }


  serializeProduct(product) {

    let offerRevisionProduct = {

      'aggregationCode': product.aggregationCode,
      'idNav': product.idNav,
      'category': this.getOfferProductCategory(product),
      'section': this.getOfferProductSection(product),
      'weight': product.weight,
      'cost': product.cost,
      'special': product.special,
      'optional': product.optional,
      'quantity': product.quantity,
      'totalQuantity': product.quantity,
      'totalPrice': this.getTotalProductPrice(product),
      'totalWeight': this.getTotalProductWeight(product),
      'price': this.getPriceListDiscounted(product),
      'priceList': this.getPriceList(product),
      'order': 1,
      'hidePrice': product.hidePrice,
      'hideWeight': product.hideWeight,
      'translations': []

    };

    offerRevisionProduct = this.setOfferRevisionProductTranslations(offerRevisionProduct, product);

    return offerRevisionProduct;

  }

  setOfferRevisionProductTranslations(offerRevisionProduct, product) {

    let locales = ['en', 'es', 'de', 'ru'];

    locales.forEach(singleLocale => {
      if (_.isNil(this.getTranslation(product, singleLocale)) || _.isUndefined(this.getTranslation(product, singleLocale))) {
        product['translations'].push({
          descriptionText: '',
          descriptionHelperText: '',
          locale: singleLocale
        });
      }

    });


    product.translations.forEach(translation => {
      offerRevisionProduct['translations'].push({
        descriptionText: translation.descriptionText,
        locale: translation.locale
      })
    });

    return offerRevisionProduct;
  }

  getTranslation(object, localeWanted) {

    let translation = object.translations.find(x => x.locale == localeWanted);

    if (_.isNil(translation) || _.isUndefined(translation)) {
      object['translations'].push({
        descriptionText: '',
        descriptionHelperText: '',
        locale: localeWanted
      });

      return this.getTranslation(object, localeWanted);
    }

    return translation;

  }


  private getOfferProductCategory(product) {

    return product.category ? {id: product.category.id} : {id: this.activeSecondCategory.id};

  }

  private getOfferProductSection(product) {

    return this.activeParentCategory.id;

  }

  getTranslationText(object, property) {

    let translation = object.translations.find(x => x.locale == this.translate.currentLang);

    if (_.isNil(translation) || _.isUndefined(translation)) {
      translation = object.translations.find(x => x.locale == 'en');
    }

    return translation[property];

  }

  getTranslationPosition(item) {

    let translationObject = item.translations.find(x => x.locale == item.selectedTranslation);

    let position = item.translations.indexOf(translationObject);

    //fallback
    if (position <= -1) {
      translationObject = item.translations.find(x => x.locale == 'en');
      position = item.translations.indexOf(translationObject);
    }

    return position;

  }


  selectProduct(item) {

    if (!_.isNull(item.selected) && !_.isUndefined(item.selected)) {

      item.selected = !item.selected;
    } else {
      item.selected = true;
    }


  }

  getSelectedCategory() {
    return this.state.selectedCategory;
  }

  setSelectedCategroy(category) {
    this.state.selectedCategory = category;
  }


  getEmptyProduct() {

    return {
      aggregationCode: 'SPEC',
      calculatedQuantity: 0,
      selected: false,
      optional: false,
      order: null,
      cost: 0,
      quantity: 1,
      special: true,
      weight: 0,
      hisePrice: false,
      translations: [

        {
          descriptionText: '',
          locale: 'en'
        },

        {
          descriptionText: '',
          locale: 'es'
        },
        {
          descriptionText: '',
          locale: 'de'
        },
        {
          descriptionText: '',
          locale: 'ru'
        }
      ]
    };
  }

  getOriginalProduct(product_code): Promise<any> {


    return this.siloService.getProductBy(product_code, this.offer.pricesDate).then(product => {

      return product;

    }).catch(error => {
      return null;
    });
  }

  trackProductChange(product) {

    if (product.aggregationCode == 'SPEC') {
      return;
    }


    let original = this.getOriginalProduct(product.aggregationCode).then(original => {

      if (!_.isNull(original)) {

        if (this.userData.user.isInternal) {

          if (isNaN(product.postCost)) {

            product.cost = original.cost;
          }

        }

        if (!_.isNull(original) && !_.isUndefined(original) &&
          (!_.isEqual(original.translations[0].descriptionText.trim(), product.translations[0].descriptionText.trim()) ||
            !_.isEqual(original.translations[1].descriptionText.trim(), product.translations[1].descriptionText.trim()) ||
            original.cost.toFixed(2) != product.cost.toFixed(2) ||
            original.weight.toFixed(1) != product.weight.toFixed(1)
          )
        ) {


          product.special = true;
          product.aggregationCode = 'SPEC';


        } else {
          product.special = false;
          product.aggregationCode = original.aggregationCode;

        }

      }

    }).catch(error => {

    });


  }

  trackProductChangePrice(product) {

    if (this.userData.user.isInternal) {

      if (product.postCost > 0) {
        product.cost = product.postCost;
      }

    }

    this.trackProductChange(product);

  }

  changeBasketDescriptionLanguage() {
    this.activeParentCategory.basket.map((x) => x.selectedTranslation = this.basketDescriptionLanguage);
  }

  onSubmit() {


  }


  closeModal() {

    this.activeModal.close();
  }


  stopPropagation(event) {
    event.stopPropagation();
  }


  moveItemUp(itemIdx) {
    if (itemIdx >= 1) {
      let self = this.activeParentCategory.basket[itemIdx];
      let previous = this.activeParentCategory.basket[itemIdx - 1];

      const self_order = self.order;
      const previous_order = previous.order;


      this.activeParentCategory.basket[itemIdx] = previous;
      this.activeParentCategory.basket[itemIdx - 1] = self;

      this.activeParentCategory.basket[itemIdx].order = self_order;
      this.activeParentCategory.basket[itemIdx - 1].order = previous_order;
    }
  }

  moveItemDown(itemIdx) {
    if (itemIdx < this.activeParentCategory.basket.length - 1) {
      let self = this.activeParentCategory.basket[itemIdx];
      let next = this.activeParentCategory.basket[itemIdx + 1]

      const self_order = self.order;
      const next_order = next.order;


      this.activeParentCategory.basket[itemIdx] = next;
      this.activeParentCategory.basket[itemIdx + 1] = self;


      this.activeParentCategory.basket[itemIdx].order = self_order;
      this.activeParentCategory.basket[itemIdx + 1].order = next_order;
    }
  }


  removeItem(item) {

    this.offerService.deleteOfferProduct(this.offer, item.id).then(response => {


      const itemIdx = this.activeParentCategory.basket.indexOf(item);
      this.activeParentCategory.basket.splice(itemIdx, 1);
      this.activeParentCategory['basket'].sort(this.compare);

      let order = 0;
      for (let product of this.activeParentCategory['basket']) {
        product.order = order;
        order++;
      }

    }).catch(error => {

    })

  }


  hidePriceItem(item) {

    item.hidePrice = !item.hidePrice;

  }

  hideWeight(item) {
    item.hideWeight = !item.hideWeight;
  }

  duplicateItem(item) {


    let order = this.isBasketEmpty() ? 0 : this.getLastElementOrder();

    this.setBasketSavingFlags();

    let product = _.cloneDeep(item);

    product = this.serializeProduct(product);
    product.order = order;

    this.offerService.createOfferProduct(this.offer, product).then(offerProduct => {

      this.setBasketSavingFlags();
      this.activeParentCategory['basket'].push(_.cloneDeep(offerProduct));
      this.activeParentCategory['basket'].sort(this.compare);


    }).catch(error => {
      this.setBasketSavingFlags();
    });


  }


  createEmptySpec() {

    if (this.basketRestOperationInCourse || this.basketSpecialRestOperationInCourse) {
      return;
    }

    let order = this.isBasketEmpty() ? 0 : this.getLastElementOrder();

    let product = this.serializeProduct(this.getEmptyProduct());
    product.order = order;

    this.basketRestOperationInCourse = true;
    this.basketSpecialRestOperationInCourse = true;

    this.offerService.createOfferSection(this.offer, this.activeParentCategory).then(offerSection => {

      this.offerService.createOfferProduct(this.offer, product).then(offerProduct => {
        offerProduct.selectedTranslation = this.basketDescriptionLanguage;
        this.activeParentCategory['basket'].push(_.cloneDeep(offerProduct));
        this.activeParentCategory['basket'].sort(this.compare);

        this.basketRestOperationInCourse = false;
        this.basketSpecialRestOperationInCourse = false;

      }).catch(error => {
        this.basketRestOperationInCourse = false;
        this.basketSpecialRestOperationInCourse = false;
      });

    });

  }

  increaseQuantity(product) {

    product.quantity++;
  }

  decreaseQuantity(product) {
    if (product.quantity > 1) {
      product.quantity--;
    }
  }


  focusSwitchAddProductItems() {

    this.switchFocusFullProductListView = !this.switchFocusFullProductListView;
  }

  focusSwitchAddSiloItems() {

    this.switchFocusFullSiloListView = !this.switchFocusFullSiloListView;
  }

  isRegularListing() {

    return false;
  }


  userCanSwitchFocusView() {
    return this.userData.user.isInternal || this.userData.user.isAdmin;
  }

  userCanEditBasketWeightAndCost() {
    return this.userData.user.isInternal || this.userData.user.isAdmin;
  }

  userCanEditBasketDescription(item) {
    return this.userData.user.isInternal || this.userData.user.isAdmin || (this.userData.user.isExternal && item.special && !this.isCatlwakStiffener(item));
  }

  isCatlwakStiffener(item) {
    let itemEnTransaltion = this.getTranslation(item, 'en');

    if (itemEnTransaltion.descriptionText == 'Catwalk stiffeners reinforcement') {
      return true;
    }

    return false;
  }

  userCanSeeDirectCost() {
    return this.userData.user.isAdmin;
  }


       ///|\\\
      /*     *\
     /*       *\
    /*         *\
   /*           *\
  /*             *\
  /***************/
  /*             */
  /***************/
  /***************/
  /*             */
  /***************/
  /***************/
  /*             */
  /***************/
  /*             */
  /*    silos    */
  /*             */
  /***************/
  /*             */
  /***************/
  /***************/
  /*             */
  /***************/
  /***************/
  /*             */
  /***************/

  userSiloModels: any;

  initSiloFilter() {

    this.siloFilter = new SiloFilter();

    this.siloService.getCategoriesSiloUserPermissionByUser(this.userData.user, this.offer.pricesDate).then(models => {

      models.sort(this.orderCategoriesSilo);

      let finalModels = [];
      for (let model of models) {
        if (model.readPermission) {
          finalModels.push({
            'value': model.category.name,
            'kprice': model.category.effectiveCoefficient
          });
        }
      }

      this.userSiloModels = finalModels;


      this.setSiloFiltervalues();


    }).catch(error => {

    });

  }


  setSiloFiltervalues() {

    this.siloFilter.siloModels = this.userSiloModels;

    if (!_.isEmpty(this.siloFilter.siloModels)) {
      this.siloFilter.filter.model = this.siloFilter.siloModels[0].value;
      this.siloFilter.setDefaultData(this.siloFilter.filter.model, this.translate.currentLang);
      this.siloFilter.selectedSiloModel = this.siloFilter.getSelectedSiloModelObject();
      this.managmentData.ksection = this.siloFilter.selectedSiloModel.kprice;
    }

    this.siloFilter.siloStandards = this.siloService.getStandards();
    this.siloFilter.stiffenersSheets = this.siloService.getStiffenersSheets(this.siloFilter.filter.model);
    this.siloFilter.wallRings = this.siloService.getWallRings(this.siloFilter.filter.model);
    this.siloFilter.sheetsRing = this.siloService.getSheetsRingNoAdmin(this.siloFilter.filter.model);

    this.siloFilter.snowLoads = this.siloService.getSnowLoads();
    this.siloFilter.seismics = this.siloService.getSeismics();
    this.siloFilter.diameters = this.siloService.getDiametersNotAdmin(this.siloFilter.filter.model);
    this.siloFilter.distancesStiffeners = this.siloService.getDistances(this.siloFilter.filter.model);
    //this.siloFilter.wallSheetsGalvanizations  = this.siloService.getFinishes(this.siloFilter.filter.model, this.translate.currentLang);
    this.siloFilter.page = 0;

    this.siloFilter.setDefaultData(this.siloFilter.filter.model, this.translate.currentLang);
    this.siloFilter.wallSheetsGalvanizations = this.siloService.getFinishes(this.siloFilter.filter.model, this.translate.currentLang);

    if (this.userData.user.isAdmin) {
      this.siloFilter.diameters = this.siloService.getDiameters(this.siloFilter.filter.model);
      this.siloFilter.sheetsRing = this.siloService.getSheetsRing(this.siloFilter.filter.model);
    }

    this.siloService.getStoredProducts().then(data => {

      this.siloFilter.storedProducts = data;
      this.siloFilter.storedProducts.sort(this.compareStoredProducts);

      this.siloFilter.filter.storedProduct = this.siloFilter.storedProducts[0].id;
      this.siloFilter.filter.productDensity = this.siloFilter.storedProducts[0].density;

    });

  }


  syncModel(value) {

    this.siloFilter.setDefaultData(value, this.translate.currentLang);

    if (this.userData.user.isAdmin) {

      this.siloFilter.diameters = this.siloService.getDiameters(this.siloFilter.filter.model);
      this.siloFilter.sheetsRing = this.siloService.getSheetsRing(this.siloFilter.filter.model);
    } else {
      this.siloFilter.sheetsRing = this.siloService.getSheetsRingNoAdmin(value);
      this.siloFilter.diameters = this.siloService.getDiametersNotAdmin(value);
    }


    this.siloFilter.stiffenersSheets = this.siloService.getStiffenersSheets(this.siloFilter.filter.model);
    this.siloFilter.distancesStiffeners = this.siloService.getDistances(this.siloFilter.filter.model);
    this.siloFilter.wallRings = this.siloService.getWallRings(this.siloFilter.filter.model);

    this.siloFilter.filter.distanceBetweenStiffeners = null;
    this.siloFilter.filter.verticalStiffenersPerWallSheet = null;
    this.siloFilter.filter.diameter = null;
    this.siloFilter.filter.wallSheetsPerRing = null;
  }

  syncStoredProductDensity(value){

    let storedProduct =  this.siloFilter.storedProducts.find(x => x.id == value);
    this.siloFilter.filter.productDensity = storedProduct.density;
  }


  moistureContentChanged(value) {

    this.offer.offerRevisionSilos[this.siloIdx].moistureContent = value / 100;

  }

  compactionChanged(value) {

    this.siloFilter.filter.compaction = value / 100;

  }


  syncDiameter(value) {

    if (this.siloFilter.isSGSelected()) {
      return;
    }

    let sheetsRings = this.siloService.getSheetsRingNoAdmin(this.siloFilter.filter.model);

    if (this.userData.user.isAdmin) {
      sheetsRings = this.siloService.getSheetsRing(this.siloFilter.filter.model);
    }


    let sheetsRing = this.miscService.findByMatchingProperties(sheetsRings, {'value': value})[0];

    this.siloFilter.filter.diameter = sheetsRing.valueDiameter;


  }

  syncSheetsRing(value) {

    if (this.siloFilter.isSGSelected()) {
      return;
    }

    let sheetsRings = this.siloService.getSheetsRingNoAdmin(this.siloFilter.filter.model);

    if (this.userData.user.isAdmin) {
      sheetsRings = this.siloService.getSheetsRing(this.siloFilter.filter.model);
    }

    let diameter = this.miscService.findByMatchingProperties(sheetsRings, {'valueDiameter': value})[0];


    this.siloFilter.filter.wallSheetsPerRing = diameter.value;

  }


  syncDistance(value) {
    let stiffenersSheets = this.siloService.getStiffenersSheets(this.siloFilter.filter.model);

    let stiffeners = this.miscService.findByMatchingProperties(stiffenersSheets, {'value': value})[0];

    this.siloFilter.filter.distanceBetweenStiffeners = stiffeners.valueDistance;


  }

  syncStiffenersSheet(value) {

    let stiffenersSheets = this.siloService.getStiffenersSheets(this.siloFilter.filter.model);

    let distance = this.miscService.findByMatchingProperties(stiffenersSheets, {'valueDistance': value})[0];


    this.siloFilter.filter.verticalStiffenersPerWallSheet = distance.value;

  }

  syncSeism(value) {

    if (value != 60) {
      this.siloFilter.filter.seismicZone = 0.00;
    }

  }

  syncSnow(value) {

    if (value > 0) {
      this.siloFilter.filter.snowLoad = 60;
    }


  }


  // Busqueda por texto de los productos 
  searchProductsSilo() {

    console.log("Entra buscador de productos by text");
    //console.log(this.siloFilter.filter.textProduct, this.siloFilter.filter.idCategoryFamily);
    //console.log(this.categoryProducts);

    // clean table // clean products
    this.cleanBasket();
    this.cleanProducts();
    this.page = 1;

    for (let category of this.categoryProducts.childNodes) {
        if(category.id == this.siloFilter.filter.idCategoryFamily) {
            //console.log(category); // familia a buscar
            this.familyToSearch = category.childNodes;
        }
    }

    let category = this.getActiveCategory();
    console.log(category.id); // diametro 
    console.log("Diametro de silo " + this.siloFilter.filter.diameter + " double ");

    var ddd = this.getInfiniteProductsBySearch(this.siloFilter.filter.textProduct);
    console.log(ddd);

    // console.log(this.familyToSearch);
    // this.set3dCategory(); categoria activa
    // this.siloFilter.page = 0;
    // this.getSilos(true, true);
    // this.scrollCallback = this.getSilos.bind(this, true, false);

  }
  // acaba function searchProductsSilo

  searchSilo() {

    this.siloFilter.page = 0;
    this.getSilos(true, true);
    this.scrollCallback = this.getSilos.bind(this, true, false);
  }

  resetSiloFilter() {
    this.initSiloFilter();
    this.setSiloFiltervalues();
    this.scrollCallback = this.getSilos.bind(this, true, false);
    this.getSilos(true, true);
  }

  getSilos(fromFilter?, clear?) {

    if (!_.isNull(fromFilter) && !_.isUndefined(fromFilter) && fromFilter == true) {
      this.siloFilter.updateHelperFilters();
      this.siloFilter.selectedSiloModel = this.siloFilter.getSelectedSiloModelObject();
      this.managmentData.ksection = this.siloFilter.selectedSiloModel.kprice;
    }

    return this.siloService.getSilos(this.siloFilter.toString(), this.offer.pricesDate)
    .then((silos) => {

      for (let silo of silos) {

        silo['cost'] = silo['cost'] * this.offer.currencyChangeRate;
        silo['quantity'] = 1;
        silo['storageCapacity'] = silo['volume'] * this.siloFilter.filter.productDensity;
        silo['compactedCapacity'] = silo['volume'] * this.siloFilter.filter.productDensity * (1 + this.siloFilter.filter.compaction);
        silo['storedProductDensity'] = this.siloFilter.filter.productDensity;
        silo['compaction'] = this.siloFilter.filter.compaction;
        silo['weight'] = this.getWeight(silo);
        silo['listWeight'] = this.getListWeight(silo);
        silo['priceList'] = this.getSiloPriceList(silo);
        silo['price'] = this.getSiloPriceDiscounted(silo);
        silo['selected'] = false;
      }
      //this.silos = silos;

      if (silos.length > 0) {
        this.siloFilter.incrementPage();
      }

      if (clear) {
        this.silos = silos;
      } else {
        this.silos = this.silos.concat(silos);
      }


    })
    .catch(error => {
      if(error.status = 404 && this.siloFilter.isFirstPage() ){
        this.silos = [];
      }
    });
  }

  getInfiniteSilos() {

    return this.siloService.getSilos(this.siloFilter.toString(), this.offer.pricesDate)
    .then(this.processSilos)
    .catch();

    //return new Promise(() => {});


  }

  private processSilos = (silos) => {

    if (!this.siloFilter.hasCapacity() && !this.siloFilter.hasVolume()) {


      for (let silo of silos) {


        silo['cost'] = silo['cost'] * this.offer.currencyChangeRate;
        silo['quantity'] = 1;
        silo['storageCapacity'] = silo['volume'] * this.siloFilter.filter.productDensity;
        silo['compactedCapacity'] = silo['volume'] * this.siloFilter.filter.productDensity * (1 + this.siloFilter.filter.compaction);
        silo['storedProductDensity'] = this.siloFilter.filter.productDensity;
        silo['compaction'] = this.siloFilter.filter.compaction;
        silo['weight'] = this.getWeight(silo);
        silo['listWeight'] = this.getListWeight(silo);
        silo['priceList'] = this.getSiloPriceList(silo);
        silo['price'] = this.getSiloPriceDiscounted(silo);
        silo['selected'] = false;

      }
      //this.silos = silos;

      if (silos.length > 0) {
        this.siloFilter.incrementPage();
      }

      this.silos = this.silos.concat(silos);
    }

  };

  getWeight(item) {

      return item.weight;

  }

  getListWeight(item) {

    if (item.grainLoadsInAccordanceWith == 'ANSI/ASAE EP433 (1988 R2011)') {

      return this.getWeight(item) * this.managmentData.kWeightAnsi;
    } else if (item.grainLoadsInAccordanceWith == 'EN 1991-4:2006') {

      return this.getWeight(item) * this.managmentData.kWeightEuro;
    }

  }

  getListWeightWith(weight) {

    if (this.selectedHBSSilo.grainLoadsInAccordanceWith == 'ANSI/ASAE EP433 (1988 R2011)') {


      return weight * this.managmentData.kWeightAnsi;


    } else if (this.selectedHBSSilo.grainLoadsInAccordanceWith == 'EN 1991-4:2006') {

      return weight * this.managmentData.kWeightEuro;
    }

  }

  getKWeightSilo(item) {

    if (item.grainLoadsInAccordanceWith == 'ANSI/ASAE EP433 (1988 R2011)') {


      return this.managmentData.kWeightAnsi;


    } else if (item.grainLoadsInAccordanceWith == 'EN 1991-4:2006') {

      return this.managmentData.kWeightEuro;
    }

  }


  getSiloPriceDiscounted(item) {


    return this.getSiloPriceList(item) * (1 - this.discount);
  }

  getSiloPriceList(item) {
    return (item.cost * this.managmentData.kprice * this.managmentData.ksection);
  }

  rowSelected(item) {

    if (this.siloFilter.isHBS()) {

      if (_.has(this.selectedHBSSilo, 'id') && item.id != this.selectedHBSSilo.id) {
        this.disseelcteSilo(this.selectedHBSSilo);
      }


      if (!_.has(this.selectedHBSSilo, 'id') || item.id != this.selectedHBSSilo.id) {

        item.selected = true;
        this.selectedHBSSilo = item;
        this.displayHBSDeatil = true;

      }

    }

  }

  hideHBSDetail() {

    this.displayHBSDeatil = false;
    this.disseelcteSilo(this.selectedHBSSilo);

  }

  disseelcteSilo(selectedHBSSilo) {
    const itemIdx = _.findIndex(this.silos, function (i) {

      return i.id == selectedHBSSilo.id;

    });

    try{
      this.silos[itemIdx].selected = false;
    }
    catch(error){
      console.log("error diselecting", error);
    }


  }

  addItem(item) {

    if (this.allowToPlay()) {

      let order = this.isBasketSilosEmpty() ? 0 : this.getLastElementSiloOrder();

      item.quantity = item.quantity || 1;
      item.price = this.getSiloPriceDiscounted(item);
      item.priceList = this.getSiloPriceList(item);

      let offerSilo = this.initOfferRevisionSiloFromSilo(item);
      offerSilo.priorityOrder = order;
      offerSilo.hidePrice = true;
      offerSilo.hideWeight = true;

      this.processingReqLoadSilos = true;


      this.offerService.createOfferSilo(this.offer, this.seralizeOfferRevisionSilo(offerSilo)).then(offerRevisionSilo => {

        this.processingReqLoadSilos = false;


        if (!this.offer.offerRevisionSilos) {
          this.offer.offerRevisionSilos = [];
        }

        console.log("created offer silo", offerRevisionSilo);
        if(offerRevisionSilo.isRecalculated){
          console.log("is recalculated");
          offerRevisionSilo['silo'] = offerRevisionSilo.recalculatedSilo;
          offerRevisionSilo.recalculatedSilo = null;
        }

        this.offer.offerRevisionSilos.push(this.unserializeOfferSilo(offerRevisionSilo));


        this.offer.offerRevisionSilos.sort(this.compare);

        this.emitData.next();



      })
      .catch(error => {

        this.processingReqLoadSilos = false;
        this.errorBox.error('Error, try again.');

        console.log("error", error);

        //this.offer.offerRevisionSilos.splice(itemIdx, 1);
      });
    }

  }

  initOfferRevisionSiloFromSilo(silo, restore?, originalOfferRevisionSilo?) {

    let offerRevisionSilo: OfferRevisionSilo;

    const storedProduct =  this.siloFilter.storedProducts.find(x => x.id == this.siloFilter.filter.storedProduct);
    const translationStoredProduct = storedProduct.translations.find(x => x.locale == 'en');
    const translationStoredProductES = storedProduct.translations.find(x => x.locale == 'es');
    const translationStoredProductDE = storedProduct.translations.find(x => x.locale == 'de');
    const translationStoredProductRU = storedProduct.translations.find(x => x.locale == 'ru');




    offerRevisionSilo = {

      silo: {id: silo.id},
      quantity: silo.quantity,
      model: silo.model,
      completeModel: silo.completeModel,
      price: silo.price,
      priceList: silo.priceList,
      weight: silo.weight,
      cost: silo.cost,
      volume: silo.volume,
      description: silo.description,
      storedProductDensity: silo.storedProductDensity,
      snowLoad: silo.snowLoad,
      fillEmptyCiclesPerYear: silo.fillEmptyCiclesPerYear,
      dischargeMode: silo.dischargeMode,
      wallSheetCorrugation: 104,
      boltsGrade: silo.boltsGrade,
      grainLoadsInAccordanceWith: silo.grainLoadsInAccordanceWith,
      kSection: this.managmentData.ksection,
      translations: [
        {
          locale: 'en',
          verticalHorizontalJointSealingText: this.siloService.getJointSealing('en'),
          boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(silo.boltsGalvanizationMethod, 'en'),
          typeOfFoundationText: this.siloService.getTypeOfFundation('Flat', 'en'),
          storedProductText: translationStoredProduct.name,
          dischargeModeText: this.siloService.getDischargeMode(silo.dischargeMode, 'en')
        },
        {
          locale: 'es',
          verticalHorizontalJointSealingText: this.siloService.getJointSealing('es'),
          boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(silo.boltsGalvanizationMethod, 'es'),
          typeOfFoundationText: this.siloService.getTypeOfFundation('Flat', 'es'),
          storedProductText: translationStoredProductES.name,
          dischargeModeText: this.siloService.getDischargeMode(silo.dischargeMode, 'es')
        },
        {
          locale: 'de',
          verticalHorizontalJointSealingText: this.siloService.getJointSealing('de'),
          boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(silo.boltsGalvanizationMethod, 'de'),
          typeOfFoundationText: this.siloService.getTypeOfFundation('Flat', 'de'),
          storedProductText: translationStoredProductDE.name,
          dischargeModeText: this.siloService.getDischargeMode(silo.dischargeMode, 'de')
        },
        {
          locale: 'ru',
          verticalHorizontalJointSealingText: this.siloService.getJointSealing('ru'),
          boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(silo.boltsGalvanizationMethod, 'ru'),
          typeOfFoundationText: this.siloService.getTypeOfFundation('Flat', 'ru'),
          storedProductText: translationStoredProductRU.name,
          dischargeModeText: this.siloService.getDischargeMode(silo.dischargeMode, 'ru')
        }
      ]

    };


    if(silo.configuration == "CALCULATED"){
      console.log("calculated silo");
      delete offerRevisionSilo.silo;

      offerRevisionSilo["recalculatedSilo"] = {id: silo.id};
    }

    if (!_.isNull(restore) && !_.isUndefined(restore) && restore) {

      offerRevisionSilo.id = originalOfferRevisionSilo.id;
      offerRevisionSilo.model = originalOfferRevisionSilo.model;
      offerRevisionSilo.cost = originalOfferRevisionSilo.cost;
      offerRevisionSilo.weight = originalOfferRevisionSilo.weight;
      offerRevisionSilo.price = originalOfferRevisionSilo.price;
      offerRevisionSilo.priceList = originalOfferRevisionSilo.priceList;
      offerRevisionSilo.quantity = originalOfferRevisionSilo.quantity;
      offerRevisionSilo.description = originalOfferRevisionSilo.description;

    }

    if (!Silo.isWTT(silo)) {


      offerRevisionSilo.designDensity = this.siloService.decimalCastingReverse(silo.designDensity);
      offerRevisionSilo.seismicZone = this.siloService.decimalCastingReverse(silo.seismicZone);
      offerRevisionSilo.seismicCode = silo.seismicCode;

      offerRevisionSilo.designWindVelocity = silo.designWindVelocity;
      offerRevisionSilo.moistureContent = silo.moistureContent / 100;
      offerRevisionSilo.loadingRatedCapacity = 0;
      offerRevisionSilo.unloadingRatedCapacity = 0;
      offerRevisionSilo.distanceBetweenStiffeners = silo.distanceBetweenStiffeners;
      offerRevisionSilo.wallSheetsGradeSteel = silo.wallSheetsGradeSteel;
      offerRevisionSilo.roofGradeSteel = silo.roofGradeSteel;
      offerRevisionSilo.verticalStiffenersGradeSteel = silo.verticalStiffenersGradeSteel;
      offerRevisionSilo.roofGalvanisationPainting = silo.roofGalvanisationPainting;
      offerRevisionSilo.verticalStiffenersGalvanizationPainting = silo.verticalStiffenersGalvanizationPainting;
      offerRevisionSilo.wallSheetsGalvanizationPainting = silo.wallSheetsGalvanizationPainting;
      offerRevisionSilo.hopper = '';
      offerRevisionSilo.capacity = silo.storageCapacity;
      offerRevisionSilo.compaction = silo.compaction;
      offerRevisionSilo.compactedCapacity = silo.compactedCapacity;

    } else {

      offerRevisionSilo.generalGradeSteel = silo.wallSheetsGradeSteel;
      offerRevisionSilo.generalGalvanisationPainting = silo.wallSheetsGalvanizationPainting;
      offerRevisionSilo.capacity = silo.volume;
    }

    if (Silo.isFBS(silo)) {

      offerRevisionSilo.heightToEave = silo.heightToEave;
      offerRevisionSilo.heightOverall = silo.heightOverall;
      offerRevisionSilo.volume = silo.volume;
    }

    return offerRevisionSilo;

  }

  seralizeOfferRevisionSilo(offerRevisionSilo) {

    if (offerRevisionSilo.designDensity) {

      offerRevisionSilo.designDensity = this.siloService.decimalCasting(offerRevisionSilo.designDensity.toString());

    }

    if (offerRevisionSilo.seismicZone) {
      offerRevisionSilo.seismicZone = this.siloService.decimalCasting(offerRevisionSilo.seismicZone.toString());
    }


    delete offerRevisionSilo.offerRevisionSiloProducts;
    delete offerRevisionSilo.newTranslations;
    delete offerRevisionSilo.currentLocale;
    delete offerRevisionSilo.defaultLocale;

    offerRevisionSilo = this.checkOfferSiloTranslations(offerRevisionSilo);

    return offerRevisionSilo;

  }


  unserializeOfferSilo(offerRevisionSilo) {

    if (offerRevisionSilo.designDensity) {

      offerRevisionSilo.designDensity = this.siloService.decimalCastingReverse(offerRevisionSilo.designDensity);

    }

    if (offerRevisionSilo.seismicZone) {
      offerRevisionSilo.seismicZone = this.siloService.decimalCastingReverse(offerRevisionSilo.seismicZone);
    }

    return offerRevisionSilo;

  }

  checkOfferSiloTranslations(offerRevisionSilo) {

    if (_.isEmpty(offerRevisionSilo.translations)) {

      offerRevisionSilo.translations = [
        {
          locale: 'en',
          verticalHorizontalJointSealingText: this.siloService.getJointSealing('en'),
          boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(offerRevisionSilo.boltsGalvanizationMethod, 'en')
        },
        {
          locale: 'es',
          verticalHorizontalJointSealingText: this.siloService.getJointSealing('es'),
          boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(offerRevisionSilo.boltsGalvanizationMethod, 'es')

        },
        {
          locale: 'de',
          verticalHorizontalJointSealingText: this.siloService.getJointSealing('de'),
          boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(offerRevisionSilo.boltsGalvanizationMethod, 'de')

        },
        {
          locale: 'ru',
          verticalHorizontalJointSealingText: this.siloService.getJointSealing('de'),
          boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(offerRevisionSilo.boltsGalvanizationMethod, 'ru')

        },


      ]

    }

    return offerRevisionSilo;

  }

  switchStorageSystemView() {

    this.activeSubViewSwitch = true;
  }

  switchSingleProductView() {
    //
    console.log("Vista productos simples");
    this.activeSubViewSwitch = false;
    this.getInfiniteProducts();
    //  this.getBasket();
  }

  isStorageSystemView() {
    return this.activeSubViewSwitch === true;
  }

  isSingleProductView() {
    return this.activeSubViewSwitch === false;
  }

  syncQyuantity(product) {

    product.totalQuantity = product.quantity;
  }


  allowToPlay() {
    return this.userCanEditEquipmentOffer();
  }

  userCanEditEquipmentOffer() {

    let canEdit = false;

    for (let role of this.userData.user.roles) {
      if (this.offer.state === 0) {
        if (((role === 'EXTERNAL_AGENT' || role === 'EXTERNAL_ADMIN') && this.offer.stateChangePending != true) || role === 'INTERNAL_AGENT' || role === 'INTERNAL_ADMIN') {
          canEdit = true;
        }
      } else if (this.offer.state === 1) {
        if (role === 'INTERNAL_AGENT' || role === 'INTERNAL_ADMIN') {
          canEdit = true;
        }
      } else if (this.offer.state === 2) {
        if (role === 'INTERNAL_ADMIN') {
          canEdit = true;
        }
      }
    }

    if (canEdit) {
      if (this.offerFreezged()) {
        canEdit = false;
      }
    }

    return canEdit;
  }

  offerFreezged() {
    return this.offer.freezeTarget == true;
  }

  userCanEditCodes() {

    return (!this.disabledCodes && (this.userData.user.isInternalTechnical || this.userData.user.isAdmin));
  }

  canSeeProductList() {
    return !this.userData.user.isInternalTechnical && !this.userData.user.isInternalProduction && !this.userData.user.isInternalAdministrative;
  }

  toggleSiloFilterView(e) {
    this.switchSiloFilterView = !this.switchSiloFilterView;
    this.siloFilter.switchBasicAdvanced(this.switchSiloFilterView);
    this.setSiloFiltervalues()
    e.stopPropagation();
  }

  selectItem(idx) {
    if (this.basketSelectedItems.indexOf(idx) !== -1) {
      this.basketSelectedItems.splice(this.basketSelectedItems.indexOf(idx), 1)
    } else {
      this.basketSelectedItems.push(idx);
    }
  }

  selectAllBasket() {
    if (this.basketSelectedItems.length > 0) {
      this.basketSelectedItems = [];
      return;
    }

    for (const item of this.activeParentCategory.basket) {
      this.selectItem(item);
    }
  }

  basketRemoveSelected() {
    this.offerService.deleteOfferProductBulk(this.offer, this.basketSelectedItems.map(item => item.id)).then(response => {

      for (const item of this.basketSelectedItems) {
        const itemIdx = this.activeParentCategory.basket.indexOf(item);
        this.activeParentCategory.basket.splice(itemIdx, 1);
        this.activeParentCategory['basket'].sort(this.compare);
      }

      let order = 0;
      for (let product of this.activeParentCategory['basket']) {
        product.order = order;
        order++;
      }

      this.basketSelectedItems = [];
    }).catch(error => {

    })
  }

  basketRemoveAll() {
    this.offerService.deleteOfferProductBulk(this.offer, this.activeParentCategory.basket.map(item => item.id)).then(response => {


      for (const item of this.activeParentCategory.basket) {
        const itemIdx = this.activeParentCategory.basket.indexOf(item);
        this.activeParentCategory.basket.splice(itemIdx, 1);
        this.activeParentCategory['basket'].sort(this.compare);
      }

      let order = 0;
      for (let product of this.activeParentCategory['basket']) {
        product.order = order;
        order++;
      }

    }).catch(error => {

    })
  }

}

@NgModule({
  declarations: [AddProductModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    CurrencyMaskModule,
    CustomPipesModule,
    NgbModule,
    InfiniteScrollerModule
  ],
  exports: [
    AddProductModalComponent
  ],
  providers: [

    TranslateService
  ]
})

export class AddProductModalModule {
}
