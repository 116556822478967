/**
 * Created by juan on 23/06/17.
 */

import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';

import { TileCasePipe } from './tile-case.pipe';
import { LineJumpPipe } from './line-jump.pipe';
import {SimpleFilter} from './simple-filter.pipe';
import {OfferAdvancedFilter} from './offer-advanced-filter';
import {LocaleCustomFormat, LocaleCustomUnity} from './custom-locale.pipe';
import {SafeHtml} from './sanitizer-html.pipe';
import { EnumToArray } from './enum-to-array.pipe';
import { MapToArray } from './map-to-array.pipe';

@NgModule({
  declarations: [
    TileCasePipe,
    SimpleFilter,
    OfferAdvancedFilter,
    LineJumpPipe,
    LocaleCustomFormat,
    LocaleCustomUnity,
    SafeHtml,
    EnumToArray,
    MapToArray
  ],
  imports: [CommonModule],
  exports: [
    TileCasePipe,
    SimpleFilter,
    OfferAdvancedFilter,
    LineJumpPipe,
    LocaleCustomFormat,
    LocaleCustomUnity,
    SafeHtml,
    EnumToArray,
    MapToArray
  ]
})

export class CustomPipesModule {}
