import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import * as StackTrace from 'stacktrace-js';

import { ErrorBoxService } from './error-box/error-box.service';

@Injectable()
export class AppErrorHandler {

	constructor(private injector: Injector) {
	}

	handleError(error: any) {
		const errorBoxService = this.injector.get(ErrorBoxService);
		const location = this.injector.get(LocationStrategy);

		const message = error.message ? error.message : error.toString();

		if (error instanceof Error) {
			StackTrace.fromError(error).then(stackFrames => {
			
				const stackString = stackFrames
					.splice(0, 20)
					.map(sf => {
						return sf.toString();
					})
					.join('<br>');

					errorBoxService.error(message + '<br>' + stackString)
			}); 
		} else if (error.status >= 500 && error.status <= 599) {
		
		}

		throw error;
	}
}