import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'enumToArray'
})
export class EnumToArray implements PipeTransform {
    transform(data: Object): Object {
        return Object.keys(data).map(function(key) {
            return data[key];
        });
    }
}
