/**
 * Created by juan on 23/06/17.
 */

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'tileCase'})
export class TileCasePipe implements PipeTransform {
  public transform(input: string): string {
    if (!input) {
      return '';
    } else {
      return input.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
    }
  }
}
