import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { LocaleCustomUnity } from '../../pipes/custom-locale.pipe';
import { StatisticsService } from './statistics.service';
import { StatisticsFilter } from './statistics-filter';
import { UserService } from '../user/user.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Report } from './report';
import { BaseChartDirective } from 'ng2-charts';
import { saveAs } from 'file-saver';
import { MiscService } from '../../shared/misc.service';
import { CityService } from '../../shared/city.service';
import { CompanyService } from '../../mts/company/company.service';

import * as _ from 'lodash';

const data = {
  2018: [
    {offers: {count: 35, value: 293877.6}, revisedOffers: {count: 1, value: 0}, orders: {count: 34, value: 2357620.72}},
    {offers: {count: 38, value: 4692347.01}, revisedOffers: {count: 0, value: 0}, orders: {count: 35, value: 161820.33}},
    {offers: {count: 14, value: 2593881.9}, revisedOffers: {count: 1, value: 18178.15}, orders: {count: 16, value: 423963}},
    {offers: {count: 58, value: 1103724.39}, revisedOffers: {count: 0, value: 0}, orders: {count: 28, value: 558520}},
    {offers: {count: 46, value: 1316685.73}, revisedOffers: {count: 2, value: -1215}, orders: {count: 47, value: 977682.72}},
    {offers: {count: 50, value: 3357324.33}, revisedOffers: {count: 0, value: 0}, orders: {count: 31, value: 381056.15}},
    {offers: {count: 39, value: 13380242.62}, revisedOffers: {count: 4, value: 13329.87}, orders: {count: 28, value: 4126874.94}},
    {offers: {count: 23, value: 1721135.04}, revisedOffers: {count: 3, value: -7375.37}, orders: {count: 13, value: 197449}},
    {offers: {count: 22, value: 4433075.37}, revisedOffers: {count: 4, value: -2384.24}, orders: {count: 31, value: 144221.8}},
    {offers: {count: 40, value: 12582851.11}, revisedOffers: {count: 6, value: 544654.75}, orders: {count: 39, value: 1188973.2}},
    {offers: {count: 40, value: 15529760.08}, revisedOffers: {count: 4, value: 95378.7200000001}, orders: {count: 25, value: 3821063.19}},
    {offers: {count: 24, value: 8910198.08}, revisedOffers: {count: 8, value: 230535.89}, orders: {count: 14, value: 328096}},
  ],
  2019: [
    {offers: {count: 45, value: 6398268.83}, revisedOffers: {count: 24, value: 849918.96}, orders: {count: 18, value: 467496.73}},
    {offers: {count: 30, value: 1635196.35}, revisedOffers: {count: 11, value: 399652.15}, orders: {count: 13, value: 153308.7}},
    {offers: {count: 0, value: 0}, revisedOffers: {count: 0, value: 0}, orders: {count: 0, value: 0}},
    {offers: {count: 0, value: 0}, revisedOffers: {count: 0, value: 0}, orders: {count: 0, value: 0}},
    {offers: {count: 0, value: 0}, revisedOffers: {count: 0, value: 0}, orders: {count: 0, value: 0}},
    {offers: {count: 0, value: 0}, revisedOffers: {count: 0, value: 0}, orders: {count: 0, value: 0}},
    {offers: {count: 0, value: 0}, revisedOffers: {count: 0, value: 0}, orders: {count: 0, value: 0}},
    {offers: {count: 0, value: 0}, revisedOffers: {count: 0, value: 0}, orders: {count: 0, value: 0}},
    {offers: {count: 0, value: 0}, revisedOffers: {count: 0, value: 0}, orders: {count: 0, value: 0}},
    {offers: {count: 0, value: 0}, revisedOffers: {count: 0, value: 0}, orders: {count: 0, value: 0}},
    {offers: {count: 0, value: 0}, revisedOffers: {count: 0, value: 0}, orders: {count: 0, value: 0}},
    {offers: {count: 0, value: 0}, revisedOffers: {count: 0, value: 0}, orders: {count: 0, value: 0}},
  ]
};

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  months = [
    'Shared.Months.January',
    'Shared.Months.February',
    'Shared.Months.March',
    'Shared.Months.April',
    'Shared.Months.May',
    'Shared.Months.June',
    'Shared.Months.July',
    'Shared.Months.August',
    'Shared.Months.September',
    'Shared.Months.October',
    'Shared.Months.November',
    'Shared.Months.December'
  ];

  report: any;

  currentMonth = new Date().getMonth();

  totals: any;
  partials: any;
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;
  countChart: any;
  countChartLabels: any;
  valueChart: any;

  switchStatisticView = true;
  minYear: number;
  maxYear: number;



  disabledRanges = {
    minYear: {min: 0, max: 0},
    maxYear: {min: 0, max: 0}
  }

  loadingReport = false;
  downloadingFile = false;

  internalUsers: any[];
  typesOfSupply: any[];
  companyGroups: any[];
  statisticsFilter: StatisticsFilter;


  constructor(private localeUnity: LocaleCustomUnity,
              private statisticsService: StatisticsService,
              private translate: TranslateService,
              private userService: UserService,
              private miscService: MiscService,
              private cityService: CityService,
              private companyService: CompanyService
            )
  {
    this.maxYear = new Date().getFullYear();
    this.minYear = this.maxYear - 1;
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateCharts();
    });

    this.statisticsFilter =  new StatisticsFilter();
    this.getInternalUsers();
    this.getTypesOfSupply();
    this.getCompanyGroups();
  }

  getInternalUsers(){

    this.userService.getUsers(null,null,"1")
         .then(users => {
           this.internalUsers = users;

         });

 }

  doCalculations() {
    this.totals = this.calculateTotals();
    this.partials = this.calculatePartials();
  }

  setUpCharts() {
    const totalOffersByMonth = this.report.yearTwo.map(month => {
      return this.calculateTotalOffer({offers: month.offers, revisedOffers: month.revisedOffers});
    });

    const totalOrdersByMonth = this.report.yearTwo.map(month => {
      return this.calculateTotalOffer({offers: month.orders, revisedOffers: month.revisedOrders});
    });

    const self = this;
    this.countChart = {
      options: {
        scaleShowVerticalLines: false,
        responsive: true,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        title: {
            display: true,
            text: self.translate.instant('MTS.statistics.n_offers_order') + ' ' + this.maxYear
        },
        legend: {
          position: 'bottom'
        }
      },
      labels: this.months.map(month => self.translate.instant(month)),
      type: 'bar',
      legend: true,
      colors: [{ // dark grey
        backgroundColor: '#00a8e0',
        borderColor: '#00a8e0',
      },
      { // grey
        backgroundColor: '#ff322e',
        borderColor: '#ff322e',
      }],
      data: [
        {
          data: totalOffersByMonth.map(item => item.count),
          label: self.translate.instant('MTS.statistics._offers')
        },
        {
          data: totalOrdersByMonth.map(item => item.count),
          label: self.translate.instant('MTS.statistics._orders')
        }
      ]
    };
    this.valueChart = {
      options: {
        scaleShowVerticalLines: false,
        responsive: true,
        title: {
            display: true,
            text: self.translate.instant('MTS.statistics.value_offers_order') + ' ' + this.maxYear
        },
        scales: {
          yAxes: [{
            ticks: {
              callback: function(value) {
                return self.localeUnity.transform(value, 'currency');
              }
            }
          }]
        },
        legend: {
          position: 'bottom'
        },
        tooltips: {
          callbacks: {
            label: function(value, datasets) {
              return datasets.datasets[value.datasetIndex].label + ': ' + self.localeUnity.transform(value.yLabel, 'currency');
            }
          }
        }
      },
      labels: this.months.map(month => self.translate.instant(month)),
      type: 'bar',
      legend: true,
      colors: [{ // dark grey
        backgroundColor: '#00a8e0',
        borderColor: '#00a8e0',
      },
      { // grey
        backgroundColor: '#ff322e',
        borderColor: '#ff322e',
      }],
      data: [
        {
          data: totalOffersByMonth.map(item => item.value),
          label: self.translate.instant('MTS.statistics._offers')
        },
        {
          data: totalOrdersByMonth.map(item => item.value),
          label: self.translate.instant('MTS.statistics._orders')
        }
      ]
    };
  }

  updateCharts() {
    if (!this.report) { return; }
    this.setUpCharts();
    this.charts.first.chart.options.title.text = this.countChart.options.title.text;
    this.charts.first.chart.config.data.labels.length = 0;
    this.charts.first.chart.config.data.labels.push(...this.countChart.labels);

    this.charts.last.chart.options.title.text = this.valueChart.options.title.text;
    this.charts.last.chart.config.data.labels.length = 0;
    this.charts.last.chart.config.data.labels.push(...this.valueChart.labels);

    this.charts.first.chart.update();
    this.charts.last.chart.update();
  }

  getReport() {
    this.loadingReport = true;
    this.statisticsService.getOffersOrdersReportByYears([this.minYear, this.maxYear], this.statisticsFilter.getFilter())
      .then(_data => {

        this.report = new Report();
        this.report.yearOne = _data[this.minYear];
        this.report.yearTwo = _data[this.maxYear];
        //this.report.yearOne = data[2018];
        //this.report.yearTwo = data[2019];
        this.calculateMonths();
        this.doCalculations();
        this.setUpCharts();
        this.loadingReport = false;
      });
  }

  resetStatistics(){

    this.statisticsFilter.initFilterObject();
    this.getReport();
  }

  calculateOffersTotal(month) {
    const result: {count: any, value: any} = {count: '-', value: '-'};

    if (month.offers && month.revisedOffers) {
      if (month.offers.count && month.revisedOffers.count) {
        result.count = month.offers.count + month.revisedOffers.count;
      }

      if (month.offers.value && month.revisedOffers.value) {
        result.value = month.offers.value + month.revisedOffers.value;
      }
    }

    return result;
  }

  calculatePercentages(month) {
    const result: {count: any, value: any} = {count: '-', value: '-'};
    const offersTotal = this.calculateOffersTotal(month);

    if (month.orders && offersTotal) {
      if (month.orders.count && this.isNumber(offersTotal.count)) {
        result.count = month.orders.count / offersTotal.count;
      }

      if (month.orders.value && this.isNumber(offersTotal.value)) {
        result.value = month.orders.value / offersTotal.value;
      }
    }

    return result;
  }

  calculateOffersYearComparison(month1, month2) {
    const result: {count: any, value: any} = {count: '-', value: '-'};


    return result;
  }

  calculateOrdersYearComparison(month1, month2) {
    const result: {count: any, value: any} = {count: '-', value: '-'};

    return result;
  }

  calculateSum(params: {count: any, value: any}[]) {
    const result: {count: any, value: any} = {count: '-', value: '-'};

    for (const param of params) {
      if (this.isNumber(param.count)) {
        if (result.count === '-') {
          result.count = 0;
        }
        result.count += param.count;
      }

      if (this.isNumber(param.value)) {
        if (result.value === '-') {
          result.value = 0;
        }
        result.value += param.value;
      }
    }

    return result;
  }

  calculateTotalOffer(...params: {offers: {count: any, value: any},
     revisedOffers: {count: any, value: any}}[]) {
    const result: {count: any, value: any} = {count: '-', value: '-'};

    params.forEach((param, i) => {
      if (param.offers && param.revisedOffers) {
        if (this.isNumber(param.offers.count) && this.isNumber(param.revisedOffers.count)) {
          if (result.count === '-') {
            result.count = 0;
          }
          result.count += (param.offers.count + param.revisedOffers.count);
        }

        if (this.isNumber(param.offers.value) && this.isNumber(param.revisedOffers.value)) {
          if (result.value === '-') {
            result.value = 0;
          }
          result.value += (param.offers.value + param.revisedOffers.value);
        }
      }
    });

    return result;
  }

  calculateOrderOfferPercents(params: {totalOffers: {count: any, value: any}, orders: {count: any, value: any}}) {
    const result: {count: any, value: any} = {count: '-', value: '-'};

    if (params.totalOffers && params.orders) {
      if (this.isNumber(params.totalOffers.count) && this.isNumber(params.orders.count)) {
        if (result.count === '-') {
          result.count = 0;
        }
        result.count += (params.orders.count / params.totalOffers.count);
      }

      if (this.isNumber(params.totalOffers.value) && this.isNumber(params.orders.value)) {
        if (result.value === '-') {
          result.value = 0;
        }
        result.value += (params.orders.value / params.totalOffers.value);
      }
    }

    if (isNaN(result.value) || result.value === Infinity) {
      result.value = '-';
    }

    if (isNaN(result.count) || result.count === Infinity) {
      result.count = '-';
    }

    return result;
  }

  calculatePercents(yearOne: {count: any, value: any}, yearTwo: {count: any, value: any}) {
    const result: {count: any, value: any} = {count: '-', value: '-'};

    if (yearOne && yearTwo) {
      if (this.isNumber(yearOne.count) && this.isNumber(yearTwo.count)) {
        result.count = (yearTwo.count - yearOne.count) / yearOne.count;
      }

      if (this.isNumber(yearOne.value) && this.isNumber(yearTwo.value)) {
        result.value = (yearTwo.value - yearOne.value) / yearOne.value;
      }
    }
    if (isNaN(result.value) || result.value === Infinity || result.value === -1) {
      result.value = '-';
    }

    if (isNaN(result.count) || result.count === Infinity || result.count === -1) {
      result.count = '-';
    }

    return result;
  }

  calculateMonths() {
    for (let i = 0; i < 12; i++) {
      // TOTAL OFFER CALCULATION
      this.report.yearOne[i].totalOffers =
        this.calculateTotalOffer({offers: this.report.yearOne[i].offers, revisedOffers: this.report.yearOne[i].revisedOffers});
      this.report.yearTwo[i].totalOffers =
        this.calculateTotalOffer({offers: this.report.yearTwo[i].offers, revisedOffers: this.report.yearTwo[i].revisedOffers});

      // TOTAL ORDER CALCULATION
      this.report.yearOne[i].totalOrders =
        this.calculateTotalOffer({offers: this.report.yearOne[i].orders, revisedOffers: this.report.yearOne[i].revisedOrders});
      this.report.yearTwo[i].totalOrders =
        this.calculateTotalOffer({offers: this.report.yearTwo[i].orders, revisedOffers: this.report.yearTwo[i].revisedOrders});

      // MONTH OR/OF %
      this.report.yearOne[i].orderOfferPercent =
        this.calculateOrderOfferPercents({totalOffers: this.report.yearOne[i].totalOffers, orders: this.report.yearOne[i].totalOrders})
      this.report.yearTwo[i].orderOfferPercent =
        this.calculateOrderOfferPercents({totalOffers: this.report.yearTwo[i].totalOffers, orders: this.report.yearTwo[i].totalOrders})

      // YEAR COMPARISON
      this.report.comparison.push({
        offers: this.calculatePercents(this.report.yearOne[i].totalOffers, this.report.yearTwo[i].totalOffers),
        orders: this.calculatePercents(this.report.yearOne[i].totalOrders, this.report.yearTwo[i].totalOrders)
      });
    }
  }

  calculatePartials() {
    const result: {
      yearOne: {
        offers: {count: any, value: any},
        revisedOffers: {count: any, value: any},
        orders: {count: any, value: any},
        revisedOrders: {count: any, value: any},
        totalOrders: {count: any, value: any},
        totalOffers: {count: any, value: any},
        percents: {count: any, value: any}
      },
      yearTwo: {
        offers: {count: any, value: any},
        revisedOffers: {count: any, value: any},
        orders: {count: any, value: any},
        revisedOrders: {count: any, value: any},
        totalOrders: {count: any, value: any},
        totalOffers: {count: any, value: any},
        percents: {count: any, value: any}
      },
      yearComparison: {
        offers: {count: any, value: any},
        orders: {count: any, value: any}
      }
    } = {
      yearOne: {
      offers: {count: '-', value: '-'},
      revisedOffers: {count: '-', value: '-'},
      orders: {count: '-', value: '-'},
      revisedOrders: {count: '-', value: '-'},
      totalOrders: {count: '-', value: '-'},
      totalOffers: {count: '-', value: '-'},
      percents: {count: '-', value: '-'}
      },
      yearTwo: {
        offers: {count: '-', value: '-'},
        revisedOffers: {count: '-', value: '-'},
        orders: {count: '-', value: '-'},
        revisedOrders: {count: '-', value: '-'},
        totalOrders: {count: '-', value: '-'},
        totalOffers: {count: '-', value: '-'},
        percents: {count: '-', value: '-'}
      },
      yearComparison: {
        offers: {count: '-', value: '-'},
        orders: {count: '-', value: '-'}
      }
    };

    const yearOneSum = {
      offers: [],
      revisedOffers: [],
      orders: [],
      revisedOrders: []
    };
    let i = 0;
    for (const month of this.report.yearOne) {
      if (i > this.currentMonth) {
        break;
      }
      yearOneSum.offers.push(month.offers);
      yearOneSum.revisedOffers.push(month.revisedOffers);
      yearOneSum.orders.push(month.orders);
      yearOneSum.revisedOrders.push(month.revisedOrders);
      i++;
    }
    result.yearOne.offers = this.calculateSum(yearOneSum.offers);
    result.yearOne.revisedOffers = this.calculateSum(yearOneSum.revisedOffers);
    result.yearOne.orders = this.calculateSum(yearOneSum.orders);
    result.yearOne.revisedOrders = this.calculateSum(yearOneSum.revisedOrders);
    result.yearOne.totalOffers = this.calculateTotalOffer(
      ...(yearOneSum.offers.map((offer, idx) => {return {offers: offer, revisedOffers: yearOneSum.revisedOffers[idx]}})));
    result.yearOne.totalOrders = this.calculateTotalOffer(
      ...(yearOneSum.orders.map((order, idx) => {return {offers: order, revisedOffers: yearOneSum.revisedOrders[idx]}})));
    result.yearOne.percents =
      this.calculateOrderOfferPercents({totalOffers: result.yearOne.totalOffers, orders: result.yearOne.totalOrders});

    const yearTwoSum = {
      offers: [],
      revisedOffers: [],
      orders: [],
      revisedOrders: []
    };
    i = 0;
    for (const month of this.report.yearTwo) {
      if (i > this.currentMonth) {
        break;
      }
      yearTwoSum.offers.push(month.offers);
      yearTwoSum.revisedOffers.push(month.revisedOffers);
      yearTwoSum.orders.push(month.orders);
      yearTwoSum.revisedOrders.push(month.revisedOrders);
      i++;
    }

    result.yearTwo.offers = this.calculateSum(yearTwoSum.offers);
    result.yearTwo.revisedOffers = this.calculateSum(yearTwoSum.revisedOffers);
    result.yearTwo.orders = this.calculateSum(yearTwoSum.orders);
    result.yearTwo.revisedOrders = this.calculateSum(yearTwoSum.revisedOrders);
    result.yearTwo.totalOffers = this.calculateTotalOffer(
      ...(yearTwoSum.offers.map((offer, idx) => {return {offers: offer, revisedOffers: yearTwoSum.revisedOffers[idx]}})));
    result.yearTwo.totalOrders = this.calculateTotalOffer(
      ...(yearTwoSum.orders.map((order, idx) => {return {offers: order, revisedOffers: yearTwoSum.revisedOrders[idx]}})));
    result.yearTwo.percents =
      this.calculateOrderOfferPercents({totalOffers: result.yearTwo.totalOffers, orders: result.yearTwo.totalOrders});

    result.yearComparison.offers = this.calculatePercents(result.yearOne.totalOffers, result.yearTwo.totalOffers);
    result.yearComparison.orders = this.calculatePercents(result.yearOne.totalOrders, result.yearTwo.totalOrders);

    return result;
  }

  calculateTotals() {
    const result: {
      yearOne: {
        offers: {count: any, value: any},
        revisedOffers: {count: any, value: any},
        orders: {count: any, value: any},
        revisedOrders: {count: any, value: any},
        totalOrders: {count: any, value: any},
        totalOffers: {count: any, value: any},
        percents: {count: any, value: any}
      },
      yearTwo: {
        offers: {count: any, value: any},
        revisedOffers: {count: any, value: any},
        orders: {count: any, value: any},
        revisedOrders: {count: any, value: any},
        totalOrders: {count: any, value: any},
        totalOffers: {count: any, value: any},
        percents: {count: any, value: any}
      },
      yearComparison: {
        offers: {count: any, value: any},
        orders: {count: any, value: any}
      }
    } = {
      yearOne: {
      offers: {count: '-', value: '-'},
      revisedOffers: {count: '-', value: '-'},
      orders: {count: '-', value: '-'},
      revisedOrders: {count: '-', value: '-'},
      totalOrders: {count: '-', value: '-'},
      totalOffers: {count: '-', value: '-'},
      percents: {count: '-', value: '-'}
      },
      yearTwo: {
        offers: {count: '-', value: '-'},
        revisedOffers: {count: '-', value: '-'},
        orders: {count: '-', value: '-'},
        revisedOrders: {count: '-', value: '-'},
        totalOrders: {count: '-', value: '-'},
        totalOffers: {count: '-', value: '-'},
        percents: {count: '-', value: '-'}
      },
      yearComparison: {
        offers: {count: '-', value: '-'},
        orders: {count: '-', value: '-'}
      }
    };

    const yearOneSum = {
      offers: [],
      revisedOffers: [],
      orders: [],
      revisedOrders: []
    };
    for (const month of this.report.yearOne) {
      yearOneSum.offers.push(month.offers);
      yearOneSum.revisedOffers.push(month.revisedOffers);
      yearOneSum.orders.push(month.orders);
      yearOneSum.revisedOrders.push(month.revisedOrders);
    }
    result.yearOne.offers = this.calculateSum(yearOneSum.offers);
    result.yearOne.revisedOffers = this.calculateSum(yearOneSum.revisedOffers);
    result.yearOne.orders = this.calculateSum(yearOneSum.orders);
    result.yearOne.revisedOrders = this.calculateSum(yearOneSum.revisedOrders);
    result.yearOne.totalOffers = this.calculateTotalOffer(
      ...(yearOneSum.offers.map((offer, i) => {return {offers: offer, revisedOffers: yearOneSum.revisedOffers[i]}})));
    result.yearOne.totalOrders = this.calculateTotalOffer(
      ...(yearOneSum.orders.map((order, i) => {return {offers: order, revisedOffers: yearOneSum.revisedOrders[i]}})));
    result.yearOne.percents =
      this.calculateOrderOfferPercents({totalOffers: result.yearOne.totalOffers, orders: result.yearOne.totalOrders});

    const yearTwoSum = {
      offers: [],
      revisedOffers: [],
      orders: [],
      revisedOrders: []
    };
    for (const month of this.report.yearTwo) {
      yearTwoSum.offers.push(month.offers);
      yearTwoSum.revisedOffers.push(month.revisedOffers);
      yearTwoSum.orders.push(month.orders);
      yearTwoSum.revisedOrders.push(month.revisedOrders);
    }

    result.yearTwo.offers = this.calculateSum(yearTwoSum.offers);
    result.yearTwo.revisedOffers = this.calculateSum(yearTwoSum.revisedOffers);
    result.yearTwo.orders = this.calculateSum(yearTwoSum.orders);
    result.yearTwo.revisedOrders = this.calculateSum(yearTwoSum.revisedOrders);
    result.yearTwo.totalOffers = this.calculateTotalOffer(
      ...(yearTwoSum.offers.map((offer, i) => {return {offers: offer, revisedOffers: yearTwoSum.revisedOffers[i]}})));
    result.yearTwo.totalOrders = this.calculateTotalOffer(
      ...(yearTwoSum.orders.map((order, i) => {return {offers: order, revisedOffers: yearTwoSum.revisedOrders[i]}})));
    result.yearTwo.percents =
      this.calculateOrderOfferPercents({totalOffers: result.yearTwo.totalOffers, orders: result.yearTwo.totalOrders});

    result.yearComparison.offers = this.calculatePercents(result.yearOne.totalOffers, result.yearTwo.totalOffers);
    result.yearComparison.orders = this.calculatePercents(result.yearOne.totalOrders, result.yearTwo.totalOrders);

    return result;
  }

  onMinYearSelected(year) {
    if (year) {
      this.disabledRanges.maxYear = {min: 2017, max: year};
      this.getReport();
    }
  }

  onMaxYearSelected(year) {
    if (year) {
      this.disabledRanges.minYear = {min: year, max: 2030};
      this.getReport();
    }
  }

  downloadPdf() {

  }

  downloadingBacklog:boolean = false;
  downloadOrdersBacklog() {
      this.downloadingBacklog = true;

      this.statisticsService.downloadBacklogOrders([this.minYear, this.maxYear], this.statisticsFilter.getFilter())
          .map((res) => res.blob())
          .subscribe(data => {
                  const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                  saveAs(blob, "Order-BACKLOG-" + this.minYear + "-" + this.maxYear + ".xlsx");
                  this.downloadingBacklog = false;

              },

              error =>  {

                  this.downloadingBacklog = false;

              }
          );
  }

  downloadingBacklogOffers: boolean = false;
  downloadOffersBacklog() {
    this.downloadingBacklogOffers = true;

    this.statisticsService.downloadBacklogOffers([this.minYear, this.maxYear], this.statisticsFilter.getFilter())
        .map((res) => res.blob())
        .subscribe(data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                saveAs(blob, "Offer-BACKLOG-" + this.minYear + "-" + this.maxYear + ".xlsx");
                this.downloadingBacklogOffers = false;

            },

            error =>  {

                this.downloadingBacklogOffers = false;

            }
        );
}

  downloadingExcel: boolean = false;
  donwloadReportExcel(){
    this.downloadingExcel = true;

    this.statisticsService.downloadExcel([this.minYear, this.maxYear], this.statisticsFilter.getFilter())
        .map((res) => res.blob())
        .subscribe(data => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                saveAs(blob, "Statistics-" + this.minYear + "-" + this.maxYear + ".xlsx");
                this.downloadingExcel = false;

            },

            error =>  {

                this.downloadingExcel = false;

            }
        );
  }

  downloadingPDF: boolean = false;
  donwloadReportPDF(){
    this.downloadingPDF = true;

    this.statisticsService.downloadPDF([this.minYear, this.maxYear], this.statisticsFilter.getFilter())
        .map((res) => res.blob())
        .subscribe(data => {
                const blob = new Blob([data], { type: 'application/pdf' });

                saveAs(blob, "Statistics-" + this.minYear + "-" + this.maxYear + ".pdf");
                this.downloadingPDF = false;

            },

            error =>  {

                this.downloadingPDF = false;

            }
        );
  }

  isNumber(value: string | number): boolean {
    return ((value != null) && !isNaN(Number(value.toString())));
  }


  getTranslationText(object,property){




      let translation =  object.translations.find(x => x.locale == this.translate.currentLang);
      if(_.isNil(translation) || _.isUndefined(translation)){
        translation =  object.translations.find(x => x.locale == "en");
      }

      return translation[property];



  }

  getTypesOfSupply() {
    this.miscService.getTypesOfSupply()
      .then(typesOfSupply => {

        this.typesOfSupply  = typesOfSupply;
      });
  }

  getCompanyGroups(): any {
    this.miscService.getCompanyGroups()
      .then(companyGroups => {

        this.companyGroups = companyGroups;

      });
  }


  filterSearchCountry = (text$: Observable<string>) =>
    text$
      .debounceTime(300)
      .distinctUntilChanged()
      .switchMap(term => {
        return term.length <= 2 ? [] : this.cityService.searchCountries(term)
          .catch(() => Observable.of([]));
      });

  formatterCountry = (x: { name: string }) => x.name;

  filterSearchCity = (text$: Observable<string>) =>
    text$
      .debounceTime(300)
      .distinctUntilChanged()
      .switchMap(term => {

        if(_.isObject(this.statisticsFilter.filter.country)){

           return Observable.fromPromise(this.cityService.getCitiesByCountry(this.statisticsFilter.filter.country.id,term));

        }else{
           return Observable.fromPromise(this.cityService.getCities(term));
        }

      })
      .catch(() => Observable.of([]));


  formatterSite = (x: { nameUtf8: string, state: { nameUtf8: string }, country: { name: string } }) => { return x.nameUtf8 + (x.country ? ', ' + x.country.name : '') };


  filterSearchCustomer = (text$: Observable<string>) =>
  text$
    .debounceTime(300)
    .distinctUntilChanged()
    .switchMap(term => {
      return term.length <= 2 ? [] : this.companyService.getCompanies(term)
        .catch(() => Observable.of([]));
    })

  formatterCustomer = (x: { name: string }) => x.name;

}
