import { FormControl, ValidatorFn, AsyncValidatorFn, FormControlDirective, FormControlName } from '@angular/forms';
import { AbstractControlOptions } from '@angular/forms/src/model';

const originFormControlNgOnChanges = FormControlDirective.prototype.ngOnChanges;
FormControlDirective.prototype.ngOnChanges = function () {
  if (!this.valueAccessor._elementRef) {
    return;
  }
  this.form.nativeElement = this.valueAccessor._elementRef.nativeElement;
  return originFormControlNgOnChanges.apply(this, arguments);
};

const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function () {
  if (!this.valueAccessor._elementRef) {
    return;
  }
  const result = originFormControlNameNgOnChanges.apply(this, arguments);
  this.control.nativeElement = this.valueAccessor._elementRef.nativeElement;
  return result;
};

export class SimezaFormControl extends FormControl {
    initialValue;

    constructor (formState: any = null, validatorOrOpts?: ValidatorFn | AbstractControlOptions | ValidatorFn[],
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]) {
            super(formState, validatorOrOpts, asyncValidator);
            this.initialValue  = formState;
        }

    get newValue() {
      return this.initialValue !== this.value;
    }
}
