import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import {Company} from './company';
import 'rxjs/add/operator/toPromise';
import {Office} from './office/office';

const endpoint = '/companies';

@Injectable()
export class CompanyService {

  public searchingCompanies: boolean = false;
  constructor(private http: Http) { }

  createCompany(company: Company): Promise<Company> {
    return this.http.post(endpoint, company)
      .toPromise()
      .then(response => response.json() as Company)
      .catch(this.handleError);
  }

  createCompanySpec(company: any): Promise<any> {

    company.group = company.group.id;
    company.office_city = company.office_city.id;

    return this.http.post(endpoint + '/spec', company)
      .toPromise()
      .then(response => response.json() as Company)
      .catch(this.handleError);
  }

  notifyNavCustomer(notify: any): Promise<any> {

    return this.http.post(endpoint + '/notify/nav', notify)
      .toPromise()
      .then(response => response.json() as Company)
      .catch(this.handleError);
  }


  checkNavCustomer(idCompany: any): Promise<any> {

    return this.http.get(endpoint + '/check/' + idCompany )
      .toPromise()
      .then(response => response.json() as Company)
      .catch(this.handleError);
  }

  // servicio para traerse las compañias / uso para filtros busqueda por string
  getCompanies(query?: string): Promise<Company[]> {
    const q = query ? '?name=' + query : '';
    // console.log(q,endpoint); check new enpoint;
    this.searchingCompanies = true;
    return this.http.get(endpoint + q)
      .toPromise()
      .then(response =>{
        this.searchingCompanies = false;
        return response.json() as Company[];
      } )
      .catch(this.handleError);
  }



  // Servicio para traerse las compañias por CIF / uso de filtro por busqueda en field
  getCompaniesbySearchCIF(query?: string): Promise<Company[]> {
    const q = query ? '?cif=' + query : '';
    this.searchingCompanies = true;
    // sacar por consola la ruta
    //console.log("enpoint completo",endpoint + '/cif' + q);
    return this.http.get(endpoint + '/cif' + q)
      .toPromise()
      .then(response =>{
        this.searchingCompanies = false;
        return response.json() as Company[];
      } )
      .catch(this.handleError);
   }
   // fin servicio filtro por CIF //




  // compañia por id
  getCompanyById(id: number): Promise<Company> {
    return this.http.get(endpoint + '/' + id)
      .toPromise()
      .then(response => response.json() as Company)
      .catch(this.handleError);
  }

  updateCompany(company: Company): Promise<Response> {

    //fix with a serializer
    company.group = company.group.id;

    return this.http.put(endpoint + '/' + company.id, company)
      .toPromise()
      .then(response => response.json())
      .catch(this.handleError);
  }

  deleteCompany(id: number): Promise<Response> {
    return this.http.delete(endpoint + '/' + id)
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  getOffices(id: number): Promise<Office[]> {
    return this.http.get(endpoint + '/' + id + '/offices')
      .toPromise()
      .then(response => response.json() as Office[])
      .catch(this.handleError);
  }


  private handleError(error: any): Promise<any> {

    return Promise.reject(error.message || error);
  }

}
