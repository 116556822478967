import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PSSComponent } from './pss.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [PSSComponent],
})
export class PSSModule { }
