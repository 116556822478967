import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
  NgModule
} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Silo} from '../../../scs/constitution/silo';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MiscService} from '../../../shared/misc.service';
import {InfiniteScrollerDirective} from '../../../shared/infinite-scroller.directive';
import {Product} from '../../../scs/constitution/productRevision';
import {OfferService} from '../offer.service';
import {ConstitutionService} from '../../../scs/constitution/constitution.service';
import {NgForm, EmailValidator, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {CustomPipesModule} from '../../../pipes/custom-pipes.module';
import {InfiniteScrollerModule} from '../../../shared/infinite-scroller.directive';


import 'rxjs/add/observable/forkJoin';
import {Offer} from '../offer';
import {Order} from '../../job-order/order';
import * as _ from 'lodash';
import {saveAs} from 'file-saver';

import {mergeMap, catchError} from 'rxjs/operators';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {of} from 'rxjs/observable/of';


@Component({
  selector: 'app-personalize-silo-modal',
  templateUrl: './personalize-silo-modal.component.html',
  styleUrls: ['./edit-offer.component.scss']
})
export class PersonalizeSiloModalComponent implements OnInit {


  @Input() userData;
  @Input() offer: Offer;
  @Input() order: Order;
  @Input() discount: number;
  @Input() commissionRate: number;
  @Input() disabledCodes: boolean = true;

  @Input() offerRevisionSilos = [];
  @Input() offerRevisionSilo = {
    extraProducts: null,
    quantity: 1,
    silo: null,
    id: null,
    model: null,
    hidePrice: null,
    hideWeight: null,
  };

  @Input() managmentData: any = {
    kprice: 0,
    kweight: 0,
    disscount: 0,
  };

  @ViewChild(InfiniteScrollerDirective) infiniteScrollerDirective: InfiniteScrollerDirective;


  scrollCallback; //TODO refactor if process silo
  private page;

  private originalProdcuts = [];
  private preloadedSpecials = [];


  private state = {
    selectedCategory: null

  }

  activeParentCategory: any = {};
  activeSecondCategory: any = {};
  activeThirdCategory: any = {};
  activeForthCategory: any = {};
  activeCategoryName: string = '';

  categoryProducts: any = {};

  //flags

  switchSilo: boolean = true;
  filter: boolean;
  activeSubViewSwitch: boolean = false;

  basketRestOperationInCourse: boolean = false;
  basketSpecialRestOperationInCourse: boolean = false;
  productLoadingRestOperationInCourse: boolean = false;
  loadingInfiniteProducts: boolean = false;
  downloadingFile: boolean = false;
  basketDescriptionLanguage: string;
  basketSelectedItems: any[] = [];

  constructor(
    private router: Router,
    private miscService: MiscService,
    private constitutionsService: ConstitutionService,
    public activeModal: NgbActiveModal,
    private offerService: OfferService,
    private translate: TranslateService
  ) {
    this.filter = true;
    this.basketDescriptionLanguage = this.translate.currentLang;
  }

  ngOnInit(): void {

    this.page = 1;
    this.getProductCategory();
    this.allowedOfferRevisionSilos();

    this.scrollCallback = this.getInfiniteProducts.bind(this);


  }

  willLoadProducts(category) {
    return (!this.filter) || (category.active && this.activeSecondCategory.id != 3) || (this.filter && this.activeSecondCategory.id == 3 && category.default && category.id != 62)
  }


  getChildNodes() {
    if (this.isStandardAccesoriesAndFilter()) {
      return this.activeSecondCategory.childNodesDefault;
    } else {
      return this.activeSecondCategory.childNodes;
    }
  }

  isStandardAccesoriesAndFilter() {
    return (this.isFilterActive() && this.isStandardAccesories());
  }


  isUpgradesAndFilter() {
    return (this.isFilterActive() && this.isUpgrades());
  }

  isStandardAccesories() {
    return this.activeSecondCategory.id == 3;
  }

  isUpgrades() {
    return this.activeSecondCategory.id == 143;
  }

  isFilterActive() {
    return this.filter;
  }

  isTotalQtyDisplayed() {
    return this.isStandardAccesories() || (
      this.isFilterActive() && (
        this.activeParentCategory.id == 19 || this.activeSecondCategory.id == 77 || this.activeSecondCategory.id == 79 ||
        this.activeSecondCategory.id == 82 || this.activeSecondCategory.id == 83 ||
        this.activeSecondCategory.id == 143
      )
    )
  }


  getActiveCategories() {

    if (_.isUndefined(this.activeSecondCategory.id)) {

      return [];
    }

    let categories = [];
    let finalCategories = [];

    if (this.isFilterActive()) {
      if (this.isStandardAccesoriesAndFilter()) {
        categories = this.activeSecondCategory.childNodesDefault;
      } else if (!_.isUndefined(this.activeSecondCategory.childNodes) && !_.isNull(this.activeSecondCategory.childNodes) && !_.isEmpty(this.activeSecondCategory.childNodes)) {
        categories = this.activeSecondCategory.childNodes;
      } else {
        categories = [this.activeSecondCategory];
      }
    } else {

      if (!_.isUndefined(this.activeThirdCategory.childNodes) && !_.isNull(this.activeThirdCategory.childNodes) && !_.isEmpty(this.activeThirdCategory.childNodes)) {
        categories = this.activeThirdCategory.childNodes;
      } else if (!_.isUndefined(this.activeThirdCategory.id)) {
        categories = [this.activeThirdCategory];
      } else {
        categories = [this.activeSecondCategory];
      }
    }



    if (_.isUndefined(categories) || _.isNull(categories)) {
      return [];
    }

    return categories;
  }

  getActiveCategory() {

    if (!_.isUndefined(this.activeForthCategory.id)) {
      return this.activeForthCategory;
    } else if (!_.isUndefined(this.activeThirdCategory.id)) {
      return this.activeThirdCategory;
    } else {
      return this.activeSecondCategory;
    }

  }

  isQuantityRequired(silo, product) {

    if (product.category.id == 177 || product.category.id == 134 || product.category.id == 192) {
      return false;
    }

    if (Silo.isSG(silo) && ((product.category.id == 17 && !_.has(this.offerRevisionSilo.silo, 'levelSensorNumber')) || product.category.id == 33 || product.category.id == 34)) {
      return false;
    }


    return true;
  }

  processSubscribeProcess(productsByCategory) {

    let categories = this.getActiveCategories();
    let counter = 0

    for (let arrayOfProducts of productsByCategory) {

      let displayedProducts = [];


      for (let product of arrayOfProducts) {

        product['cost'] = product['cost'] * this.offer.currencyChangeRate;

        if (this.isFilterActive() && this.isStandardAccesories() && this.isQuantityRequired(this.offerRevisionSilo.silo, product)) {

          this.constitutionsService.getProdcutQuantityByCategory(product.category.id, this.offerRevisionSilo).then(quantity => {

            if (quantity > 0) {
              //roof ladder
              //45/60° HBS Hopper Silo Vertical Ladder from Compression Ring to Ground Level
              //DigitalProbe supports

              switch (this.offerRevisionSilo.silo.model) {
                case 'FBS':
                  if (product.category.id != 35 && product.category.id != 68 && product.category.id != 69
                    && product.category.id != 45 && product.category.id != 46 &&

                   ( product.category.id < 5 ||  product.category.id > 14)

                  ) {

                    product['selected'] = true;
                  } else {
                    product['selected'] = false;
                  }
                  if(
                      this.offerRevisionSilo.silo.lowestDoorCode === this.offerRevisionSilo.silo.slopeDoorCode &&
                      this.offerRevisionSilo.silo.slopeDoorCode === product['aggregationCode']  ){
                    
                    let esText, enText, deText, ruText;
                    let esHelperText, enHelperText, deHelperText, ruHelperText;
                    const esTranslation = this.getTranslation(product, 'es');
                    const enTranslation = this.getTranslation(product, 'en');
                    const deTranslation = this.getTranslation(product, 'de');
                    const ruTranslation = this.getTranslation(product, 'ru');

            

                    enText = ' positioned on ring ' + this.offerRevisionSilo.silo.slopeRing + ', above the angle of repose';
                    esText = ' situada en la virola ' + this.offerRevisionSilo.silo.slopeRing + ', sobre el ángulo de reposo';
                    deText = ' befindet sich im Ring ' + this.offerRevisionSilo.silo.slopeRing + ', über den Schüttwinkel';
                    ruText = ' расположенная на кольце' + this.offerRevisionSilo.silo.slopeRing + ', над углом естественного откоса';

                    esTranslation['descriptionText'] += esText;
                    enTranslation['descriptionText'] += enText;
                    deTranslation['descriptionText'] += deText;
                    ruTranslation['descriptionText'] += ruText;


                    enHelperText = ', same model for door positioned on ring '+this.offerRevisionSilo.silo.lowestRing+' - already included in the silo constitution';
                    esHelperText = ',  mismo modelo para puerta posicionada en virola '+this.offerRevisionSilo.silo.lowestRing+' - ya incluída en la constitución del silo';
                    deHelperText = ', gleiches Modell für die Inspektionsluke, die sich im Ring '+this.offerRevisionSilo.silo.lowestRing+'  befindet - ist schon in der Siloausstattung';
                    ruHelperText = ', та же самая модель для инспекционной дверцы, расположенной на кольце '+this.offerRevisionSilo.silo.lowestRing+' - уже включенa в комплектацию силоса';

                    esTranslation['descriptionHelperText'] += esHelperText;
                    enTranslation['descriptionHelperText'] += enHelperText;
                    deTranslation['descriptionHelperText'] += deHelperText;
                    ruTranslation['descriptionHelperText'] += ruHelperText;

                  } else if (this.offerRevisionSilo.silo.lowestDoorCode === product['aggregationCode']) {

                    let esText, enText, deText, ruText;
                    let esHelperText, enHelperText, deHelperText, ruHelperText;
                   
                    const esTranslation = this.getTranslation(product, 'es');
                    const enTranslation = this.getTranslation(product, 'en');
                    const deTranslation = this.getTranslation(product, 'de');
                    const ruTranslation = this.getTranslation(product, 'ru');

                  
                    enText = ' positioned on ring ' + this.offerRevisionSilo.silo.lowestRing ;
                    esText = '  situada en la virola ' + this.offerRevisionSilo.silo.lowestRing ;
                    deText = '  befindet sich im zweiten Ring ' + this.offerRevisionSilo.silo.lowestRing ;
                    ruText = '  расположенная на втором кольце' + this.offerRevisionSilo.silo.lowestRing ;

             
                    esTranslation['descriptionText'] += esText;
                    enTranslation['descriptionText'] += enText;
                    deTranslation['descriptionText'] += deText;
                    ruTranslation['descriptionText'] += ruText;

                    enHelperText = ', already included in the silo constitution';
                    esHelperText = ', ya incluída en la constitución del silo';
                    deHelperText = ', ist schon in der Siloausstattung enthalten';
                    ruHelperText = ', уже включенa в комплектацию силоса';

                    esTranslation['descriptionHelperText'] += esHelperText;
                    enTranslation['descriptionHelperText'] += enHelperText;
                    deTranslation['descriptionHelperText'] += deHelperText;
                    ruTranslation['descriptionHelperText'] += ruHelperText;

                  } else if (this.offerRevisionSilo.silo.slopeDoorCode === product['aggregationCode']) {

                    let esText, enText, deText, ruText;
                    const esTranslation = this.getTranslation(product, 'es');
                    const enTranslation = this.getTranslation(product, 'en');
                    const deTranslation = this.getTranslation(product, 'de');
                    const ruTranslation = this.getTranslation(product, 'ru');

            

                    enText = ' positioned on ring ' + this.offerRevisionSilo.silo.slopeRing + ', above the angle of repose';
                    esText = ' situada en la virola ' + this.offerRevisionSilo.silo.slopeRing + ', sobre el ángulo de reposo';
                    deText = ' befindet sich im Ring ' + this.offerRevisionSilo.silo.slopeRing + ', über den Schüttwinkel';
                    ruText = ' расположенная на кольце' + this.offerRevisionSilo.silo.slopeRing + ', над углом естественного откоса';

                    esTranslation['descriptionText'] += esText;
                    enTranslation['descriptionText'] += enText;
                    deTranslation['descriptionText'] += deText;
                    ruTranslation['descriptionText'] += ruText;

                  }

                  break;

                case 'HBS':
                  if (product.category.id != 35 && product.category.id != 68 && product.category.id != 69
                    && product.category.id != 45 && product.category.id != 46
                    && product.category.id != 62 && product.category.id != 63 && product.category.id != 70 && product.category.id != 71 && product.category.id != 72
                    && product.category.id != 73 && product.category.id != 74 && product.category.id != 75
                  ) {

                    product['selected'] = true;
                  } else {
                    product['selected'] = false;
                  }
                  break;

                case 'HBS-S':
                  if (product.category.id != 35 && product.category.id != 68 && product.category.id != 69
                    && product.category.id != 45 && product.category.id != 46
                    && product.category.id != 62 && product.category.id != 63 && product.category.id != 70 && product.category.id != 71 && product.category.id != 72
                    && product.category.id != 73 && product.category.id != 74 && product.category.id != 75 &&
                    ( product.category.id < 5 ||  product.category.id > 14)
                  ) {

                    product['selected'] = true;
                  } else {
                    product['selected'] = false;
                  }

                 

                  break;

                case 'DP':
                case 'DPT':

                  product['selected'] = false;

                  break;

              }


              product['quantity'] = quantity;
              product['totalQuantity'] = product['quantity'] * this.offerRevisionSilo.quantity;

              displayedProducts.push(product);


            }


          }).catch(error => {

            //product["selected"] = false;

          });


        } else {


          if (this.isFilterActive() &&
            (((product.category.id == 94 || product.category.id == 191) && product['motorEfficiency'] == 'IE2') || product.category.id == 184)) //if is an aeration system or inspection platform tls
          {
            product['selected'] = true;


          } else {
            product['selected'] = false;

          }


          if (product.category.id == 93) {

            this.constitutionsService.getProdcutQuantityByCategory(product.category.id, this.offerRevisionSilo).then(quantity => {


              product['quantity'] = quantity;
              product['totalQuantity'] = product['quantity'] * this.offerRevisionSilo.quantity;
            });
          } else {
            product['quantity'] = 1;

            if (this.isTotalQtyDisplayed()) {
              product['totalQuantity'] = product['quantity'] * this.offerRevisionSilo.quantity;
            } else {
              product['totalQuantity'] = product['quantity'];
            }
          }


          if (product.category.id != 168) {
            displayedProducts.push(product);
          }

        }


      }

      if (!_.isUndefined(categories[counter])) {

        categories[counter]['products'] = displayedProducts;
      }


      counter++;
    }

    this.addPreloadedSpecials();

  }

  addPreloadedSpecials() {

    if (this.isStandardAccesoriesAndFilter() &&
      !Silo.isWTT(this.offerRevisionSilo.silo) &&
      !Silo.isSG(this.offerRevisionSilo.silo)) {

      this.preloadedSpecials = [];

      let specialProduct = this.getEmptyProduct();

      const esTranslation = this.getTranslation(specialProduct, 'es');
      const enTranslation = this.getTranslation(specialProduct, 'en');
      const deTranslation = this.getTranslation(specialProduct, 'de');
      const ruTranslation = this.getTranslation(specialProduct, 'ru');

      esTranslation.descriptionText = 'Refuerzo de pasarela';
      enTranslation.descriptionText = 'Catwalk stiffeners reinforcement';
      deTranslation.descriptionText = ' Verstärkte Versteifungsrippen für die Befestigung des Laufsteges';
      ruTranslation.descriptionText = 'Усиленные ребра жесткости для крепления галереи'


      specialProduct.cost = this.offerRevisionSilo.silo.costCatwalkStiffeners;
      specialProduct.weight = this.offerRevisionSilo.silo.weightCatwalkStiffeners;
      //specialProduct.quantity = this.offerRevisionSilo.silo.totalNumberOfCatwalkStiffeners;
      specialProduct.quantity = 1;
      specialProduct.totalQuantity = this.offerRevisionSilo.quantity;
      specialProduct.selected = true;

      this.preloadedSpecials.push(specialProduct);
    }

  }


  processSubscribeProcesInfinite(arrayOfProducts) {

    let category = this.getActiveCategory();
    let counter = 0
    let displayedProducts = [];


    for (let product of arrayOfProducts) {
      product['cost'] = product['cost'] * this.offer.currencyChangeRate;
      product['quantity'] = 1;
      product['totalQuantity'] = product['quantity'];

      displayedProducts.push(product);

    }


    if (!_.isUndefined(category)) {


      if (_.has(category, 'products') && !_.isEmpty(category['products'])) {
   
        category['products'] = category['products'].concat(displayedProducts);
      } else {

        category['products'] = displayedProducts;
      }

    }

  }


  private postprocessProducts = (products) => {

    if (!_.isEmpty(products[0])) {
      this.page = this.page + 1;
   
    }

    this.loadingInfiniteProducts = false;
    this.processSubscribeProcesInfinite(products);

  };


  getProducts() {

    let categories = this.getActiveCategories();

    if (!_.isEmpty(categories)) {
      this.productLoadingRestOperationInCourse = true;
      const getProductsByCategory = val =>
        this.constitutionsService.getProdcutsByCategory(val.id,
          this.offer.pricesDate,
          this.filter,
          this.offerRevisionSilo,
          this.page,
          this.offerRevisionSilo.quantity
        );


      const source = of(categories);
      const returnedProducts = source.pipe(mergeMap(q => forkJoin(...q.map(getProductsByCategory))), catchError(error => of(error)));
      const subscribe = returnedProducts.subscribe(val => {


        this.productLoadingRestOperationInCourse = false;


        this.processSubscribeProcess(val);

      });
    }


    /*
        const getProductQuantityByCategory = val =>
          new Promise(resolve =>

            {
                resolve(`Promise Resolved: ${val}`)
            }
          );

        const src = of(subscribe);
        const exp = source.pipe(mergeMap(q => forkJoin(...q.map(getProductQuantityByCategory))))

    */

  }


  getInfiniteProducts() {

    if (this.loadingInfiniteProducts || this.productLoadingRestOperationInCourse) {
      return [];
    }

    this.loadingInfiniteProducts = true;

    let category = this.getActiveCategory();

    return this.constitutionsService.getProdcutsByCategory(category.id, this.offer.pricesDate, this.filter, this.offerRevisionSilo, this.page).then(this.postprocessProducts);

  }

  cleanSelected() {


    let categories = this.getActiveCategories();

    for (let category of categories) {
      if (!_.isUndefined(category.products)) {
        for (let product of category.products) {
          product.selected = false;
        }
      }

    }


    if (this.preloadedSpecials[0]) {

      for (let product of this.preloadedSpecials) {

        if (product.selected) {

          product.selected = false;
        }

      }

    }

    this.page = 1;

  }

  cleanProducts() {
    let categories = this.getActiveCategories();

    for (let category of categories) {
      if (!_.isUndefined(category.products)) {
        category.products = [];
      }

    }

    if (this.preloadedSpecials[0]) {

      this.preloadedSpecials = [];

    }

  }

  cleanBasket() {
    this.activeParentCategory['basket'] = [];

  }

  switchProductsView() {

    this.cleanSelected();

    this.page = 1;

    if (!this.filter) {
  
      this.getInfiniteProducts();
      if (!_.isUndefined(this.infiniteScrollerDirective)) {
        this.infiniteScrollerDirective.resetScroll();
      }

    } else {
      this.getProducts();
    }
  }

  hasChilds(category) {

    if (!_.isEmpty(category.childNodes)) {
      return true;
    } else {
      return false;
    }

  }

  syncQyuantity(product) {

    if (this.isTotalQtyDisplayed()) {
      product.totalQuantity = product.quantity * this.offerRevisionSilo.quantity;
    } else {
      product.totalQuantity = product.quantity;
    }


  }

  getKWeight() {
    return 1.15;
  }


  getWeightList(product) {
    return product.weight * this.getKWeight();
  }

  getPriceList(product) {

    let coefficientsvalue = this.getCoefficientValue(product);

    return product.cost * coefficientsvalue * this.managmentData.kprice;
  }

  getCoefficientValue(product) {
    let coefficientsvalue = 1;


    if (this.categoryHasPriceCoefficients(this.activeSecondCategory)) {
      coefficientsvalue *= this.activeSecondCategory.effectiveCoefficient;
    } else if (this.categoryHasPriceCoefficients(this.activeParentCategory)) {
      coefficientsvalue *= this.activeParentCategory.effectiveCoefficient;
    }


    return coefficientsvalue;
  }

  getPriceListBasket(product) {

    const coefficientsvalue = this.getCoefficientValue(product);

    return product.cost * coefficientsvalue * this.managmentData.kprice;

  }

  getPriceListDiscounted(product) {

    return this.getPriceList(product) * (1 - this.discount);
  }

  getPriceListDiscountedBasket(product) {

    return this.getPriceListBasket(product) * (1 - this.discount);
  }

  getProductMargin(product) {
    return (this.getPriceListDiscounted(product) - product.cost) / this.getPriceListDiscounted(product);
  }


  getTotalProductPrice(product) {
   
    return this.getPriceListDiscounted(product) * product['totalQuantity'];
  }

  getTotalProductPriceWithCommissionRate(product) {
    return this.getTotalProductPrice(product) * (1 + this.commissionRate);
  }

  getTotalProductCommission(product) {
    return this.getTotalProductPriceWithCommissionRate(product) - this.getTotalProductPrice(product);
  }


  getTotalProductPriceBasket(product) {
 
    return this.getPriceListDiscountedBasket(product) * product['totalQuantity'];
  }

  getProductPriceWithCommissionRateBasket(product) {
    return this.getPriceListDiscountedBasket(product) * (1 + this.commissionRate);
  }

  getTotalProductPriceWithCommissionRateBasket(product) {
    return this.getTotalProductPriceBasket(product) * (1 + this.commissionRate);
  }

  getTotalProductCommissionBasket(product) {
    return this.getTotalProductPriceWithCommissionRateBasket(product) - this.getTotalProductPriceBasket(product);
  }


  getTotalProductWeight(product) {
    return this.getWeightList(product) * product['totalQuantity'];
  }

  getTotalCost(product) {
    return product.cost * product['totalQuantity'];
  }

  getTotlMargin(product) {

    if (this.getTotalProductPrice(product) == 0) {
      return 0;
    }

    return (this.getTotalProductPrice(product) - this.getTotalCost(product)) / this.getTotalProductPrice(product);
  }



  //  Get total products asignados al silo que esta fuera 
  getBasket() {

    let categories = [this.activeParentCategory];


    // BASKT PRODUCTS --> 
    const getBasketProducts = category => {
      console.log("Busca basket products -> id -> " + this.offerRevisionSilo.id + " category id _> " + category.id + " silo model _> " + this.offerRevisionSilo.model);
      return this.offerService.getOfferSiloProductByCategory(this.offerRevisionSilo.id, category.id, this.offerRevisionSilo.model)
    }

    this.setBasketSavingFlagsOn();

    const source = of(categories);
    const basketProducts = source.pipe(mergeMap(q => forkJoin(...q.map(getBasketProducts))), catchError(error => {

      this.setBasketSavingFlagsOff();
      return of(error);
    }));
    
    // BASKT PRODUCTS -->    // BASKT PRODUCTS -->
    console.log(basketProducts);

    const subscribe = basketProducts.subscribe(allBasketProucts => {

      this.setBasketSavingFlagsOff();
      this.activeParentCategory['basket'] = [];
      console.log('basket must be empty: ',this.activeParentCategory['basket'] );

      for (let basketProucts of allBasketProucts) {
        for (let item of basketProucts) {
          item.selectedTranslation = this.basketDescriptionLanguage;
        }
        this.activeParentCategory['basket'] = this.activeParentCategory['basket'].concat(basketProucts);
      }

      this.activeParentCategory['basket'].sort(this.compare);

      console.log(" Basket de productos entero --> ");
      console.log(this.activeParentCategory['basket']);
    });


  }
  //  Get total products asignados al silo que esta fuera // fin --> fin 


  getProductCategory() {

    this.categoryProducts = {};
    this.constitutionsService.getProductCategories(this.offer.pricesDate).then(categoryProducts => {

      this.categoryProducts = categoryProducts;

      this.orderCategories(this.categoryProducts.childNodes);

      this.setDefaults();

    }).catch(error => {
    
    })
  }


  orderCategories(categoryNodes) {

    for (let categoryNode of categoryNodes) {


      if (_.has(categoryNode, 'childNodes') && !_.isEmpty(categoryNode.childNodes)) {
        this.orderCategories(categoryNode.childNodes)
      }
    }

    if (!_.isNull(categoryNodes[0]['categoryOrder']))
      categoryNodes.sort(this.compareCategories);


  }


  categoryHasPriceCoefficients(category) {

    if (!_.isUndefined(category) && category.effectiveCoefficient != 1) {
      return true;
    } else {
      return false;
    }

  }

  isCategoryActive(category) {

    if (!category.active) {

      return false;
    }

    /* if(_.isUndefined( category.read ) || !category.read){
       return false;
     }*/


    let hiddenCategoriesForModel = this.constitutionsService.getHiddenCategoriesForModel(this.offerRevisionSilo.silo);

    const itemIdx = _.findIndex(hiddenCategoriesForModel, function (hiddedCategoryId) {

      return hiddedCategoryId == category.id;

    });

    if (itemIdx === -1) {
      return true
    } else {

      return false;
    }

  }

  isCategoryThirdActive(category, parentCategory) {

    if (!category.active) {

      return false;
    }

    /* if(_.isUndefined( parentCategory.read ) || !parentCategory.read){
       return false;
     }*/

    let hiddenCategoriesForModel = this.constitutionsService.getHiddenCategoriesForModel(this.offerRevisionSilo.silo);

    const itemIdx = _.findIndex(hiddenCategoriesForModel, function (hiddedCategoryId) {

      return hiddedCategoryId == category.id;

    });

    if (itemIdx === -1) {
      return true
    } else {

      return false;
    }

  }

  isCategoryInUploadProcess(category) {


    let hiddenCategoriesForModel = this.constitutionsService.getCategoriesInUploadingProcess();

    const itemIdx = _.findIndex(hiddenCategoriesForModel, function (hiddedCategoryId) {

      return hiddedCategoryId == category.id;

    });

    if (itemIdx === -1) {
      return false
    } else {

      return true;
    }
  }

  setDefaults() {

    if (this.categoryProducts.childNodes[0]) {

      this.activeParentCategory = this.categoryProducts.childNodes[0];
      this.activeSecondCategory = this.activeParentCategory.childNodes[0];
      this.activeThirdCategory = this.activeSecondCategory.childNodes[0];
      this.activeForthCategory = this.activeThirdCategory.childNodes[0];

      this.setActiveCategoryName();

      console.log("Silo model " + this.offerRevisionSilo.model);

      this.constitutionsService.getProductDefaultCategories(this.offerRevisionSilo.model).then(categoryProducts => {

        let finalCategories = [];

        for (const category of categoryProducts) {
          if (this.isDefaultCategoryForModel(category.id)) {
            finalCategories.push(category);
          }
        }

        this.activeSecondCategory['childNodesDefault'] = finalCategories;

        this.getProducts();
        this.getBasket();


      }).catch(error => {

      });


    }

  }

  setActiveCategoryName() {

    this.activeCategoryName = this.getTranslationText(this.activeParentCategory, 'name');

  }

  isSelected2dCategory(category_id) {

    return this.activeSecondCategory.id == category_id;

  }

  isHelperTextSet(item) {

    let translation = item.translations.find(x => x.locale == this.translate.currentLang);


    if (_.isUndefined(translation) || _.isNull(translation)) {
      return false;
    }

    return !_.isNull(translation.descriptionHelperText) && !_.isUndefined(translation.descriptionHelperText) && !_.isEmpty(translation.descriptionHelperText) && translation.descriptionHelperText != ''
  }


  switch2dCategory(parentCategory, category) {

    this.cleanBasket();
    this.cleanProducts();

    let lastCategory = _.cloneDeep(this.activeSecondCategory);
    this.saveActiveBasket(lastCategory);

    this.activeSecondCategory = category;
    this.activeParentCategory = parentCategory;

    this.setActiveCategoryName();
    this.set3dCategory();
    this.set4dCategory();

    this.getBasket();

    this.preloadedSpecials = [];

    this.page = 1;

    if (!this.isRegularListing()) {
     
      this.getInfiniteProducts();
      if (!_.isUndefined(this.infiniteScrollerDirective)) {
        this.infiniteScrollerDirective.resetScroll();
      }
    } else {
      this.getProducts();
    }

  }

  set3dCategory() {
    this.activeThirdCategory = null;
    if (this.activeSecondCategory.childNodes[0]) {

      for (const category of this.activeSecondCategory.childNodes) {
        if (this.isCategoryActive(category)) {
          this.activeThirdCategory = category;
          break;
        }
      }

    } else {
      this.activeThirdCategory = {};
    }
  }

  set4dCategory() {
    this.activeForthCategory = null;
    if (this.activeThirdCategory.childNodes && this.activeThirdCategory.childNodes[0]) {

      this.activeForthCategory = this.activeThirdCategory.childNodes[0];

    } else {
      this.activeForthCategory = {};
    }
  }

  isSelected3dCategory(category_id) {

    return this.activeThirdCategory.id == category_id;

  }

  isSelected4dCategory(category_id) {

    return this.activeForthCategory.id == category_id;

  }

  switch3dCategory(category) {

    this.cleanProducts();
    this.activeThirdCategory = category;

    this.set4dCategory();
    this.page = 1;

    if (!this.isRegularListing()) {
     

      this.getInfiniteProducts();
      if (!_.isUndefined(this.infiniteScrollerDirective)) {
        this.infiniteScrollerDirective.resetScroll();
      }
    } else {
      this.getProducts();
    }


  }

  switch4dCategory(category) {

    this.cleanProducts();
    this.activeForthCategory = category;

    this.page = 1;

    if (!this.isRegularListing()) {


      this.getInfiniteProducts();
      if (!_.isUndefined(this.infiniteScrollerDirective)) {
        this.infiniteScrollerDirective.resetScroll();
      }
    } else {
      this.getProducts();
    }

  }


  someProductChecked() {

    let categories = this.getActiveCategories();

    if (this.productLoadingRestOperationInCourse || _.isEmpty(categories)) {
      return false;
    }

    for (let category of categories) {
      if (_.has(category, 'products') && (!_.isUndefined(category.products) && !_.isNull(category.products))) {

        for (let product of category.products) {
          if (product.selected) {

            return true;
          }
        }
      }


    }

    if (this.isStandardAccesories() || this.isUpgrades()) {

      for (let product of this.preloadedSpecials) {
        if (product.selected) {

          return true;
        }
      }

    }


    return false;
  }

  isBasketEmpty() {

    if (_.isNull(this.activeParentCategory.basket) || _.isUndefined(this.activeParentCategory.basket) || _.isEmpty(this.activeParentCategory.basket)) {
      return true;
    } else {
      return false;
    }

  }

  getLastElementOrder() {

    return this.activeParentCategory.basket[this.activeParentCategory.basket.length - 1].order + 1;

  }

  compare(a, b) {
    if (a.order < b.order)
      return -1;
    if (a.order > b.order)
      return 1;
    return 0;
  }

  compareCategories(a, b) {


    if (a.categoryOrder < b.categoryOrder)
      return -1;
    if (a.categoryOrder > b.categoryOrder)
      return 1;
    return 0;
  }

  addSelectedProducts() {

    let order = this.isBasketEmpty() ? 0 : this.getLastElementOrder();

    let categories = this.getActiveCategories();

    for (let category of categories) {
      if (!_.isUndefined(category.products) && !_.isNull(category.products)) {
          for (let product of category.products) {

          if (product.selected) {

            product.selected = !product.selected;
            product = this.serializeProduct(product);
            product.order = order;
            product.hidePrice = this.offerRevisionSilo.hidePrice;
            product.hideWeight = this.offerRevisionSilo.hideWeight;

            this.offerService.createOfferSiloProduct(this.offerRevisionSilo.id, product, this.offerRevisionSilo.model).then(offerProduct => {
         
              offerProduct.selectedTranslation = this.basketDescriptionLanguage;
              this.activeParentCategory['basket'].push(_.cloneDeep(offerProduct));

              this.activeParentCategory['basket'].sort(this.compare);

            }).catch(error => {

            });

            order++;

          }

        }

      }
    }

    if (this.isStandardAccesories() || this.isUpgrades()) {

      for (let product of this.preloadedSpecials) {
        if (product.selected) {

          product.selected = !product.selected;
          product = this.serializeProduct(product);
          product.order = order;
          product.hidePrice = this.offerRevisionSilo.hidePrice;
          product.hideWeight = this.offerRevisionSilo.hideWeight;

          this.offerService.createOfferSiloProduct(this.offerRevisionSilo.id, product, this.offerRevisionSilo.model).then(offerProduct => {
   
            this.activeParentCategory['basket'].push(_.cloneDeep(offerProduct));

            this.activeParentCategory['basket'].sort(this.compare);

          }).catch(error => {

          });

          order++;
        }
      }

    }


  }

  savebasketRestOperationInCourse: boolean = false;

  setBasketSavingFlags() {
    this.basketRestOperationInCourse = !this.basketRestOperationInCourse;
    this.savebasketRestOperationInCourse = !this.savebasketRestOperationInCourse;
  }


  setBasketSavingFlagsOn() {
    this.basketRestOperationInCourse = true;
    this.savebasketRestOperationInCourse = true;
  }


  setBasketSavingFlagsOff() {
    this.basketRestOperationInCourse = false;
    this.savebasketRestOperationInCourse = false;
  }

  isBasketOperationInCourse() {
    return this.basketRestOperationInCourse || this.savebasketRestOperationInCourse
  }

  saveActiveBasket(category?) {

    //this.setBasketSavingFlags();
    let basket = this.activeParentCategory['basket'];

    if (!_.isUndefined(category)) {
      basket = category.basket;
    }

    if (!_.isEmpty(basket)) {
      this.setBasketSavingFlagsOn()

      const editBasketProducts = product => {

        product = this.serializeProductBasket(product);
        return this.offerService.editOfferSiloProduct(this.offerRevisionSilo.id, product, this.offerRevisionSilo.model)
      }


      const source = of(basket);
      const editedProducts = source.pipe(mergeMap(q => forkJoin(...q.map(editBasketProducts))), catchError(error => {

    
        this.setBasketSavingFlagsOff();
        return of(error);
      }));
      const subscribe = editedProducts.subscribe(val => {
       
        this.setBasketSavingFlagsOff();

      });
    }


  }

  serializeProductBasket(originalProduct) {

    let product = _.cloneDeep(originalProduct);

    const productCategory = this.getOfferProductCategory(product);

    product.totalPrice = this.getTotalProductPriceBasket(product);
    product.price = this.getPriceListDiscountedBasket(product);
    product.priceList = this.getPriceListBasket(product);
    product.totalWeight = this.getTotalProductWeight(product);
    product.category = productCategory;

    delete product.offerRevisionSilo;
    delete product.newTranslations;
    delete product.currentLocale;
    delete product.creationDate;
    delete product.deletedAt;
    delete product.offerRevision;
    delete product.defaultLocale;


    return product;

  }

  private getOfferProductCategory(product) {

    return product.category ? {id: product.category.id} : {id: this.activeSecondCategory.id};

  }

  serializeProduct(product) {

    const productCategory = this.getOfferProductCategory(product);

    let offerRevisionProduct = {

      'aggregationCode': product.aggregationCode,
      'idNav': product.idNav,
      'category': productCategory,
      'weight': product.weight,
      'cost': product.cost,
      'special': product.special,
      'optional': product.optional,
      'quantity': product.quantity,
      'totalQuantity': product.totalQuantity,
      'totalPrice': this.getTotalProductPrice(product),
      'totalWeight': this.getTotalProductWeight(product),
      'price': this.getPriceListDiscounted(product),
      'priceList': this.getPriceList(product),
      'order': 1,
      'hidePrice': product.hidePrice,
      'hideWeight': product.hideWeight,
      'translations': []


    };

    offerRevisionProduct = this.setOfferRevisionProductTranslations(offerRevisionProduct, product);

    return offerRevisionProduct;

  }

  setOfferRevisionProductTranslations(offerRevisionProduct, product) {

    let locales = ['en', 'es', 'de', 'ru'];

    locales.forEach(singleLocale => {
      if (_.isNil(this.getTranslation(product, singleLocale)) || _.isUndefined(this.getTranslation(product, singleLocale))) {
        product['translations'].push({
          descriptionText: '',
          descriptionHelperText: '',
          locale: singleLocale
        });
      }

    });

    product.translations.forEach(translation => {
      offerRevisionProduct['translations'].push({
        descriptionText: translation.descriptionText,
        descriptionHelperText: translation.descriptionHelperText,
        locale: translation.locale
      })
    });


    return offerRevisionProduct;
  }

  getTranslationText(object, property) {

    try {
      let translation = object.translations.find(x => x.locale == this.translate.currentLang);
      if (_.isNil(translation) || _.isUndefined(translation)) {
  
        translation = object.translations.find(x => x.locale == 'en');
      }
      return translation[property];
    }
    catch(e){
      console.log("in catch e");
      return "Error in translations, remove and add the product to basket please";
    }

 

  }


  getTranslation(object, localeWanted) {

    let translation = object.translations.find(x => x.locale == localeWanted);

    if (_.isNil(translation) || _.isUndefined(translation)) {
      object['translations'].push({
        descriptionText: '',
        descriptionHelperText: '',
        locale: localeWanted
      });

      return this.getTranslation(object, localeWanted);
    }

    return translation;

  }

  getTranslationPosition(item) {

    let translationObject = item.translations.find(x => x.locale == item.selectedTranslation);

    let position = item.translations.indexOf(translationObject);

    //fallback
    if (position <= -1) {
      translationObject = item.translations.find(x => x.locale == 'en');
      position = item.translations.indexOf(translationObject);
    }

    return position;

  }


  selectProduct(item) {

    if (!_.isNull(item.selected) && !_.isUndefined(item.selected)) {

      item.selected = !item.selected;
    } else {
      item.selected = true;
    }


  }

  getSelectedCategory() {
    return this.state.selectedCategory;
  }

  setSelectedCategroy(category) {
    this.state.selectedCategory = category;
  }


  getEmptyProduct() {

    let totalQuantity = 1;

    if (this.isStandardAccesories()) {
      totalQuantity = this.offerRevisionSilo.quantity;
    }

    return {
      aggregationCode: 'SPEC',
      calculatedQuantity: 0,
      selected: false,
      optional: false,
      order: null,
      cost: 0,
      quantity: 1,
      totalQuantity: totalQuantity,
      special: true,
      weight: 0,
      hidePrice: true,
      hideWeight: true,
      translations: [

        {
          descriptionText: '',
          locale: 'en'
        },

        {
          descriptionText: '',
          locale: 'es'
        },
        {
          descriptionText: '',
          locale: 'de'
        },
        {
          descriptionText: '',
          locale: 'ru'
        }
      ]
    };
  }

  getOriginalProduct(product_code): Promise<any> {


    return this.constitutionsService.getProductBy(product_code, this.offer.pricesDate).then(product => {

      return product;

    }).catch(error => {

      return null;
    });
  }

  trackProductChange(product) {

    if (product.aggregationCode == 'SPEC') {
      return;
    }

    let original = this.getOriginalProduct(product.aggregationCode).then(original => {

      if (!_.isNull(original)) {

        if (this.userData.user.isInternal) {

          if (isNaN(product.postCost)) {

            product.cost = original.cost;
          }

        }

        if (!_.isNull(original) && !_.isUndefined(original) &&
          (!_.isEqual(original.translations[0].descriptionText.trim(), product.translations[0].descriptionText.trim()) ||
            !_.isEqual(original.translations[1].descriptionText.trim(), product.translations[1].descriptionText.trim()) ||
            original.cost.toFixed(2) != product.cost.toFixed(2) ||
            original.weight.toFixed(1) != product.weight.toFixed(1)
          )
        ) {


          product.special = true;
          product.aggregationCode = 'SPEC';


        } else {
          product.special = false;
          product.aggregationCode = original.aggregationCode;

        }

      }

    });

  }

  trackProductChangePrice(product) {

    if (this.userData.user.isInternal) {

      if (product.postCost > 0) {
        product.cost = product.postCost;
      }
    }

    this.trackProductChange(product);

  }

  changeBasketDescriptionLanguage() {
    this.activeParentCategory.basket.map((x) => x.selectedTranslation = this.basketDescriptionLanguage);
  }


  onSubmit() {


  }


  closeModal() {

    this.activeModal.close();
  }


  stopPropagation(event) {
    event.stopPropagation();
  }


  moveItemUp(itemIdx) {
    if (itemIdx >= 1) {
      let self = this.activeParentCategory.basket[itemIdx];
      let previous = this.activeParentCategory.basket[itemIdx - 1];

      const self_order = self.order;
      const previous_order = previous.order;


      this.activeParentCategory.basket[itemIdx] = previous;
      this.activeParentCategory.basket[itemIdx - 1] = self;

      this.activeParentCategory.basket[itemIdx].order = self_order;
      this.activeParentCategory.basket[itemIdx - 1].order = previous_order;
    }
  }

  moveItemDown(itemIdx) {
    if (itemIdx < this.activeParentCategory.basket.length - 1) {
      let self = this.activeParentCategory.basket[itemIdx];
      let next = this.activeParentCategory.basket[itemIdx + 1]

      const self_order = self.order;
      const next_order = next.order;


      this.activeParentCategory.basket[itemIdx] = next;
      this.activeParentCategory.basket[itemIdx + 1] = self;


      this.activeParentCategory.basket[itemIdx].order = self_order;
      this.activeParentCategory.basket[itemIdx + 1].order = next_order;
    }
  }


  removeItem(item) {

    this.offerService.deleteOfferSiloProduct(this.offerRevisionSilo.id, item.id, this.offerRevisionSilo.model).then(response => {


      const itemIdx = this.activeParentCategory.basket.indexOf(item);
      this.activeParentCategory.basket.splice(itemIdx, 1);
      this.activeParentCategory['basket'].sort(this.compare);

      let order = 0;
      for (let product of this.activeParentCategory['basket']) {
        product.order = order;
        order++;
      }

    }).catch(error => {

    })

  }


  hidePriceItem(item) {

    item.hidePrice = !item.hidePrice;

  }

  hideWeight(item) {
    item.hideWeight = !item.hideWeight;
  }


  duplicateItem(item) {


    let order = this.isBasketEmpty() ? 0 : this.getLastElementOrder();

    this.setBasketSavingFlags();

    let product = _.cloneDeep(item);

    product = this.serializeProduct(product);
    product.order = order;

    this.offerService.createOfferSiloProduct(this.offerRevisionSilo.id, product, this.offerRevisionSilo.model).then(offerProduct => {
 

      this.setBasketSavingFlags();
      this.activeParentCategory['basket'].push(_.cloneDeep(offerProduct));
      this.activeParentCategory['basket'].sort(this.compare);


    }).catch(error => {
      this.setBasketSavingFlags();
    });


  }


  createEmptySpec() {

    if (this.basketRestOperationInCourse || this.basketSpecialRestOperationInCourse) {
      return;
    }

    let order = this.isBasketEmpty() ? 0 : this.getLastElementOrder();

    let product = this.serializeProduct(this.getEmptyProduct());
    product.order = order;
    product.hidePrice = this.offerRevisionSilo.hidePrice;
    product.hideWeight = this.offerRevisionSilo.hideWeight;

    this.basketRestOperationInCourse = true;
    this.basketSpecialRestOperationInCourse = true;

    this.offerService.createOfferSection(this.offer, this.activeParentCategory).then(offerSection => {

      this.offerService.createOfferSiloProduct(this.offerRevisionSilo.id, product, this.offerRevisionSilo.model).then(offerProduct => {
        offerProduct.selectedTranslation = this.basketDescriptionLanguage;
        this.activeParentCategory['basket'].push(_.cloneDeep(offerProduct));
        this.activeParentCategory['basket'].sort(this.compare);

        this.basketRestOperationInCourse = false;
        this.basketSpecialRestOperationInCourse = false;

      }).catch(error => {
        this.basketRestOperationInCourse = false;
        this.basketSpecialRestOperationInCourse = false;
      });

    });

  }


  switchSpecialCategoryFilter() {

  }

  allowedOfferRevisionSilos() {

    let allowedOfferRevisionSilos = [];

    for (let silo of this.offerRevisionSilos) {

      if (!_.isEqual(silo.id, this.offerRevisionSilo.id)) {

        allowedOfferRevisionSilos.push(_.cloneDeep(silo));

      }

    }


    return allowedOfferRevisionSilos;

  }

  increaseQuantity(product) {

    product.quantity++;
  }

  decreaseQuantity(product) {
    if (product.quantity > 1) {
      product.quantity--;
    }
  }


  focusSwitchAddItems() {
    this.switchSilo = !this.switchSilo;
  }

  loadingPreview: boolean = false;
  proformaHTML: string = '';

  switchEquipmentView() {
    this.activeSubViewSwitch = !this.activeSubViewSwitch;

    if (this.activeSubViewSwitch) {

      this.setBasketSavingFlags();
      this.loadingPreview = true;
      let basket = this.activeParentCategory['basket'];

      const editBasketProducts = product => {

        product = this.serializeProductBasket(product);
        return this.offerService.editOfferSiloProduct(this.offerRevisionSilo.id, product, this.offerRevisionSilo.model)
      }


      const source = of(basket);
      const editedProducts = source.pipe(mergeMap(q => forkJoin(...q.map(editBasketProducts))), catchError(error => of(error)));
      const subscribe = editedProducts.subscribe(val => {

        this.setBasketSavingFlags();


        this.offerService.downloadEquipmentPreview(this.offer, this.offerRevisionSilo.id, this.offerRevisionSilo.model, this.translate.currentLang).then(html => {

          this.loadingPreview = false;
          this.proformaHTML = html._body;


        }).catch(error => {

          this.loadingPreview = false;

        });
      });


    }

  }

  downloadEquipmentPDF(locale) {


    let basket = this.activeParentCategory['basket'];

    if (!_.isEmpty(basket)) {

      this.setBasketSavingFlags();
      this.downloadingFile = true;

      const editBasketProducts = product => {

        product = this.serializeProductBasket(product);
        return this.offerService.editOfferSiloProduct(this.offerRevisionSilo.id, product, this.offerRevisionSilo.model)
      }


      const source = of(basket);
      const editedProducts = source.pipe(mergeMap(q => forkJoin(...q.map(editBasketProducts))), catchError(error => {

        this.downloadingFile = false;
        this.setBasketSavingFlags();
        return of(error)
      }));

      const subscribe = editedProducts.subscribe(val => {

        this.setBasketSavingFlags();


        this.offerService.downloadEquipmentPDF(this.offer, this.offerRevisionSilo.id, this.offerRevisionSilo.model, locale)
        .map((res) => res.blob())
        .subscribe(data => {
            this.downloadingFile = false;
            const blob = new Blob([data], {type: 'application/pdf'});

            saveAs(blob, 'Equipment-list' + '-' + this.offer.fileRef);

          },

          error => {
            this.downloadingFile = false;
          

          }
        )

      });
    }

  }

  //regular means no infinite behaviour, filter on or third category childs
  isRegularListing() {

    return this.filter;

    //return true;
  }

  isDefaultCategoryForModel(categoryId) {

    let defaultCategoryIds = this.constitutionsService.getDefaultCategoriesByModel(this.offerRevisionSilo.silo);

    if (defaultCategoryIds.indexOf(categoryId) == -1) {
      return false;
    } else {
      return true;
    }

  }

  userCanSwitchFocusView() {
    return this.userData.user.isInternal || this.userData.user.isAdmin;
  }

  userCanEditBasketWeightAndCost() {
    return this.userData.user.isInternal || this.userData.user.isAdmin;
  }

  userCanEditBasketDescription(item) {
    return this.userData.user.isInternal || this.userData.user.isAdmin || (this.userData.user.isExternal && item.special && !this.isCatlwakStiffener(item));
  }

  isCatlwakStiffener(item) {
    let itemEnTransaltion = this.getTranslation(item, 'en');

    if (itemEnTransaltion.descriptionText == 'Catwalk stiffeners reinforcement') {
      return true;
    }

    return false;
  }

  userCanEditWeight() {
    return this.userData.user.isInternal || this.userData.user.isAdmin;
  }

  userCanSeeDirectCost() {
    return this.userData.user.isAdmin;
  }

  allowToPlay() {
    return this.userCanEditEquipmentOffer();
  }

  userCanEditEquipmentOffer() {

    let canEdit = false;

    for (let role of this.userData.user.roles) {
      if (this.offer.state === 0) {
        if (((role === 'EXTERNAL_AGENT' || role === 'EXTERNAL_ADMIN') && this.offer.stateChangePending != true) || role === 'INTERNAL_AGENT' || role === 'INTERNAL_ADMIN') {
          canEdit = true;
        }
      } else if (this.offer.state === 1) {
        if (role === 'INTERNAL_AGENT' || role === 'INTERNAL_ADMIN') {
          canEdit = true;
        }
      } else if (this.offer.state === 2) {
        if (role === 'INTERNAL_ADMIN') {
          canEdit = true;
        }
      }
    }

    if (canEdit) {
      if (this.offerFreezged()) {
        canEdit = false;
      }
    }

    return canEdit;
  }

  offerFreezged() {
    return this.offer.freezeTarget == true;
  }


  userCanEditCodes() {


    return (!this.disabledCodes && (this.userData.user.isInternalTechnical || this.userData.user.isAdmin));

  }

  canSeeProductList() {
    return !this.userData.user.isInternalTechnical && !this.userData.user.isInternalProduction && !this.userData.user.isInternalAdministrative;
  }

  selectItem(idx) {
    if (this.basketSelectedItems.indexOf(idx) !== -1) {
      this.basketSelectedItems.splice(this.basketSelectedItems.indexOf(idx), 1)
    } else {
      this.basketSelectedItems.push(idx);
    }
  }

  selectAllBasket() {
    if (this.basketSelectedItems.length > 0) {
      this.basketSelectedItems = [];
      return;
    }

    for (const item of this.activeParentCategory.basket) {
      this.selectItem(item);
    }
  }

  basketRemoveSelected() {
    this.offerService.deleteOfferProductBulk(this.offer, this.basketSelectedItems.map(item => item.id)).then(response => {


      for (const item of this.basketSelectedItems) {
        const itemIdx = this.activeParentCategory.basket.indexOf(item);
        this.activeParentCategory.basket.splice(itemIdx, 1);
        this.activeParentCategory['basket'].sort(this.compare);
      }

      let order = 0;
      for (let product of this.activeParentCategory['basket']) {
        product.order = order;
        order++;
      }

      this.basketSelectedItems = [];
    }).catch(error => {
     
    })
  }

  basketRemoveAll() {
    this.offerService.deleteOfferProductBulk(this.offer, this.activeParentCategory.basket.map(item => item.id)).then(response => {


      for (const item of this.activeParentCategory.basket) {
        const itemIdx = this.activeParentCategory.basket.indexOf(item);
        this.activeParentCategory.basket.splice(itemIdx, 1);
        this.activeParentCategory['basket'].sort(this.compare);
      }

      let order = 0;
      for (let product of this.activeParentCategory['basket']) {
        product.order = order;
        order++;
      }

    }).catch(error => {
    
    })
  }
}


@NgModule({
  declarations: [PersonalizeSiloModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    CurrencyMaskModule,
    CustomPipesModule,
    NgbModule,
    InfiniteScrollerModule
  ],
  exports: [
    PersonalizeSiloModalComponent
  ],
  providers: [

    TranslateService
  ]
})

export class PersonalizeSiloModalModule {
}
