import {Component, OnInit, ViewChild} from '@angular/core';
import {Company} from './company';
import {CompanyService} from './company.service';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {DelConfModalComponent} from '../../shared/delete-confirmation-modal.component';
import {LoginService} from '../login/login.service';

import { CreateCompanyModalComponent } from './create-company/create-company-modal.component';



@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})

export class CompanyComponent implements OnInit {

  companies: Company[];
  userData: any;
  orderByField = 'name';
  reverseSort = false;
  dtRendered = false;
  loadingCompanies: boolean = false;
  dtOptions: DataTables.Settings = {};

  constructor(
    private router: Router,
    private companyService: CompanyService,
    private modalService: NgbModal,
    private loginService: LoginService,
  ) { }


  ngOnInit() {

    /* Opciones para data tables */
    this.dtOptions = {
     "order": [[ 1, "asc" ]],
      responsive: true,
      paging: true,
      "language": {
        "decimal": ",",
        "thousands": "."
      }
    };

    console.log("Hello Companies");
    this.getCompanies();
    this.getUserData();
  }

  getUserData() {
    this.loginService.getUser()
      .then(user => this.userData = user);
  }

  getCompanies(): void {

    this.loadingCompanies = true;
    this.companyService.getCompanies()
      .then(companies => {
        this.companies = companies;

        this.loadingCompanies = false;
      })
      .catch(error => {
        this.loadingCompanies = false;
      });


  }


  openDeleteModal(company) {
    const modal = this.modalService.open(DelConfModalComponent, {
      backdrop: 'static',
      keyboard: false,
      // size: 'sm',
      windowClass: 'rmv-modal',
    });
    modal.componentInstance.text = '<b>' + company.name + '</b>, ' + company.address + ', ' + company.cif;
    modal.componentInstance.type = 'company';
    modal.result.then((result) => {
      if (result === 'Accept') {
        this.removeCompnay(company);
      } else { }
    });
  }

  removeCompnay(company: Company): void {
    this.companyService.deleteCompany(company.id)
      .then(response => this.getCompanies());
  }

  trackByFn(index, item) {
    return index;
  }

  getDefaultOffice(company){

    for (let office of company.offices){

       if(office.isDefault)
       {
         return office;
       }

    }

    return null;

  }

  canEditCompany(company){

    if(this.userData.user.isExternal && this.userData.user.person.office.company.id == company.id)
    {
        return false;
    }

    return true;

  }

  checkingCustomer:boolean = false;
  checkCompanyNav(company){
    this.checkingCustomer = true;
    this.companyService.checkNavCustomer(company.id)
    .then(response => {
      console.log("response check comapny", response);
      this.checkingCustomer = false;

    })
    .catch(error => {
      this.checkingCustomer = false;
    });
  }

  createCompanyModal() {
    const modalRef = this.modalService.open(CreateCompanyModalComponent, {  size: 'lg', windowClass: 'company-modal' });
    modalRef.componentInstance.newCompany.subscribe((company: Company) => {
      this.getCompanies();
       modalRef.close();
     });
     modalRef.result.then((result) => {
     }, (dismiss) => {

     });
   }

}
