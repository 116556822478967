/**
 * Created by juan on 21/06/17.
 */

import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router} from '@angular/router';
import { GroupGuardService } from './group-guard.service';
import {Location, LocationStrategy} from '@angular/common';

@Injectable()
export class GroupGuard implements CanActivateChild {
  constructor(
    private groupGuardService: GroupGuardService,
    private router: Router,
    private location: Location,
    private platformStrategy: LocationStrategy
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    return this.groupGuardService.canActivateRoute(route.data['component'])
      .then(canNavigate => {
        if (!canNavigate) {
          this.router.navigate(['404']);
          return false;
        }
        return canNavigate;
      });
  }
}
