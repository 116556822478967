export enum DocFileType {
    PDF = 'application/pdf',
    EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export const DocumentTypes = {
    FullDocs: {name: 'PSS.offerProforma.FullDocs', type: 'full', fileType: DocFileType.PDF},
    Proforma: {name: 'PSS.offerProforma.Proforma', type: 'proforma', fileType: DocFileType.PDF},
    ComercialConditions: {name: 'PSS.offerProforma.ComercialConditions', type: 'commercial', fileType: DocFileType.PDF},
    DesignSpecifications: {name: 'PSS.offerProforma.DesignSpecifications', type: 'silo-design', fileType: DocFileType.PDF},
    EquipmentList: {name: 'PSS.offerProforma.EquipmentList', type: 'equipment-list', fileType: DocFileType.PDF},
    EquipmentListWeight: {name: 'PSS.offerProforma.EquipmentListWeight', type: 'equipment-list-weight', fileType: DocFileType.PDF},
    ExcelPrice: {name: 'PSS.offerProforma.ExcelPrice', type: 'price', fileType: DocFileType.EXCEL}
}

export const DocumentTypesExternal = {
    EquipmentList: {name: 'PSS.offerProforma.EquipmentList', type: 'equipment-list', fileType: DocFileType.PDF},
}

export const OrderDocumentTypes = {
    AdministrationDownload: {name: 'PSS.orderProforma.AdministrationDownload', type: 'administrative', fileType: DocFileType.PDF},
    TechnicalDownload: {name: 'PSS.orderProforma.TechnicalDownload', type: 'technical', fileType: DocFileType.PDF},
    EquipmentCodes: {name: 'PSS.orderProforma.EquipmentCodes', type: 'equipment-codes', fileType: DocFileType.PDF},
    EquipmentListWeight: {name: 'PSS.offerProforma.EquipmentListWeight', type: 'equipment-weight', fileType: DocFileType.PDF}
}


export const OrderDocumentTypesInternalAgent = {
    AdministrationDownload: {name: 'PSS.orderProforma.AdministrationDownload', type: 'administrative', fileType: DocFileType.PDF},
    TechnicalDownload: {name: 'PSS.orderProforma.TechnicalDownload', type: 'technical', fileType: DocFileType.PDF},
    EquipmentCodes: {name: 'PSS.orderProforma.EquipmentCodes', type: 'equipment-codes', fileType: DocFileType.PDF},
    EquipmentListWeight: {name: 'PSS.offerProforma.EquipmentListWeight', type: 'equipment-weight', fileType: DocFileType.PDF}
}

export const OrderDocumentTypesInternalTechnical = {
   
    TechnicalDownload: {name: 'PSS.orderProforma.TechnicalDownload', type: 'technical', fileType: DocFileType.PDF},
    EquipmentCodes: {name: 'PSS.orderProforma.EquipmentCodes', type: 'equipment-codes', fileType: DocFileType.PDF},
    EquipmentListWeight: {name: 'PSS.offerProforma.EquipmentListWeight', type: 'equipment-weight', fileType: DocFileType.PDF}
}

export const OrderDocumentTypesInternalAdministrative = {
    AdministrationDownload: {name: 'PSS.orderProforma.AdministrationDownload', type: 'administrative', fileType: DocFileType.PDF},
}

export const OrderDocumentTypesInternalProduction = {
  
    TechnicalDownload: {name: 'PSS.orderProforma.TechnicalDownload', type: 'technical', fileType: DocFileType.PDF},
    EquipmentCodes: {name: 'PSS.orderProforma.EquipmentCodes', type: 'equipment-codes', fileType: DocFileType.PDF},
    EquipmentListWeight: {name: 'PSS.offerProforma.EquipmentListWeight', type: 'equipment-weight', fileType: DocFileType.PDF}

}

export interface DocTypeEnum {
    name: string;
    type: string;
    fileType: DocFileType;
}
