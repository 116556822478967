/**
 * Created by juan on 4/07/17.
 */

import * as _ from 'lodash';

import { Order } from '../job-order/order';

export class Offer {
  title: string;
  id: number;
  idTempOffer: number;
  idOffer: number;
  yearGroup: number;
  revision: number;
  customer: any;
  idc: string;
  user: any;
  idu: string;
  ids: string;
  stg: string;
  sts: string;
  parent: number;
  currency: any;
  lang_id: number;
  state: number;
  status: string;
  offerRevisionSilos: any[];
  offerRevisionProducts: any[];
  country: any;
  cityOfInstallation: any;
  creationDate: string;
  ofCreationDate: string;
  pricesDate: string;
  offer: any;
  placeOfDelivery: any;
  placeOfDeliveryAddress: string;
  placeOfDeliveryZipCode: string;
  bankDetails: any;
  exclusions: any[];
  typeOfSupply: any;
  scopeOfSupplyOverride: string;
  scopeOfSupply: string;
  termsOfDelivery: any;
  formattedHeadings: string;
  currencyChangeRate: number;
  configPriceBehaviour: number;
  transport: {trailer: {}, container20: {}, container40: {}, transportBy?: boolean, markUp?: number, showTransport?: boolean};

  transportCalculation: any;
  allowChangeNextState: boolean;
  discount: number;
  commissions = [];
  commissionsCalc: boolean;
  price: number;
  targetPrice: number;
  weight: number;
  stateChangePending: boolean;
  transportBy: number;
  dateAvailabilityStart: number;
  dateAvailabilityEnd: number;
  dateAvailabilityOverride: string;
  paymentTerm: any;
  paymentTerm1: number;
  paymentTerm2: number;
  paymentTerm3: number;
  paymentDays1: number;
  paymentDays2: number;
  paymentDays3: number;
  paymentTermsOverride: string;

  validity: number;
  validityOverride: string;

  headingsIntakeCapacity: number;
  headingsDeliveryCapacity: number;
  capacityTotal: number;
  headingsOverride: string;

  inquiryInformationInquiryBy: any;
  inquiryInformationInquiryByOverride: string;
  inquiryInformationProjectName: string;

  inquiryInformationQuotedBy: any;
 
  
  finalPrice: number;
  exWorksPrice: number;
  exWorksPriceList: number;
  exWorksCost: number;
  transportPrice: number;
  siloErectionPrice: number;
  supervision: number;
  servicePrice: number;
  
  commissionRate: number;
  targetDiscount: number;
  targetCommissionRate: number;

  markupSupervision: number;
  markupSiloErection: number;
  markupTransport: number;
  markupService: number;
  freezeTarget: boolean;
  targetUsed: boolean;
  
  father: any;
  fileRef: string;

  offerRevisionCommissions = [];
  adquired: boolean;

  orderRevision: Order;
  generalCoefficient: any;

  historyNotes: Array<any>;

  constructor() {
    this.offerRevisionSilos = [];
    this.customer = null;
    this.country = null;
    this.cityOfInstallation = null;
    this.exclusions = [];
    this.configPriceBehaviour = 0;
    this.transport = {trailer: {}, container20: {}, container40: {}};
  }

  // STEPS VALIDATORS
  static validateCustomer(offer: Offer) {
    if ( _.isUndefined(offer.customer) || _.isNull(offer.customer) || !_.isObject(offer.customer)) {
      return {step: 1, errors: ['all']};
    }

    return {step: 1, errors: []};
  }

  static validateSiteOfInstallation(offer: Offer) {
    
    if ( _.isUndefined(offer.cityOfInstallation) || _.isNull(offer.cityOfInstallation) || !_.isObject(offer.cityOfInstallation)) {
      return {step: 2, errors: ['all']};
    }

    return {step: 2, errors: []};
  }

  static validateUpdatePrices(offer: Offer) {
    if (offer.configPriceBehaviour === undefined || offer.configPriceBehaviour === null) {
      return {step: 3, errors: ['Price behaviour']};
    }

    return {step: 3, errors: []};
  }

  static validateDateAvailability(offer: Offer) {
    
    let errors = [];
  
    if ((offer.dateAvailabilityStart === undefined || offer.dateAvailabilityStart === null) &&
        (offer.dateAvailabilityEnd === undefined || offer.dateAvailabilityEnd === null)) {
      
          return {step: 4, errors: ['Date Availability']};
    }

    return {step: 4, errors: []};
  }

  static validateTermsOfDelivery(offer: Offer) {
    if (offer.termsOfDelivery === undefined || offer.termsOfDelivery === null) {
      return {step: 5, errors: ['Terms of delivery']};
    }

    return {step: 5, errors: []};
  }

  static validatePlaceOfDelivery(offer: Offer) {
    let errors = [];
  
    if(offer.termsOfDelivery.name === 'CFR' ||
              offer.termsOfDelivery.name === 'CIF' ){
        
        if(_.isNil(offer.placeOfDelivery.id)){
          errors.push("City and Country");
        }
    }
    else if(offer.termsOfDelivery.name === 'CPT' ||
              offer.termsOfDelivery.name === 'CIP' ||
              offer.termsOfDelivery.name === 'DAT' ||
              offer.termsOfDelivery.name === 'DAP' ||
              offer.termsOfDelivery.name === 'DDP' ){


        if(_.isNil(offer.placeOfDelivery.id)){
          errors.push("City and Country");
        }
        if(_.isNil(offer.placeOfDeliveryZipCode)){
          errors.push("ZIP Code");
        }
        if(_.isNil(offer.placeOfDeliveryAddress)){
          errors.push("Address");
        }

    }


    return {step: 6, errors: errors};
  }

  static validatePaymentTerms(offer: Offer) {
    let errors = [];
    
    if((!_.isNil(offer.paymentTermsOverride) && !_.isEmpty(offer.paymentTermsOverride))){
      return {step: 7, errors: errors};
    } 
    else if(!_.isNil(offer.paymentTerm)){

      switch(offer.paymentTerm.name){

          default:
            
       

            if( (!_.isNil(offer.paymentTermsOverride) && !_.isEmpty(offer.paymentTermsOverride)) ||
              offer.paymentTerm1 + offer.paymentTerm2 + offer.paymentTerm3 == 100
            ){
              
            }
            else{
              errors.push('The addition of percentages is not 100');
            }
            
            break;

          case "TRAN":
            break;

      }

   
    }
    else{
       errors.push('No Payment Term Selected');
    }
  
    

    return {step: 7, errors: errors};
  }

  static validateBankDetails(offer: Offer) {
    if (offer.bankDetails === undefined || offer.bankDetails === null) {
      return {step: 8, errors: ['Bank details']};
    }

    return {step: 8, errors: []};
  }

  static validateOfferValidity(offer: Offer) {
    let errors = [];
    if (offer.validityOverride === undefined || offer.validityOverride === null || offer.validityOverride === '') {
      if (offer.validity === undefined || offer.validity === null) {
        errors.push('all');
      }
    }

    return {step: 9, errors: errors};
  }

  static validateHeadings(offer: Offer) {
    let errors = [];
  

    return {step: 12, errors: errors};
  }

  static validateTypeOfSupply(offer: Offer) {
    if (offer.typeOfSupply === undefined || offer.typeOfSupply === null) {
      return {step: 11, errors: ['Type of supply']};
    }

    return {step: 11, errors: []};
  }

  static validateInquiryInfo(offer: Offer) {
    let errors = [];
    if (offer.inquiryInformationProjectName === undefined || offer.inquiryInformationProjectName === null) {
      errors.push('Project name');
    }

    if ((offer.inquiryInformationInquiryBy === undefined || offer.inquiryInformationInquiryBy === null) &&
        (offer.inquiryInformationInquiryByOverride === undefined || offer.inquiryInformationInquiryByOverride === null)) {
      errors.push('Inquiry by');
    }

    return {step: 13, errors: errors};
  }

  static validateTransport(offer: Offer) {
    let errors = [];

    /*if (offer.termsOfDelivery.name !== 'EXW' && offer.termsOfDelivery.name !== 'FCA') {
      if(offer.transport.transportBy === false) {
        if (offer.transport.container20['quantity'] === undefined || offer.transport.container20['quantity'] === null) {
          errors.push('Container 20 quantity');
        }
        if (offer.transport.container20['costPerUnit'] === undefined || offer.transport.container20['costPerUnit'] === null) {
          errors.push('Container 20 cost per unit');
        }

        if (offer.transport.container40['quantity'] === undefined || offer.transport.container40['quantity'] === null) {
          errors.push('Container 40 quantity');
        }
        if (offer.transport.container40['costPerUnit'] === undefined || offer.transport.container40['costPerUnit'] === null) {
          errors.push('Container 40 cost per unit');
        }
      } else if (offer.transport.transportBy === true) {
        if (offer.transport.trailer['quantity'] === undefined || offer.transport.trailer['quantity'] === null) {
          errors.push('Trailer quantity');
        }
        if (offer.transport.trailer['costPerUnit'] === undefined || offer.transport.trailer['costPerUnit'] === null) {
          errors.push('Trailer cost per unit');
        }
      }
    }*/

    return {step: 14, errors: errors};
  }

  static validateCurrency(offer: Offer) {
    if (offer.currency.symbol !== 'EUR') {
      if (offer.currencyChangeRate === undefined || offer.currencyChangeRate === null) {
        return {step: 17, errors: ['Currency change rate']};
      }
    }

    return {step: 17, errors: []};
  }

  static validateProdcuts(offer: Offer){
    if(!this.hasSilos(offer)){
        return {step: 0, errors: ['PSS.offerErrors.NoProductsAdded']};
        
    }
    if(!this.hasAFiexSilo(offer)){
        return {step: 0, errors: ['All Optional Prodcuts']};
        
    }
    
    return {step: 0, errors: []};
     
    
  }

  static checkSteps(offer) {
    let steps = [];
    if (offer.state >= 0 && offer.state < 3) {
      //steps.push(this.validateProdcuts(offer));
      steps.push(this.validateCustomer(offer));
      steps.push(this.validateSiteOfInstallation(offer));
    }
    if (offer.state >= 1 && offer.state < 3) {
     // steps.push(this.validateProdcuts(offer));
      steps.push(this.validateCustomer(offer));
      steps.push(this.validateSiteOfInstallation(offer));
      
      steps.push(this.validateUpdatePrices(offer));
      steps.push(this.validateDateAvailability(offer));
      steps.push(this.validateTermsOfDelivery(offer));
      steps.push(this.validatePlaceOfDelivery(offer));
      steps.push(this.validatePaymentTerms(offer));
      steps.push(this.validateBankDetails(offer));
      steps.push(this.validateOfferValidity(offer));
      steps.push(this.validateHeadings(offer));
      steps.push(this.validateTypeOfSupply(offer));
      steps.push(this.validateInquiryInfo(offer));
      steps.push(this.validateTransport(offer));
      steps.push(this.validateCurrency(offer));
    }
    return steps;
  }

  static hasSilos(offer){

    let hasSilo = offer.offerRevisionSilos.some(function(offerSilo){

      let agCode = offerSilo.silo ? offerSilo.silo.aggregationCode : offerSilo.aggregationCode;

      return _.startsWith(agCode, 'S') || _.startsWith(agCode, 'K');

    });

    return hasSilo;

  }

  static hasAFiexSilo(offer){


    let hasFiexSilo = offer.offerRevisionSilos.some(function(offerSilo){


      return !offerSilo.optional || offerSilo.optional == 0 || offerSilo.optional === false;

    });

    return hasFiexSilo;

  }



}