import { ComponentKeyValue, ComponentsRelation, generateRelations } from '../data-types/components-relation';

export const AnchorBoltTypes: ComponentKeyValue[] = [
    {
        key: 'STRAIGHT',
        value: 'STRAIGHT'
    },
    {
        key: 'CORRUGATED',
        value: 'CORRUGATED'
    },
    {
        key: 'CHEMICAL_HIT_RE',
        value: 'CHEMICAL_HIT_RE'
    },
    {
        key: 'CHEMICAL_WURTH',
        value: 'CHEMICAL_WURTH'
    },
    {
        key: 'CHEMICAL_HVU_HAS',
        value: 'CHEMICAL_HVU_HAS'
    },
    {
        key: 'MECHANICAL_HSA',
        value: 'MECHANICAL_HSA'
    },
    {
        key: 'MECHANICAL_WURTH',
        value: 'MECHANICAL_WURTH'
    }
];

export const AnchorBoltMaterials: ComponentKeyValue[] = [
    {
        key: 'S-275-JR',
        value: 'S-275-JR'
    },
    {
        key: 'S-355-JR',
        value: 'S-355-JR'
    },
    {
        key: 'FeB 44K',
        value: 'FeB 44K'
    },
    {
        key: 'B-500-S',
        value: 'B-500-S'
    },
    {
        key: 'B-400-S',
        value: 'B-400-S'
    },
    {
        key: 'c5.8',
        value: 'c5.8'
    }
];

// ComponentRelations<K, T> is optional as it defaults to: K = string | T = ComponentKeyValue[]
export const AnchorBoltTypesMaterials: ComponentsRelation = generateRelations(
    {
        value: AnchorBoltTypes[0],
        relations: [AnchorBoltMaterials[0], AnchorBoltMaterials[1]]
    },
    {
        value: AnchorBoltTypes[1],
        relations: [AnchorBoltMaterials[2], AnchorBoltMaterials[3], AnchorBoltMaterials[4]]
    },
    {
        value: AnchorBoltTypes[2],
        relations: [AnchorBoltMaterials[5]]
    },
    {
        value: AnchorBoltTypes[3],
        relations: [AnchorBoltMaterials[5]]
    },
    {
        value: AnchorBoltTypes[4],
        relations: [AnchorBoltMaterials[5]]
    },
    {
        value: AnchorBoltTypes[5],
        relations: [AnchorBoltMaterials[5]]
    },
    {
        value: AnchorBoltTypes[6],
        relations: [AnchorBoltMaterials[5]]
    }
)
