import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef,  ChangeDetectionStrategy  } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgbModal, NgbTabset, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
//import { debounceTime } from 'rxjs/operator/debounceTime';
//import { distinctUntilChanged } from 'rxjs/operator/distinctUntilChanged';

import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import * as _ from 'lodash';
import { saveAs } from 'file-saver';

import { OfferService } from '../offer.service';
import { Offer } from '../offer';
import { OfferRevisionSilo } from '../offer-revision-silo';

import { UserService } from '../../../mts/user/user.service';
import { User } from '../../../mts/user/user';
import { CreateUserModalComponent } from '../../../mts/user/create-user/create-user-modal.component';

import { CompanyService } from '../../../mts/company/company.service';
import { CreateCompanyModalComponent } from '../../../mts/company/create-company/create-company-modal.component';
import { PersonalizeSiloModalComponent } from './personalize-silo-modal.component';
import { AddProductModalComponent } from './add-product-modal.component';
import { Company } from '../../../mts/company/company';

import { CityService } from '../../../shared/city.service';
import { MiscService } from '../../../shared/misc.service';

import { ConstitutionService } from '../../../scs/constitution/constitution.service';
import { CISService } from '../../../scs/cis/cis.service';
import { Silo } from '../../../scs/constitution/silo';
import { SiloDesignOptions } from '../../../scs/constitution/silo-design-options';
import { ProductCategory } from '../../../scs/constitution/productCategory';

import { LoginService } from '../../../mts/login/login.service';

import { ErrorBoxService } from '../../../shared/error-box/error-box.service';
import { DelConfModalComponent } from '../../../shared/delete-confirmation-modal.component';


import { LineJumpPipe } from '../../../pipes/line-jump.pipe';
import { LocaleCustomUnity } from '../../../pipes/custom-locale.pipe';

import { TranslateService } from '@ngx-translate/core';

import { mergeMap, catchError } from 'rxjs/operators';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { of } from 'rxjs/observable/of';


import { DocumentTypes, DocumentTypesExternal, DocTypeEnum, DocFileType } from '../../../shared/data-types/document-type';

@Component({

  selector: 'app-edit-offer',
  templateUrl: './edit-offer.component.html',
  styleUrls: ['./edit-offer.component.scss']
})
export class EditOfferComponent implements OnInit {

  companySearchAdd = [
    {
      html: `<i class="fa fa-plus-circle"></i> Add New`,
      fn: 'createCompanyModal'
    }
  ]

	notFound: boolean = false;
	processingReq: boolean = false;
  processingReqSaveOffer: boolean = false;
  processingReqLoadSilos: boolean = false;
  processingReqLoadProducts: boolean = false;
  processingReqOfferActions: boolean = false;
  controlPriceTarget: boolean = false;

  categoryProducts= [];

  discountWarning = false;
  negativeDiscountWarning = false;

  downloadingPdf = false;

	userData: any;
  commissionName: string;

  tabstatus = {};
  proforma = {error: false};


  minSiloQuantity = 1;

  offer: Offer;
  offerData = new Offer();

  //TODO refactor default values from web service
  cityPlaceholder = 'Utebo Aragón';
  cityPlaceOfDeliveryPlaceholder = 'Utebo Aragón';
  zipCodePlaceOfDeliveryPlaceholder = '50180';
  addressPlaceOfDeliveryPlaceholder = 'C/ Francia s/n';


  banks: any[];
  exclusions: any[];
  typesOfSupply: any[];
  currencies: any[];
  termsOfDelivery: any[];
  paymentTerms: any[];
  companyContacts: any[];
  quotedByUsers: User[];

  selectedCountry: any;
  selectedPlaceOfDeliveryCountry: any;

  customExclusion: any;
  siloIdx: any;

	silos: Array<Silo> = [];
 
  siloDesignOptions: SiloDesignOptions;

  currency: any;
  currencyChangeRate: any;

  offerSteps: FormGroup;
  customerControl: FormControl;
  siteOfInstallation: FormControl;
  placeOfDeliveryControl: FormControl;
  placeOfDeliveryCountry: FormControl;
  placeOfDeliveryAddressControl: FormControl;
  placeOfDeliveryZipCodeControl: FormControl;

  paymentDays1Control: FormControl;
  paymentDays2Control: FormControl;
  paymentDays3Control: FormControl;

  paymentTerm1Control: FormControl;
  paymentTerm2Control: FormControl;
  paymentTerm3Control: FormControl;

  headingsIntakeCapacityControl: FormControl;
  descriptionOfGoodsControl: FormControl;
  headingsDeliveryCapacityControl: FormControl;
  capacityTotalOverrideControl: FormControl;

  transportControl: FormControl;
  scopeOfSupplyOverrideControl: FormControl;
  scopeOfSupplyControl: FormControl;

  stepsErrors;

  @ViewChild('currencyChangeModal') currencyChangeModal;
  @ViewChild('sendToNextErrors') sendToNextErrors;
  @ViewChild('notAllowedModal') notAllowedModal;
  @ViewChild('siloDesign') siloDesignModal;
  @ViewChild('tabsOfferSteps')  tabsOfferSteps:NgbTabset;


  siloDesignModalRef; any;
 // private tabsOfferSteps:NgbTabset;


  @ViewChild('grainloadInstance') grainloadInstance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();


  @ViewChild('densityInstance') densityInstance: NgbTypeahead;
  focusDensity$ = new Subject<string>();
  clickDensity$ = new Subject<string>();

  @ViewChild('snowInstance') snowInstance: NgbTypeahead;
  focusSnow$ = new Subject<string>();
  clickSnow$ = new Subject<string>();

  @ViewChild('dischargeInstance') dischargeInstance: NgbTypeahead;
  focusDischarge$ = new Subject<string>();
  clickDischarge$ = new Subject<string>();

  @ViewChild('distanceStiffenersInstance') distanceStiffenersInstance: NgbTypeahead;
  focusDistanceStiffeners$ = new Subject<string>();
  clickDistanceStiffeners$ = new Subject<string>();

  @ViewChild('galvanisationRoofInstance') galvanisationRoofInstance: NgbTypeahead;
  focusGalvanisationRoof$ = new Subject<string>();
  clickGalvanisationRoof$ = new Subject<string>();

  @ViewChild('generalGalvanisationPaintingInstance') generalGalvanisationPaintingInstance: NgbTypeahead;
  focusGeneralGalvanisationPainting$ = new Subject<string>();
  clickGeneralGalvanisationPainting$ = new Subject<string>();


  @ViewChild('galvanisationWallSheetsInstance') galvanisationWallSheetsInstance: NgbTypeahead;
  focusGalvanisationWallSheets$ = new Subject<string>();
  clickGalvanisationWallSheets$ = new Subject<string>();

  @ViewChild('hopperInstance') hopperInstance: NgbTypeahead;
  focusHopper$ = new Subject<string>();
  clickHopper$ = new Subject<string>();

   @ViewChild('seismicZoneInstance') seismicZoneInstance: NgbTypeahead;
  focusSeismicZone$ = new Subject<string>();
  clickSeismicZone$ = new Subject<string>();

  documentTypes = {};

  swtichSilo: boolean;

  managmentData: any = {
    kprice: 0,
    kweight: 0,
    disscount: 0,
  };


  // Array customizado para separar los precios en las ofertas // 
  offersSeparatePrices: any[];
  priceOnlyAccesores = 0;
  priceOnlySilo = 0;
  priceSyloComplete = 0;
  priceExtrasAccesories = 0;
  productsPrice = 0;
  /*separatePrices: any = {
      siloPrice: 0,
      accesoriesPrice: 0
  };*/

  // Document types enum assignment for iteration on HTML


  download: { docType: any, lang: string };

  // Revision modal
  historyNote: any = {
    revision: 0,
    translations: [
      {
        locale: "en",
        description: ""
      },
      {
        locale: "es",
        description: ""

      }
    ]

};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private offerService: OfferService,
    private userService: UserService,
    private companyService: CompanyService,
    private cityService: CityService,
    private miscService: MiscService,
    private siloService: ConstitutionService,
    private cisService: CISService,
    private loginService: LoginService,
    private errorBox: ErrorBoxService,
    private cdr: ChangeDetectorRef,
    private _fb: FormBuilder,
    private lineJumpPipe: LineJumpPipe,
    private localeCustomUnity: LocaleCustomUnity,
    private translate: TranslateService
  ) {


    this.download = {docType: null, lang: this.translate.currentLang};
   }


  ngAfterViewInit(){

  }

  ngOnInit() {

    this.initFormControls();

   
    this.initDesignSpecs();
    this.getOffer();

    this.getDependencies();
    this.getCurrentTime();

    // get equipament products for silos 
    // this.getEquipmentCategoryBasket(); // si se el precio del silo puedo saber el precio 

    this.swtichSilo = true;

  }


  initFormControls()
  {
    this.customerControl = this._fb.control('');
    this.siteOfInstallation = this._fb.control('');
    this.placeOfDeliveryControl = this._fb.control('');

    this.placeOfDeliveryAddressControl = this._fb.control('');
    this.placeOfDeliveryZipCodeControl = this._fb.control('');
    this.placeOfDeliveryCountry = this._fb.control('');
    this.paymentDays1Control = this._fb.control('');
    this.paymentDays2Control = this._fb.control('');
    this.paymentDays3Control = this._fb.control('');
    this.paymentTerm1Control = this._fb.control('');
    this.paymentTerm2Control = this._fb.control('');
    this.paymentTerm3Control = this._fb.control('');

    this.headingsIntakeCapacityControl = this._fb.control('');
    this.descriptionOfGoodsControl = this._fb.control('');
    this.headingsDeliveryCapacityControl = this._fb.control('');
    this.capacityTotalOverrideControl = this._fb.control('');
    this.scopeOfSupplyOverrideControl = this._fb.control('');
    this.scopeOfSupplyControl = this._fb.control('');

    this.transportControl = this._fb.control('');


    this.offerSteps = this._fb.group({
      customer: [''],
      country: [''],
      cityOfInstallation: this.siteOfInstallation,
      capacityTotalOverride: this.capacityTotalOverrideControl,
      configPriceBehaviour: [''],
      currency: [''],
      currencyChangeRate: [''],
      bankDetails: [''],
      paymentTerm: [''],
      paymentTerm1: this.paymentTerm1Control,
      paymentTerm2: this.paymentTerm2Control,
      paymentTerm3: this.paymentTerm3Control,
      paymentDays1: this.paymentDays1Control,
      paymentDays2: this.paymentDays2Control,
      paymentDays3: this.paymentDays3Control,
      paymentTermsOverride: [''],
      dateAvailabilityStart: [''],
      dateAvailabilityEnd: [''],
      dateAvailabilityOverride: [''],
      termsOfDelivery: [''],
      placeOfDeliveryCountry: this.placeOfDeliveryCountry,
      placeOfDelivery: this.placeOfDeliveryControl,
      placeOfDeliveryAddress: this.placeOfDeliveryAddressControl,
      placeOfDeliveryZipCode: this.placeOfDeliveryZipCodeControl,
      validity: [''],
      validityOverride: [''],
      descriptionOfGoods: this.descriptionOfGoodsControl,
      headingsIntakeCapacity:  this.headingsIntakeCapacityControl,
      headingsDeliveryCapacity: this.headingsDeliveryCapacityControl,
      headingsOverride: [''],
      typeOfSupply: [''],
      scopeOfSupplyOverride: this.scopeOfSupplyOverrideControl,
      scopeOfSupply: this.scopeOfSupplyControl,
      inquiryInformationProjectName: [''],
      inquiryInformationInquiryBy: [''],
      inquiryInformationInquiryByOverride: [''],
      inquiryInformationQuotedBy: [''],
      inquiryInformationQuotedByOverride: [''],
      customExclusion: [''],
      transportMarckUp: [''],
      transportShowProforma: [''],
      transport: ['']
    });
  }



  initDesignSpecs(){

    this.siloDesignOptions = {
      grainLoads : this.siloService.getGrainLoads(),
      designDensities : this.siloService.getDesignDensities(),
      snowLoads : this.siloService.getSnowLoad(),
      dischargeModes : this.siloService.getDischargeModes(this.translate.currentLang),
      distancesBetweenStiffeners : this.siloService.getDistanceBetweenStiffeners(),
      galvanisationsRoof : this.siloService.getGalvanisationRoof(),
      generalGalvanisation : this.siloService.getGalvanisationRoof(),
      galvanisationsWallSheet : this.siloService.getGalvanisationWallSheets(),
      hoppers : this.siloService.getHoppers(),
      seismicZones: this.siloService.getSeismicZones()
    }




  }



 checkOfferSiloTranslations(offerRevisionSilo){

   if(_.isEmpty(offerRevisionSilo.translations)){

         offerRevisionSilo.translations = [
          {
            locale: "en",
            verticalHorizontalJointSealingText: this.siloService.getJointSealing("en"),
            boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(offerRevisionSilo.boltsGalvanizationMethod, "en")
          },
          {
            locale: "es",
            verticalHorizontalJointSealingText: this.siloService.getJointSealing("es"),
            boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(offerRevisionSilo.boltsGalvanizationMethod, "es")

          }
         ]

   }

   return offerRevisionSilo;

 }

  getOfferPrices(){

    this.offerData.exWorksPrice  = 0;
    this.offerData.exWorksPriceList = 0;
    this.offerData.exWorksCost  = 0;

    this.offerService.getOfferRevisionSegmentedPrice(this.offerData.offer.id, this.offerData.revision).then(prices => {

        this.offerData.exWorksPrice  = prices.exworks;
        this.offerData.exWorksPriceList = prices.exworksPriceList;
        this.offerData.exWorksCost  = prices.exworksCost;

        this.setCommissions();
        this.setTargetPrice();

    });
 }

  compareBydate(a,b) {
    if (a.creationDate < b.creationDate)
      return -1;
    if (a.creationDate > b.creationDate)
      return 1;
    return 0;
  }

 loadingOffer: boolean = false;
 loadingFieldPetkusOrder: boolean = false;
 referenceGeneralK: any;
 getOffer() {

    console.log(" Entra en getOffer(); ");

    // 

    this.loadingOffer = true;
    this.route.params
      .switchMap((params: Params) => this.offerService.getOfferByIdAndRev(params['id'], params['rev']))
      .subscribe(
      offer => {
        this.loadingOffer = false;
        //console.log(offer);
        this.offer = this.serializeOffer(offer);
        this.offerData = _.cloneDeep(this.offer);

        console.log(this.offerData);
        // console.log(this.offerData.customer.idNavision); // ?? --> 


        //if(this.offerData.customer.idNavision == 'CL000250' || this.offerData.customer.idNavision == 'CL000251'){
           // --> 
        this.loadingFieldPetkusOrder = true;
        //} else {
        this.loadingFieldPetkusOrder = true;
        console.log('No es petkus orders'); 
        //}

       

        this.initManagementData();
        this.offerData.offer.historyNotes.sort(this.compareBydate);
        this.getOfferProductSections(offer);
        this.getOfferPrices();
        this.getRevisionsOfOffer();

        this.processingReqLoadSilos = true;

        if(this.cisService.isInputFromCisAvailable()){

          // get silo caracteristis ?? --> 
          let silo = this.cisService.inputFromCis.recalculatedSilo;

          silo['kSection'] = 1;
          silo['cost'] = silo['costWithAnchors']*this.offerData.currencyChangeRate;
          silo['weight'] = silo['weightWithAnchors'];
          silo['quantity'] = this.cisService.inputFromCis.silosQuantity;
          silo['listWeight'] = this.getListWeight(silo);
          silo['priceList'] = this.getSiloPriceList(silo);
          // 
          console.log("Price lists getSiloPriceList(); ");
          
          silo['price'] =  this.getSiloPriceDiscounted(silo);

          this.errorBox.success('Adding Recalculated Silo...');

          this.addRecalcualtedItem(silo);

        }else{

          this.getOfferSilos();
        }


        this.getDocuments('comercial');
        this.getPreviousOfferRevision();
        this.initOfferdata();
        this.referenceGeneralK = this.offerData.generalCoefficient;

        this.disableFields();



      },
      error => {
        if (error.status === 404) {
          this.notFound = true;
        }

        this.loadingOffer = false;
      }
      );
  }

  setCommissions(){

    for (let commission of this.offerData.offerRevisionCommissions) {

        this.updatePrice(commission);
    }

  }


  initOfferdata(){
      this.selectedCountry = this.offerData.cityOfInstallation ? this.offerData.cityOfInstallation.country ? this.offerData.cityOfInstallation.country : null : null;
      this.selectedPlaceOfDeliveryCountry = this.offerData.placeOfDelivery ? this.offerData.placeOfDelivery.country ? this.offerData.placeOfDelivery.country : null : null;

      if(_.isNil(this.offerData.inquiryInformationQuotedBy) || _.isUndefined(this.offerData.inquiryInformationQuotedBy)){

        this.selectQuotedBy(this.offerData.user);
      }

      this.currency= this.offerData.currency;
      this.currencyChangeRate = this.offerData.currencyChangeRate;
      this.configPriceBehaviour = this.offerData.configPriceBehaviour;

      if(this.offerData.cityOfInstallation){
   
        this.setCityPlaceholder(this.offerData.cityOfInstallation.country.capital);
      }



  }

  disableFields() {
    if (this.disableInputCustom() ) {

      this.offerSteps.disable();

    } else {
      this.offerSteps.enable();
      this.blockSiteOfInstallation();
      this.blockPlaceOfDelivery();
      this.blockDelivery();
      this.blockPaymentDays();
      this.blokHeadings();
    }
  }


  userCanEditOffer(){

    let canEdit = false;


    if(this.userData){
       for (let role of this.userData.user.roles) {
        if (this.offerData.state === 0) {
          if ( ( (role === 'EXTERNAL_AGENT' || role === 'EXTERNAL_ADMIN') && this.offerData.stateChangePending!=true )  || role === 'INTERNAL_AGENT' || role === 'INTERNAL_ADMIN') {
            canEdit = true;
          }
        } else if (this.offerData.state === 1) {
          if (role === 'INTERNAL_AGENT' || role === 'INTERNAL_ADMIN') {
            canEdit = true;
          }
        } else if (this.offerData.state === 2) {
          if (role === 'INTERNAL_ADMIN') {
            canEdit = true;
          }
        }
      }

    }


    //let canEdit = true;
    return canEdit;
  }

  userCanSaveOffer(){

     let canSave = false;


    if(this.userData)
      for (let role of this.userData.user.roles) {
        if (this.offerData.state === 0) {
          if (!this.offerData.stateChangePending && (role === 'EXTERNAL_AGENT' || role === 'EXTERNAL_ADMIN' || role === 'INTERNAL_AGENT' || role === 'INTERNAL_ADMIN')) {
            canSave = true;
          }
          else if(this.offerData.stateChangePending && role !== 'EXTERNAL_AGENT' && role !== 'EXTERNAL_ADMIN'){
            canSave = true;
          }

        } else if (this.offerData.state === 1) {
          if (role === 'INTERNAL_AGENT' || role === 'INTERNAL_ADMIN') {
            canSave = true;
          }
        } else if (this.offerData.state === 2) {
          if (role === 'INTERNAL_ADMIN') {
            canSave = true;
          }
        }
      }

    
    return canSave;
  }


  canAllSteps(){
    if(_.isUndefined(this.userData) || _.isUndefined(this.offerData.state || _.isNull(this.offerData.state )) ){
      return false;
    }
    return (this.offerData.state >= 1 && this.offerData.state <= 4) && (User.hasRole(this.userData.user,'INTERNAL_ADMIN') ||
                                                                      User.hasRole(this.userData.user,'INTERNAL_AGENT') ||
                                                                      User.hasRole(this.userData.user,'SUPER_ADMIN') );
  }

  userCanShowCosts(){
    return User.hasRole(this.userData.user,'INTERNAL_ADMIN');
  }


  blockSiteOfInstallation(item?) {

    let country = item || this.selectedCountry;
    if (country) {

      if (country.id !== undefined && country.id !== null) {

        this.siteOfInstallation.enable();
        return;
      }
    }
    this.siteOfInstallation.disable();
  }

  blockPlaceOfDelivery(item?) {

    let country = item || this.selectedPlaceOfDeliveryCountry;
    if (country) {

      if (country.id !== undefined && country.id !== null) {

        this.placeOfDeliveryControl.enable();
        return;
      }
    }
    this.placeOfDeliveryControl.disable();
  }

  selectedCountryOperations(item?){

    this.blockSiteOfInstallation(item);
    this.setCityPlaceholder(item.capital);

  }

  setCityPlaceholder(capital){

    this.cityPlaceholder = capital;

  }

  selectedCountryPlaceOfDeliveryOperations(item?){
     this.blockPlaceOfDelivery(item);
     this.setCityPlaceOfDeliveryPlaceholder(item.capital);
  }

  setCityPlaceOfDeliveryPlaceholder(capital){
   
    this.cityPlaceOfDeliveryPlaceholder = capital
  }

  blokHeadings(){

     if ( !_.isNull(this.offerData.typeOfSupply)  && !_.isUndefined(this.offerData.typeOfSupply)) {

         switch(this.offerData.typeOfSupply.name){

           case "SAC":
           case "TKY":
           case "FES":
           case "SHE":
           case "HEQ":
           case "WTT":
             this.headingsIntakeCapacityControl.enable();
             this.headingsDeliveryCapacityControl.enable();
             this.capacityTotalOverrideControl.enable();
             break;

           default:
             this.headingsIntakeCapacityControl.disable();
             this.headingsDeliveryCapacityControl.disable();
             this.capacityTotalOverrideControl.disable();
             break;

         }

     }
  }

  headingsSiteActive(){
    if ( !_.isNull(this.offerData.typeOfSupply)  && !_.isUndefined(this.offerData.typeOfSupply)) {

         switch(this.offerData.typeOfSupply.name){

           case "SAC":
           case "TKY":
           case "FES":
           case "SHE":
           case "WTT":
             return true;

           default:
             return false;

         }

     }

  }

  headingCapacityActive(){

        if ( !_.isNull(this.offerData.typeOfSupply)  && !_.isUndefined(this.offerData.typeOfSupply)) {

         switch(this.offerData.typeOfSupply.name){

           case "SAC":
           case "TKY":
           case "FES":
           case "SHE":
           case "WTT":
             return true;

           default:
             return false;

         }

     }

  }

  getTypeOfSupplyText(){

    let textN = "";

    if ( !_.isNull(this.offerData.typeOfSupply)  && !_.isUndefined(this.offerData.typeOfSupply)) {

         switch(this.offerData.typeOfSupply.name){

           case "SAC":
           case "TKY":
           case "SHE":
           case "FES": 

              this.translate.get('PSS.offerSteps.headings.TonesCerealPlant').subscribe((text: string) => {
                  textN = text;
              });
              break;



           case "WTT":
             textN = "M3 " + this.getTranslationText(this.offerData.typeOfSupply,'descriptionText');
             break;


         }

    }

    return textN;



  }

  blockDelivery() {
    if (this.offerData.termsOfDelivery !== null && this.offerData.termsOfDelivery !== undefined) {


      if (this.offerData.termsOfDelivery.name === 'FCA' ||
          this.offerData.termsOfDelivery.name === 'EXW' ){

        this.placeOfDeliveryControl.disable();
        this.placeOfDeliveryAddressControl.disable();
        this.placeOfDeliveryZipCodeControl.disable();
        this.placeOfDeliveryCountry.disable();


        this.selectedPlaceOfDeliveryCountry = null;
        this.offerData.placeOfDelivery = null;
        this.offerData.placeOfDeliveryAddress = null;
        this.offerData.placeOfDeliveryZipCode = null;


        this.zipCodePlaceOfDeliveryPlaceholder = '50180';
        this.addressPlaceOfDeliveryPlaceholder = 'C/ Francia s/n';


      }
      else if(this.offerData.termsOfDelivery.name === 'FAS' ||
              this.offerData.termsOfDelivery.name === 'FOB'){


        this.placeOfDeliveryControl.enable();
        this.placeOfDeliveryCountry.enable();

        this.placeOfDeliveryAddressControl.disable();
        this.placeOfDeliveryZipCodeControl.disable();

       this.offerData.placeOfDeliveryAddress = null;
        this.offerData.placeOfDeliveryZipCode = null;

        this.zipCodePlaceOfDeliveryPlaceholder = 'ZIP Code';
        this.addressPlaceOfDeliveryPlaceholder = 'Address';

      }
      else if(this.offerData.termsOfDelivery.name === 'CFR' ||
              this.offerData.termsOfDelivery.name === 'CIF'){


        this.placeOfDeliveryControl.enable();
        this.placeOfDeliveryCountry.enable();

        this.placeOfDeliveryAddressControl.disable();
        this.placeOfDeliveryZipCodeControl.disable();

        this.offerData.placeOfDeliveryAddress = null;
        this.offerData.placeOfDeliveryZipCode = null;

        this.zipCodePlaceOfDeliveryPlaceholder = 'ZIP Code';
        this.addressPlaceOfDeliveryPlaceholder = 'Address';

      }

      else {
        this.placeOfDeliveryControl.enable();
        this.placeOfDeliveryCountry.enable();
        this.placeOfDeliveryAddressControl.enable();
        this.placeOfDeliveryZipCodeControl.enable();


        this.zipCodePlaceOfDeliveryPlaceholder = 'ZIP Code';
        this.addressPlaceOfDeliveryPlaceholder = 'Address';


      }

        this.setCityPlaceOfDeliveryPlaceholder('Madrid');


    }

  }

  blockDeliverySelection(){
       if (this.offerData.termsOfDelivery !== null && this.offerData.termsOfDelivery !== undefined) {


      if (this.offerData.termsOfDelivery.name === 'FCA' ||
          this.offerData.termsOfDelivery.name === 'EXW' ){

        this.placeOfDeliveryControl.disable();
        this.placeOfDeliveryAddressControl.disable();
        this.placeOfDeliveryZipCodeControl.disable();
        this.placeOfDeliveryCountry.disable();


        this.selectedPlaceOfDeliveryCountry = null;
        this.offerData.placeOfDelivery = null;
        this.offerData.placeOfDeliveryAddress = null;
        this.offerData.placeOfDeliveryZipCode = null;


        this.zipCodePlaceOfDeliveryPlaceholder = '50180';
        this.addressPlaceOfDeliveryPlaceholder = 'C/ Francia s/n';


      }
      else if(this.offerData.termsOfDelivery.name === 'FAS' ||
              this.offerData.termsOfDelivery.name === 'FOB'){


        this.placeOfDeliveryControl.enable();
        this.placeOfDeliveryCountry.enable();

        this.placeOfDeliveryAddressControl.disable();
        this.placeOfDeliveryZipCodeControl.disable();

       this.offerData.placeOfDeliveryAddress = null;
        this.offerData.placeOfDeliveryZipCode = null;

        this.zipCodePlaceOfDeliveryPlaceholder = 'ZIP Code';
        this.addressPlaceOfDeliveryPlaceholder = 'Address';

      }
      else if(this.offerData.termsOfDelivery.name === 'CFR' ||
              this.offerData.termsOfDelivery.name === 'CIF'){


        this.placeOfDeliveryControl.enable();
        this.placeOfDeliveryCountry.enable();

        this.placeOfDeliveryAddressControl.disable();
        this.placeOfDeliveryZipCodeControl.disable();

        this.offerData.placeOfDeliveryAddress = null;
        this.offerData.placeOfDeliveryZipCode = null;

        this.zipCodePlaceOfDeliveryPlaceholder = 'ZIP Code';
        this.addressPlaceOfDeliveryPlaceholder = 'Address';

      }

      else {
        this.placeOfDeliveryControl.enable();
        this.placeOfDeliveryCountry.enable();
        this.placeOfDeliveryAddressControl.enable();
        this.placeOfDeliveryZipCodeControl.enable();


        this.zipCodePlaceOfDeliveryPlaceholder = 'ZIP Code';
        this.addressPlaceOfDeliveryPlaceholder = 'Address';


      }


        this.selectedPlaceOfDeliveryCountry = null;
        this.offerData.placeOfDelivery = null;
        this.offerData.placeOfDeliveryAddress = null;
        this.offerData.placeOfDeliveryZipCode = null;
        this.setCityPlaceOfDeliveryPlaceholder('Madrid');


    }
  }

  switchPlaceOfDeliveryByTermOfDelivery(){

      switch(this.offerData.termsOfDelivery.name){

        default:
        case "EXW":
        case "FCA":
          return 0;

        case "FAS":
        case "FOB":
          return 1;

        case "CFR":
        case "CIF":
          return 2;

        case "CPT":
        case "CIP":
        case "DAT":
        case "DAP":
        case "DDP":
          return 3;

      };

  }

  changeMarkUp($event)
  {
    
  }

  serializeOffer(offer: Offer): Offer {
    if (offer.exclusions === null) {
      offer.exclusions = [];
    }
    if (!offer.offerRevisionCommissions) {
      offer.offerRevisionCommissions = [];
    }



    return offer;
  }

  getDependencies() {
    this.getUser();
    this.getQuotedByUsers();
    this.getPaymentTerms();
    this.getBanks();
    this.getExclusions();
    this.getTypesOfSupply();
    this.getCurrencies();
    this.getTermsOfDelivery();


  }

  getBanks(): any {
    this.miscService.getBanks()
      .then(banks => this.banks = banks);
  }

  getExclusions() {
    this.miscService.getExclusions()
      .then(exclusions => {
        this.exclusions = exclusions;

      });


     this.initEmptyCustomExclusion();

  }

  initEmptyCustomExclusion(){
   this.customExclusion = {
     translations: [
        {
          locale: "en",
          name: ""
        },
        {
          locale: "es",
          name: ""

        },
        {
          locale: "ru",
          name: ""
        },
        {
          locale: "de",
          name: ""
        }
      ]
    };
  }

  getTypesOfSupply() {
    this.miscService.getTypesOfSupply()
      .then(typesOfSupply => {

        this.typesOfSupply = typesOfSupply;
        this.typesOfSupply.sort(this.compare);
      });
  }

  disableTypeOfSupply(typeOfSupply){

      /*let offerHasSilos = Offer.hasSilos(this.offerData);

      switch(typeOfSupply.name){
        case "TKY":
        case "SAC":
        case "SHE":
        case "FES":
          return !offerHasSilos;
        default:
          return offerHasSilos;

      }*/


  }

  getCurrencies() {
    this.miscService.getCurrencies()
      .then(currencies => {
        this.currencies = currencies;
      
      });
  }

  getTermsOfDelivery() {
    this.miscService.getTermsOfDelivery()
      .then(terms =>{
        this.termsOfDelivery = terms;
      
      });
  }

  getListWeight(item){

    if(item.grainLoadsInAccordanceWith.substring(0, 2) == "AN"){

      return item.weight*this.managmentData.kWeightAnsi;


    }
    else if(item.grainLoadsInAccordanceWith.substring(0, 2) == "EN"){

      return item.weight*this.managmentData.kWeightEuro;
    }


  }



  getKWeight(item){

     if(item.grainLoadsInAccordanceWith.substring(0, 2) == "AN"){


      return this.managmentData.kWeightAnsi;


    }
    else if(item.grainLoadsInAccordanceWith.substring(0, 2) == "EN"){

      return this.managmentData.kWeightEuro;
    }

  }


  getPaymentTerms() {
    this.miscService.getPaymentTerms()
      .then(terms => {
        this.paymentTerms = terms;


       if(_.isUndefined(this.offerData.paymentTerm) || _.isNull(this.offerData.paymentTerm)){
          this.offerData.paymentTerm = terms[0];
          this.offerData.paymentTerm1 = 30;
          this.offerData.paymentTerm2 = 70;
       }


      });
  }

   getUser() {

    this.loginService.getUser()
      .then(user =>{
   
          this.userData = user;


          if(this.userData.user.isExternal){
            this.documentTypes = DocumentTypesExternal;
          }
          else{
            this.documentTypes = DocumentTypes;
          }

      

      });
  }

  getQuotedByUsers(){

     this.userService.getUsers(null,null,"1")
          .then(users => {
            this.quotedByUsers = users;
     

          }).catch(error => {
          
          });

  }

  open(content, windowClass?, backdrop?) {
    this.modalService.open(content, { size: 'lg', windowClass: windowClass, backdrop: backdrop }).result.then((result) => {
      if (result === 'Search') {
        //this.search();
      }



    }, (reason) => {
    });
  }

  openProforma(content, windowClass?, backdrop?) {

      this.modalService.open(content, { size: 'lg', windowClass: windowClass, backdrop: backdrop });

  }

  moveItemUp(itemIdx) {
    if (itemIdx >= 1) {
      const self = this.offerData.offerRevisionSilos[itemIdx];
      const previous = this.offerData.offerRevisionSilos[itemIdx - 1];

      const self_order = self.priorityOrder;
      const previous_order = previous.priorityOrder;

      this.offerData.offerRevisionSilos[itemIdx] = previous;
      this.offerData.offerRevisionSilos[itemIdx - 1] = self;



      this.offerData.offerRevisionSilos[itemIdx].priorityOrder = self_order;
      this.offerData.offerRevisionSilos[itemIdx-1].priorityOrder = previous_order;
    }
  }

  moveItemDown(itemIdx) {
    if (itemIdx < this.offerData.offerRevisionSilos.length - 1) {
      const self = this.offerData.offerRevisionSilos[itemIdx];
      const next = this.offerData.offerRevisionSilos[itemIdx + 1];

      const self_order = self.priorityOrder;
      const next_order = next.priorityOrder;

      this.offerData.offerRevisionSilos[itemIdx] = next;
      this.offerData.offerRevisionSilos[itemIdx + 1] = self;

      this.offerData.offerRevisionSilos[itemIdx].priorityOrder = self_order;
      this.offerData.offerRevisionSilos[itemIdx+1].priorityOrder = next_order;
    }
  }

  moveSectionUp(itemIdx){
    if (itemIdx >= 1) {
      const self = this.categoryProducts[itemIdx];
      const previous = this.categoryProducts[itemIdx - 1];

      const self_order = self.priorityOrder;
      const previous_order = previous.priorityOrder;

      this.categoryProducts[itemIdx] = previous;
      this.categoryProducts[itemIdx - 1] = self;



      this.categoryProducts[itemIdx].priorityOrder = self_order;
      this.categoryProducts[itemIdx-1].priorityOrder = previous_order;
    }
  }


  moveSectionDown(itemIdx){
    if (itemIdx < this.categoryProducts.length - 1) {
      const self = this.categoryProducts[itemIdx];
      const next = this.categoryProducts[itemIdx + 1];

      const self_order = self.priorityOrder;
      const next_order = next.priorityOrder;

      this.categoryProducts[itemIdx] = next;
      this.categoryProducts[itemIdx + 1] = self;

      this.categoryProducts[itemIdx].priorityOrder = self_order;
      this.categoryProducts[itemIdx+1].priorityOrder = next_order;
    }
  }

  moveItemProductUp(itemIdx) {
    if (itemIdx < this.offerData.offerRevisionProducts.length - 1) {
      const self = this.offerData.offerRevisionProducts[itemIdx];
      const next = this.offerData.offerRevisionProducts[itemIdx + 1];

      const self_order = self.priorityOrder;
      const next_order = next.priorityOrder;

      this.offerData.offerRevisionProducts[itemIdx] = next;
      this.offerData.offerRevisionProducts[itemIdx + 1] = self;

      this.offerData.offerRevisionProducts[itemIdx].priorityOrder = self_order;
      this.offerData.offerRevisionProducts[itemIdx+1].priorityOrder = next_order;
    }
  }

  moveItemProductDown(itemIdx) {
    if (itemIdx >= 1) {
      const self = this.offerData.offerRevisionProducts[itemIdx];
      const previous = this.offerData.offerRevisionProducts[itemIdx - 1];

      const self_order = self.priorityOrder;
      const previous_order = previous.priorityOrder;

      this.offerData.offerRevisionProducts[itemIdx] = previous;
      this.offerData.offerRevisionProducts[itemIdx - 1] = self;



      this.offerData.offerRevisionProducts[itemIdx].priorityOrder = self_order;
      this.offerData.offerRevisionProducts[itemIdx-1].priorityOrder = previous_order;
    }
  }

  removeItem(item, fromRecalculation?) {

    if(!this.disableInputCustom()){

      this.offerService.deleteOfferRevisionSilo(this.offerData, item.id, item.model).then(response => {

          this.getOfferSilos();

          this.getOfferPrices();

          if(fromRecalculation){

          }
          else{
            this.errorBox.success('Silo removed successfully.');
          }

      });


    }
  }

  removeItemProduct(item) {

    if(!this.disableInputCustom()){

      this.offerService.deleteOfferProduct(this.offerData, item.id).then(response => {
          this.getOfferPrices();

          const itemIdx = this.offerData.offerRevisionProducts.indexOf(item);
          this.offerData.offerRevisionProducts.splice(itemIdx, 1);

          this.offerData.offerRevisionProducts.sort(this.compare);

          let order = 0;
          for(let product of this.offerData.offerRevisionProducts){
              product.priorityOrder = order;
              order++;
          }

          this.errorBox.success('Product removed successfully.');
      });


    }
  }

  removeSection(category){

    let products = category['products'];

    if(!_.isEmpty(products)){

      this.processingReqLoadProducts = true;


     this.offerService.deleteOfferSection(this.offerData, category.category).then(response => {

          this.processingReqLoadProducts = false;
          this.getOfferProductSections(this.offerData);
          this.errorBox.success('Section removed successfully.');
          this.getOfferPrices();

     }). catch(error=> {
      
     });

    }

  }

  compare(a,b) {
    if (a.priorityOrder < b.priorityOrder)
      return -1;
    if (a.priorityOrder > b.priorityOrder)
      return 1;
    return 0;
  }

  compareSection(a,b) {
    if (a.sectionOrder < b.sectionOrder)
      return -1;
    if (a.sectionOrder > b.sectionOrder)
      return 1;
    return 0;
  }



  increaseQuantity(silo) {

      silo.quantity++;

  }

  decreaseQuantity(silo) {
    if (silo.quantity > this.minSiloQuantity) {
      silo.quantity--;
    }
  }


  initOfferRevisionSiloFromRecalcualtedSilo(silo){

    let offerRevisionSilo: OfferRevisionSilo;

    offerRevisionSilo = {

         recalculatedSilo: {id: silo.id},
         quantity: silo.quantity,
         model: silo.model,
         completeModel: silo.completeModel,
         price: silo.price,
         priceList: silo.priceList,
         weight:  silo.weight,
         cost: silo.cost,
         volume: silo.volume,
         description: silo.description,
        
         storedProductDensity: silo.storedProductDensity,
         snowLoad: silo.snowLoad,
         fillEmptyCiclesPerYear: silo.fillEmptyCiclesPerYear,
         dischargeMode: silo.dischargeMode,
         wallSheetCorrugation: 104,
         boltsGrade: silo.boltsGrade,
       
         grainLoadsInAccordanceWith: silo.grainLoadsInAccordanceWith,
         kSection: 1,
         translations: [
          {
            locale: "en",
            verticalHorizontalJointSealingText: this.siloService.getJointSealing("en"),
            boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(silo.boltsGalvanizationMethod, "en"),
            typeOfFoundationText: this.siloService.getTypeOfFundation("Flat", "en"),
            storedProductText: silo.storedProduct,
            dischargeModeText: this.siloService.getDischargeMode(silo.dischargeMode, 'en')
          },
          {
            locale: "es",
            verticalHorizontalJointSealingText: this.siloService.getJointSealing("es"),
            boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(silo.boltsGalvanizationMethod, "es"),
            typeOfFoundationText: this.siloService.getTypeOfFundation("Flat", "es"),
            storedProductText: silo.storedProduct,
            dischargeModeText: this.siloService.getDischargeMode(silo.dischargeMode, 'es')
          },
          {
            locale: 'de',
            verticalHorizontalJointSealingText: this.siloService.getJointSealing('de'),
            boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(silo.boltsGalvanizationMethod, 'de'),
            typeOfFoundationText: this.siloService.getTypeOfFundation('Flat', 'de'),
            storedProductText: silo.storedProduct,
            dischargeModeText: this.siloService.getDischargeMode(silo.dischargeMode, 'de')
          },
          {
            locale: 'ru',
            verticalHorizontalJointSealingText: this.siloService.getJointSealing('ru'),
            boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(silo.boltsGalvanizationMethod, 'ru'),
            typeOfFoundationText: this.siloService.getTypeOfFundation('Flat', 'ru'),
            storedProductText: silo.storedProduct,
            dischargeModeText: this.siloService.getDischargeMode(silo.dischargeMode, 'ru')
          }

         ]


         
    };

    offerRevisionSilo.designDensity= this.siloService.decimalCastingReverse(silo.designDensity);
    offerRevisionSilo.seismicZone= this.siloService.decimalCastingReverse(silo.seismicZone);
    offerRevisionSilo.seismicCode = silo.seismicCode;

    offerRevisionSilo.designWindVelocity = silo.designWindVelocity;
    offerRevisionSilo.moistureContent = silo.moistureContent/100;
    offerRevisionSilo.loadingRatedCapacity = 0;
    offerRevisionSilo.unloadingRatedCapacity = 0;
    offerRevisionSilo.distanceBetweenStiffeners = silo.distanceBetweenStiffeners;
    offerRevisionSilo.wallSheetsGradeSteel = silo.wallSheetsGradeSteel;
    offerRevisionSilo.roofGradeSteel = silo.roofGradeSteel;
    offerRevisionSilo.verticalStiffenersGradeSteel = silo.verticalStiffenersGradeSteel;
    offerRevisionSilo.roofGalvanisationPainting = silo.roofGalvanisationPainting;
    offerRevisionSilo.verticalStiffenersGalvanizationPainting = silo.verticalStiffenersGalvanizationPainting;
    offerRevisionSilo.wallSheetsGalvanizationPainting = silo.wallSheetsGalvanizationPainting;
    offerRevisionSilo.hopper = "";
    offerRevisionSilo.capacity= silo.storageCapacity;
    offerRevisionSilo.compaction= silo.compaction;
    offerRevisionSilo.compactedCapacity = silo.compactedCapacity;



    return offerRevisionSilo;
  }

  initOfferRevisionSiloFromSilo(silo, restore?, originalOfferRevisionSilo?) {

    let offerRevisionSilo: OfferRevisionSilo;

  //  const storedProduct =  this.originalOfferRevisionSilo.translations.find(x => x.id == this.siloFilter.filter.storedProduct);
  //  let translationStoredProduct = storedProduct.translations.find(x => x.locale == 'en');
  //  let translationStoredProductES = storedProduct.translations.find(x => x.locale == 'es');
  //  let translationStoredProductDE = storedProduct.translations.find(x => x.locale == 'de');
  //  let translationStoredProductRU = storedProduct.translations.find(x => x.locale == 'ru');

    offerRevisionSilo = {

      silo: {id: silo.id},
      quantity: silo.quantity,
      model: silo.model,
      completeModel: silo.completeModel,
      price: silo.price,
      priceList: silo.priceList,
      weight: silo.weight,
      cost: silo.cost,
      volume: silo.volume,
      description: silo.description,
      storedProductDensity: silo.storedProductDensity,
      snowLoad: silo.snowLoad,
      fillEmptyCiclesPerYear: silo.fillEmptyCiclesPerYear,
      dischargeMode: silo.dischargeMode,
      wallSheetCorrugation: 104,
      boltsGrade: silo.boltsGrade,
      grainLoadsInAccordanceWith: silo.grainLoadsInAccordanceWith,
      kSection: this.managmentData.ksection,
      translations: [
        {
          locale: 'en',
          verticalHorizontalJointSealingText: this.siloService.getJointSealing('en'),
          boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(silo.boltsGalvanizationMethod, 'en'),
          typeOfFoundationText: this.siloService.getTypeOfFundation('Flat', 'en'),
        
          dischargeModeText: silo.dischargeMode
        },
        {
          locale: 'es',
          verticalHorizontalJointSealingText: this.siloService.getJointSealing('es'),
          boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(silo.boltsGalvanizationMethod, 'es'),
          typeOfFoundationText: this.siloService.getTypeOfFundation('Flat', 'es'),
   
          dischargeModeText: 'Central por gravedad'
        },
        {
          locale: 'de',
          verticalHorizontalJointSealingText: this.siloService.getJointSealing('de'),
          boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(silo.boltsGalvanizationMethod, 'de'),
          typeOfFoundationText: this.siloService.getTypeOfFundation('Flat', 'de'),
 
          dischargeModeText: silo.dischargeMode
        },
        {
          locale: 'ru',
          verticalHorizontalJointSealingText: this.siloService.getJointSealing('ru'),
          boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(silo.boltsGalvanizationMethod, 'ru'),
          typeOfFoundationText: this.siloService.getTypeOfFundation('Flat', 'ru'),
   
          dischargeModeText: silo.dischargeMode
        }
      ]

    };

    if (!_.isNull(restore) && !_.isUndefined(restore) && restore) {

      const offerSiloTranslationEN =  originalOfferRevisionSilo.translations.find(x => x.locale == 'en');
      const offerSiloTranslationES =  originalOfferRevisionSilo.translations.find(x => x.locale == 'es');
      const offerSiloTranslationDE =  originalOfferRevisionSilo.translations.find(x => x.locale == 'de');
      const offerSiloTranslationRU =  originalOfferRevisionSilo.translations.find(x => x.locale == 'ru');

      offerRevisionSilo.id = originalOfferRevisionSilo.id;
      offerRevisionSilo.model = originalOfferRevisionSilo.model;
      offerRevisionSilo.cost = originalOfferRevisionSilo.cost;
      offerRevisionSilo.weight = originalOfferRevisionSilo.weight;
      offerRevisionSilo.price = originalOfferRevisionSilo.price;
      offerRevisionSilo.priceList = originalOfferRevisionSilo.priceList;
      offerRevisionSilo.quantity = originalOfferRevisionSilo.quantity;
      offerRevisionSilo.description = originalOfferRevisionSilo.description;

      offerRevisionSilo.translations =  [
        {
          id: offerSiloTranslationEN.id,
          locale: 'en',
          verticalHorizontalJointSealingText: this.siloService.getJointSealing('en'),
          boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(silo.boltsGalvanizationMethod, 'en'),
          typeOfFoundationText: this.siloService.getTypeOfFundation('Flat', 'en'),
          storedProductText: offerSiloTranslationEN.storedProductText,
          dischargeModeText: this.siloService.getDischargeMode(silo.dischargeMode, 'en')
        },
        {
          id: offerSiloTranslationES.id,
          locale: 'es',
          verticalHorizontalJointSealingText: this.siloService.getJointSealing('es'),
          boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(silo.boltsGalvanizationMethod, 'es'),
          typeOfFoundationText: this.siloService.getTypeOfFundation('Flat', 'es'),
          storedProductText:  offerSiloTranslationES.storedProductText,
          dischargeModeText: this.siloService.getDischargeMode(silo.dischargeMode, 'es')
        },
        {
          id: offerSiloTranslationDE.id,
          locale: 'de',
          verticalHorizontalJointSealingText: this.siloService.getJointSealing('de'),
          boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(silo.boltsGalvanizationMethod, 'de'),
          typeOfFoundationText: this.siloService.getTypeOfFundation('Flat', 'de'),
          storedProductText: offerSiloTranslationDE.storedProductText,
          dischargeModeText: this.siloService.getDischargeMode(silo.dischargeMode, 'de')
        },
        {
          id: offerSiloTranslationRU.id,
          locale: 'ru',
          verticalHorizontalJointSealingText: this.siloService.getJointSealing('ru'),
          boltsGalvanizationMethodText: this.siloService.getBoltGalvanizationMethod(silo.boltsGalvanizationMethod, 'ru'),
          typeOfFoundationText: this.siloService.getTypeOfFundation('Flat', 'ru'),
          storedProductText: offerSiloTranslationRU.storedProductText,
          dischargeModeText: this.siloService.getDischargeMode(silo.dischargeMode, 'ru')
        }
      ]

    }

    if (!Silo.isWTT(silo)) {


      offerRevisionSilo.designDensity = this.siloService.decimalCastingReverse(silo.designDensity);
      offerRevisionSilo.seismicZone = this.siloService.decimalCastingReverse(silo.seismicZone);
      offerRevisionSilo.seismicCode = silo.seismicCode;

      offerRevisionSilo.designWindVelocity = silo.designWindVelocity;
      offerRevisionSilo.moistureContent = silo.moistureContent / 100;
      offerRevisionSilo.loadingRatedCapacity = 0;
      offerRevisionSilo.unloadingRatedCapacity = 0;
      offerRevisionSilo.distanceBetweenStiffeners = silo.distanceBetweenStiffeners;
      offerRevisionSilo.wallSheetsGradeSteel = silo.wallSheetsGradeSteel;
      offerRevisionSilo.roofGradeSteel = silo.roofGradeSteel;
      offerRevisionSilo.verticalStiffenersGradeSteel = silo.verticalStiffenersGradeSteel;
      offerRevisionSilo.roofGalvanisationPainting = silo.roofGalvanisationPainting;
      offerRevisionSilo.verticalStiffenersGalvanizationPainting = silo.verticalStiffenersGalvanizationPainting;
      offerRevisionSilo.wallSheetsGalvanizationPainting = silo.wallSheetsGalvanizationPainting;
      offerRevisionSilo.hopper = '';
      offerRevisionSilo.capacity = silo.storageCapacity;
      offerRevisionSilo.compaction = silo.compaction;
      offerRevisionSilo.compactedCapacity = silo.compactedCapacity;

    } else {

      offerRevisionSilo.generalGradeSteel = silo.wallSheetsGradeSteel;
      offerRevisionSilo.generalGalvanisationPainting = silo.wallSheetsGalvanizationPainting;
      offerRevisionSilo.capacity = silo.volume;
    }

    return offerRevisionSilo;

  }

  getLastElementSiloOrder(){

    this.offer.offerRevisionSilos.sort(this.compare);
    return this.offer.offerRevisionSilos[ this.offer.offerRevisionSilos.length - 1].priorityOrder + 1;

  }

  isBasketSilosEmpty(){

    if(_.isNull(this.offer.offerRevisionSilos) || _.isUndefined(this.offer.offerRevisionSilos) || _.isEmpty(this.offer.offerRevisionSilos) ){
      return true;
    }
    else{
      return false;
    }

  }


  addRecalcualtedItem(item) {


      const order  = this.cisService.inputFromCis.originalOfferSilo.priorityOrder;

      item.quantity = item.quantity || 1;
      item.price = this.getSiloPriceDiscounted(item);
      item.priceList =  this.getSiloPriceList(item);

      let offerSilo = this.initOfferRevisionSiloFromRecalcualtedSilo(item);
      //offerSilo.translations = this.cisService.inputFromCis.originalOfferSilo.translations;
      offerSilo.priorityOrder = order;

      this.processingReqLoadSilos = true;

      this.offerService.createOfferSiloFromRecalcualtedSilo(
        this.offer, 
        this.seralizeOfferRevisionSiloRecalculated(offerSilo),
        this.cisService.inputFromCis.originalOfferSilo.id
      )
      .then(offerRevisionSilo => {

        this.removeItem(this.cisService.inputFromCis.originalOfferSilo, true);

       /*
        this.offerData.offerRevisionSilos.push(this.unserializeOfferSilo(offerRevisionSilo));


        this.offerData.offerRevisionSilos.sort(this.compare);*/

        //this.getOfferSilos();
        this.cisService.inputFromCis = undefined;

        this.errorBox.success('Silo added successfully.');

      })
      .catch(error => {

        this.processingReqLoadSilos=false,
        this.errorBox.error('Error, try again.');
        //this.offer.offerRevisionSilos.splice(itemIdx, 1);
      });

  }

  isBasketEmpty(){

    if(_.isNull(this.offerData.offerRevisionSilos) || _.isUndefined(this.offerData.offerRevisionSilos) || _.isEmpty(this.offerData.offerRevisionSilos) ){
      return true;
    }
    else{
      return false;
    }

  }

  getLastElementOrder(){

    this.offerData.offerRevisionSilos.sort(this.compare);
    return this.offerData.offerRevisionSilos[ this.offerData.offerRevisionSilos.length - 1].priorityOrder + 1;

  }

  checkOrder(){

    if(!_.isEmpty(this.offerData.offerRevisionSilos)){

      const firstOfferSiloOrder = this.offerData.offerRevisionSilos[0].priorityOrder;

      if(_.isNull(firstOfferSiloOrder)){

        let order = 0;
        for(let product of this.offerData.offerRevisionSilos){
            product.priorityOrder = order;
            order++;
        }
      }

    }
  }

  seralizeOfferRevisionSilo(offerRevisionSilo){

    if(offerRevisionSilo.designDensity){

      offerRevisionSilo.designDensity =  this.siloService.decimalCasting(offerRevisionSilo.designDensity.toString());

     }

     if(offerRevisionSilo.seismicZone){
        offerRevisionSilo.seismicZone =  this.siloService.decimalCasting(offerRevisionSilo.seismicZone.toString());
     }


    delete offerRevisionSilo.offerRevisionSiloProducts;
    delete offerRevisionSilo.newTranslations;
    delete offerRevisionSilo.currentLocale;
    delete offerRevisionSilo.defaultLocale;

    delete offerRevisionSilo.silo;
    delete offerRevisionSilo.recalculatedSilo;


    offerRevisionSilo = this.checkOfferSiloTranslations(offerRevisionSilo);


    return offerRevisionSilo;

  }

  seralizeOfferRevisionSiloRecalculated(offerRevisionSilo){

    if(offerRevisionSilo.designDensity){

      offerRevisionSilo.designDensity =  this.siloService.decimalCasting(offerRevisionSilo.designDensity.toString());

     }

     if(offerRevisionSilo.seismicZone){
        offerRevisionSilo.seismicZone =  this.siloService.decimalCasting(offerRevisionSilo.seismicZone.toString());
     }


    delete offerRevisionSilo.offerRevisionSiloProducts;
    delete offerRevisionSilo.newTranslations;
    delete offerRevisionSilo.currentLocale;
    delete offerRevisionSilo.defaultLocale;

    delete offerRevisionSilo.silo;



    offerRevisionSilo = this.checkOfferSiloTranslations(offerRevisionSilo);


    return offerRevisionSilo;

  }

  seralizeOfferRevisionSection(offerSection){

    let finalSection = {
      id: offerSection.category.id,
      sectionOrder: offerSection.sectionOrder,
      optional: offerSection.optional,
      hidePrice: offerSection.hidePrice
    }


    return finalSection;

  }


  duplicateItem(item) {

    if(!this.disableInputCustom()){

     let order  = this.isBasketEmpty() ? 0 : this.getLastElementOrder();

     this.processingReqLoadSilos = true;

     this.offerService.duplicateOfferRevisionSilo(this.offerData, item.id, item.model).then(offerRevisionSilo => {

          offerRevisionSilo = this.unserializeOfferSilo(offerRevisionSilo);
          offerRevisionSilo.priorityOrder = order;

          this.offerService.editOfferSilo(this.offerData, this.seralizeOfferRevisionSilo(offerRevisionSilo)).then(offerRevisionSilo=> {
              this.processingReqLoadSilos = false;

              this.getOfferSilos();
              this.getOfferPrices();

              if(offerRevisionSilo.isRecalculated){
                offerRevisionSilo['silo'] = offerRevisionSilo.recalculatedSilo;
                offerRevisionSilo.recalculatedSilo = null;
              }

              this.errorBox.success('Silo duplicated successfully.');

          }). catch(error => {


             this.processingReqLoadSilos = false;
             this.errorBox.error('Error, contact support.');

          });



      }).catch(error => {

         this.processingReqLoadSilos = false;
         this.errorBox.error('Error, contact support.');
      });

    }

  }

  checkQuantity(silo, e?) {
    if (e) {
      if (e.keyCode !== 13) {
        return;
      }
    }

    if (silo.quantity < this.minSiloQuantity) {
      silo.quantity = this.minSiloQuantity;
    }
  }



  searchGrainsLoad = (text$: Observable<string>) =>
    text$
      .debounceTime(200).distinctUntilChanged()
      .merge(this.focus$)
      .merge(this.click$.filter(() =>{ if(_.isNull(this.grainloadInstance) || _.isUndefined(this.grainloadInstance)){ return true; } else { return !this.grainloadInstance.isPopupOpen()}}))
      //.map(term => (term === '' ? this.siloDesignOptions.grainLoads : this.siloDesignOptions.grainLoads.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10));
      .map(term => (this.siloDesignOptions.grainLoads ));

  searchDesignDensity = (text$: Observable<string>) =>
    text$
      .debounceTime(200).distinctUntilChanged()
      .merge(this.focusDensity$)
      .merge(this.clickDensity$.filter(() =>{ if(_.isNull(this.densityInstance) || _.isUndefined(this.densityInstance)){ return true; } else { return !this.densityInstance.isPopupOpen()}}))
      //.map(term => (term === '' ? this.siloDesignOptions.designDensities : this.siloDesignOptions.designDensities.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10));
      .map(term => (this.siloDesignOptions.designDensities ));

  searchSnow = (text$: Observable<string>) =>
    text$
      .debounceTime(200).distinctUntilChanged()
      .merge(this.focusSnow$)
      .merge(this.clickSnow$.filter(() =>{ if(_.isNull(this.snowInstance) || _.isUndefined(this.snowInstance)){ return true; } else { return !this.snowInstance.isPopupOpen()}}))
      //.map(term => (term === '' ? this.siloDesignOptions.snowLoads : this.siloDesignOptions.snowLoads.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10));
      .map(term => (this.siloDesignOptions.snowLoads));

  searchDischarge = (text$: Observable<string>) =>
    text$
      .debounceTime(200).distinctUntilChanged()
      .merge(this.focusDischarge$)
      .merge(this.clickDischarge$.filter(() =>{ if(_.isNull(this.dischargeInstance) || _.isUndefined(this.dischargeInstance)){ return true; } else { return !this.dischargeInstance.isPopupOpen()}}))
      .map(term => (this.siloDesignOptions.dischargeModes ));


  searchDistanceStiffeners = (text$: Observable<string>) =>
    text$
      .debounceTime(200).distinctUntilChanged()
      .merge(this.focusDistanceStiffeners$)
      .merge(this.clickDistanceStiffeners$.filter(() =>{ if(_.isNull(this.distanceStiffenersInstance) || _.isUndefined(this.distanceStiffenersInstance)){ return true; } else { return !this.distanceStiffenersInstance.isPopupOpen()}}))
      .map(term => (this.siloDesignOptions.distancesBetweenStiffeners ));

  searchGalvanisationRoof = (text$: Observable<string>) =>
    text$
      .debounceTime(200).distinctUntilChanged()
      .merge(this.focusGalvanisationRoof$)
      .merge(this.clickGalvanisationRoof$.filter(() =>{ if(_.isNull(this.galvanisationRoofInstance) || _.isUndefined(this.galvanisationRoofInstance)){ return true; } else { return !this.galvanisationRoofInstance.isPopupOpen()}}))
      .map(term => (this.siloDesignOptions.galvanisationsRoof ));


  searchGeneralGalvanisationPainting = (text$: Observable<string>) =>
    text$
      .debounceTime(200).distinctUntilChanged()
      .merge(this.focusGeneralGalvanisationPainting$)
      .merge(this.clickGeneralGalvanisationPainting$.filter(() =>{ if(_.isNull(this.generalGalvanisationPaintingInstance) || _.isUndefined(this.generalGalvanisationPaintingInstance)){ return true; } else { return !this.generalGalvanisationPaintingInstance.isPopupOpen()}}))
      .map(term => (this.siloDesignOptions.generalGalvanisation ));

  searchGalvanisationWallSheets = (text$: Observable<string>) =>
    text$
      .debounceTime(200).distinctUntilChanged()
      .merge(this.focusGalvanisationWallSheets$)
      .merge(this.clickGalvanisationWallSheets$.filter(() =>{ if(_.isNull(this.galvanisationWallSheetsInstance) || _.isUndefined(this.galvanisationWallSheetsInstance)){ return true; } else { return !this.galvanisationWallSheetsInstance.isPopupOpen()}}))
      .map(term => (this.siloDesignOptions.galvanisationsWallSheet ));

  searchHoppers = (text$: Observable<string>) =>
    text$
      .debounceTime(200).distinctUntilChanged()
      .merge(this.focusHopper$)
      .merge(this.clickHopper$.filter(() =>{ if(_.isNull(this.hopperInstance) || _.isUndefined(this.hopperInstance)){ return true; } else { return !this.hopperInstance.isPopupOpen()}}))
      .map(term => (this.siloDesignOptions.hoppers ));

  searchSeismicZone = (text$: Observable<string>) =>
    text$
      .debounceTime(200).distinctUntilChanged()
      .merge(this.focusSeismicZone$)
      .merge(this.clickSeismicZone$.filter(() =>{ if(_.isNull(this.seismicZoneInstance) || _.isUndefined(this.seismicZoneInstance)){ return true; } else { return !this.seismicZoneInstance.isPopupOpen()}}))
      .map(term => (this.siloDesignOptions.seismicZones ));


  step1Search = (text$: Observable<string>) =>
    text$
      .debounceTime(300)
      .distinctUntilChanged()
      .switchMap(term => {
        return term.length <= 2 ? [] : this.companyService.getCompanies(term)
          .catch(() => Observable.of([]));
      })



  formatterCustomer = (x: { name: string }) => x.name;

  step2SearchCountry = (text$: Observable<string>) =>
    text$
      .debounceTime(300)
      .distinctUntilChanged()
      .switchMap(term => {
        return term.length <= 2 ? [] : this.cityService.searchCountries(term)
          .catch(() => Observable.of([]));
      })

  formatterCountry = (x: { name: string }) => x.name;
  formatterPlaceOfDeliveryCountry = (x: { name: string }) => x.name;

  step2SearchSite = (text$: Observable<string>) =>
    text$
      .debounceTime(300)
      .distinctUntilChanged()
      .switchMap(term => {
        return Observable.fromPromise(this.cityService.getCitiesByCountry(this.selectedCountry.id, term));
      })
      .catch(() => Observable.of([]));

  step6SearchSite = (text$: Observable<string>) =>
    text$
      .debounceTime(300)
      .distinctUntilChanged()
      .switchMap(term => {
        return Observable.fromPromise(this.cityService.getCitiesByCountry(this.selectedPlaceOfDeliveryCountry.id, term));
      })
      .catch(() => Observable.of([]));

  formatterSite = (x: { nameUtf8: string, state: { nameUtf8: string } }) => { return x.nameUtf8 + (x.state ? ', ' + x.state.nameUtf8 : '') };

  formatterPlaceOfDelivery = (x: { nameUtf8: string, state: { nameUtf8: string } }) => { return x.nameUtf8 + (x.state ? ', ' + x.state.nameUtf8 : '') };

  canSaveOffer(): boolean {
    return _.isEqual(this.offer, this.offerData);
  }

  onSubmit(): Promise<boolean> {
    let saved;

    if (!this.offerService.offerCanBe(this.offerData, 'saved')) {
      this.modalService.open(this.notAllowedModal);
      this.offerData = _.cloneDeep(this.offer);
      return Promise.resolve(false);
    }
 
    let updatedOffer = { ...this.offerData };
    updatedOffer.customer = this.offerData.customer ? { id: this.offerData.customer.id } : null;
    updatedOffer.cityOfInstallation = this.offerData.cityOfInstallation ? { id: this.offerData.cityOfInstallation.id } : null;
    updatedOffer.placeOfDelivery = this.offerData.placeOfDelivery ? { id: this.offerData.placeOfDelivery.id } : null;
    updatedOffer.bankDetails = this.offerData.bankDetails ? { id: this.offerData.bankDetails.id } : null;
    updatedOffer.typeOfSupply = this.offerData.typeOfSupply ? { id: this.offerData.typeOfSupply.id } : null;
    updatedOffer.currency = this.offerData.currency ? { id: this.offerData.currency.id } : null;
    updatedOffer.termsOfDelivery = this.offerData.termsOfDelivery ? { id: this.offerData.termsOfDelivery.id } : null;

    updatedOffer['paymentTerm'] = this.offerData['paymentTerm'] ? { id: this.offerData['paymentTerm'].id } : null;
    updatedOffer.inquiryInformationInquiryBy = this.offerData.inquiryInformationInquiryBy ? { id: this.offerData.inquiryInformationInquiryBy.id } : null;
    updatedOffer.inquiryInformationQuotedBy = this.offerData.inquiryInformationQuotedBy ? { id: this.offerData.inquiryInformationQuotedBy.id } : null;

    updatedOffer.father = updatedOffer.father ? {id: updatedOffer.father.id} : null;
    //updatedOffer.transportPrice = this.getShipmentTotal(true);

    delete updatedOffer.offerRevisionSilos;
    delete updatedOffer.offerRevisionProducts;
    delete updatedOffer.offerRevisionCommissions;
    delete updatedOffer.user;
    delete updatedOffer.orderRevision;
    delete updatedOffer.capacityTotal;

    delete updatedOffer.status;
    delete updatedOffer.adquired;
    delete updatedOffer.scopeOfSupplyOverride;

    delete updatedOffer.idTempOffer;
    delete updatedOffer.idOffer;
    delete updatedOffer.yearGroup;
    delete updatedOffer.targetUsed;
    delete updatedOffer.offer.historyNotes;

    updatedOffer.targetDiscount = this.calcExworksDisscountTarget();
    updatedOffer.targetCommissionRate = this.calcCommissionRatePercentageTarget();
    updatedOffer.commissionRate = this.calcCommissionRatePercentage();

    return this.offerService.updateOffer(updatedOffer)
      .then((offer: any) => {

    
        this.errorBox.success('Offer saved successfully.');

        this.offer = this.serializeOffer(offer);
        this.offerData = _.cloneDeep(this.offer);
        this.getOfferPrices();
        this.getOfferProductSections(offer);
        this.offerData.offer.historyNotes.sort(this.compareBydate);

        this.selectedPlaceOfDeliveryCountry = this.offer.placeOfDelivery ? this.offer.placeOfDelivery.country ? this.offer.placeOfDelivery.country : null : null;
        this.blockDelivery();

        this.setCommissions();
       // this.setTargetPrice();


        return true;
      }, error => {

        return false;
      });
  }

  saveOffer(){

    let silos = this.offerData.offerRevisionSilos;
    let sections = this.categoryProducts;

    this.saveOfferCommissions();

    this.processingReqSaveOffer = true;

    let order = 0;

    for(let product of this.offerData.offerRevisionSilos){
        product.priorityOrder = order;
   
        order++;
    }

    let orderSection = 0;

    for(let section of this.categoryProducts){
        section.sectionOrder = orderSection;
  
        orderSection++;
    }


    const editOfferSilo = val =>
      this.offerService.editOfferSilo(this.offerData, this.seralizeOfferRevisionSilo(val));


    const source = of(silos);
    const returnedProducts = source.pipe(mergeMap(q => forkJoin(...q.map(editOfferSilo))), catchError(error =>{
    
        this.processingReqSaveOffer = false;
        return of(error);
      }
    ));

    const subscribe = returnedProducts.subscribe(val => {


      this.onSubmit()
        .then(saved => {

         this.checkDiscountColumn();
         this.getOfferSilos();

         this.processingReqSaveOffer = false;


        });

    });



    const editOfferSection = section =>
      this.offerService.editOfferSection(this.offerData, this.seralizeOfferRevisionSection(section));


    const sourceS = of(sections);
    const returnedSections = sourceS.pipe(mergeMap(q => forkJoin(...q.map(editOfferSection))), catchError(error =>{
      
        this.processingReqSaveOffer = false;
        return of(error);
      }
    ));

    const subscribeS = returnedSections.subscribe(sections => {


    });

    this.saveOfferCommissions();

    if(_.isEmpty(silos)){

      this.onSubmit()
        .then(saved => {

         this.checkDiscountColumn();
         this.getOfferSilos();
         this.processingReqSaveOffer = false;


        });
    }


  }

  offerSilosReplica:OfferRevisionSilo[] = [];

  getOfferSilos(){

    this.offerService.getOfferSilos(this.offer).then(offerSilos => {
       
        this.processingReqLoadSilos = false;

        //console.log("Silos en la oferta -->  ");
        //console.log(offerSilos);

        for(let offerRevisionSilo of offerSilos){

          offerRevisionSilo = this.unserializeOfferSilo(offerRevisionSilo);

          if(offerRevisionSilo.isRecalculated){
            offerRevisionSilo['silo'] = offerRevisionSilo.recalculatedSilo;
            offerRevisionSilo.recalculatedSilo = null;
          }

        }


        this.offerData.offerRevisionSilos = offerSilos;
        this.offerSilosReplica=offerSilos;

        this.checkOrder();
    
        this.offerData.offerRevisionSilos.sort(this.compare);
        this.offerSilosReplica.sort(this.compare);

        this.updatePrices();



        // Array customizado para separar los precios en las ofertas // 
  


        //console.log(" Tratar array aqui --> ");
        //console.log(this.offerData);
        //console.log(this.managmentData.kprice);
        var totalcosts = 0;
        var totalCosts2 = 0;
        var totalCostsAll = 0;
        this.priceOnlyAccesores = 0;
        this.priceOnlySilo = 0;
        this.priceSyloComplete = 0;
        this.priceExtrasAccesories = 0;
        // para todas las ofertas de silos --> 
        //console.log("ff");
        console.log(this.offerData.offerRevisionSilos);
        for(let siloWithAccessories of this.offerData.offerRevisionSilos) {

            totalcosts = 0;
            totalCosts2 = 0;

            totalCostsAll += this.getSiloPriceDiscountedTotal(siloWithAccessories);
            
            for(let productosAcces of siloWithAccessories.offerRevisionSiloProducts) {
              //console.log("Cantidad a coger de este producto --> " + productosAcces.quantity + "  De el producto den coste--> " + productosAcces.cost);
              totalcosts += (productosAcces.cost*productosAcces.quantity);
            }
            // calculo del coste por componentes con K section and discount
            let discount = this.offerData.freezeTarget ? this.calcExworksDisscountTarget() : this.offerData.discount;
            // console.log("Descuento --> " +  discount + " Cantidad de silos asi --> " + siloWithAccessories.quantity );
            totalcosts = (totalcosts*siloWithAccessories.quantity); // x la cantidad de silos pedidos 
            //console.log("Antes de aplicar Para " + siloWithAccessories.completeModel +  " El total de coste de los productos individuales --> " + totalcosts);
            var totalCosts2 = (totalcosts*this.managmentData.kprice*siloWithAccessories.kSection)*(1-discount); // aplicar costes de productos & kprice --> 
            //console.log("Despues de aplicar Para " + siloWithAccessories.completeModel +  " El total de coste de los productos individuales --> " + totalCosts2);
            this.priceOnlyAccesores += totalCosts2;

            console.log("total precios accesorios del silo actual ---> " + siloWithAccessories.completeModel +  " --> " + totalCosts2);           
            /*
            var separatePrices = {
              siloPrice: 0,
              accesoriesPrice: totalCosts2
            };
            this.offersSeparatePrices.push(separatePrices);
            */
           

        } // fin for **
        console.log();
        this.priceOnlySilo = totalCostsAll-this.priceOnlyAccesores;
        //console.log("**********");
        //console.log("** Resultado final");
        this.priceSyloComplete = this.priceOnlyAccesores+this.priceOnlySilo;
        this.priceExtrasAccesories = this.offerData.exWorksPrice - this.priceSyloComplete;
        console.log("**  Precio solo accesorios / silo ");
        console.log("**  Precio completos --> ** " +  this.priceSyloComplete);
        console.log("**  Precio Accesorios --> ** " +  this.priceExtrasAccesories); // --> 
        console.log("**  Target freeze --> " +  this.offerData.freezeTarget);
        //console.log('Only accesorioes files --> ',this.priceOnlyAccesores);
        //console.log('Only Silo files --> ', this.priceOnlySilo);
        // total costes // calculo del resto con el silo
      
    }).catch(error => {

      this.processingReqLoadSilos = false

    });

  }

  unserializeOfferSilo(offerRevisionSilo){

    if(offerRevisionSilo.designDensity){

         offerRevisionSilo.designDensity =  this.siloService.decimalCastingReverse(offerRevisionSilo.designDensity);
   
    }

    if(offerRevisionSilo.seismicZone){
        offerRevisionSilo.seismicZone =  this.siloService.decimalCastingReverse(offerRevisionSilo.seismicZone);
     }

     return offerRevisionSilo;

  }

  saveOfferSilos(){

    this.offerData.offerRevisionSilos.sort(this.compare);

    let order = 0;
    for(let product of this.offerData.offerRevisionSilos){
        product.priorityOrder = order;
        product.priceList = this.getSiloPriceList(product);
     
        order++;
    }

    let categories = this.offerData.offerRevisionSilos;

    if(!_.isEmpty(categories)){

      const editOfferSilo = val =>
        this.offerService.editOfferSilo(this.offerData, this.seralizeOfferRevisionSilo(val));


      const source = of(categories);
      const returnedProducts = source.pipe(mergeMap(q => forkJoin(...q.map(editOfferSilo))), catchError(error =>{

        return of(error);
      }  ));
      const subscribe = returnedProducts.subscribe(val => {


        for(let offerRevisionSilo of val){

             offerRevisionSilo = this.unserializeOfferSilo(offerRevisionSilo);

        }

        this.offerData.offerRevisionSilos = val;


      });
    }

  }

  getSiloPriceList(item){
     return (item.cost*this.managmentData.kprice);
  }


  sendToNext() {

    this.processingReqSaveOffer = true;

    if (!this.offerService.offerCanBe(this.offerData, 'sendToNext', false)) {
      this.modalService.open(this.notAllowedModal, {});
      this.processingReqSaveOffer = false;
      return Promise.resolve(false);
    }

    this.stepsErrors = Offer.checkSteps(this.offerData);
    for (let step of this.stepsErrors) {
      if (step.errors.length > 0) {
        this.openStepsErrorModal();
        this.processingReqSaveOffer = false;
        return;
      }
    }

    this.offerService.sendToNextState(this.offerData.offer.id, this.offerData.revision)
      .then((offer: Offer) => {
        this.offer = this.serializeOffer(offer);
        this.offerData = _.cloneDeep(this.offer);
        this.offerData.offer.historyNotes.sort(this.compareBydate);

        this.disableFields();
        //this.setTargetPrice();

        this.processingReqSaveOffer = false;

        this.processingReqLoadSilos = true;
        this.getOfferSilos();

        this.getOfferPrices();
        this.setCommissions();

      });

  }

  allStepsCorrect(){

     let stepsErrors = Offer.checkSteps(this.offerData);

      for (let step of stepsErrors) {

        if (step.errors.length > 0) {

          return false;
        }
      }

      return true;
  }

  openStepsErrorModal() {
    this.modalService.open(this.sendToNextErrors, {}).result.then((result) => {
      if (result === 'Search') {
        //this.search();
      }
    }, (reason) => {
    });
  }

  sendBack() {
    this.processingReqOfferActions = true;

    this.offerService.sendToBackState(this.offerData.offer.id, this.offerData.revision)
      .then((offer: any) => {

        this.offer = this.serializeOffer(offer);
        this.offerData = _.cloneDeep(this.offer);
        this.offerData.offer.historyNotes.sort(this.compareBydate);

        this.disableFields();
        //this.setTargetPrice();

        this.processingReqOfferActions = false;
        this.processingReqLoadSilos = true;

        this.getOfferSilos();

        this.getOfferPrices();
        this.setCommissions();

      });
  }

  setTargetPrice(){

      if(!this.offerData.freezeTarget && !this.offerData.targetUsed){

        this.offerData.targetPrice = this.calcFinalOfferPriceWithCurrencyRate();
      }

  }

  resetTarget(){
    this.offerData.targetPrice = this.calcFinalOfferPriceWithCurrencyRate();
  }

  rejectNext() {
    this.offerService.rejectNext(this.offerData.offer.id, this.offerData.revision)
      .then(offer => { 
        this.offerData = offer; this.offer = offer;
        this.offerData.offer.historyNotes.sort(this.compareBydate);
      });
  }


  blockPaymentDays(event?) {


    if(_.isNil(this.offerData.paymentTerm)){

      this.paymentDays1Control.disable();
      this.paymentDays2Control.disable();
      this.paymentDays3Control.disable();
      this.paymentTerm1Control.disable();
      this.paymentTerm2Control.disable();
      this.paymentTerm3Control.disable();
    }
    else{



      switch (this.offerData.paymentTerm.name) {
        default:
        case "XXLCREDYY":
        case "LCREDXXYY":

          this.paymentTerm1Control.enable();
          this.paymentTerm2Control.enable();
          this.paymentTerm3Control.disable();

          this.paymentDays1Control.enable();
          this.paymentDays2Control.enable();
          this.paymentDays3Control.disable();

          this.offerData.paymentTerm1 = 30;
          this.offerData.paymentTerm2 = 70;
          this.offerData.paymentTerm3 = null;

          this.offerData.paymentDays1 = null;
          this.offerData.paymentDays2 = null;
          this.offerData.paymentDays3 = null;

          break;
        case "XXBTYY":
          this.paymentTerm1Control.enable();
          this.paymentTerm2Control.enable();
          this.paymentTerm3Control.disable();

          this.paymentDays1Control.enable();
          this.paymentDays2Control.enable();
          this.paymentDays3Control.disable();

          this.offerData.paymentTerm1 = 30;
          this.offerData.paymentTerm2 = 70;
          this.offerData.paymentTerm3 = null;

          this.offerData.paymentDays1 = null;
          this.offerData.paymentDays2 = null;
          this.offerData.paymentDays3 = null;


          break;
        case "XXTRANYY":
          this.paymentTerm1Control.enable();
          this.paymentTerm2Control.enable();
          this.paymentTerm3Control.disable();

          this.paymentDays1Control.enable();
          this.paymentDays2Control.disable();
          this.paymentDays3Control.disable();

          this.offerData.paymentTerm1 = 30;
          this.offerData.paymentTerm2 = 70;
          this.offerData.paymentTerm3 = null;

          this.offerData.paymentDays1 = null;
          this.offerData.paymentDays2 = null;
          this.offerData.paymentDays3 = null;

          break;
        case "TRAN":
        case "LCRED":
        case "DEL":
          this.paymentTerm1Control.disable();
          this.paymentTerm2Control.disable();
          this.paymentTerm3Control.disable();

          this.paymentDays1Control.enable();
          this.paymentDays2Control.disable();
          this.paymentDays3Control.disable();


          this.offerData.paymentTerm1 = 100;
          this.offerData.paymentTerm2 = null;
          this.offerData.paymentTerm3 = null;

          this.offerData.paymentDays1 = null;
          this.offerData.paymentDays2 = null;
          this.offerData.paymentDays3 = null;
          break;

        case "XXBTYY-ZZ/WW":

          this.paymentTerm1Control.enable();
          this.paymentTerm2Control.enable();
          this.paymentTerm3Control.enable();

          this.paymentDays1Control.enable();
          this.paymentDays2Control.enable();
          this.paymentDays3Control.disable();

          this.offerData.paymentTerm1 = 30;
          this.offerData.paymentTerm2 = 50;
          this.offerData.paymentTerm3 = 20;

          this.offerData.paymentDays1 = null;
          this.offerData.paymentDays2 = null;
          this.offerData.paymentDays3 = null;

          break;

      }

    }


  }

  dateAvailabilityCalc() {
    if (!_.isNil(this.offerData.dateAvailabilityStart) && !_.isNil(this.offerData.dateAvailabilityEnd)) {
      if (this.offerData.dateAvailabilityStart > this.offerData.dateAvailabilityEnd) {
        this.offerData.dateAvailabilityEnd = this.offerData.dateAvailabilityStart + 1;
      }
    }
  }

  addExclusion(exclusion) {
    this.offerData.exclusions.push({
      id: exclusion.id,
      translations: [
        {
          locale: "en",
          name: exclusion.translations[0].name
        },
        {
          locale: "es",
          name: exclusion.translations[1].name

        }
      ]
    });
  }

  addCustomExclusion() {
    this.offerData.exclusions.push(
      {
        id: null,
        translations: [
          {
            locale: "en",
            name: this.customExclusion.translations[0].name
          },
          {
            locale: "es",
            name: this.customExclusion.translations[1].name

          }
        ]

      }



      );

      this.initEmptyCustomExclusion();
  }

  removeExclusion(exclusion) {
    this.offerData.exclusions.splice(this.offerData.exclusions.indexOf(exclusion), 1);
  }

  addAllExclusions(): void {

      let exclusions = [];

      for(const exclusion of this.exclusions){

        let newExclusion = {
          id: exclusion.id,
          translations: []
        };

        newExclusion = this.setExclusionsTranslations(exclusion, newExclusion);

        exclusions.push(newExclusion);
      }

      this.offerData.exclusions = exclusions;


  }

  setExclusionsTranslations(fromExclusion,exclusion){
    fromExclusion.translations.forEach(translation => {
      exclusion["translations"].push({
        name: translation.name,
        locale: translation.locale
      })
    });

    return exclusion;
  }

  removeAllExclusions(): void {
    this.offerData.exclusions = [];
  }

  findExclusions(exclusion): boolean {
    return _.findIndex(this.offerData.exclusions, ['id', exclusion.id]) === -1;
  }

  equals(obj1, obj2) {
    return _.isEqual(obj1, obj2);
  }

  equalName(obj1, obj2) {
    return  obj1.name ==  obj2.name;
  }


  compareByName(obj1, obj2){

    return obj1 && obj2 ? obj1.name == obj2.name : obj1 == obj2;

  }

  offerCanBe(action): boolean {
    return this.offerService.offerCanBe(this.offerData, action);
  }

  getTotalQuantity(): number {
    let total = 0;
    for (let item of this.offerData.offerRevisionSilos) {
      total += item.quantity;
    }
    return total;
  }

  getTotalWeight(): number {
    let total = 0;
    for (let item of this.offerData.offerRevisionSilos) {
      total += item.weight * item.quantity;
    }
    return total;
  }

 /* getTotalPrice(): number {
    let total = 0;
    for (let item of this.offerData.offerRevisionSilos) {
      total += item.price * item.quantity;
    }
    return total;
  }
*/
  getDefaultOffice(){

    if(!_.isNil(this.offerData.customer) && _.isObject(this.offerData.customer)){


       for (let office of this.offerData.customer.offices){

           if(office.isDefault)
           {
             return office;
           }

       }

    }
    else{
      return null;
    }
  }

  getDefaultPerson(){

    let defaultOffice = this.getDefaultOffice();
    if(!_.isUndefined(defaultOffice) && !_.isEmpty(defaultOffice.users)){

      for (let person of defaultOffice.users){

           if(person.isDefault)
           {
             return person;
           }

       }

    }

    return null;

  }

  calcStorageCapacity(show?) {
    let total = 0;

    if (this.offerData.offerRevisionSilos) {
      for (const offerRevisionSilo of this.offerData.offerRevisionSilos) {

        total +=  offerRevisionSilo.capacity * offerRevisionSilo.quantity ;
      }

    }


   if(show){

     return this.localeCustomUnity.transform(total,"capacity"); //kilo to tonns
   }


    return total;
  }

  getStorageCapacityUnit(){

     if ( !_.isNull(this.offerData.typeOfSupply)  && !_.isUndefined(this.offerData.typeOfSupply)) {

         switch(this.offerData.typeOfSupply.name){

           case "WTT":
             return "m<sup>3</sup>";

           default:
             return "T";

         }

     }

     return "T";

  }

  canSetPlaceOfDelivery() {
    return this.offerData.termsOfDelivery ? !(_.isEqual(this.offerData.termsOfDelivery.name, 'EXW') || _.isEqual(this.offerData.termsOfDelivery.name, 'FCA')) : false;
  }

  canViewProforma(): boolean {
    if (this.userData) {
      for (let role of this.userData.user.roles) {
        if (role === 'SUPER_ADMIN' || role === 'INTERNAL_ADMIN' || role === 'INTERNAL_USER') {
          return true;
        }
      }
    }
    return false;
  }

  getHeadingsOverrideHTML() {
    if (this.offerData.headingsOverride) {
      return this.lineJumpPipe.transform(this.offerData.headingsOverride).split('<br>');
    }
    return '';
  }
  getPaymentTermsOverrideHTML(){

    if (this.offerData.paymentTermsOverride) {
      return this.lineJumpPipe.transform(this.offerData.paymentTermsOverride).split('<br>');
    }
    return '';
  }

  getDateAvailabilityOverrideHTML() {

    if (this.offerData.dateAvailabilityOverride) {
      return this.lineJumpPipe.transform(this.offerData.dateAvailabilityOverride).split('<br>');
    }
    return '';
  }

  getValidityOverrideHTML() {

    if (this.offerData.validityOverride) {
      return this.lineJumpPipe.transform(this.offerData.validityOverride).split('<br>');
    }
    return '';
  }

  selectCurrency(target) {
    target.blur();

    if(this.currency.symbol === "EURO"){
      this.currencyChangeRate = this.calculateComulatedCurrencyRateReverse();
    }

  }


  log() {

  }

  currencyChangeModaltitle:string = "";
  requiredNote: boolean = false;
  revisionConfirmationModal() {

      this.translate.get('PSS.offerRevision.newRevision').subscribe((text: string) => {
        this.currencyChangeModaltitle = text;
      });

      if(this.offerData.currency.symbol != "EURO"){
        const euroCurrency =  this.currencies.find(x => x.symbol == 'EURO');
        const currentCurrency =  this.currencies.find(x => x.symbol == this.offerData.currency.symbol );
        this.currencies = [];
        this.currencies.push(euroCurrency);
        this.currencies.push(currentCurrency);

      }


      this.requiredNote = true;

        const modalRef =  this.modalService.open(this.currencyChangeModal, {size: 'lg', windowClass: 'full-window-modal',  backdrop: 'static', keyboard: false });

        modalRef.result.then((result) => {
        if (result === 'Accept') {

          this.addHistoryNote().then(historyNote => {
          
            this.createRevision();
          
          }).catch(error => {
            switch(error.status){
              case 403:{
                this.errorBox.error("Can not create revision");
                break;
              }
  
            }
      
           
          });


        } else {
          this.offerData.currency = _.cloneDeep(this.offer.currency);
          this.offerData.currencyChangeRate = _.cloneDeep(this.offer.currencyChangeRate);
        }
      }, (reason) => {
        this.offerData.currency = _.cloneDeep(this.offer.currency);
        this.offerData.currencyChangeRate = _.cloneDeep(this.offer.currencyChangeRate);
      });

  }

  duplicationConfirmationModal() {

    this.translate.get('PSS.offerRevision.newDuplicate').subscribe((text: string) => {
      this.currencyChangeModaltitle = text;
    });

    this.requiredNote = false;

    const modalRef = this.modalService.open(this.currencyChangeModal, {size: 'lg', windowClass: 'full-window-modal',  backdrop: 'static', keyboard: false });

     modalRef.result.then((result) => {
      if (result === 'Accept') {

        this.duplicateOffer();


      } else { //revert changes if canceled
        this.offerData.currency = _.cloneDeep(this.offer.currency);
        this.offerData.currencyChangeRate = _.cloneDeep(this.offer.currencyChangeRate);
      }
    }, (reason) => { //on error revert changes to original
      this.offerData.currency = _.cloneDeep(this.offer.currency);
      this.offerData.currencyChangeRate = _.cloneDeep(this.offer.currencyChangeRate);
    });

}
  calculateShipmentPrice(shipments, markup?): number {
    let final = 0;
    let markupValue = this.offerData.transport.markUp;
 
    for (let shipment of shipments) {
      let shipCost = shipment.costPerUnit * shipment.quantity;
      if (markup) {
        shipCost += shipCost * (markupValue);
      }
      final += shipCost;
    }

		return final;
  }


  getShipmentMargin(percentage): number{

    switch(percentage){
      case true:
       return this.offerData.markupTransport;
      case false:
        return this.getShipmentTotal(false) - this.getShipmentTotal(true);
    }

  }

  getSupervisionMargin(percentage): number{

    switch(percentage){
      case true:
       return this.offerData.markupSupervision;
      case false:
        return this.getSupervisionTotal(false) - this.getSupervisionTotal(true);
    }

  }

  getErectionMargin(percentage): number{

    switch(percentage){
      case true:
       return this.offerData.markupSiloErection;
      case false:
        return this.getErectionTotal(false) - this.getErectionTotal(true);
    }

  }

  getServiceMargin(percentage): number{

    switch(percentage){
      case true:
       return this.offerData.markupService;
      case false:
        return this.getServiceTotal(false) - this.getServiceTotal(true);
    }

  }


  getShipmentTotal(cost): number {

    /*switch(this.offerData.transport.transportBy){
      case true: //trailers
        return this.calculateShipmentPrice([this.offerData.transport.trailer], price);

      case false: //containers
        return this.calculateShipmentPrice([this.offerData.transport.container20, this.offerData.transport.container40 ], price);
    } */


    if(cost){

      return this.offerData.transportPrice * (1 - this.offerData.markupTransport);
    }
    else{
      return this.offerData.transportPrice;
    }

  }

  getSupervisionTotal(cost): number {

    if(cost){
      return this.offerData.supervision * (1 - this.offerData.markupSupervision);
    }
    else{
      return this.offerData.supervision;
    }

  }

  getErectionTotal(cost) : number{

    if(cost){
      return this.offerData.siloErectionPrice * (1 - this.offerData.markupSiloErection);
    }
    else{
      return this.offerData.siloErectionPrice;
    }

  }

  getServiceTotal(cost) : number{

    if(cost){
      return this.offerData.servicePrice * (1 - this.offerData.markupService);
    }
    else{
      return this.offerData.servicePrice;
    }

  }


  getCertificateTotal(cost) : number{

    if(cost){
      return this.offerData.servicePrice * (1 - this.offerData.markupService);
    }
    else{
      return this.offerData.servicePrice;
    }

  }



  checkDiscountBounds() {
   /* if (this.offerData.discount > this.offerData.user.discount) {
     
      if (this.userData.user.roles.indexOf('INTERNAL_ADMIN') === -1) {
        this.discountWarning = true;
        this.percentageInput(this.offerData.user.discount * 100, 'offerData.discount');
      }
    } else if (this.offerData.discount < 0) {
      this.negativeDiscountWarning = true;
      this.percentageInput(this.offerData.user.discount * 100, 'offerData.discount');
    }

    */

  }

  checkUserComission(value){
    //this.offerData.user.commission = value;

  }

  percentageInput(value, object) {

    object.amount = value/100;


  }

  updateOtherCommissions(updatedCommission) {

    for (let commission of this.offerData.offerRevisionCommissions) {
      if (!_.isEqual(commission, updatedCommission)) {

          commission.amount = this.calcDiscountWithCommissionCurrency() * commission.amount;

      }
    }
  }

  updatePercentage(value,commission) {

    commission.amount = value / this.calcExworksWithComissionUnbalanced();

  }

  updatePrice(commission) {

    commission.amountMoney = this.calcExworksWithComissionUnbalanced() * commission.amount;

  }

  sumCommissionsPrice(): number {
    let total = 0;

    for (let commission of this.offerData.offerRevisionCommissions) {
      total += commission.amount;
    }

    return total;
  }

  calcDiscountWithCommissionNoASMPercent(): number {
    let total = 0;

    total = (this.calcDiscountWithCommissionNoASMCurrency() / this.sumCommissionsPrice() === 0 ? 1 : this.sumCommissionsPrice()) / this.calcDiscountWithCommissionNoASMCurrency();

    return total;
  }

  calcDiscountWithCommissionNoASMCurrency(): number {
    let total = 0;

    total = this.sumCommissionsPrice() + (this.offerData.exWorksPrice * (1 - this.offerData.discount));

    return total;
  }


  setSecondBreadcrumb(el) {
    let breadcrum: any = document.getElementsByTagName('app-breadcrumbs');
    let currentBread = breadcrum[0];
    if (currentBread.lastElementChild) {
      let padding = currentBread.lastElementChild.offsetLeft + currentBread.lastElementChild.clientWidth / 2;
      el.style.paddingLeft = padding + 'px';
    
    }
  }

  // apply 10% de precio al silo -- 
  applyPorcentInSilo(siloPrice) {
    return (siloPrice*1.56);
  }

    // apply 10% de precio al silo -- 
  applyPorcentInSiloTotals(siloPrice,accesoriesPrice) {
    return (siloPrice*1.56)+(accesoriesPrice*1.48);
  }


  // apply 15% de precio al silo -- 
  applyPorcentAccesories(accesoriesPrice) {
    return (accesoriesPrice*1.48);
  }

  configPriceBehaviour: number;
  getNewCurrencyObject(){
    const newCurrency = {
      currency: this.currency.id,
      currencyChangeRate: this.currencyChangeRate,
      pricesDate: this.pricesDate.year + "-" + this.pricesDate.month + "-" + this.pricesDate.day,
      configPriceBehaviour: this.configPriceBehaviour

    };

    return newCurrency;
  }

  duplicateOffer() {
    this.processingReqOfferActions = true;

    const newCurrency = this.getNewCurrencyObject();
   
    this.offerService.duplicateOffer(this.offerData.offer.id, this.offerData.revision, newCurrency)
      .then(offerDup => {
        this.router.navigate(['/dashboard', 'offers', 'edit', offerDup.offer.id, 'revision', offerDup.revision]);
        this.processingReqOfferActions = false;
      }).catch(error=> {
        this.processingReqOfferActions = false;
      });
  }

  createRevision() {
    this.processingReqOfferActions = true;

    const newCurrency =  this.getNewCurrencyObject();

   // console.table(newCurrency);

    this.offerService.createOfferRevision(this.offerData.offer.id, this.offerData.revision, newCurrency)
      .then(offerRev => {
        this.processingReqOfferActions = false;
        this.router.navigate(['/dashboard', 'edit', offerRev.offer.id, 'revision', offerRev.revision]);
      }).catch(error => {
        this.processingReqOfferActions = false;

        switch(error.status){
          case 403:
            this.errorBox.error("Can not create revision");
            break;
        }
      });
  }

  openDeleteModal() {
    const modal = this.modalService.open(DelConfModalComponent, {
      backdrop: 'static',
      keyboard: false,
      // size: 'sm',
      windowClass: 'rmv-modal',
    });
    modal.componentInstance.text = '<b>' + this.offer.title + '</b>';
    modal.componentInstance.type = 'offer';
    modal.result.then((result) => {
      if (result === 'Accept') {
        this.removeOffer(this.offerData);
      } else { }
    });
  }

  removeOffer(offer) {
    this.processingReqOfferActions = true;
    this.offerService.deleteOffer(offer.offer.id, offer.revision)
      .then(offer_id => {
        this.router.navigate(['/dashboard', 'offers']);
        this.processingReqOfferActions = false;
      }).catch(eror => {
        this.processingReqOfferActions = false;
      });
  }

  createCompanyModal() {

   const modalRef = this.modalService.open(CreateCompanyModalComponent, {  size: 'lg', windowClass: 'company-modal' });

   if(!_.isObject(this.offerData.customer)){
      modalRef.componentInstance.newCompanyData.name = this.offerData.customer;
   }


   modalRef.componentInstance.newCompany.subscribe((company: Company) => {
     this.offerData.customer = company;
     this.offerData.inquiryInformationInquiryBy =  this.getDefaultPerson();
      modalRef.close();
    });
    modalRef.result.then((result) => {
    }, (dismiss) => {
   
    });


  }

  createPersonalizeSiloModal(offerRevisionSilo){

    const modalRef = this.modalService.open(PersonalizeSiloModalComponent, {  size: 'lg', windowClass: 'full-window-modal' });

    modalRef.componentInstance.offerRevisionSilo = offerRevisionSilo;
    modalRef.componentInstance.offerRevisionSilos = this.offerData.offerRevisionSilos;
    modalRef.componentInstance.userData = this.userData;
    modalRef.componentInstance.offer = this.offerData;
    modalRef.componentInstance.managmentData = this.managmentData;

    let discount = this.offerData.freezeTarget ? this.calcExworksDisscountTarget() : this.offerData.discount;
    let commissionRate = this.offerData.freezeTarget ? this.calcCommissionRatePercentageTarget() : this.calcCommissionRatePercentage();


    modalRef.componentInstance.discount = discount;
    modalRef.componentInstance.commissionRate = commissionRate;
    modalRef.componentInstance.offer = this.offerData;

    modalRef.result.then((data) => {

      this.getOfferPrices();
      this.getOfferSilos();
    }, (reason) => {
      // on dismiss

      this.getOfferPrices();
      this.getOfferSilos();
    });

  }

  createAddProductModal(category?){
    const modalRef = this.modalService.open(AddProductModalComponent, {  size: 'lg', windowClass: 'full-window-modal' });


    modalRef.componentInstance.userData = this.userData;

    modalRef.componentInstance.offer = this.offerData;
    modalRef.componentInstance.managmentData = this.managmentData;


    let discount = this.offerData.freezeTarget ? this.calcExworksDisscountTarget() : this.offerData.discount;
    let commissionRate = this.offerData.freezeTarget ? this.calcCommissionRatePercentageTarget() : this.calcCommissionRatePercentage();

    modalRef.componentInstance.discount = discount;
    modalRef.componentInstance.commissionRate = commissionRate;

    if(!_.isUndefined(category) && !_.isNull(category)){

      let cat = {
        "childNodes": [category]
      };

      modalRef.componentInstance.categoryProducts = cat;
      modalRef.componentInstance.isSingleEquipment = true;

    }
    else{
      modalRef.componentInstance.isSingleEquipment = false;
    }

    modalRef.result.then((data) => {
      this.getOfferProductSections(this.offerData);
      this.getOfferPrices();
    }, (reason) => {
      // on dismiss
      this.getOfferProductSections(this.offerData);
      this.getOfferPrices();
    });

    modalRef.componentInstance.emitData.subscribe(() => {
        modalRef.close();
        this.getOfferPrices();
        this.updatePrices();
  
    });

  }

  createPersonalizeSiloDesignModal(offerRevisionSilo){

      this.siloIdx = this.offerData.offerRevisionSilos.indexOf(offerRevisionSilo);

      this.siloDesignModalRef = this.modalService.open(this.siloDesignModal, { size: 'lg',  windowClass: 'full-window-modal'  });

  }

  isSiloDesignForWatertank(offerSilo){
    return Silo.isWTT(offerSilo);
  }

  isSiloDesignForFBS(offerSilo){
    return Silo.isFBS(offerSilo);
  }

  isSiloDesignForDP(offerSilo){
    return Silo.isDP(offerSilo);
  }

  showModelsMissMatchAlert: boolean;

  restoreOfferRevisionSilo(){

    if(!this.disableInputCustom()){

      let originalOfferRevSilo = {...this.offerData.offerRevisionSilos[this.siloIdx]};

      this.offerService.getOfferSiloOriginalSilo(this.offerData, originalOfferRevSilo ).then( silo => {

        this.offerData.offerRevisionSilos[this.siloIdx] = this.initOfferRevisionSiloFromSilo(silo, true, originalOfferRevSilo);


      });

    }

  }

  closeSiloDesignModal(offerRevisionSilo){

    this.siloDesignModalRef.close();
    if(!this.disableInputCustom()){

        const itemIdx = this.offerData.offerRevisionSilos.indexOf(offerRevisionSilo);

        this.offerService.editOfferSilo(this.offerData, this.seralizeOfferRevisionSilo(offerRevisionSilo)).then(offerRevisionSilo => {

           this.offerData.offerRevisionSilos[itemIdx] = this.unserializeOfferSilo(offerRevisionSilo);


           this.errorBox.success('Silo updated successfully.');

        }).catch(error => {

        });
    }

  }


  moistureContentChanged(value){

    this.offerData.offerRevisionSilos[this.siloIdx].moistureContent = value/100;

  }


  transportMarkupChanged(value){
      this.offerData.markupTransport = value/100;
  }

  supervisionMarkupChanged(value){
      this.offerData.markupSupervision = value/100;
  }

  siloErectionMarkupChanged(value){
      this.offerData.markupSiloErection = value/100;
  }

  serviceMarkupChanged(value){
      this.offerData.markupService = value/100;
  }

  extBtnFn(extBtn: any) {
    this[extBtn.fn]();
  }

  selectInquiryBy(user) {
    this.offerData.inquiryInformationInquiryBy = user;
  }

  selectQuotedBy(user) {
    this.offerData.inquiryInformationQuotedBy = user;
  }

  checkStep(step: number): boolean {
    let correct = false;
    switch (step) {
      case 1:
        if (!_.isNil(this.offerData.customer)) {
          correct = !_.isNil(this.offerData.customer.id);
        }
        break;

      case 2:
        if (!_.isNil(this.offerData.cityOfInstallation)) {
          correct = !_.isNil(this.offerData.cityOfInstallation.id);
        }
        break;

      case 3:
        correct = !_.isNil(this.offerData.configPriceBehaviour);
        break;

      case 4:
        correct =  !_.isNil(this.offerData.dateAvailabilityStart) || !_.isNil(this.offerData.dateAvailabilityEnd) ;
        break;

      case 5:
        correct = !_.isNil(this.offerData.termsOfDelivery);
        break;

      case 6:

      if(!_.isNil(this.offerData.termsOfDelivery)){

        if (this.offerData.termsOfDelivery.name === 'EXW' ||
            this.offerData.termsOfDelivery.name === 'FCA' ||
            this.offerData.termsOfDelivery.name === 'FAS' ||
            this.offerData.termsOfDelivery.name === 'FOB' ){

              correct = true;
        }
        else if(this.offerData.termsOfDelivery.name === 'CFR' ||
                this.offerData.termsOfDelivery.name === 'CIF' ){

          correct = !_.isNil(this.offerData.placeOfDelivery);
        }
        else if(this.offerData.termsOfDelivery.name === 'CPT' ||
                this.offerData.termsOfDelivery.name === 'CIP' ||
                this.offerData.termsOfDelivery.name === 'DAT' ||
                this.offerData.termsOfDelivery.name === 'DAP' ||
                this.offerData.termsOfDelivery.name === 'DDP' ){

          correct = !_.isNil(this.offerData.placeOfDelivery) &&
                    !_.isNil(this.offerData.placeOfDeliveryZipCode) &&
                    !_.isNil(this.offerData.placeOfDeliveryAddress);

        }

      }


      break;

      case 7:


        if(!_.isNil(this.offerData.paymentTerm)){

          if( (!_.isNil(this.offerData.paymentTermsOverride) && !_.isEmpty(this.offerData.paymentTermsOverride)) ||
            this.offerData.paymentTerm1 + this.offerData.paymentTerm2 + this.offerData.paymentTerm3 == 100
          ){

            correct = true;
          }


        }

        break;

      case 8:
        if (this.offerData.bankDetails) {
          correct = !_.isNil(this.offerData.bankDetails.id);
        }
        break;

      case 9:
        correct = !_.isNil(this.offerData.validity) || (!_.isNil(this.offerData.validityOverride) && !_.isEmpty(this.offerData.validityOverride));
        break;

      case 10:
        // TODO: Exclusions
         correct = true;
        break;

      case 11:
        correct = true;
        break;

      case 12:
        if (this.offerData.typeOfSupply) {
          correct = !_.isNil(this.offerData.typeOfSupply.id);
        }
        break;

      case 13:
        correct = (!_.isNil(this.offerData.inquiryInformationInquiryBy) || (!_.isNil(this.offerData.inquiryInformationInquiryByOverride) && !_.isEmpty(this.offerData.inquiryInformationInquiryByOverride))) &&
                  ((!_.isNil(this.offerData.inquiryInformationProjectName) && !_.isEmpty(this.offerData.inquiryInformationProjectName)));
        break;

      case 14:
        // TODO: Transport
        correct = true;
        break;

      case 15:
        // TODO: Price
        correct = true;
        break;

      case 16:
        // TODO: Material Booking
        correct = true;
        break;

      case 17:
        if (this.offerData.currency) {
          if (_.isEqual(this.offerData.currency.symbol, 'EUR')) {
            correct = true;
          } else {
            correct = !_.isNil(this.offerData.currencyChangeRate) && !_.isEmpty(this.offerData.currencyChangeRate);
          }
        }
        break;

      default:
        correct = false;
        break;
    }
    return correct;
  }

  neededSteps(step): boolean {
    let needed = [];

    if (this.offerData.state === 0) {
      needed = [1, 2];
    } else {
      needed = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
    }

    return needed.indexOf(step) !== -1;
  }

  isSendToNextButtonDisabled(){


   return  !this.offerCanBe('sendToNext') ||
           this.processingReqSaveOffer ||
           (this.offerData.stateChangePending && this.offerData.state !== 2 && !this.offerCanBe('acceptReject'));
  }

  downloadFullDocuments(locale){
    this.downloadPdfFinal('full', 'Full-doc', locale);
  }

  downloadProformaDocuments(locale){
    this.downloadPdfFinal('proforma', 'Proforma', locale);
  }

  downloadComercialDocuments(locale){
    this.downloadPdfFinal('commercial', 'Commercial', locale);
  }

  downloadSiloDesignDocuments(locale){
    this.downloadPdfFinal('silo-design', 'Silo-Design', locale);
  }

  downloadEquipmentListDocuments(locale){
    this.downloadPdfFinal('equipment-list', 'Equipment-List', locale);
  }

  downloadEquipmentListWeightDocuments(locale){
    this.downloadPdfFinal('equipment-list-weight', 'Equipment-List-Weight', locale);
  }


  downloadDocument() {
    this.downloadingPdf = true;

    const docType = (<DocTypeEnum>this.download.docType);

    if(docType.type == "price"){
      this.downloadExcelPrice(this.download.lang);
    }
    else{

      this.offerService.downloadDocument(docType.type, this.offerData, this.download.lang)
        .map((res) => res.blob())
        .subscribe(data => {
          const blob = new Blob([data], { type: docType.fileType });

          this.translate.get(docType.name).subscribe((res: string) => {
            saveAs(blob, `${res}-${this.offerData.fileRef}-${this.download.lang.toUpperCase()}-${this.offerData.currency.symbol.toUpperCase()}`)
          })
          this.downloadingPdf = false;
        }, error => {
     
            this.errorBox.error('Error, try again.');

            this.downloadingPdf = false;
        });
    }

  }



  downloadPdfFinal(document,fileName,locale) {
    this.downloadingPdf = true;

    this.offerService.downloadOfferDocument(this.offerData, document,locale)
      .map((res) => res.blob())
      .subscribe(data => {
        const blob = new Blob([data], { type: 'application/pdf' });

        saveAs(blob, fileName + '-' + this.offerData.fileRef);
        this.downloadingPdf = false;
      },

      error =>  {
          
          this.errorBox.error('Error, try again.');

          this.downloadingPdf = false;

        }
      )


  }

  downloadPdfPivot(locale, offerRevisionSilo){

    this.downloadingPdf = true;

    const itemIdx = this.offerData.offerRevisionSilos.indexOf(offerRevisionSilo);

    this.offerService.editOfferSilo(this.offerData, this.seralizeOfferRevisionSilo(offerRevisionSilo)).then(offerRevisionSilo => {

      this.offerData.offerRevisionSilos[itemIdx] = this.unserializeOfferSilo(offerRevisionSilo);
      // this.errorBox.success('Silo updated successfully.');

        this.offerService.downloadSinglePivot(this.offerData, offerRevisionSilo,"silo-design",locale)
          .map((res) => res.blob())
          .subscribe(data => {
            const blob = new Blob([data], { type: 'application/pdf' });
            this.downloadingPdf = false;
            saveAs(blob, "Silo-Design" + '-' + this.offerData.fileRef);

          },

          error =>  {
     
              this.downloadingPdf = false;
            }
          );

      });


  }


  createUserModal() {
    const modalRef = this.modalService.open(CreateUserModalComponent, { windowClass: 'form-modal' });

    modalRef.componentInstance.offices = this.offerData.customer.offices;

    modalRef.componentInstance.newUser.subscribe((user) => {

      this.companyService.getCompanyById(this.offerData.customer.id)
        .then(company => this.offerData.customer = company);

      this.offerData.inquiryInformationInquiryBy = user.person;
      modalRef.close();
    });
    modalRef.result.then((result) => {
    }, (dismiss) => {
  
    });
  }

  previousTab(){
    let activeTabInt = parseInt(this.tabsOfferSteps.activeId);

    if(!this.isFirstTab(activeTabInt)){
      let previousTab = this.getPreviousTab(activeTabInt);

      this.tabsOfferSteps.select(previousTab);
    }

  }

  nextTab(){

    let activeTabInt = parseInt(this.tabsOfferSteps.activeId);

    if(!this.isLastTab(activeTabInt)){

      let nextTab = this.getNextTab(activeTabInt);
  
      this.tabsOfferSteps.select(nextTab);
    }

  }

  isFirstTab(tabNumber){

    return tabNumber==1 ? true : false;

  }

  isLastTab(tabNumber){
    return tabNumber==15 ? true : false;
  }

  getNextTab(tabNumber) : string{

    if(tabNumber==12){
      return String(14);

    }

    else{
      return String(tabNumber+1);
    }

  }

  getPreviousTab(tabNumber) : string {

    return String(tabNumber-1);
  }

  onChangeCustomerModel($event){

  }
  tabChangeSteps(){

  }
  transportMarkUpChanged(value){
 
    this.offerData.transport.markUp = value/100;
  }

  // disabled target 
  disabledTargetShowIfIsDefined(){


  }


  disableInputCustom(){
   return this.offerFreezged() || this.offerData.state === 3 || this.offerData.state === 4 || !this.userCanEditOffer()
  }

  disableInputCustomTarget(){
   return  this.offerFreezged() || this.offerData.state === 3 || this.offerData.state === 4 || !this.userCanEditOffer()
  }

  getTranslationPaymentTerm(object,propery){

    let translation = this.miscService.getTranslationSteps(object,this.translate.currentLang);
  
    if(!translation){
      return "Pending";
    }

    let paymentTermText = translation[propery]
                .replace("XX",'<span style=" color: #a58701 !important">'+this.offerData.paymentTerm1 +'</span>' )
                .replace("YY",'<span  style=" color: #a58701 !important">' + this.offerData.paymentTerm2 +'</span>' )
                .replace("ZZ",'<span  style=" color: #a58701 !important">' + this.offerData.paymentTerm3 +'</span>' );

      let daysAfterThe = "DAYS AFTER THE";
      let daysAfter = "DAYS AFTER";
      let at = "AT";
      let atSightUpon = "AT SIGHT UPON";

      switch(this.translate.currentLang){
        case "es":
          daysAfterThe = "DÍAS DESPUÉS DE";
          daysAfter = "DÍAS DESPUÉS DE";
          at= "A";
          atSightUpon = "A LA VISTA Y CONTRA";
          break;

        case "ru":
            daysAfterThe = "ДНЕЙ ПОСЛЕ";
            daysAfter = "ДНЕЙ ПОСЛЕ";
            at = "";
            atSightUpon = "НА ОСНОВАНИИ ПРЕДСТАВЛЕНИЯ ";
            break;

        case "de":
            daysAfterThe = "TAGE NACH DER";
            daysAfter = "TAGE NACH DER";
            at = "DER";
            atSightUpon = "GEGEN VORLAGE VON";
            break;
      }


    if(!_.isNull(this.offerData.paymentDays1)){
      switch(object.name){
        case "LCRED":
            paymentTermText = paymentTermText.replace("{{AA}}",'<span style=" color: #a58701 !important">'+this.offerData.paymentDays1 +' '+daysAfterThe+'</span>' );
          break;
        default:
          paymentTermText = paymentTermText.replace("{{AA}}",'<span style=" color: #a58701 !important">'+this.offerData.paymentDays1 +' '+daysAfter+'</span>' );
          break;
      }

    }
    else{
      paymentTermText = paymentTermText.replace("{{AA}}",'<span style=" color: #a58701 !important">'+at+'</span>' );
    }




    if(!_.isNull(this.offerData.paymentDays2)){
      switch(object.name){
        case "XXBTYY":
          paymentTermText = paymentTermText.replace("{{BB}}",'<span style=" color: #a58701 !important">'+this.offerData.paymentDays2 +' '+daysAfter+'</span>' );
          break;
        default:
          paymentTermText = paymentTermText.replace("{{BB}}",'<span style=" color: #a58701 !important">'+this.offerData.paymentDays2 +' '+daysAfterThe+'</span>' );
          break;
      }

   }
   else{
      switch(object.name){
        case "XXBTYY":
          paymentTermText = paymentTermText.replace("{{BB}}",'<span style=" color: #a58701 !important">'+at+'</span>' );
          break;
        default:
          paymentTermText = paymentTermText.replace("{{BB}}",'<span style=" color: #a58701 !important">'+atSightUpon+'</span>' );
          break;
      }

    }



    if(!_.isNull(this.offerData.paymentDays3)){
      paymentTermText = paymentTermText.replace("{{CC}}",'<span style=" color: #a58701 !important">'+this.offerData.paymentDays3 +' '+daysAfter+'</span>' );
    }
    else{
      paymentTermText = paymentTermText.replace("{{CC}}",'<span style=" color: #a58701 !important">'+at+'</span>' );
    }

    return paymentTermText;

  }

  //TODO: refactor in provider
  getTranslationText(object,property){


    if(!_.isNull(object) && !_.isUndefined(object)){

      let translation =  object.translations.find(x => x.locale == this.translate.currentLang);
      if(_.isNil(translation) || _.isUndefined(translation)){
        translation =  object.translations.find(x => x.locale == "en");
      }

      return translation[property];
    }


  }

  getTranslationPosition(item){

    let translationObject =  item.translations.find(x => x.locale == this.translate.currentLang);

    let position = item.translations.indexOf(translationObject);

    //fallback
    if(position <= -1){
      translationObject =  item.translations.find(x => x.locale == "en");
      position = item.translations.indexOf(translationObject);
    }

    return position;

  }

  comparePaymentTerm(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }



  focusSwitchAddItems(){
    this.swtichSilo = !this.swtichSilo;
  }

  emptyDesignSpecifications(section){
    switch(section){
      case "designData":
        this.offerData.offerRevisionSilos[this.siloIdx].storedProduct = null;
        this.offerData.offerRevisionSilos[this.siloIdx].moistureContent = 0;
        this.offerData.offerRevisionSilos[this.siloIdx].storedProductDensity = null;
        this.offerData.offerRevisionSilos[this.siloIdx].designDensity = null;
        this.offerData.offerRevisionSilos[this.siloIdx].grainLoadsInAccordanceWith = null;
        this.offerData.offerRevisionSilos[this.siloIdx].snowLoad = null;
        this.offerData.offerRevisionSilos[this.siloIdx].designWindVelocity = null;
        this.offerData.offerRevisionSilos[this.siloIdx].seismicZone = null;
        this.offerData.offerRevisionSilos[this.siloIdx].seismicCode = null;
        this.offerData.offerRevisionSilos[this.siloIdx].fillEmptyCiclesPerYear = null;
        this.offerData.offerRevisionSilos[this.siloIdx].loadingRatedCapacity = null;
        this.offerData.offerRevisionSilos[this.siloIdx].unloadingRatedCapacity = null;
        this.offerData.offerRevisionSilos[this.siloIdx].typeOfFoundation = null;
        this.offerData.offerRevisionSilos[this.siloIdx].dischargeMode = null;
        break;
      case "geometricData":
        this.offerData.offerRevisionSilos[this.siloIdx].wallSheetCorrugation = null;
        this.offerData.offerRevisionSilos[this.siloIdx].distanceBetweenStiffeners = null;
        break;
      case "material":
        this.offerData.offerRevisionSilos[this.siloIdx].roofGradeSteel = null;
        this.offerData.offerRevisionSilos[this.siloIdx].wallSheetsGradeSteel = null;
        this.offerData.offerRevisionSilos[this.siloIdx].verticalStiffenersGradeSteel = null;
        this.offerData.offerRevisionSilos[this.siloIdx].boltsGrade = null;
        this.offerData.offerRevisionSilos[this.siloIdx].verticalHorizontalJointSealing = null;
        this.offerData.offerRevisionSilos[this.siloIdx].generalGradeSteel = null;
        break;
      case "surfaceProtection":
        this.offerData.offerRevisionSilos[this.siloIdx].roofGalvanisationPainting = null;
        this.offerData.offerRevisionSilos[this.siloIdx].wallSheetsGalvanizationPainting = null;
        this.offerData.offerRevisionSilos[this.siloIdx].verticalStiffenersGalvanizationPainting = null;
        this.offerData.offerRevisionSilos[this.siloIdx].hopper = null;
        this.offerData.offerRevisionSilos[this.siloIdx].boltsGalvanizationMethod = null;
        this.offerData.offerRevisionSilos[this.siloIdx].generalGalvanisationPainting = null;
        break;
    }
  }


  initManagementData(){
    this.managmentData = {
      kprice: this.offerData.generalCoefficient.value,
      kWeightAnsi: this.siloService.getKSectionWeightAnsi(),
      kWeightEuro: this.siloService.getKSectionWeightEuro(),
     // discount: this.siloService.getGlobalDisscount(),

    }
  }



  // ¿¿Sirve tambien para posible componentes?


  getIndividualCostsFinal(item) {

    //console.log("Entra en getSiloPriceDiscounted");
    //console.log(item);

    let discount = this.offerData.freezeTarget ? this.calcExworksDisscountTarget() : this.offerData.discount;

    return (item.cost*this.managmentData.kprice*item.kSection)*(1-discount)
  }

  getSiloPriceDiscounted(item) {

    //console.log("Entra en getSiloPriceDiscounted");
    //console.log(item);

    let discount = this.offerData.freezeTarget ? this.calcExworksDisscountTarget() : this.offerData.discount;

    return (item.cost*this.managmentData.kprice*item.kSection)*(1-discount)
  }

  getSiloPriceDiscountedReferenceCost(item){

    let discount = this.offerData.freezeTarget ? this.calcExworksDisscountTarget() : this.offerData.discount;

    return (item.referenceCost*this.referenceGeneralK.value)*(1-discount)
  }

  getSiloPriceDiscountedTotal(item){
    // cnsole.log(this.getSiloPriceDiscounted(item)); Mismo precio que se muestra en el listado 
    return this.getSiloPriceDiscounted(item)* item.quantity;
  }

  getSiloPriceDiscountedTotalReferenceCost(item){
    return this.getSiloPriceDiscountedReferenceCost(item)* item.quantity;
  }

  getTotalSiloPriceWithCommissionRate(product){

    if(this.offerData.freezeTarget){
      return this.getSiloPriceDiscountedTotal(product) * (1 + this.calcCommissionRatePercentageTarget());
    }
    else{
      return this.getSiloPriceDiscountedTotal(product) * (1 + this.calcCommissionRatePercentage());
    }

  }

  getTotalSiloPriceWithCommissionRateReferenceCost(product){

    if(this.offerData.freezeTarget){
      return this.getSiloPriceDiscountedTotalReferenceCost(product) * (1 + this.calcCommissionRatePercentageTarget());
    }
    else{
      return this.getSiloPriceDiscountedTotalReferenceCost(product) * (1 + this.calcCommissionRatePercentage());
    }

  }

  getProductPriceDiscounted(item){

    let discount = this.offerData.freezeTarget ? this.calcExworksDisscountTarget() : this.offerData.discount;


    if(_.has(item, "category") && _.has(item.category, 'effectiveCoefficient') ){
      return (item.cost* item.category.effectiveCoefficient*this.managmentData.kprice)*(1-discount);
    }

    return (item.cost*this.managmentData.kprice)*(1-discount);
  }

  getProductPriceDiscountedReferenceCost(item){

    let discount = this.offerData.freezeTarget ? this.calcExworksDisscountTarget() : this.offerData.discount;

    if(_.has(item, "category") && _.has(item.category, 'effectiveCoefficient') ){
      return (item.referenceCost*item.category.effectiveCoefficient*this.referenceGeneralK.value)*(1-discount);
    }

    return  (item.referenceCost*this.referenceGeneralK.value)*(1-discount);

  }


  getProductPriceDiscountedTotal(item){
    if(_.has(item, "totalQuantity") ){
      return this.getProductPriceDiscounted(item)* item.totalQuantity;
    }

    return this.getProductPriceDiscounted(item)* item.quantity;
  }

  getProductPriceDiscountedTotalReferenceCost(item){

    if(_.has(item, "totalQuantity") ){
      return this.getProductPriceDiscountedReferenceCost(item)* item.totalQuantity;
    }

    return this.getProductPriceDiscountedReferenceCost(item)* item.quantity;

 }

  getTotalProductPriceWithCommissionRate(product){

    if(this.offerData.freezeTarget){
      return this.getProductPriceDiscountedTotal(product) * (1 + this.calcCommissionRatePercentageTarget());
    }
    else{
      return this.getProductPriceDiscountedTotal(product) * (1 + this.calcCommissionRatePercentage());
    }


  }

  getTotalProductPriceWithCommissionRateReferenceCost(product){

    if(this.offerData.freezeTarget){
      return this.getProductPriceDiscountedTotalReferenceCost(product) * (1 + this.calcCommissionRatePercentageTarget());
    }
    else{
      return this.getProductPriceDiscountedTotalReferenceCost(product) * (1 + this.calcCommissionRatePercentage());
    }


  }

  getTotalSiloCommission(product){
    return this.getTotalSiloPriceWithCommissionRate(product) - this.getSiloPriceDiscountedTotal(product);
  }

  flagDiscount: boolean = false;
  flagNewDiscount: boolean = false;

  managementDisscountChanged(value){
  
    const realValue = value/100;

    if(!this.userData.user.isAdmin){

        if(realValue >  this.offerData.user.discount ){

           this.offerData.discount =  this.offerData.user.discount;
           this.flagDiscount = true;

        }else{
           this.offerData.discount = value/100;
           this.flagDiscount = false;
        }
    }

    else{
      this.offerData.discount = value/100;
    }

    this.setCommissions();
    this.checkDiscountColumn();
    this.setTargetPrice()

  }



  checkDiscountColumn(){
    if(this.offerData.discount != this.offer.discount){
       this.flagNewDiscount = true;
    }else{
      this.flagNewDiscount = false;
    }
  }




  disseelcteSilo(selectedHBSSilo){
     const itemIdx = _.findIndex(this.silos, function(i) {

        return i.id == selectedHBSSilo.id;

    });


     this.silos[itemIdx].selected = false;;
  }

  hidePriceItem(item){

    item.hidePrice = !item.hidePrice;

  }

  hidePriceSection(item){
    item.hidePrice = !item.hidePrice;
  }

  hideWeight(item){
    item.hideWeight = !item.hideWeight;
  }

  getOfferProductSections(offerData){

    this.offerService.getOfferProductCategories(offerData).then(offerSections => {
      this.categoryProducts = offerSections;

      this.categoryProducts.sort(this.compareSection);


      this.getOfferProductsInCategory();

    }).catch(error => {

       switch(error.status){
         case 404:
           this.categoryProducts = [];
           break;

       }
    })
  }


  parentCategories: ProductCategory[] = [];
  getEquipmentCategoryBasket(){
    console.log("Entra en getEquipmentCategoryBasket(); ");

    this.siloService.getProductParentCategories().then(categories => {
       this.parentCategories = categories;
       console.log(this.parentCategories);
    });

  }

  getOfferProductsInCategory(){

    let discount = this.offerData.freezeTarget ? this.calcExworksDisscountTarget() : this.offerData.discount;
    let commissionRate = this.offerData.freezeTarget ? this.calcCommissionRatePercentageTarget() : this.calcCommissionRatePercentage();

    for(let category of this.categoryProducts){

      this.offerService.getOfferProductByCategory(this.offerData, category.category.id).then(offerProducts => {

         let totalPriceList = 0;
         let totalWeight = 0;
         let totalQty = 0;

         for(let product of offerProducts){
           totalPriceList += product.priceList*product.totalQuantity;
           totalWeight += product.totalWeight;
           totalQty += product.totalQuantity;

            if(!product.special){
              this.siloService.getProductCostByDate(product.aggregationCode,this.pricesDate ).then(cost => {

                product.referenceCost = cost.cost * this.currencyChangeRate;


              }). catch(error => {
         
                product.referenceCost = product.cost;
              });
           }
           else{
            product.referenceCost = product.cost;
           }


         }

        // 
        console.log(" ¿Productos en la oferta? --> ");
        console.log(offerProducts);
        var totalPriceOnlyProducts = 0;

        for (let product of offerProducts) {
          totalPriceOnlyProducts += product.totalPrice;
        }
        // PSS.offerErrors.Productos
        this.productsPrice = totalPriceOnlyProducts;
        console.log('Total price de products only --> ' + totalPriceOnlyProducts + " // ");

         category['products'] = offerProducts;
         category['totalPriceList'] = totalPriceList;
         category['totalWeight'] = totalWeight;
         category['totalQty'] = totalQty;

      }).catch(error => {
       // this.processingReqLoadProducts = false;
      });

    }

  }

  getSectionProducts(offerCategory){


    return offerCategory.products;
  }

  getTotalSectionPrice(offerCategory){



    let discount = this.offerData.freezeTarget ? this.calcExworksDisscountTarget() : this.offerData.discount;

    return offerCategory.totalPriceList * ( 1 - discount);
  }

  getTotalSectionPriceWithCommissionRate(offerCategory){

    let commissionRate = this.offerData.freezeTarget ? this.calcCommissionRatePercentageTarget() : this.calcCommissionRatePercentage();

    return this.getTotalSectionPrice(offerCategory) * (1 + commissionRate);
  }

  getTotalSectionCommission(offerCategory){
    return this.getTotalSectionPriceWithCommissionRate(offerCategory) - this.getTotalSectionPrice(offerCategory);
  }


  calcTotalCommissionPercent(): number {
    let total = 0;
    //total += this.offerData.user.commission;

    for (let commission of this.offerData.offerRevisionCommissions) {
      total += commission.amount;
    }
    return total;
  }



  calcExworksMarginPercentage(){
    return ((this.offerData.exWorksPriceList * (1 - this.offerData.discount)) - (this.offerData.exWorksCost)) / (this.offerData.exWorksPriceList * (1 - this.offerData.discount))
  }

  calcExworksMarginPercentageTarget(){
    return ((this.offerData.exWorksPriceList * (1 - this.calcExworksDisscountTarget())) - (this.offerData.exWorksCost)) / (this.offerData.exWorksPriceList * (1 - this.calcExworksDisscountTarget()))
  }

  calcTotalCommissionCurrency(): number {
    let total = 0;
    //total += this.offerData.user.commission * this.calcDiscountWithCommissionCurrency();

    for (let commission of this.offerData.offerRevisionCommissions) {
      total += commission.amountMoney;
    }

    return total;
  }



  calcExworksWithComissionUnbalanced(){

    let totalComissions = this.calcTotalCommissionPercent();
    if(_.isNaN(totalComissions)){
      totalComissions = 0;
    }

    let unbalancedCommissionExworks = (this.offerData.exWorksPriceList * (1 - this.offerData.discount)) / (1-totalComissions );

    return unbalancedCommissionExworks;
  }



  calcExworksWithComissionUnbalancedPercentage(){
    return 1-((1-this.offerData.discount)/(1-this.calcTotalCommissionPercent()))
  }

  calcExworksWithComissionUnbalancedPercentageTarget(){
    return 1-((1-this.calcExworksDisscountTarget())/(1-this.calcTotalCommissionPercentTarget()))
  }

  calcExworksWithComissionUnbalancedCost(){
    return this.offerData.exWorksCost  + this.calcTotalCommissionCurrency();
  }

  calcExworksWithComissionUnbalancedMargin(){
      return this.calcExworksWithComissionUnbalanced() - this.calcExworksWithComissionUnbalancedCost();
  }

  calcExworksWithComissionUnbalancedMarginTarget(){
      return this.calcExworksWithComissionUnbalancedTarget() - this.calcExworksWithComissionUnbalancedCostTarget();
  }

  calcExworksWithComissionUnbalancedMarginPercentage(){
      return this.calcExworksWithComissionUnbalancedMargin() / this.calcExworksWithComissionUnbalanced();
  }

  calcExworksWithComissionUnbalancedMarginPercentageTarget(){
      return this.calcExworksWithComissionUnbalancedMarginTarget() / this.calcExworksWithComissionUnbalancedTarget();
  }

  calcBalanceCommission(){

    return (this.calcTotalCommissionCurrency()/(1-this.calcExworksMarginPercentage())-this.calcTotalCommissionCurrency());

  }

  calcBalanceCommissionTarget(){


    return (this.calcTotalCommissionCurrencyTarget()/(1-this.calcExworksMarginPercentageTarget())-this.calcTotalCommissionCurrencyTarget());

  }


  calcBalanceCommissionPercentage(){
    return this.calcBalanceCommission() / this.calcExworksWithComissionUnbalanced();
  }

  calcBalanceCommissionPercentageTarget(){
    return this.calcBalanceCommissionTarget() / this.calcExworksWithComissionUnbalancedTarget();
  }

  calcDiscountWithCommissionPercent(): number {
   /* let total = 0;

    total = 1 - ((1 - this.offerData.discount) / (1 - this.calcTotalCommissionPercent()));

    return total;*/

    return (this.offerData.exWorksPriceList - this.calcDiscountWithCommissionCurrency()) / this.offerData.exWorksPriceList

  }

  calcDiscountWithCommissionPercentTarget(): number {
   /* let total = 0;

    total = 1 - ((1 - this.offerData.discount) / (1 - this.calcTotalCommissionPercent()));

    return total;*/

    return ( (this.offerData.exWorksPriceList)- this.calcDiscountWithCommissionCurrencyTarget()) /
              (this.offerData.exWorksPriceList);

  }

  calcDiscountWithCommissionCurrency(): number {


   return this.calcExworksWithComissionUnbalanced() + this.calcBalanceCommission();

  }



  calcDiscountWithCommissionCurrencyCost(){
    return this.offerData.exWorksCost + this.calcTotalCommissionCurrency();
  }

  calcExworksWithComissionUnbalancedCostTarget(){
    return this.offerData.exWorksCost  + this.calcTotalCommissionCurrencyTarget();
  }

  calcTotalCommissionCurrencyTarget(): number {
    let total = 0;

    for (let commission of this.offerData.offerRevisionCommissions) {
      total += this.calcTargetCommisionAmountMoney(commission);
    }

    return total;
  }


  calcTargetCommisionAmountMoney(commission): number{

    let targetCommissionAmountMoney = this.calcExworksWithComissionUnbalancedTarget() * commission.amount;

    return  targetCommissionAmountMoney;
  }

  calcExworksWithComissionUnbalancedTarget(){

    let unbalancedCommissionExworks =   (this.offerData.exWorksPriceList * (1 - this.calcExworksDisscountTarget()) ) / (1-this.calcTotalCommissionPercentTarget() );

    return unbalancedCommissionExworks;
  }

  calcExworksDisscountTarget() : number {

    let discount = 0;

    if(this.offerData.exWorksPriceList == 0){
      return discount;
    }

    if(this.calcTotalCommissionPercentTarget() <= 0){

        discount = 1-(this.calcDiscountWithCommissionCurrencyTarget()/this.offerData.exWorksPriceList);
    }
    else{
    

      discount= 1+(

        (this.offerData.exWorksCost-Math.sqrt(this.offerData.exWorksCost*this.offerData.exWorksCost+4*this.offerData.exWorksCost*this.calcTotalCommissionPercentTarget()/
          (1-this.calcTotalCommissionPercentTarget())*this.calcDiscountWithCommissionCurrencyTarget()) )
        /
        (2*this.offerData.exWorksPriceList*this.calcTotalCommissionPercentTarget()/(1-this.calcTotalCommissionPercentTarget()))
      );

    }
    // console.log("Exworks discount target *^** " + discount) ;
    return discount;

  }

  calcTotalCommissionPercentTarget(): number {
    let total = 0;
    //total += this.offerData.user.commission;

    for (let commission of this.offerData.offerRevisionCommissions) {
      total += commission.amount;
    }
    return total;
  }

  //cast to EURO for calcualtions

  calcDiscountWithCommissionCurrencyTarget(): number{

    return (this.offerData.targetPrice ) -
    (this.offerData.transportPrice  + this.offerData.siloErectionPrice  + this.offerData.supervision  + this.offerData.servicePrice  );

  }


  calcExworksNaturalPrice(){
    return this.offerData.exWorksPriceList;
  }

  calcExworksNaturalPriceTarget(){
  
    return this.offerData.exWorksPriceList;
  }

  calcExworksDisscountMargin(){
    return this.calcDiscountWithCommissionCurrency() - this.calcDiscountWithCommissionCurrencyCost();
  }

  calcExworksDisscountMarginTarget(){
    return this.calcDiscountWithCommissionCurrencyTarget() - this.calcExworksWithComissionUnbalancedCostTarget();
  }

  calcExworksDisscountMarginPercentage(){
    return this.calcExworksDisscountMargin() / this.calcDiscountWithCommissionCurrency();
  }

  calcExworksDisscountMarginPercentageTarget(){
    return this.calcExworksDisscountMarginTarget() / this.calcDiscountWithCommissionCurrencyTarget();
  }

  calcFinalOfferPrice(): number {
   // return this.offerData.exWorksPrice * (1 - (1 - ((1 - this.offerData.discount) / (1 - this.calcTotalCommissionPercent())))) + this.getShipmentTotal(true) + this.getSupervisionTotal();

    let finalPrice =  (this.calcDiscountWithCommissionCurrency() )  + this.offerData.transportPrice + this.offerData.siloErectionPrice + this.offerData.supervision + this.offerData.servicePrice;

    return finalPrice ;

  }

  calcFinalOfferPriceWithCurrencyRate(): number {
   // return this.offerData.exWorksPrice * (1 - (1 - ((1 - this.offerData.discount) / (1 - this.calcTotalCommissionPercent())))) + this.getShipmentTotal(true) + this.getSupervisionTotal();

    let finalPrice =  (this.calcDiscountWithCommissionCurrency() )  + this.offerData.transportPrice + this.offerData.siloErectionPrice + this.offerData.supervision + this.offerData.servicePrice;

    return finalPrice ;

  }

  calcFinalOfferCost(): number{
    return this.calcDiscountWithCommissionCurrencyCost()  +
            this.getShipmentTotal(true) +
            this.getSupervisionTotal(true) +
            this.getErectionTotal(true) +
            this.getServiceTotal(true);
  }

  calcFinalOfferCostTarget(): number{
    return this.calcExworksWithComissionUnbalancedCostTarget() +
    this.getShipmentTotal(true)+
    this.getSupervisionTotal(true)+
    this.getErectionTotal(true)+
    this.getServiceTotal(true) ;
  }

  calcFinalOfferMargin(): number{
    return this.calcFinalOfferPrice() - this.calcFinalOfferCost();
  }

  calcFinalOfferMarginTarget(): number{
    return (this.offerData.targetPrice) - this.calcFinalOfferCostTarget();
  }

  calcFinalOfferMarginPercentage(): number{
    return this.calcFinalOfferMargin() / this.calcFinalOfferPrice();
  }

  calcFinalOfferMarginPercentageTarget(): number{
    return this.calcFinalOfferMarginTarget() / (this.offerData.targetPrice);
  }

  calcTargetCommisionAmount(commission) : number{
    return commission.amount;
  }

  calcCommissionRateCurrency() : number {
    return this.calcBalanceCommission() + this.calcTotalCommissionCurrency();
  }

  calcCommissionRatePercentage(): number {
    const value = this.calcCommissionRateCurrency() /  ( this.offerData.exWorksPriceList * (1 - this.offerData.discount));

    if(_.isNaN(value))
    {
      return 0
    }

    return value
  }


  calcCommissionRateCurrencyTarget() : number{
    return this.calcBalanceCommissionTarget() + this.calcTotalCommissionCurrencyTarget();
  }

  calcCommissionRatePercentageTarget(): number{

    const value = this.calcCommissionRateCurrencyTarget() /  ( this.offerData.exWorksPriceList * (1 - this.calcExworksDisscountTarget()));

    if(_.isNaN(value))
    {
      return 0
    }

    return value

  }

  addCommissionPerson(name) {

    let offerCommission = { personName: name, percentage: true, amount: 0, amountMoney: 0, isAsm:false };

    this.offerService.createOfferCommission(this.offerData, offerCommission).then(offerCommission => {

      this.offerData.offerRevisionCommissions.push(offerCommission);
      this.commissionName = null;

      this.setCommissions();
      this.setTargetPrice();

    }).catch(error => {

   
    });



  }

  saveOfferCommissions(){

    let noASMCommissions = this.offerData.offerRevisionCommissions.filter(function(commission){
      return commission.isAsm == false;
    });

    const editOfferCommission = commission =>
      this.offerService.editOfferCommission(this.offerData, commission);


    const sourceS = of(noASMCommissions);
    const returnedCommissions = sourceS.pipe(mergeMap(q => forkJoin(...q.map(editOfferCommission))), catchError(error =>{
      
        //this.processingReqSaveOffer = false;
        return of(error);
      }
    ));

    const subscribeS = returnedCommissions.subscribe(commissions => {

    });
  }

  removeCommissionPerson(commission) {

    if(!commission.isAsm){

      this.offerService.deleteOfferCommission(this.offerData, commission).then(result => {

        const idx = this.offerData.offerRevisionCommissions.indexOf(commission);
        this.offerData.offerRevisionCommissions.splice(idx, 1);


        this.setCommissions();
        this.setTargetPrice();

      });

    }

  }



  freezgeOffer(){

    if(!this.userData.user.isAdmin && this.calcExworksDisscountTarget() > this.offerData.user.discount){
      this.controlPriceTarget = true;
      this.flagDiscount = true
    }
    else{
      this.controlPriceTarget = true;
      this.offerData.freezeTarget = true;
      this.offerSteps.disable();
    }


  }

  unFreezgeOffer(){
    this.offerData.freezeTarget = false;
    this.offerSteps.enable();
    //this.setTargetPrice();
  }


  offerFreezged(){
    return this.offerData.freezeTarget == true;
  }

  offerUnFreezged(){
    return this.offerData.freezeTarget == false;

  }

  downloadingExcel:boolean = false;

  downloadExcelPrice(lang){

    this.downloadingExcel = true;
    this.downloadingPdf = true;

    this.offerService.downloadExcelPrice(this.offerData, lang)
      .map((res) => res.blob())
      .subscribe(data => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        saveAs(blob, "Price" + '-' + this.offerData.fileRef + ".xlsx");
        this.downloadingExcel = false;
        this.downloadingPdf = false;
      },

      error =>  {
        
          this.downloadingExcel = false;
          this.downloadingPdf = false;
          //this.proforma.error = true;
        }
      )
  }

  adquireRevision(){
    this.processingReqOfferActions = true;

    this.offerService.adquireOffer(this.offerData.offer.id, this.offerData.revision)
      .then(response => {

        this.offerData.adquired=true;
        this.processingReqOfferActions = false;

        this.router.navigate(['/dashboard', 'orders', 'edit', response.order.jobOrder.id, 'revision', response.order.revision]);

      }).catch(error => {
        this.processingReqOfferActions = false;
       
      });

  }

  openAdquireModal() {
    const modal = this.modalService.open(DelConfModalComponent, {
      backdrop: 'static',
      keyboard: false,
      // size: 'sm',
      windowClass: 'rmv-modal',
    });
    modal.componentInstance.text = '<b>' + this.offer.title + '</b>';
    modal.componentInstance.type = 'offer';
    modal.result.then((result) => {
      if (result === 'Accept') {
        this.removeOffer(this.offerData);
      } else { }
    });
  }

  offerAdquired(){
    return (this.offerData.state === 4 && this.offerData.adquired === true);
  }

  offerRevisions: any[] = [];
  offerRevisionsRaw: any[] = [];
  selectedRevision: any= null;
  pricesDate: any;

  getRevisionsOfOffer() {
    //this.selectedRevision = this.offer;
    this.offerService.getRevisionsOfOffer(this.offerData.offer.id)
    .then(revisions => {
      this.offerRevisions = [];
      this.offerRevisionsRaw = revisions;

      for(const offerRevision of revisions){
         this.offerRevisions.push(offerRevision.revision);
      }


    })
    .catch(error => {
      
    });
  }

  calculateComulatedCurrencyRateReverse(){
    let rate = 1;
    for(const offerRevision of this.offerRevisionsRaw){
      if(offerRevision.id == this.offerData.id){
        rate = rate * offerRevision.currencyChangeRate;
        console.log("rate processed", offerRevision.currencyChangeRate);
      }
      
   }

   return 1/rate;
  }


  getCurrentTime(){
    const currentTime = new Date();

    this.pricesDate = {
         "year": currentTime.getFullYear(),
         "month": currentTime.getMonth()+1,
         "day": currentTime.getDate()
        };


  }

  trackRevisionChange(value){

      for(const offerRevision of this.offerRevisionsRaw){

        if(value.target.value == offerRevision.revision){

          var currentTime = new Date(offerRevision.ofCreationDate);

          this.pricesDate = {
               "year": currentTime.getFullYear(),
               "month": currentTime.getMonth()+1,
               "day": currentTime.getDate()
              };
          this.updatePrices();
        }

      }


  }

  trackUpdatePricesDateChange(value){

    this.selectedRevision = undefined;
    this.pricesDate = value;

    this.updatePrices();

  }

  setTodayDate(){

    const currentTime = new Date();
    this.selectedRevision = undefined;
    this.pricesDate = {
         "year": currentTime.getFullYear(),
         "month": currentTime.getMonth()+1,
         "day": currentTime.getDate()
        };

    this.updatePrices();

  }

  kPriceDiffers(){

    if(!_.isNull(this.referenceGeneralK) && !_.isNull(this.offerData.generalCoefficient) && this.offerData.generalCoefficient.id != this.referenceGeneralK.id )
    {
      return true;
    }

    return false;
  }

  updatePrices(){

    this.offerService.geKtPriceCoeffcientByDate(this.pricesDate).then(kprice => {
      this.referenceGeneralK = kprice;
 
    });

  
    for (let offerRevisionSilo of this.offerSilosReplica){

 
      this.siloService.getSiloCostByDate(offerRevisionSilo.silo.id, offerRevisionSilo.model,this.pricesDate ).then(cost => {


        if(!offerRevisionSilo.special){
          offerRevisionSilo.referenceCost = cost.cost * this.currencyChangeRate;
        }
        else{
          offerRevisionSilo.referenceCost = offerRevisionSilo.cost;
        }


        if(_.has(offerRevisionSilo,'offerRevisionSiloProducts')){
          for(let product of offerRevisionSilo.offerRevisionSiloProducts){
            if(!product.special){
                this.siloService.getProductCostByDate(product.aggregationCode,this.pricesDate ).then(cost => {


                  product.referenceCost = cost.cost * this.currencyChangeRate;

                }). catch(error => {
                 
                  product.referenceCost = product.cost;
                });
            }
            else{
                product.referenceCost = product.cost;
            }
          }
        }


      }). catch(error => {
  
        offerRevisionSilo.referenceCost = offerRevisionSilo.cost;
      });

    }

    for(let category of this.categoryProducts) {
      if(_.has(category,'products')){
         for(let product of category['products']){

            if(!product.special){
              this.siloService.getProductCostByDate(product.aggregationCode,this.pricesDate ).then(cost => {

             

                product.referenceCost = cost.cost * this.currencyChangeRate;


              }). catch(error => {
                
                product.referenceCost = product.cost;
              });
           }
           else{
            product.referenceCost = product.cost;
           }


         }
        }

    }

   
    // console.log("  resultado  ?? --> ");
    // console.log(this.offerData);


  }


  getOfferCreationDate(){
    return this.offerData.state == 4 ? this.offerData.ofCreationDate : this.offerData.creationDate;
  }


  getTotalCostUpdate(){
    let finalCost = 0;
    for (const offerRevisionSilo of this.offerSilosReplica){
      finalCost+=offerRevisionSilo.cost*offerRevisionSilo.quantity;

      if(_.has(offerRevisionSilo,'offerRevisionSiloProducts')){
        for(const product of offerRevisionSilo.offerRevisionSiloProducts){

          finalCost+=product.cost*product.totalQuantity;

        }
      }

    }

    for(const offerCategory of this.categoryProducts){
      if(_.has(offerCategory,'products')){
        for(const product of offerCategory.products ){
          finalCost+=product.cost*product.totalQuantity;
        }
      }
    }

    return finalCost;

  }

  getTotalCostUpdateReferenceCost(){
    let finalCost = 0;

    for (let offerRevisionSilo of this.offerSilosReplica){
      finalCost+=offerRevisionSilo.referenceCost*offerRevisionSilo.quantity;
      if(_.has(offerRevisionSilo,'offerRevisionSiloProducts')){
        for(let product of offerRevisionSilo.offerRevisionSiloProducts){

          finalCost+= product.referenceCost*product.totalQuantity;

        }
      }
    }

    for(const offerCategory of this.categoryProducts){
      if(_.has(offerCategory,'products')){
        for(const product of offerCategory.products ){
          finalCost+=product.referenceCost*product.totalQuantity;
        }
      }

    }

    return finalCost;
  }

  getTotalPriceDiscountedUpdate(){

    let finalPrice = 0;
    for (const offerRevisionSilo of this.offerSilosReplica){
      finalPrice+=this.getSiloPriceDiscountedTotal(offerRevisionSilo);

      if(_.has(offerRevisionSilo,'offerRevisionSiloProducts')){
        for(const product of offerRevisionSilo.offerRevisionSiloProducts){

          finalPrice+=this.getProductPriceDiscountedTotal(product);

        }
      }

    }

    for(const offerCategory of this.categoryProducts){
      if(_.has(offerCategory,'products')){
        for(const product of offerCategory.products ){
          finalPrice+=this.getProductPriceDiscountedTotal(product);
        }
      }
    }

    return finalPrice;

  }

  getTotalPriceDiscountedUpdateReferenceCost(){
    let finalPrice = 0;

    for (let offerRevisionSilo of this.offerSilosReplica){
      finalPrice+=this.getSiloPriceDiscountedTotalReferenceCost(offerRevisionSilo);
      if(_.has(offerRevisionSilo,'offerRevisionSiloProducts')){
        for(let product of offerRevisionSilo.offerRevisionSiloProducts){

          finalPrice+= this.getProductPriceDiscountedTotalReferenceCost(product);

        }
      }
    }

    for(const offerCategory of this.categoryProducts){
      if(_.has(offerCategory,'products')){
        for(const product of offerCategory.products ){
          finalPrice+=this.getProductPriceDiscountedTotalReferenceCost(product);
        }
      }
    }

    return finalPrice;
  }


  getTotalProductPriceWithCommissionRateUpdate(){

    let finalPrice = 0;
    for (const offerRevisionSilo of this.offerSilosReplica){
      finalPrice+=this.getTotalSiloPriceWithCommissionRate(offerRevisionSilo);

      if(_.has(offerRevisionSilo,'offerRevisionSiloProducts')){
        for(const product of offerRevisionSilo.offerRevisionSiloProducts){

          finalPrice+=this.getTotalProductPriceWithCommissionRate(product);

        }
      }

    }

    for(const offerCategory of this.categoryProducts){
      if(_.has(offerCategory,'products')){
        for(const product of offerCategory.products ){
          finalPrice+=this.getTotalProductPriceWithCommissionRate(product);
        }
      }
    }

    return finalPrice;
  }

  getTotalProductPriceWithCommissionRateUpdateReferenceCost(){
    let finalPrice = 0;

    for (let offerRevisionSilo of this.offerSilosReplica){
      finalPrice+=this.getTotalSiloPriceWithCommissionRateReferenceCost(offerRevisionSilo);
      if(_.has(offerRevisionSilo,'offerRevisionSiloProducts')){
        for(let product of offerRevisionSilo.offerRevisionSiloProducts){

          finalPrice+= this.getTotalProductPriceWithCommissionRateReferenceCost(product);

        }
      }
    }

    for(const offerCategory of this.categoryProducts){
      if(_.has(offerCategory,'products')){
        for(const product of offerCategory.products ){

          finalPrice+=this.getTotalProductPriceWithCommissionRateReferenceCost(product);
        }
      }
    }

    return finalPrice;
  }

  getVariationUpdate(product){
    if(product.cost <= 0){
      return 0;
    }

   return ( this.getTotalProductPriceWithCommissionRateReferenceCost(product) - this.getTotalProductPriceWithCommissionRate(product) )  / this.getTotalProductPriceWithCommissionRate(product)
  }


  getVariationTotalUpdatePercent(){
    if( this.getTotalCostUpdate() <= 0){
      return 0;
    }

    return (this.getTotalProductPriceWithCommissionRateUpdateReferenceCost() - this.getTotalProductPriceWithCommissionRateUpdate()) / this.getTotalProductPriceWithCommissionRateUpdate();

  }

  getVariationTotalUpdate(){
    if( this.getTotalCostUpdate() <= 0){
      return 0;
    }

    return this.getTotalProductPriceWithCommissionRateUpdateReferenceCost() - this.getTotalProductPriceWithCommissionRateUpdate();

  }

  showItemUpdate(item){

    if(!_.has(item, 'referenceCost')){
      return false;
    }

    if(item.special){
      return true;
    }

    if( !_.isNull( this.getTotalProductPriceWithCommissionRateReferenceCost(item)) && this.getTotalProductPriceWithCommissionRateReferenceCost(item).toFixed(2) != this.getTotalProductPriceWithCommissionRate(item).toFixed(2)){
      return true;
    }

    return false;

  }

  highlightPriceDifference(item){
   return this.getTotalProductPriceWithCommissionRateReferenceCost(item).toFixed(2) != this.getTotalProductPriceWithCommissionRate(item).toFixed(2)
  }

  showUpdateNew(){
   return !this.kPriceDiffers() || this.offerData.configPriceBehaviour==2;
  }

  addHistoryNote() : Promise<any> {
  
    this.historyNote.revision = this.offerData.revision + 1;
    
    return this.offerService.addHistorynote(this.offerData.offer.id, this.offerData.revision, this.historyNote);

  }

  disableAccept(){
    if(
        this.historyNote.translations[0].description.trim().length <= 0 &&
        this.requiredNote)
    {
      return true;
    }
    return false;
  }


  updatingPrices: boolean  =false;

  getUpdatePricesBody(){
    const body = {
      pricesDate: this.pricesDate.year + "-" + this.pricesDate.month + "-" + this.pricesDate.day,
      configPriceBehaviour: this.offerData.configPriceBehaviour
    }

    return body;
  }

  getPricesDateObejctDate(){
    return new Date(this.pricesDate.year + "-" + this.pricesDate.month + "-" + this.pricesDate.day)
  }

  updatePricesAction(){
    this.updatingPrices = true;
     this.offerService.updateOfferPrices(this.offerData.offer.id, this.offerData.revision, this.getUpdatePricesBody()).then(result => {
    
        this.updatingPrices = false;
        this.getOffer();
     })
     .catch(error => {
      this.updatingPrices = false;
     
     })
  }

  offerHasRevisions(){
    return _.size(this.offerRevisions) > 0 ? true : false;
  }


  getOriginalSilo(offerRevisionSilo: OfferRevisionSilo): Promise<any>
  {

     return this.siloService.getSiloById(offerRevisionSilo.silo.id, offerRevisionSilo.silo.model, this.offerData.creationDate ).then(silo => {

        return silo;

     }).catch(error => {

        return null;
     });

  }

  getSiloWeight(item){
    
      return item.weight;
    
  }

  trackSiloChange(offerRevisionSilo: OfferRevisionSilo){

  
    if(offerRevisionSilo.isRecalculated){
      return null;
    }

    this.getOriginalSilo(offerRevisionSilo).then(original => {
    
      if(!_.isNull(original) && !_.isNull(original.cost) ){


        if(!_.isNull(original) && !_.isUndefined(original) &&
            ( !_.isEqual(original.completeModel.trim(), offerRevisionSilo.completeModel.trim()) ||

              original.cost.toFixed(2)!=offerRevisionSilo.cost.toFixed(2) ||
              this.getSiloWeight(original).toFixed(1)!=offerRevisionSilo.weight.toFixed(1)
            )
          ){

          offerRevisionSilo.special= true;
          this.updatePrices();
        }
        else{
          offerRevisionSilo.special= false;

        }

      }

    });

  }

  goToOrder(){
    this.router.navigate(['/dashboard', 'orders', 'edit', this.offerData.orderRevision.jobOrder.id, 'revision', this.offerData.orderRevision.revision]);
  }

  previousOfferRevision: Offer;
  getPreviousOfferRevision(){
    this.offerService.getPreviousOfferByIdAndRev(this.offerData.offer.id, this.offerData.revision).then(previousOfferRevision => {
      this.previousOfferRevision = previousOfferRevision;
    }).catch( error => {
    
    });
  }

  goToPreviousOffer(){
    this.router.navigate(['/dashboard', 'offers', 'edit', this.previousOfferRevision.offer.id, 'revision',this.previousOfferRevision.revision]);
  }

  recalcualteSilo(offerSilo: any){

    const inputFromOfferData = {
      offerTitle: this.offerData.title,
      offerId: this.offerData.offer.id,
      offerRevision: this.offerData.revision,
      offerSilo: offerSilo,
      offerClient: this.offerData.customer ? this.offerData.customer.name : '',
      offerSiteOfInstallation: this.getStringSiteOfInstallation(this.offerData),
      offerShipmentSite: this.getStringSiteOfShipment(this.offerData),
      enableSaveAttachToOffer: this.userCanEditOffer()
    };

    this.cisService.goToCIS(inputFromOfferData);

  }

  getStringSiteOfInstallation(offer){

    let cityOfInstalaltion = "";

    if(offer.cityOfInstallation){
      cityOfInstalaltion = offer.cityOfInstallation.nameUtf8.toUpperCase() + ', ' +  offer.cityOfInstallation.country.name.toUpperCase();
    }

    return cityOfInstalaltion;
  }

  getStringSiteOfShipment(offer){

    let cityOfInstalaltion = "";

    if(offer.placeOfDelivery){
      cityOfInstalaltion = offer.placeOfDelivery.nameUtf8.toUpperCase() + ', ' +  offer.placeOfDelivery.country.name.toUpperCase();
    }

    return cityOfInstalaltion;

  }


  hideShowPriceWeight(item, type, state){

    this.offerService.hideShowOfferSiloPriceWeight(this.offerData, item, type, state).then(offerRevisionSilo => {

      item.hidePrice = offerRevisionSilo.hidePrice;
      item.hideWeight = offerRevisionSilo.hideWeight;

      this.errorBox.success('Operation Ok');

    }). catch(error => {

      this.errorBox.error('Error, contact support.');

    });

  }

  hideShowPriceWeightProduct(item, type, state){

    this.offerService.hideShowOfferSiloPriceWeightProduct(this.offerData, item, type, state).then(offerRevisionProduct => {

      item.hidePrice = offerRevisionProduct.hidePrice;
      item.hideWeight = offerRevisionProduct.hideWeight;

      this.errorBox.success('Operation Ok');

    }). catch(error => {

      this.errorBox.error('Error, contact support.');

    });

  }

  uploadingCommercial:boolean = false;
  offerDocuments = [ 'comercial'];

  onFileChange(event, docType: string) {

    switch(docType){
  

      case "comercial":{
        this.uploadingCommercial = true;
        break;
      }

    }


    let formData = new FormData();
    formData.append('File', event.target.files[0]);

    this.offerService.uploadFile(this.offerData.offer.id, this.offerData.revision,formData, docType).then(result => {

      switch(docType){
    
        case "comercial":{
          this.uploadingCommercial = false;
          break;
        }
    
      }

      this.getDocuments(docType);
    }).catch(error => {
      switch(docType){
      
        case "comercial":{
          this.uploadingCommercial = false;
        }
      
      }
    });

  }


  getDocuments(docType: string){
    this.offerService.getDocuments(this.offerData.offer.id, this.offerData.revision, docType).then(documents => {

      this.offerDocuments[docType] = documents;

    });
  }


  // descarga de documento que ya esta en estado orden //
  downloadOfferDocument(item){
     this.offerService.getDocument(this.offerData.offer.id,this.offerData.revision,item.document).map((res) => res.blob())
     .subscribe(data => {
       const blob = new Blob([data], { type: data.type });
       this.errorBox.success('Document download successfully.', true);
       saveAs(blob, item.documentName);
     },
     error =>  {
         this.errorBox.error('Error, try again.');
       }
     );
  }

  // funcion para borrar documento
  deleteDocument(doctype:string, item){

    this.offerService.deleteFile(this.offerData.offer.id,this.offerData.revision,item.id).then(result => {

      const itemIdx = this.offerDocuments[doctype].indexOf(item);
      this.offerDocuments[doctype].splice(itemIdx, 1);

    },
    error =>  {
        this.errorBox.error('Error, try again.');
      }
    );


  }

  disableUploads() {
    return false;
  }

}
