import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators  } from '@angular/forms';
import { SimezaFormBuilder } from 'app/shared/simeza-form-builder';
import { SimezaFormControl } from 'app/shared/simeza-form-control';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SaveSiloOfferConfirmationModalComponent} from '../../shared/save-silo-offer-confirmation-modal.component';
import { SaveSiloConfirmationModalComponent} from '../../shared/save-silo-confirmation-modal.component';
import { UnSaveConfModalComponent} from '../../shared/unsave-confirmation-modal.component';

import * as _ from 'lodash';
import { saveAs } from 'file-saver';


import { RunnerModalComponent } from './runner/runner-modal.component';
import { CISService } from './cis.service';

import { AnchorBoltTypes, AnchorBoltTypesMaterials } from './data/anchor-bolt-type-material';
import { LoadPressuresTypes, LoadPressuresDensityCalculation } from './data/load-pressures-density-calculation';
import { TiknessTypes } from './data/tikness';
import { StiffenersTypes } from './data/stiffeners';
import { SteelTypeTypes } from './data/steelTypes';
import { WindRingsTypes } from './data/windRings';


export enum CISView {
  SILO_DESIGN,
  STANDARD_LOAD,
  MATERIALS,
  COSTS
}

@Component({
  selector: 'app-cis',
  templateUrl: './cis.component.html',
  styleUrls: ['./cis.component.scss']
})
export class CisComponent implements OnInit, OnDestroy {



  CISView = CISView;
  detailedView: CISView = CISView.SILO_DESIGN;

  densityForCalculationSubscription: Subscription;
  lowestRingSubscription: Subscription;
  
  downloadingPartList:boolean =false;
  siloResponse = [];

  siloModels = [
    'FBS'
    
  ];

  anchorBoltTypes = AnchorBoltTypes;
  anchorBoltTypesMaterials = AnchorBoltTypesMaterials;

  loadPressuresTypes = LoadPressuresTypes;
  tiknessTypes = TiknessTypes;
  stiffenersTypes = StiffenersTypes;
  steelTypeTypes = SteelTypeTypes;
  windRingsTypes = WindRingsTypes;

  formDefaultValues = {
    jobId:"",
    projectData: {
      siloModel: '',
      aggregationCode: '',
      numberOfSilosStandard: 1,
      referenceCode: '',
      client: undefined,
      countryOfDest: undefined,
      siteOfInstallation: undefined,
      offerTitle: ''
    },
    configuration: {
      designType: 1,
      siloType: 'FBS',
      configurationType: 'MANUAL INPUT',
      model: '',
      diameter: undefined,
      numberOfRings: undefined,
      numberSheetsPerRing: undefined,
      numberStiffenersPerSheet: undefined,
      stiffenersTotal: undefined
    },
    detailedConfiguration: {
      sheets: {
        upperRing: undefined,
        positionOfRingWithLogo: 2,
        numberOfSheetsWithLogoPerRing: 0,
        restOfRings: '',
        stiffenersConnection: 'C'
      },
      catwalk: {
        hasCatwalk: true,
        numberOfSupportsPerSilo: 2,
        hasCentralSupport: false,
        catwalkWidth: 1200,
        handrailHeight: 1200,
        distanceBetweenVerticalSupports: undefined,
        weightOfChainConveyor: 150,
        weightOfCatwalk: undefined,
        catwalkOverload: undefined,
        catwalkTotalLoad: undefined,
        weightOfCatwalkSupport: undefined,
        numberOfStiffenersPerSupport: 2,
        catwalkStiffenersTotal: undefined,
        standardStiffeners: '',
        catwalkStiffeners: '',
        catwalkCode: '',
        centralSupportCode: undefined,
        horizontalAssemblyCode: undefined,
        verticalAssemblyCode: undefined
      },
      windRings: {
        reinforcementType: 'ADDITIONAL TUBE',
        galvanizing: 'Z6',
        windRingsTotal: undefined,
        tubeProfil: '2 1/2"'
      },
      inspectionDoors: {
        numberOfDoors: 1,
        lowestDoorType: undefined,
        lowestDoorCode: undefined,
        lowestRing: undefined,
        hasAdditionalSlopeDoor: false,
        slopeDoorType: undefined,
        slopeDoorCode: undefined,
        slopeRing: undefined,
        hasAdditionalDoors: false,
        numberOfAdditionalDoors: 1,
        additionalDoorRing: undefined,
        additionalDoorType: undefined,
        additionalDoorCode: undefined,
        areAdditionalDoorsUnified: false
      },
      anchorBoltsAndPlates: {
        includeAnchorBolts: true,
        type: 'CHEMICAL_WURTH',
   
        numberOfBoltsPerPlate: 1,
        includeShimPlatesInList: false,
        numberOfShimPlates2mmPerPlate: 0,
        numberOfShimPlates3mmPerPlate: 0,
        boltDiameter: undefined,
        boltCode: undefined,
        basePlateCode: undefined,
        numberOfBoltsPerSilo: undefined,
        numberOfShimPlates2mmPerSilo: undefined,
        numberOfShimPlates3mmPerSilo: undefined,
        numberOfShimPlates2mmCode: undefined,
        numberOfShimPlates3mmCode: undefined,
      },
      roof: {
       
    
        roofAssemblyCode: undefined,
        roofAssemblyDesign: "OLD DESIGN",
        roofCoverCode: undefined,
        roofStructureCode: undefined,
        // probes: true,
        numberOfLowerRoofVents: undefined,
        numberOfIntermediateRoofVents: 0,
        numberOfUpperRoofVents: undefined,
        lowerRoofVentsType: undefined,
        intermediateRoofVentsType: undefined,
        upperRoofVentsType: undefined,
        lowerRoofVentsCode: undefined,
        intermediateRoofVentsCode: undefined,
        upperRoofVentsCode: undefined,
        numberOfLowerSupports: 0,
        numberOfIntermediateSupports: 0,
        numberOfUpperSupports: 0,
        lowerSupportsCode: undefined,
        intermediateSupportsCode: undefined,
        upperSupportsCode: undefined,
        lowerSupportsModel: undefined,
        intermediateSupportsModel: undefined,
        upperSupportsModel: undefined,
        numberOfHatches: 1,
        hatchCode: undefined,
        roofInsulation: false,
        roofInsulationCode: undefined
      }
    },
    designRequeriments: {
      standardsAndLimitations: {
        loadsAndPressures: 'ANSI-ASAE',
        resistance: 'EUROCODE',
        sheetPercentaje: 98,
        stiffenersPercentaje: 98
      }
    },
    loads: {
      productLoads: {
        product: 'WHEAT',
        densityForCalculation: 8.18,
        productDensity: 7.5,
        moistureContent: 14,
        fillingDiameter: 500,
        fillingEccentricity: 0,
        speedReducers: false,
        numberOfSpeedReducers: 0,
        typeOfDischarge: 'GRAVITY',
        dischargeDiameter: 500,
        dischargeEccentricity: 0,
        fillEmptyCiclesPerYear: 10,
        hasLateralDischarge: false,
        lateralDischargeRing: 5,
        lateralDischargeStandard: 'EUROCODE'
      },
      roofLoads: {
        snow: 60,
        peakLoad: undefined,
        probeLoad: undefined,
        totalNumberOfProbes: undefined
      },
      windLoads: {
        windSpeed: 144,
        windPressure: 100,
        typeOfInstallation: 'BATTERY',
        globalPressureCoeficient: 1
      },
      seismicLoads: {
        seismicStandard: 'EUROCODE',
        basicAcceleration: 0,
        importanceOfClass: "I",
        typeOfSoil: 'C',
        beheaviourCoefficient: 4,
        designAcceleration: undefined,
        hasReinforcementOfHorizontalJoints: false,
        hasSeismicEffectOnSheets: false
      },
      specialLoads: {
        numberOfCycles: 0,
        overpressure: 0,
        temperatureIncrement: 0
      }
    },
    materials: {
      numberOfRings: undefined,
      numberOfStiffeners: undefined,
      numberOfSplices: undefined,
      sheetsMaterials: 'S350GD',
      sheetsFromRing: undefined,
      sheetsGalvanization: 'Z6',
      stiffenersMaterials: 'S350GD',
      stiffenersFromRing: undefined,
      stiffenersGalvanization: 'Z6',
      splicesMaterials: 'S350GD',
      splicesFromRing: undefined,
      roofMaterials: 'S350GD',
      roofGalvanization: 'Z6',
      windRingsMaterials: 'S-275-JR',
      ringBoltsMaterials: "c8.8",
      ringBoltsFromRing: undefined,
      ringBoltsGalvanization: 'ZC',
      roofBoltsMaterials: "c8.8",
      roofBoltsGalvanization: undefined,
      basePlatesMaterials: 'S-275-JR',
      anchorBoltsMaterials: "c5.8",
      anchorBoltsFromRing: undefined,
    }
  };

  form = this.fb.group({
    jobId: [""],
    projectData: this.fb.group({
      siloModel: [{value: this.formDefaultValues.projectData.siloModel, disabled: true}],
      aggregationCode: [{value: this.formDefaultValues.projectData.aggregationCode, disabled: true}],
      numberOfSilosStandard: [this.formDefaultValues.projectData.numberOfSilosStandard],
      referenceCode: [this.formDefaultValues.projectData.referenceCode],
      client: [{value: this.formDefaultValues.projectData.client, disabled: true}],
      countryOfDest: [this.formDefaultValues.projectData.countryOfDest],
      siteOfInstallation: [this.formDefaultValues.projectData.siteOfInstallation],
      offerTitle: [this.formDefaultValues.projectData.offerTitle]
    }),
    configuration: this.fb.group({
      designType: [this.formDefaultValues.configuration.designType],
      numberOfRings: [this.formDefaultValues.configuration.numberOfRings],
      numberSheetsPerRing: [this.formDefaultValues.configuration.numberSheetsPerRing],
      numberStiffenersPerSheet: [this.formDefaultValues.configuration.numberStiffenersPerSheet],

      siloType: [{value: this.formDefaultValues.configuration.siloType, disabled: true }],
      configurationType: [{value: this.formDefaultValues.configuration.configurationType, disabled: true }],
      model: [{ value: this.formDefaultValues.configuration.model, disabled: true }],
      diameter: [{ value: this.formDefaultValues.configuration.diameter, disabled: true }],
      stiffenersTotal: [{ value: this.formDefaultValues.configuration.stiffenersTotal, disabled: true }]
    }),


    detailedConfiguration: this.fb.group({
      sheets: this.fb.group({
        upperRing: [this.formDefaultValues.detailedConfiguration.sheets.upperRing],
        positionOfRingWithLogo: [this.formDefaultValues.detailedConfiguration.sheets.positionOfRingWithLogo],
        numberOfSheetsWithLogoPerRing: [this.formDefaultValues.detailedConfiguration.sheets.numberOfSheetsWithLogoPerRing],
        restOfRings: [this.formDefaultValues.detailedConfiguration.sheets.restOfRings],
        stiffenersConnection: [this.formDefaultValues.detailedConfiguration.sheets.stiffenersConnection]
      }),
      catwalk: this.fb.group({
        hasCatwalk: [this.formDefaultValues.detailedConfiguration.catwalk.hasCatwalk],
        numberOfSupportsPerSilo: [this.formDefaultValues.detailedConfiguration.catwalk.numberOfSupportsPerSilo],
        hasCentralSupport: [this.formDefaultValues.detailedConfiguration.catwalk.hasCentralSupport],
        catwalkWidth: [this.formDefaultValues.detailedConfiguration.catwalk.catwalkWidth],
        handrailHeight: [this.formDefaultValues.detailedConfiguration.catwalk.handrailHeight],
        distanceBetweenVerticalSupports: [{ value: this.formDefaultValues.detailedConfiguration.catwalk.distanceBetweenVerticalSupports,
          disabled: true }],
        weightOfChainConveyor: [this.formDefaultValues.detailedConfiguration.catwalk.weightOfChainConveyor],
        weightOfCatwalk: [{ value: this.formDefaultValues.detailedConfiguration.catwalk.weightOfCatwalk, disabled: true }],
        catwalkOverload:  [{ value: this.formDefaultValues.detailedConfiguration.catwalk.catwalkOverload, disabled: true }],
        catwalkTotalLoad: [{ value: this.formDefaultValues.detailedConfiguration.catwalk.catwalkTotalLoad, disabled: true }],
        weightOfCatwalkSupport: [{ value: this.formDefaultValues.detailedConfiguration.catwalk.weightOfCatwalkSupport, disabled: true }],
        numberOfStiffenersPerSupport: [this.formDefaultValues.detailedConfiguration.catwalk.numberOfStiffenersPerSupport],
        catwalkStiffenersTotal: [{ value: this.formDefaultValues.detailedConfiguration.catwalk.catwalkStiffenersTotal, disabled: true }],
        standardStiffeners: [this.formDefaultValues.detailedConfiguration.catwalk.standardStiffeners],
        catwalkStiffeners: [this.formDefaultValues.detailedConfiguration.catwalk.catwalkStiffeners],
        catwalkCode: [{ value: this.formDefaultValues.detailedConfiguration.catwalk.catwalkCode, disabled: true }],
        centralSupportCode: [{ value: this.formDefaultValues.detailedConfiguration.catwalk.centralSupportCode, disabled: true }],
        horizontalAssemblyCode: [{ value: this.formDefaultValues.detailedConfiguration.catwalk.horizontalAssemblyCode, disabled: true }],
        verticalAssemblyCode: [{ value: this.formDefaultValues.detailedConfiguration.catwalk.verticalAssemblyCode, disabled: true }]
      }),
      windRings: this.fb.group({
        reinforcementType: [this.formDefaultValues.detailedConfiguration.windRings.reinforcementType],
        galvanizing: [this.formDefaultValues.detailedConfiguration.windRings.galvanizing],
        windRingsTotal: [{ value: this.formDefaultValues.detailedConfiguration.windRings.windRingsTotal, disabled: true }],
        tubeProfil: [this.formDefaultValues.detailedConfiguration.windRings.tubeProfil]
      }),
      inspectionDoors: this.fb.group({
        numberOfDoors: [{value: this.formDefaultValues.detailedConfiguration.inspectionDoors.numberOfDoors, disabled: true} ],
        lowestDoorType: [{ value: this.formDefaultValues.detailedConfiguration.inspectionDoors.lowestDoorType, disabled: true }],
        lowestDoorCode: [{ value: this.formDefaultValues.detailedConfiguration.inspectionDoors.lowestDoorCode, disabled: true }],
        lowestRing: [this.formDefaultValues.detailedConfiguration.inspectionDoors.lowestRing],
        hasAdditionalSlopeDoor: [this.formDefaultValues.detailedConfiguration.inspectionDoors.hasAdditionalSlopeDoor],
        slopeDoorType: [{ value: this.formDefaultValues.detailedConfiguration.inspectionDoors.slopeDoorType, disabled: true }],
        slopeDoorCode: [{ value: this.formDefaultValues.detailedConfiguration.inspectionDoors.slopeDoorCode, disabled: true }],
        slopeRing: [{ value: this.formDefaultValues.detailedConfiguration.inspectionDoors.slopeRing, disabled: true }],
        hasAdditionalDoors: [this.formDefaultValues.detailedConfiguration.inspectionDoors.hasAdditionalDoors],
        numberOfAdditionalDoors: [this.formDefaultValues.detailedConfiguration.inspectionDoors.numberOfAdditionalDoors],
        additionalDoorRing: [this.formDefaultValues.detailedConfiguration.inspectionDoors.additionalDoorRing],
        additionalDoorType: [this.formDefaultValues.detailedConfiguration.inspectionDoors.additionalDoorType],
        additionalDoorCode: [this.formDefaultValues.detailedConfiguration.inspectionDoors.additionalDoorCode],
        additionalDoorRing2: [this.formDefaultValues.detailedConfiguration.inspectionDoors.additionalDoorRing],
        additionalDoorType2: [this.formDefaultValues.detailedConfiguration.inspectionDoors.additionalDoorType],
        additionalDoorCode2: [this.formDefaultValues.detailedConfiguration.inspectionDoors.additionalDoorCode],
        additionalDoorRing3: [this.formDefaultValues.detailedConfiguration.inspectionDoors.additionalDoorRing],
        additionalDoorType3: [this.formDefaultValues.detailedConfiguration.inspectionDoors.additionalDoorType],
        additionalDoorCode3: [this.formDefaultValues.detailedConfiguration.inspectionDoors.additionalDoorCode],
        areAdditionalDoorsUnified: [this.formDefaultValues.detailedConfiguration.inspectionDoors.areAdditionalDoorsUnified]
      }),
      anchorBoltsAndPlates: this.fb.group({
        includeAnchorBolts: [this.formDefaultValues.detailedConfiguration.anchorBoltsAndPlates.includeAnchorBolts],
        type: [this.formDefaultValues.detailedConfiguration.anchorBoltsAndPlates.type],

        numberOfBoltsPerPlate: [this.formDefaultValues.detailedConfiguration.anchorBoltsAndPlates.numberOfBoltsPerPlate],
        includeShimPlatesInList: [this.formDefaultValues.detailedConfiguration.anchorBoltsAndPlates.includeShimPlatesInList],
        numberOfShimPlates2mmPerPlate: [this.formDefaultValues.detailedConfiguration.anchorBoltsAndPlates.numberOfShimPlates2mmPerPlate],
        numberOfShimPlates3mmPerPlate: [this.formDefaultValues.detailedConfiguration.anchorBoltsAndPlates.numberOfShimPlates3mmPerPlate],
        boltDiameter: [{value: this.formDefaultValues.detailedConfiguration.anchorBoltsAndPlates.boltDiameter, disabled: true}],
        boltCode: [{value: this.formDefaultValues.detailedConfiguration.anchorBoltsAndPlates.boltCode, disabled: true}],
        basePlateCode: [{value: this.formDefaultValues.detailedConfiguration.anchorBoltsAndPlates.basePlateCode, disabled: true}],
        numberOfBoltsPerSilo: [{value: this.formDefaultValues.detailedConfiguration.anchorBoltsAndPlates.numberOfBoltsPerSilo, disabled: true}],
        numberOfShimPlates2mmPerSilo: [{value: this.formDefaultValues.detailedConfiguration.anchorBoltsAndPlates.numberOfShimPlates2mmPerSilo, disabled: true}],
        numberOfShimPlates3mmPerSilo: [{value: this.formDefaultValues.detailedConfiguration.anchorBoltsAndPlates.numberOfShimPlates3mmPerSilo, disabled: true}],
        numberOfShimPlates2mmCode: [{value: this.formDefaultValues.detailedConfiguration.anchorBoltsAndPlates.numberOfShimPlates2mmCode, disabled: true}],
        numberOfShimPlates3mmCode: [{value: this.formDefaultValues.detailedConfiguration.anchorBoltsAndPlates.numberOfShimPlates3mmCode, disabled: true}]
      }),
      roof: this.fb.group({
        roofAssemblyCode:  [{value: this.formDefaultValues.detailedConfiguration.roof.roofAssemblyCode, disabled: true}],
        roofAssemblyDesign:  [this.formDefaultValues.detailedConfiguration.roof.roofAssemblyDesign],
        roofCoverCode: [{value: this.formDefaultValues.detailedConfiguration.roof.roofCoverCode, disabled: true}],
        roofStructureCode: [{value: this.formDefaultValues.detailedConfiguration.roof.roofStructureCode, disabled: true}],
        // probes: [this.formDefaultValues.detailedConfiguration.roof.probes],
        numberOfLowerRoofVents: [this.formDefaultValues.detailedConfiguration.roof.numberOfLowerRoofVents],
        numberOfIntermediateRoofVents: [this.formDefaultValues.detailedConfiguration.roof.numberOfIntermediateRoofVents],
        numberOfUpperRoofVents: [this.formDefaultValues.detailedConfiguration.roof.numberOfUpperRoofVents],
        lowerRoofVentsType: [this.formDefaultValues.detailedConfiguration.roof.lowerRoofVentsType],
        intermediateRoofVentsType: [this.formDefaultValues.detailedConfiguration.roof.intermediateRoofVentsType],
        upperRoofVentsType: [this.formDefaultValues.detailedConfiguration.roof.upperRoofVentsType],
        lowerRoofVentsCode: [{value: this.formDefaultValues.detailedConfiguration.roof.lowerRoofVentsCode, disabled: true}],
        intermediateRoofVentsCode: [{value: this.formDefaultValues.detailedConfiguration.roof.intermediateRoofVentsCode, disabled: true}],
        upperRoofVentsCode: [{value: this.formDefaultValues.detailedConfiguration.roof.upperRoofVentsCode, disabled: true}],
        numberOfLowerSupports: [this.formDefaultValues.detailedConfiguration.roof.numberOfLowerSupports],
        numberOfIntermediateSupports: [this.formDefaultValues.detailedConfiguration.roof.numberOfIntermediateSupports],
        numberOfUpperSupports: [this.formDefaultValues.detailedConfiguration.roof.numberOfUpperSupports],
        lowerSupportsCode: [this.formDefaultValues.detailedConfiguration.roof.lowerSupportsCode],
        intermediateSupportsCode: [this.formDefaultValues.detailedConfiguration.roof.intermediateSupportsCode],
        upperSupportsCode: [this.formDefaultValues.detailedConfiguration.roof.upperSupportsCode],
        lowerSupportsModel: [this.formDefaultValues.detailedConfiguration.roof.lowerSupportsModel],
        intermediateSupportsModel: [this.formDefaultValues.detailedConfiguration.roof.intermediateSupportsModel],
        upperSupportsModel: [this.formDefaultValues.detailedConfiguration.roof.upperSupportsModel],
        numberOfHatches: [this.formDefaultValues.detailedConfiguration.roof.numberOfHatches],
        hatchCode: [{value: this.formDefaultValues.detailedConfiguration.roof.hatchCode, disabled: true}],
        roofInsulation: [this.formDefaultValues.detailedConfiguration.roof.roofInsulation],
        roofInsulationCode: [{value: this.formDefaultValues.detailedConfiguration.roof.roofInsulationCode, disabled: true}]
      })
    }),
    designRequeriments: this.fb.group({
      standardsAndLimitations: this.fb.group({
        loadsAndPressures: [this.formDefaultValues.designRequeriments.standardsAndLimitations.loadsAndPressures],
        resistance: [this.formDefaultValues.designRequeriments.standardsAndLimitations.resistance],
        sheetPercentaje: [this.formDefaultValues.designRequeriments.standardsAndLimitations.sheetPercentaje,
           Validators.pattern(/^\d+\.\d{2}$/)],
        stiffenersPercentaje: [this.formDefaultValues.designRequeriments.standardsAndLimitations.stiffenersPercentaje,
          Validators.pattern(/^\d+\.\d{2}$/)]
      })
    }),
    loads: this.fb.group({
      productLoads: this.fb.group({
        product: [this.formDefaultValues.loads.productLoads.product],
        densityForCalculation: [this.formDefaultValues.loads.productLoads.densityForCalculation],
        productDensity: [this.formDefaultValues.loads.productLoads.productDensity],
        moistureContent: [this.formDefaultValues.loads.productLoads.moistureContent],
        fillingDiameter: [this.formDefaultValues.loads.productLoads.fillingDiameter],
        fillingEccentricity: [this.formDefaultValues.loads.productLoads.fillingEccentricity],
        speedReducers: [this.formDefaultValues.loads.productLoads.speedReducers],
        numberOfSpeedReducers: [this.formDefaultValues.loads.productLoads.numberOfSpeedReducers],
        typeOfDischarge: [this.formDefaultValues.loads.productLoads.typeOfDischarge],
        dischargeDiameter: [this.formDefaultValues.loads.productLoads.dischargeDiameter],
        dischargeEccentricity: [this.formDefaultValues.loads.productLoads.dischargeEccentricity],
        fillEmptyCiclesPerYear: [this.formDefaultValues.loads.productLoads.fillEmptyCiclesPerYear],
        hasLateralDischarge: [this.formDefaultValues.loads.productLoads.hasLateralDischarge],
        lateralDischargeRing: [this.formDefaultValues.loads.productLoads.lateralDischargeRing],
        lateralDischargeStandard: [this.formDefaultValues.loads.productLoads.lateralDischargeStandard]
      }),
      roofLoads: this.fb.group({
        snow: [this.formDefaultValues.loads.roofLoads.snow],
        peakLoad: [this.formDefaultValues.loads.roofLoads.peakLoad],
        probeLoad: [this.formDefaultValues.loads.roofLoads.probeLoad],
        totalNumberOfProbes: [{ value: this.formDefaultValues.loads.roofLoads.totalNumberOfProbes, disabled: true }]
      }),
      windLoads: this.fb.group({
        windSpeed: [this.formDefaultValues.loads.windLoads.windSpeed],
        windPressure: [{ value: this.formDefaultValues.loads.windLoads.windPressure, disabled: true }],
        typeOfInstallation: [this.formDefaultValues.loads.windLoads.typeOfInstallation],
        globalPressureCoeficient: [this.formDefaultValues.loads.windLoads.globalPressureCoeficient]
      }),
      seismicLoads: this.fb.group({
        seismicStandard: [{ value: this.formDefaultValues.loads.seismicLoads.seismicStandard, disabled: true }],
        basicAcceleration: [this.formDefaultValues.loads.seismicLoads.basicAcceleration],
        importanceOfClass: [this.formDefaultValues.loads.seismicLoads.importanceOfClass],
        typeOfSoil: [this.formDefaultValues.loads.seismicLoads.typeOfSoil],
        beheaviourCoefficient: [this.formDefaultValues.loads.seismicLoads.beheaviourCoefficient],
        designAcceleration: [{ value: this.formDefaultValues.loads.seismicLoads.designAcceleration, disabled: true }],
        hasReinforcementOfHorizontalJoints: [this.formDefaultValues.loads.seismicLoads.hasReinforcementOfHorizontalJoints],
        hasSeismicEffectOnSheets: [this.formDefaultValues.loads.seismicLoads.hasSeismicEffectOnSheets]
      }),
      specialLoads: this.fb.group({
        numberOfCycles: [this.formDefaultValues.loads.specialLoads.numberOfCycles],
        overpressure: [this.formDefaultValues.loads.specialLoads.overpressure],
        temperatureIncrement: [this.formDefaultValues.loads.specialLoads.temperatureIncrement]
      })
    }),
    materials: this.fb.group({
      numberOfRings: [{ value: this.formDefaultValues.materials.numberOfRings, disabled: true }],
      numberOfStiffeners: [{ value: this.formDefaultValues.materials.numberOfStiffeners, disabled: true }],
      numberOfSplices: [{ value: this.formDefaultValues.materials.numberOfSplices, disabled: true }],
      sheetsMaterials: [this.formDefaultValues.materials.sheetsMaterials],
      sheetsFromRing: [this.formDefaultValues.materials.sheetsFromRing],
      sheetsGalvanization: [this.formDefaultValues.materials.sheetsGalvanization],
      stiffenersMaterials: [this.formDefaultValues.materials.stiffenersMaterials],
      stiffenersFromRing: [this.formDefaultValues.materials.stiffenersFromRing],
      stiffenersGalvanization: [this.formDefaultValues.materials.stiffenersGalvanization],
      splicesMaterials: [{ value: this.formDefaultValues.materials.splicesMaterials, disabled: true }],
      splicesFromRing: [{ value: this.formDefaultValues.materials.splicesFromRing, disabled: true }],
      roofMaterials: [this.formDefaultValues.materials.roofMaterials],
      roofGalvanization: [this.formDefaultValues.materials.roofGalvanization],
      windRingsMaterials: [this.formDefaultValues.materials.windRingsMaterials],
      ringBoltsMaterials: [this.formDefaultValues.materials.ringBoltsMaterials],
      ringBoltsFromRing: [this.formDefaultValues.materials.ringBoltsFromRing],
      ringBoltsGalvanization: [this.formDefaultValues.materials.ringBoltsGalvanization],
      roofBoltsMaterials: [this.formDefaultValues.materials.roofBoltsMaterials],
      roofBoltsGalvanization: [{ value: this.formDefaultValues.materials.roofBoltsGalvanization, disabled: true }],
      basePlatesMaterials: [this.formDefaultValues.materials.basePlatesMaterials],
      anchorBoltsMaterials: [this.formDefaultValues.materials.anchorBoltsMaterials],
      anchorBoltsFromRing: [{ value: this.formDefaultValues.materials.anchorBoltsFromRing, disabled: true }]
    })
  });

  constructor(private fb: SimezaFormBuilder,
              private modalService: NgbModal,
              private router: Router,
              public cisService: CISService
            ) 
  {

    this.router.events.subscribe((navigationEvent) => {
      
        if(navigationEvent instanceof NavigationEnd && navigationEvent.url == "/dashboard/cis"){
       
          window.scrollTo(0, 0);
        }
            
    });

  }

  setDynamicInputs(){
    const numberSheetsPerRing = this.form.get(['configuration','numberSheetsPerRing']).value;
   
    if(numberSheetsPerRing>13){
      this.form.get(['detailedConfiguration', 'catwalk', 'hasCentralSupport']).patchValue(true);
    }
    else{
      this.form.get(['detailedConfiguration', 'catwalk', 'hasCentralSupport']).patchValue(false);
    }


    const loadsAndPressures = this.form.get('designRequeriments.standardsAndLimitations.loadsAndPressures').value;

    const numberOfRings = this.form.get('configuration.numberOfRings').value;
    
    if(numberOfRings>0){
      this.form.get(['detailedConfiguration','inspectionDoors', 'lowestRing']).patchValue(numberOfRings - 1);
    }
    
        
    const hasCatwalk = this.form.get(['detailedConfiguration', 'catwalk', 'hasCatwalk']).value;
    
    if(hasCatwalk==false){
      
      this.form.get(['detailedConfiguration', 'catwalk', 'catwalkOverload']).patchValue(0);
    }
    else{
      const snow = this.form.get(['loads','roofLoads','snow']).value;
      const catwalkWidth = this.form.get(['detailedConfiguration', 'catwalk', 'catwalkWidth']).value;
      let catWalkOverload = snow*(catwalkWidth/1000);
      
      if( catWalkOverload < 100 ){
        catWalkOverload = 100;
      }

      this.form.get(['detailedConfiguration', 'catwalk', 'catwalkOverload']).patchValue(catWalkOverload);
    }
        

    const numberOfSupportsPerSilo = this.form.get(['detailedConfiguration', 'catwalk', 'numberOfSupportsPerSilo']).value;
    const numberOfStiffenersPerSupport = this.form.get(['detailedConfiguration', 'catwalk', 'numberOfStiffenersPerSupport']).value
    const catwalkStiffenersTotal = numberOfSupportsPerSilo*numberOfStiffenersPerSupport;

    this.form.get(['detailedConfiguration', 'catwalk', 'catwalkStiffenersTotal']).patchValue(catwalkStiffenersTotal); 


    const basePlateCode = this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'basePlateCode']).value;

    if(basePlateCode){
      const sheetsPerRing =  this.form.get(['configuration','numberSheetsPerRing']).value;
      const numberStiffenersPerSheet = this.form.get(['configuration','numberStiffenersPerSheet']).value;
      const numberShiimPLates2mm = this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates2mmPerPlate']).value;
      const numberShiimPLates3mm = this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates3mmPerPlate']).value;

      const numberOfShimPlates2mmPerSilo = sheetsPerRing * parseInt(numberStiffenersPerSheet) * numberShiimPLates2mm;
      const numberOfShimPlates3mmPerSilo = sheetsPerRing * parseInt(numberStiffenersPerSheet) * numberShiimPLates3mm;

      this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates2mmPerSilo']).patchValue(numberOfShimPlates2mmPerSilo);
      this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates3mmPerSilo']).patchValue(numberOfShimPlates3mmPerSilo);
    }




    const product = this.form.get(['loads', 'productLoads', 'product']).value;
    switch(product){
      case "WHEAT":{

        const laodsAndPressures = this.form.get('designRequeriments.standardsAndLimitations.loadsAndPressures').value;
        
        if (laodsAndPressures === 'EUROCODE') {

          this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(9);
        } else {
          this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(8.18);
        }
        break;
    
      }
      case "CORN":{
        this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(8);
        break;
      }
      case "SOYA BEANS":{
        this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(8);
        break;
      }
      case "BARLEY":{
        this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(8);
        break;
      }
      case "SUNFLOWER":{
        this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(14);
        break;
      }
      case "FLOUR":{
        this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(7);
        break;
      }
      case "RAPE SEED":{
        this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(9.10);
        break;
      }
      case "SOYBEAN MEAL":{
        this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(6);
        break;
      }

    }
  

    const windSpeed = this.form.get(['loads', 'windLoads', 'windSpeed']).value;
          
    const windPressure =  Number(Math.pow(Number.parseFloat(windSpeed)/3.6, 2) / 16);
    
    const windPressureRounded = windPressure.toFixed(2);

    this.form.get(['loads', 'windLoads', 'windPressure']).setValue(Number.parseFloat(windPressureRounded));

    
    const basicAcceleration = this.form.get(['loads','seismicLoads','basicAcceleration']).value;
        
    if(basicAcceleration >= 0.3){
      this.form.get(['loads', 'seismicLoads', 'hasReinforcementOfHorizontalJoints']).setValue(true);
    }
    else{
      this.form.get(['loads', 'seismicLoads', 'hasReinforcementOfHorizontalJoints']).setValue(false);
    }

    if(loadsAndPressures === 'EUROCODE'){
      if(basicAcceleration > 0){
        this.form.get(['loads', 'seismicLoads', 'hasSeismicEffectOnSheets']).setValue(true);
      }
      else{
        this.form.get(['loads', 'seismicLoads', 'hasSeismicEffectOnSheets']).setValue(false);
      }
    }
    else{
      this.form.get(['loads', 'seismicLoads', 'hasSeismicEffectOnSheets']).setValue(false);
    }
  





    const anchorBoltType =  this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'type']).value;

    (<SimezaFormControl>this.form.get(['materials', 'anchorBoltsMaterials'])).initialValue =
      this.anchorBoltTypesMaterials[anchorBoltType][0].value;
    this.form.get(['materials', 'anchorBoltsMaterials']).patchValue(this.anchorBoltTypesMaterials[anchorBoltType][0].value)
  

    if(anchorBoltType !== this.formDefaultValues.detailedConfiguration.anchorBoltsAndPlates.type){
      this.anchorBoltTypeWarning = true;
    }
    else{
      this.anchorBoltTypeWarning = false;
    }
 
    

    const sheetsMaterials = this.form.get(['materials', 'sheetsMaterials']).value;
    const sheetsFromRing = this.form.get(['materials', 'sheetsFromRing']).value;
    
    if(sheetsMaterials !== this.formDefaultValues.materials.sheetsMaterials && !(sheetsFromRing>0)){
      this.sheetMaterialWarning = true;
    }else{
      this.sheetMaterialWarning = false;
    }
 
    

    const stiffenersMaterials = this.form.get(['materials', 'stiffenersMaterials']).value;
    const stiffenersFromRing = this.form.get(['materials', 'stiffenersFromRing']).value;
    
    if(stiffenersMaterials !== this.formDefaultValues.materials.stiffenersMaterials && !(stiffenersFromRing>0)){
      this.stiffenersMaterialWarning = true;
    }else{
      this.stiffenersMaterialWarning = false;
    }
   
  

    const ringBoltsMaterials = this.form.get(['materials', 'ringBoltsMaterials']).value;    
    const ringBoltsFromRing = this.form.get(['materials', 'ringBoltsFromRing']).value;
    
    if(ringBoltsMaterials !== this.formDefaultValues.materials.ringBoltsMaterials && !(ringBoltsFromRing>0)){
      this.ringBoltsMaterialWarning = true;
    }else{
      this.ringBoltsMaterialWarning = false;
    }


  }

  anchorBoltTypeWarning: boolean =false;
  sheetMaterialWarning: boolean =false;
  stiffenersMaterialWarning: boolean=false;
  ringBoltsMaterialWarning: boolean = false;

  ngOnInit() {

    this.form.get(['configuration','numberSheetsPerRing']).
      valueChanges.subscribe(val => {
   
        if(val>13){
          this.form.get(['detailedConfiguration', 'catwalk', 'hasCentralSupport']).patchValue(true);
        }
        else{
          this.form.get(['detailedConfiguration', 'catwalk', 'hasCentralSupport']).patchValue(false);
        }

        const basePlateCode = this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'basePlateCode']).value;

        if(basePlateCode){
          const sheetsPerRing = val;
          const numberStiffenersPerSheet = this.form.get(['configuration','numberStiffenersPerSheet']).value;
          const numberShiimPLates2mm = this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates2mmPerPlate']).value;
          const numberShiimPLates3mm = this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates3mmPerPlate']).value;

          const numberOfShimPlates2mmPerSilo = sheetsPerRing * parseInt(numberStiffenersPerSheet) * numberShiimPLates2mm;
          const numberOfShimPlates3mmPerSilo = sheetsPerRing * parseInt(numberStiffenersPerSheet) * numberShiimPLates3mm;

          this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates2mmPerSilo']).patchValue(numberOfShimPlates2mmPerSilo);
          this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates3mmPerSilo']).patchValue(numberOfShimPlates3mmPerSilo);
        }

        this.cisService.calculatePeakLoad(val).then(data => {
          console.log("data peak load", data);
          if(!_.isNull(data)){
            this.form.get(['loads', 'roofLoads', 'peakLoad']).setValue(data.value);
          }
         
        });


        this.cisService.calculateProbeLoad(val, this.form.get('configuration.numberOfRings').value ).then(data => {
          console.log("data peak load", data);
          if(!_.isNull(data)){
            const valueRounded = data.value.toFixed(2);
            this.form.get(['loads', 'roofLoads', 'probeLoad']).setValue(Number.parseFloat(valueRounded));
          }
         
        });

        
    });

    this.form.get(['configuration','numberStiffenersPerSheet']).
      valueChanges.subscribe(val => {


        const basePlateCode = this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'basePlateCode']).value;

        if(basePlateCode){
          const sheetsPerRing =  this.form.get(['configuration','numberSheetsPerRing']).value;
          const numberStiffenersPerSheet = val;
          const numberShiimPLates2mm = this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates2mmPerPlate']).value;
          const numberShiimPLates3mm = this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates3mmPerPlate']).value;

          const numberOfShimPlates2mmPerSilo = sheetsPerRing * parseInt(numberStiffenersPerSheet) * numberShiimPLates2mm;
          const numberOfShimPlates3mmPerSilo = sheetsPerRing * parseInt(numberStiffenersPerSheet) * numberShiimPLates3mm;

          this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates2mmPerSilo']).patchValue(numberOfShimPlates2mmPerSilo);
          this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates3mmPerSilo']).patchValue(numberOfShimPlates3mmPerSilo);
        }
    });


    this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates2mmPerPlate']).
      valueChanges.subscribe(val => {


        const basePlateCode = this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'basePlateCode']).value;

        if(basePlateCode){
          const sheetsPerRing = this.form.get(['configuration','numberSheetsPerRing']).value;
          const numberStiffenersPerSheet = this.form.get(['configuration','numberStiffenersPerSheet']).value;
          const numberShiimPLates2mm = val
          const numberShiimPLates3mm = this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates3mmPerPlate']).value;

          const numberOfShimPlates2mmPerSilo = sheetsPerRing * parseInt(numberStiffenersPerSheet) * numberShiimPLates2mm;
          const numberOfShimPlates3mmPerSilo = sheetsPerRing * parseInt(numberStiffenersPerSheet) * numberShiimPLates3mm;

          this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates2mmPerSilo']).patchValue(numberOfShimPlates2mmPerSilo);
          this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates3mmPerSilo']).patchValue(numberOfShimPlates3mmPerSilo);
        }
    });

    this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates3mmPerPlate']).
      valueChanges.subscribe(val => {


        const basePlateCode = this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'basePlateCode']).value;

        if(basePlateCode){
          const sheetsPerRing =  this.form.get(['configuration','numberSheetsPerRing']).value;
          const numberStiffenersPerSheet = this.form.get(['configuration','numberStiffenersPerSheet']).value;
          const numberShiimPLates2mm = this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates2mmPerPlate']).value;
          const numberShiimPLates3mm = val;

          const numberOfShimPlates2mmPerSilo = sheetsPerRing * parseInt(numberStiffenersPerSheet) * numberShiimPLates2mm;
          const numberOfShimPlates3mmPerSilo = sheetsPerRing * parseInt(numberStiffenersPerSheet) * numberShiimPLates3mm;

          this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates2mmPerSilo']).patchValue(numberOfShimPlates2mmPerSilo);
          this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates3mmPerSilo']).patchValue(numberOfShimPlates3mmPerSilo);
        }
    });



    this.form.get(['detailedConfiguration', 'catwalk', 'hasCatwalk']).
      valueChanges.subscribe(val => {
    
        if(val==false){
          
          this.form.get(['detailedConfiguration', 'catwalk', 'catwalkOverload']).patchValue(0);
        }
        else{
          const snow = this.form.get(['loads','roofLoads','snow']).value;
          const catwalkWidth = this.form.get(['detailedConfiguration', 'catwalk', 'catwalkWidth']).value;
          let catWalkOverload = snow*(catwalkWidth/1000);
          
          if( catWalkOverload < 100 ){
            catWalkOverload = 100;
          }

          this.form.get(['detailedConfiguration', 'catwalk', 'catwalkOverload']).patchValue(catWalkOverload);
        }
        
    });


    this.form.get(['loads','roofLoads','snow']).
      valueChanges.subscribe(val => {
      
        const hastCatwalk = this.form.get(['detailedConfiguration', 'catwalk', 'hasCatwalk']).value;

        if(hastCatwalk){
          const snow = val;
          const catwalkWidth = this.form.get(['detailedConfiguration', 'catwalk', 'catwalkWidth']).value;

          let catWalkOverload = snow*(catwalkWidth/1000);

          if( catWalkOverload < 100 ){
            catWalkOverload = 100;
          }

          this.form.get(['detailedConfiguration', 'catwalk', 'catwalkOverload']).patchValue(catWalkOverload);
        }
        
    });


    this.form.get(['detailedConfiguration','catwalk','catwalkWidth']).
    valueChanges.subscribe(val => {
    
      const hastCatwalk = this.form.get(['detailedConfiguration', 'catwalk', 'hasCatwalk']).value;

      if(hastCatwalk){
        const snow = this.form.get(['loads','roofLoads','snow']).value;
        const catwalkWidth = val;

        let catWalkOverload = snow*(catwalkWidth/1000);

        if( catWalkOverload < 100 ){
          catWalkOverload = 100;
        }

        this.form.get(['detailedConfiguration', 'catwalk', 'catwalkOverload']).patchValue(catWalkOverload);
      }
      
    });

   this.form.get(['detailedConfiguration', 'catwalk', 'numberOfSupportsPerSilo']).
    valueChanges.subscribe(val => {

      const catwalkStiffenersTotal = val * this.form.get(['detailedConfiguration', 'catwalk', 'numberOfStiffenersPerSupport']).value;
      this.form.get(['detailedConfiguration', 'catwalk', 'catwalkStiffenersTotal']).patchValue(catwalkStiffenersTotal); 

   });

   this.form.get(['detailedConfiguration', 'catwalk', 'numberOfStiffenersPerSupport']).
    valueChanges.subscribe(val => {

      const catwalkStiffenersTotal = val * this.form.get(['detailedConfiguration', 'catwalk', 'numberOfSupportsPerSilo']).value;
      this.form.get(['detailedConfiguration', 'catwalk', 'catwalkStiffenersTotal']).patchValue(catwalkStiffenersTotal); 

   });


    this.densityForCalculationSubscription = this.form.get('designRequeriments.standardsAndLimitations.loadsAndPressures').
      valueChanges.subscribe(val => {
  
        const product = this.form.get(['loads', 'productLoads', 'product']).value;
        if(product === "WHEAT"){

          if (val === 'EUROCODE') {
    
            this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(9);
          } else {
            this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(8.18);
          }
        }

        const basicAcceleration = this.form.get(['loads','seismicLoads','basicAcceleration']).value;

        if(val === 'EUROCODE'){
          if(basicAcceleration > 0){
            this.form.get(['loads', 'seismicLoads', 'hasSeismicEffectOnSheets']).setValue(true);
          }
          else{
            this.form.get(['loads', 'seismicLoads', 'hasSeismicEffectOnSheets']).setValue(false);
          }
        }
        else{
          this.form.get(['loads', 'seismicLoads', 'hasSeismicEffectOnSheets']).setValue(false);
        }

    });


    this.lowestRingSubscription = this.form.get('configuration.numberOfRings').
      valueChanges.subscribe(val => {
     
        this.form.get(['detailedConfiguration','inspectionDoors', 'lowestRing']).patchValue(val - 1);


        this.cisService.calculateProbeLoad(this.form.get(['configuration','numberSheetsPerRing']).value, val ).then(data => {
         
          if(!_.isNull(data)){
            const valueRounded = data.value.toFixed(2);
            this.form.get(['loads', 'roofLoads', 'probeLoad']).setValue(Number.parseFloat(valueRounded));
          }
         
        });
  
    });

    this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'type'])
    .valueChanges.subscribe(val => {
      (<SimezaFormControl>this.form.get(['materials', 'anchorBoltsMaterials'])).initialValue =
        this.anchorBoltTypesMaterials[val][0].value;
      this.form.get(['materials', 'anchorBoltsMaterials']).patchValue(this.anchorBoltTypesMaterials[val][0].value)
    });



    this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'type'])
      .valueChanges.subscribe(val => {
        if(val !== this.formDefaultValues.detailedConfiguration.anchorBoltsAndPlates.type){
          this.anchorBoltTypeWarning = true;
        }
        else{
          this.anchorBoltTypeWarning = false;
        }
    });
    

    this.form.get(['materials', 'sheetsMaterials'])
      .valueChanges.subscribe(val => {
        
        const sheetsFromRing = this.form.get(['materials', 'sheetsFromRing']).value;
        if(val !== this.formDefaultValues.materials.sheetsMaterials && !(sheetsFromRing>0)){
          this.sheetMaterialWarning = true;
        }else{
          this.sheetMaterialWarning = false;
        }
    });
    
    this.form.get(['materials', 'sheetsFromRing'])
    .valueChanges.subscribe(val => {
        
     
        if(this.form.get(['materials', 'sheetsMaterials']).value !== this.formDefaultValues.materials.sheetsMaterials && !(val>0)){
          this.sheetMaterialWarning = true;
        }else{
          this.sheetMaterialWarning = false;
        }
    });

    this.form.get(['materials', 'stiffenersMaterials'])
      .valueChanges.subscribe(val => {
        
        const stiffenersFromRing = this.form.get(['materials', 'stiffenersFromRing']).value;
        if(val !== this.formDefaultValues.materials.stiffenersMaterials && !(stiffenersFromRing>0)){
          this.stiffenersMaterialWarning = true;
        }else{
          this.stiffenersMaterialWarning = false;
        }
    });
    
    this.form.get(['materials', 'stiffenersFromRing'])
    .valueChanges.subscribe(val => {
        
        
        if(this.form.get(['materials', 'stiffenersMaterials']).value !== this.formDefaultValues.materials.stiffenersMaterials && !(val>0)){
          this.stiffenersMaterialWarning = true;
        }else{
          this.stiffenersMaterialWarning = false;
        }
    });

    this.form.get(['materials', 'ringBoltsMaterials'])
      .valueChanges.subscribe(val => {
        
        const ringBoltsFromRing = this.form.get(['materials', 'ringBoltsFromRing']).value;
        if(val !== this.formDefaultValues.materials.ringBoltsMaterials && !(ringBoltsFromRing>0)){
          this.ringBoltsMaterialWarning = true;
        }else{
          this.ringBoltsMaterialWarning = false;
        }
    });

    this.form.get(['materials', 'ringBoltsFromRing'])
    .valueChanges.subscribe(val => {
        
        if(this.form.get(['materials', 'ringBoltsMaterials']).value !== this.formDefaultValues.materials.ringBoltsMaterials && !(val>0)){
          this.ringBoltsMaterialWarning = true;
        }else{
          this.ringBoltsMaterialWarning = false;
        }
    });
    

    this.form.get(['loads', 'productLoads', 'product'])
      .valueChanges.subscribe(val => {
          
        switch(val){
          case "WHEAT":{

            const laodsAndPressures = this.form.get('designRequeriments.standardsAndLimitations.loadsAndPressures').value;
           
            if (laodsAndPressures === 'EUROCODE') {
    
              this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(9);
            } else {
              this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(8.18);
            }
            break;
        
          }
          case "CORN":{
            this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(8);
            break;
          }
          case "SOYA BEANS":{
            this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(8);
            break;
          }
          case "BARLEY":{
            this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(8);
            break;
          }
          case "SUNFLOWER":{
            this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(14);
            break;
          }
          case "FLOUR":{
            this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(7);
            break;
          }
          case "RAPE SEED":{
            this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(9.10);
            break;
          }
          case "SOYBEAN MEAL":{
            this.form.controls.loads['controls'].productLoads.controls.densityForCalculation.setValue(6);
            break;
          }

        }
    });


    this.form.get(['loads', 'windLoads', 'windSpeed'])
      .valueChanges.subscribe(val => {
        
        const windPressure =  Number(Math.pow(Number.parseFloat(val)/3.6, 2) / 16);
      
        const windPressureRounded = windPressure.toFixed(2);

        this.form.get(['loads', 'windLoads', 'windPressure']).setValue(Number.parseFloat(windPressureRounded));

    });


    this.form.get(['loads','seismicLoads','basicAcceleration'])
      .valueChanges.subscribe(val => {
          
        if(val >= 0.3){
          this.form.get(['loads', 'seismicLoads', 'hasReinforcementOfHorizontalJoints']).setValue(true);
        }
        else{
          this.form.get(['loads', 'seismicLoads', 'hasReinforcementOfHorizontalJoints']).setValue(false);
        }


        const loadsAndPressures = this.form.get('designRequeriments.standardsAndLimitations.loadsAndPressures').value;

        if(loadsAndPressures === 'EUROCODE'){
          if(val > 0){
            this.form.get(['loads', 'seismicLoads', 'hasSeismicEffectOnSheets']).setValue(true);
          }
          else{
            this.form.get(['loads', 'seismicLoads', 'hasSeismicEffectOnSheets']).setValue(false);
          }
        }
        else{
          this.form.get(['loads', 'seismicLoads', 'hasSeismicEffectOnSheets']).setValue(false);
        }
  
    });
  

    


    if(this.cisService.isInputFromOfferAvailable()){
      this.loadOfferSiloData();
     
    
    }
    
  }

  disableSave(){
    
    return  !(this.siloResponse.length > 0) || this.savingSilo || ( _.has(this.siloResponse[0],"saved")  && this.siloResponse[0]["saved"] );
  }


  loadOfferSiloData(){
    this.siloResponse = [];
    
    let silo = this.cisService.inputFromOffer.offerSilo.silo;
    console.log("offer silo cost", this.cisService.inputFromOffer.offerSilo.cost);
    silo.costWithAnchors = this.cisService.inputFromOffer.offerSilo.cost;
    silo.weightWithAnchors = this.cisService.inputFromOffer.offerSilo.weight;
    
    this.siloResponse.push(silo);

    this.cisService.getSiloCalculation(this.cisService.inputFromOffer.offerSilo.silo.id)
    .then(data => {
     

      let inputForCalculation = JSON.parse(data.inputForCalculation);
      console.log("project data from calc", inputForCalculation);

      this.form.setValue(inputForCalculation);

      this.form.get(['projectData','client']).patchValue(this.cisService.inputFromOffer.offerClient);
      this.form.get(['projectData','countryOfDest']).patchValue(this.cisService.inputFromOffer.offerShipmentSite);
      this.form.get(['projectData','siteOfInstallation']).patchValue(this.cisService.inputFromOffer.offerSiteOfInstallation);
      this.form.get(['projectData','offerTitle']).patchValue(this.cisService.inputFromOffer.offerTitle);
      this.form.get(['projectData','numberOfSilosStandard']).patchValue(this.cisService.inputFromOffer.offerSilo.quantity);
      this.setDynamicInputs();

    })
    .catch(error => {
      console.log("error", error);

      this.form.get(['configuration','numberOfRings']).patchValue(this.cisService.inputFromOffer.offerSilo.silo.wallRings);
      this.form.get(['detailedConfiguration','inspectionDoors', 'lowestRing']).patchValue(this.cisService.inputFromOffer.offerSilo.silo.wallRings - 1);
      this.form.get(['configuration','numberSheetsPerRing']).patchValue(this.cisService.inputFromOffer.offerSilo.silo.wallSheetsPerRing);
      this.form.get(['configuration','numberStiffenersPerSheet']).patchValue(this.cisService.inputFromOffer.offerSilo.silo.verticalStiffenersPerWallSheet);
      
      this.form.get(['designRequeriments','standardsAndLimitations','loadsAndPressures']).patchValue(this.cisService.inputFromOffer.offerSilo.silo.loadsAndPresures);
      this.form.get(['loads','roofLoads','snow']).patchValue(this.cisService.inputFromOffer.offerSilo.silo.snowLoad);
      this.form.get(['loads','seismicLoads','basicAcceleration']).patchValue(this.cisService.inputFromOffer.offerSilo.silo.seismicZone);

      this.form.get(['projectData','client']).patchValue(this.cisService.inputFromOffer.offerClient);
      this.form.get(['projectData','countryOfDest']).patchValue(this.cisService.inputFromOffer.offerShipmentSite);
      this.form.get(['projectData','siteOfInstallation']).patchValue(this.cisService.inputFromOffer.offerSiteOfInstallation);
      this.form.get(['projectData','offerTitle']).patchValue(this.cisService.inputFromOffer.offerTitle);
      this.form.get(['projectData','numberOfSilosStandard']).patchValue(this.cisService.inputFromOffer.offerSilo.quantity);

      this.setDynamicInputs();
    });
  


  }


  changeView(viewType: CISView) {
    this.detailedView = viewType;
  }

  resetField(fieldName: string) {
    let value = undefined;
    let currentEntry = this.formDefaultValues;
    for (const key of fieldName.split('.')) {
      value = currentEntry[key];
      currentEntry = currentEntry[key];
    }
    this.form.get(fieldName).reset(value);
  }


  loadingSilo:boolean = false;
  loadingSelection: boolean = false;

  siloHasError(){
    const silo = this.siloResponse[0];

    return (
      (_.has(silo, 'error1') && silo.error1!='' )|| 
      (_.has(silo, 'error2') && silo.error2!='')|| 
      (_.has(silo, 'error3') && silo.error3!='')
    );
  }

  loadCalcualtedDataInForm(){
    


    this.form.get(['projectData','siloModel']).patchValue(this.siloResponse[0].completeModel);
    this.form.get(['projectData','aggregationCode']).patchValue(this.siloResponse[0].aggregationCode);

    this.form.get(['configuration','diameter']).patchValue(this.siloResponse[0].diameter);
    this.form.get(['configuration','model']).patchValue(this.siloResponse[0].commercialModel);
    this.form.get(['configuration','stiffenersTotal']).patchValue(this.siloResponse[0].verticalStiffeners);

    // ------------------------------------------------------------------------------
    // Here, first we check if the value of "Upper Ring" is set before calculation, if it is, we would treat the field
    // as a manual input, and before the calculation we will leave its background light-yellow, if it is not se beforehand,
    // to prevent the background to change with the 'appOlNew' directive, we cast the 'AbstractControl' from "this.form.get"
    // and cast it to our 'SimezaFormControl', with this, we can set the 'initialValue' to the calculated one the server has sent us,
    // so it will take the new value as if it was the original one, thus not changing the background
    if (!this.form.get(['detailedConfiguration','sheets', 'upperRing']).value) {
      (<SimezaFormControl>this.form.get(['detailedConfiguration','sheets', 'upperRing'])).initialValue = this.siloResponse[0].upperRing;
    }
    this.form.get(['detailedConfiguration','sheets', 'upperRing']).patchValue(this.siloResponse[0].upperRing);
    // ------------------------------------------------------------------------------

    this.form.get(['detailedConfiguration','sheets', 'positionOfRingWithLogo']).patchValue(this.siloResponse[0].positionOfRingwWithLogo);


    if(this.siloResponse[0].restOfRings == "AS CALCULATED"){
      this.form.get(['detailedConfiguration','sheets', 'restOfRings']).patchValue(this.formDefaultValues.detailedConfiguration.sheets.restOfRings);
    }
    else{
      this.form.get(['detailedConfiguration','sheets', 'restOfRings']).patchValue(this.siloResponse[0].restOfRings);
    }
    
    this.form.get(['detailedConfiguration','sheets', 'stiffenersConnection']).patchValue(this.siloResponse[0].stiffenersConnection);
  
    this.form.get(['detailedConfiguration', 'catwalk', 'hasCatwalk']).patchValue(this.siloResponse[0].catwalk);
    this.form.get(['detailedConfiguration', 'catwalk', 'catwalkCode']).patchValue(this.siloResponse[0].catwalkCode);
    this.form.get(['detailedConfiguration', 'catwalk', 'horizontalAssemblyCode']).patchValue(this.siloResponse[0].horizontalAssemblyCode);
    this.form.get(['detailedConfiguration', 'catwalk', 'centralSupportCode']).patchValue(this.siloResponse[0].centralSupportCode);
    this.form.get(['detailedConfiguration', 'catwalk', 'verticalAssemblyCode']).patchValue(this.siloResponse[0].verticalAssemblyCode);

    this.form.get(['detailedConfiguration', 'catwalk', 'numberOfSupportsPerSilo']).patchValue(this.siloResponse[0].supports);
    this.form.get(['detailedConfiguration', 'catwalk', 'hasCentralSupport']).patchValue(this.siloResponse[0].centralSupport);
    this.form.get(['detailedConfiguration', 'catwalk', 'catwalkWidth']).patchValue(this.siloResponse[0].catwalkWidth);
    this.form.get(['detailedConfiguration', 'catwalk', 'handrailHeight']).patchValue(this.siloResponse[0].handrailHeight);
    
    this.form.get(['detailedConfiguration', 'catwalk', 'distanceBetweenVerticalSupports']).patchValue(this.siloResponse[0].distanceBetweenVerticalSupport);
    this.form.get(['detailedConfiguration', 'catwalk', 'weightOfChainConveyor']).patchValue(this.siloResponse[0].weightOfChainConveyor);
    this.form.get(['detailedConfiguration', 'catwalk', 'weightOfCatwalk']).patchValue(this.siloResponse[0].weightOfCatwalk);

    this.form.get(['detailedConfiguration', 'catwalk', 'catwalkOverload']).patchValue(this.siloResponse[0].catwalkOverload);
    this.form.get(['detailedConfiguration', 'catwalk', 'catwalkTotalLoad']).patchValue(this.siloResponse[0].catwalkTotalLoad);

    this.form.get(['detailedConfiguration', 'catwalk', 'weightOfCatwalkSupport']).patchValue(this.siloResponse[0].weightOfCatwalkSupport);
    this.form.get(['detailedConfiguration', 'catwalk', 'numberOfStiffenersPerSupport']).patchValue(this.siloResponse[0].stiffenersPerSupport);
    this.form.get(['detailedConfiguration', 'catwalk', 'catwalkStiffenersTotal']).patchValue(this.siloResponse[0].totalNumberOfCatwalkStiffeners);

    
    if(this.siloResponse[0].standardStiffeners == "AS CALCULATED"){
      this.form.get(['detailedConfiguration','catwalk', 'standardStiffeners']).patchValue(this.formDefaultValues.detailedConfiguration.catwalk.standardStiffeners);
    }
    else{
      this.form.get(['detailedConfiguration','catwalk', 'standardStiffeners']).patchValue(this.siloResponse[0].standardStiffeners);
    }
    
    if(this.siloResponse[0].catwalkStiffeners == "AS CALCULATED"){
      this.form.get(['detailedConfiguration','catwalk', 'catwalkStiffeners']).patchValue(this.formDefaultValues.detailedConfiguration.catwalk.catwalkStiffeners);
    }
    else{
      this.form.get(['detailedConfiguration','catwalk', 'catwalkStiffeners']).patchValue(this.siloResponse[0].catwalkStiffeners);
    }

  
    this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'includeAnchorBolts']).patchValue(this.siloResponse[0].includeAnchorBoltsInList);
    this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'type']).patchValue(this.siloResponse[0].anchorBoltsType);
    this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfBoltsPerPlate']).patchValue(this.siloResponse[0].boltsPerPlate);
    this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'includeShimPlatesInList']).patchValue(this.siloResponse[0].includeShimPlatesInList);
    
    this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates2mmPerPlate']).patchValue(this.siloResponse[0].shimPlates2mmPerPlate);
    this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates3mmPerPlate']).patchValue(this.siloResponse[0].shimPlates3mmPerPlate);
     
    this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'boltDiameter']).patchValue(this.siloResponse[0].boltDiammeter);
    this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'boltCode']).patchValue(this.siloResponse[0].boltCode);
    this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'basePlateCode']).patchValue(this.siloResponse[0].basePlateCode);
    this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfBoltsPerSilo']).patchValue(this.siloResponse[0].boltsPerSilo);
    this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates2mmPerSilo']).patchValue(this.siloResponse[0].shimPlates2mmPerSilo);
    this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates3mmPerSilo']).patchValue(this.siloResponse[0].shimPlates3mmPerSilo);
    this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates2mmCode']).patchValue(this.siloResponse[0].shimCode2mm);
    this.form.get(['detailedConfiguration', 'anchorBoltsAndPlates', 'numberOfShimPlates3mmCode']).patchValue(this.siloResponse[0].shimCode3mm);
    

    this.form.get(['detailedConfiguration', 'roof', 'roofAssemblyCode']).patchValue(this.siloResponse[0].roofAssemblyCode);
    this.form.get(['detailedConfiguration', 'roof', 'roofAssemblyDesign']).patchValue(this.siloResponse[0].roofDesign);
    this.form.get(['detailedConfiguration', 'roof', 'roofCoverCode']).patchValue(this.siloResponse[0].roofCoverCode);
    this.form.get(['detailedConfiguration', 'roof', 'roofStructureCode']).patchValue(this.siloResponse[0].roofStructureCode);

    if (!this.form.get(['detailedConfiguration','roof', 'numberOfLowerRoofVents']).value) {
      (<SimezaFormControl>this.form.get(['detailedConfiguration','roof', 'numberOfLowerRoofVents'])).initialValue = this.siloResponse[0].lowerRoofVentsNumber;
    }
    this.form.get(['detailedConfiguration','roof', 'numberOfLowerRoofVents']).patchValue(this.siloResponse[0].lowerRoofVentsNumber);

    this.form.get(['detailedConfiguration', 'roof', 'lowerRoofVentsType']).patchValue(this.siloResponse[0].typeOfRoofVent);
    this.form.get(['detailedConfiguration', 'roof', 'lowerRoofVentsCode']).patchValue(this.siloResponse[0].roofVentCode);
    
    this.form.get(['detailedConfiguration', 'roof', 'numberOfIntermediateRoofVents']).patchValue(this.siloResponse[0].intermediateRoofVentsNumber);
    this.form.get(['detailedConfiguration', 'roof', 'intermediateRoofVentsType']).patchValue(this.siloResponse[0].typeOfIntermediateRoofVent);
    this.form.get(['detailedConfiguration', 'roof', 'intermediateRoofVentsCode']).patchValue(this.siloResponse[0].intermediateRoofVentCode);


    if (!this.form.get(['detailedConfiguration','roof', 'numberOfUpperRoofVents']).value) {
      (<SimezaFormControl>this.form.get(['detailedConfiguration','roof', 'numberOfUpperRoofVents'])).initialValue = this.siloResponse[0].upperRoofVentsNumber;
    }
    this.form.get(['detailedConfiguration','roof', 'numberOfUpperRoofVents']).patchValue(this.siloResponse[0].upperRoofVentsNumber);

    this.form.get(['detailedConfiguration', 'roof', 'upperRoofVentsType']).patchValue(this.siloResponse[0].typeOfUpperRoofVent);
    this.form.get(['detailedConfiguration', 'roof', 'upperRoofVentsCode']).patchValue(this.siloResponse[0].upperRoofVentCode);
       
    this.form.get(['detailedConfiguration', 'roof', 'numberOfLowerSupports']).patchValue(this.siloResponse[0].lowerSupportsNumber);

    this.form.get(['detailedConfiguration', 'roof', 'lowerSupportsModel']).patchValue(this.siloResponse[0].lowerFanSupportModel);


    this.form.get(['detailedConfiguration', 'roof', 'numberOfIntermediateSupports']).patchValue(this.siloResponse[0].intermediateSupportsNumber);
    this.form.get(['detailedConfiguration', 'roof', 'intermediateSupportsCode']).patchValue(this.siloResponse[0].intermediateFanSupportCode);
    this.form.get(['detailedConfiguration', 'roof', 'intermediateSupportsModel']).patchValue(this.siloResponse[0].intermediateFanSupportModel);

 
    this.form.get(['detailedConfiguration', 'roof', 'numberOfUpperSupports']).patchValue(this.siloResponse[0].upperSupportsNumber);
  
    this.form.get(['detailedConfiguration', 'roof', 'upperSupportsCode']).patchValue(this.siloResponse[0].upperFanSupportCode);
    this.form.get(['detailedConfiguration', 'roof', 'upperSupportsModel']).patchValue(this.siloResponse[0].upperFanSupportModel);
    
    this.form.get(['detailedConfiguration', 'roof', 'numberOfHatches']).patchValue(this.siloResponse[0].hatchesNumber);
    this.form.get(['detailedConfiguration', 'roof', 'hatchCode']).patchValue(this.siloResponse[0].hatchCode);
    this.form.get(['detailedConfiguration', 'roof', 'roofInsulation']).patchValue(this.siloResponse[0].roofInsulation);
    this.form.get(['detailedConfiguration', 'roof', 'roofInsulationCode']).patchValue(this.siloResponse[0].roofInsulationCode);


    if(this.siloResponse[0].reinforcementType == "ADDITIONAL TUBE"){
      this.form.get(['detailedConfiguration','windRings', 'reinforcementType']).patchValue(this.formDefaultValues.detailedConfiguration.windRings.reinforcementType);
    }
    else{
      this.form.get(['detailedConfiguration','windRings', 'reinforcementType']).patchValue(this.siloResponse[0].reinforcingType);
    }


    if(this.siloResponse[0].galvanization == "Z6"){
      this.form.get(['detailedConfiguration','windRings', 'galvanizing']).patchValue(this.formDefaultValues.detailedConfiguration.windRings.galvanizing);
    }
    else{
      this.form.get(['detailedConfiguration','windRings', 'galvanizing']).patchValue(this.siloResponse[0].galvanization);
    }

    this.form.get(['detailedConfiguration','windRings', 'windRingsTotal']).patchValue(this.siloResponse[0].windRings);
    
    //tubeProfile

    //Inspection doors
    this.form.get(['detailedConfiguration','inspectionDoors', 'numberOfDoors']).patchValue(this.siloResponse[0].doors);
    this.form.get(['detailedConfiguration','inspectionDoors', 'lowestDoorType']).patchValue(this.siloResponse[0].lowestDoorType);
    this.form.get(['detailedConfiguration','inspectionDoors', 'lowestDoorCode']).patchValue(this.siloResponse[0].lowestDoorCode);
    this.form.get(['detailedConfiguration','inspectionDoors', 'lowestRing']).patchValue(this.siloResponse[0].lowestRing);

    //slope doors
    this.form.get(['detailedConfiguration','inspectionDoors', 'hasAdditionalSlopeDoor']).patchValue(this.siloResponse[0].additionalSlopeDoors);


    this.form.get(['detailedConfiguration','inspectionDoors', 'slopeDoorType']).patchValue(this.siloResponse[0].slopeDoorType);
    this.form.get(['detailedConfiguration','inspectionDoors', 'slopeDoorCode']).patchValue(this.siloResponse[0].slopeDoorCode);
    this.form.get(['detailedConfiguration','inspectionDoors', 'slopeRing']).patchValue(this.siloResponse[0].slopeRing);


    //additional doors
    this.form.get(['detailedConfiguration','inspectionDoors', 'hasAdditionalDoors']).patchValue(this.siloResponse[0].additionalDoors);



    this.form.get(['detailedConfiguration','inspectionDoors', 'numberOfAdditionalDoors']).patchValue(this.siloResponse[0].numberAdditionalDoors);


    this.form.get(['detailedConfiguration','inspectionDoors', 'additionalDoorRing']).patchValue(this.siloResponse[0].additionalDoorRing);
    this.form.get(['detailedConfiguration','inspectionDoors', 'additionalDoorType']).patchValue(this.siloResponse[0].additionalDoorType);
    this.form.get(['detailedConfiguration','inspectionDoors', 'additionalDoorCode']).patchValue(this.siloResponse[0].additionalDoorCode);




    this.form.get(['detailedConfiguration','inspectionDoors', 'additionalDoorRing2']).patchValue(this.siloResponse[0].additionalDoorRing2);
    this.form.get(['detailedConfiguration','inspectionDoors', 'additionalDoorType2']).patchValue(this.siloResponse[0].additionalDoorType2);
    this.form.get(['detailedConfiguration','inspectionDoors', 'additionalDoorCode2']).patchValue(this.siloResponse[0].additionalDoorCode2);





    this.form.get(['detailedConfiguration','inspectionDoors', 'additionalDoorRing3']).patchValue(this.siloResponse[0].additionalDoorRing3);
    this.form.get(['detailedConfiguration','inspectionDoors', 'additionalDoorType3']).patchValue(this.siloResponse[0].additionalDoorType3);
    this.form.get(['detailedConfiguration','inspectionDoors', 'additionalDoorCode3']).patchValue(this.siloResponse[0].additionalDoorCode3);


    this.form.get(['detailedConfiguration','inspectionDoors', 'areAdditionalDoorsUnified']).patchValue(this.siloResponse[0].unifiedDoors);

    //standard and load

    this.form.get(['designRequeriments','standardsAndLimitations', 'loadsAndPressures']).patchValue(this.siloResponse[0].loadsAndPresures);  
    this.form.get(['designRequeriments','standardsAndLimitations', 'resistance']).patchValue(this.siloResponse[0].resistance);  

    this.form.get(['designRequeriments','standardsAndLimitations', 'sheetPercentaje']).patchValue(this.siloResponse[0].sheetsWorkingFactor); 
    this.form.get(['designRequeriments','standardsAndLimitations', 'stiffenersPercentaje']).patchValue(this.siloResponse[0].stiffenersWorkingFactor); 
    
    //product loads
    this.form.get(['loads', 'productLoads', 'product']).patchValue(this.siloResponse[0].storedProduct); 
    this.form.get(['loads', 'productLoads', 'densityForCalculation']).patchValue(this.siloResponse[0].designDensity); 
    this.form.get(['loads', 'productLoads', 'productDensity']).patchValue(this.siloResponse[0].storedProductDensity); 
    this.form.get(['loads', 'productLoads', 'moistureContent']).patchValue(this.siloResponse[0].moistureContent); 
       

    this.form.get(['loads', 'productLoads', 'fillingDiameter']).patchValue(this.siloResponse[0].fillingDiameter); 
    this.form.get(['loads', 'productLoads', 'fillingEccentricity']).patchValue(this.siloResponse[0].fillingEccentricity); 
    this.form.get(['loads', 'productLoads', 'speedReducers']).patchValue(this.siloResponse[0].speedReducers); 
    this.form.get(['loads', 'productLoads', 'numberOfSpeedReducers']).patchValue(this.siloResponse[0].speedReducersNumber); 

    this.form.get(['loads', 'productLoads', 'typeOfDischarge']).patchValue(this.siloResponse[0].dischargeType); 
    this.form.get(['loads', 'productLoads', 'dischargeDiameter']).patchValue(this.siloResponse[0].dischargeDiameter); 
    this.form.get(['loads', 'productLoads', 'dischargeEccentricity']).patchValue(this.siloResponse[0].dischargeEccentricity); 
    this.form.get(['loads', 'productLoads', 'fillEmptyCiclesPerYear']).patchValue(this.siloResponse[0].fillEmptyCiclesPerYear); 

    this.form.get(['loads', 'productLoads', 'hasLateralDischarge']).patchValue(this.siloResponse[0].lateralDischarge); 

    this.form.get(['loads', 'productLoads', 'lateralDischargeRing']).patchValue(this.siloResponse[0].dischargeRing); 
    this.form.get(['loads', 'productLoads', 'lateralDischargeStandard']).patchValue(this.siloResponse[0].lateralDischargeStandard); 
  

    this.form.get(['loads', 'roofLoads', 'snow']).patchValue(this.siloResponse[0].snowLoad); 
    this.form.get(['loads', 'roofLoads', 'peakLoad']).patchValue(this.siloResponse[0].roofPeakLoadCapacity); 
    this.form.get(['loads', 'roofLoads', 'probeLoad']).patchValue(this.siloResponse[0].probeLoad); 
    this.form.get(['loads', 'roofLoads', 'totalNumberOfProbes']).patchValue(this.siloResponse[0].probesNumber); 

    this.form.get(['loads', 'windLoads', 'windSpeed']).patchValue(this.siloResponse[0].designWindVelocity); 
    this.form.get(['loads', 'windLoads', 'windPressure']).patchValue(this.siloResponse[0].windPreasure); 
    this.form.get(['loads', 'windLoads', 'typeOfInstallation']).patchValue(this.siloResponse[0].typeOfInstallation); 
    this.form.get(['loads', 'windLoads', 'globalPressureCoeficient']).patchValue(this.siloResponse[0].globalPressureCoefficient); 

    this.form.get(['loads', 'seismicLoads', 'basicAcceleration']).patchValue(this.siloResponse[0].seismicZone); 
    this.form.get(['loads', 'seismicLoads', 'importanceOfClass']).patchValue(this.siloResponse[0].importanceClass);
    this.form.get(['loads', 'seismicLoads', 'typeOfSoil']).patchValue(this.siloResponse[0].typeOfSoil);
    this.form.get(['loads', 'seismicLoads', 'beheaviourCoefficient']).patchValue(this.siloResponse[0].behaviourCoefficient);
    this.form.get(['loads', 'seismicLoads', 'designAcceleration']).patchValue(this.siloResponse[0].designAcceleration);
    this.form.get(['loads', 'seismicLoads', 'hasReinforcementOfHorizontalJoints']).patchValue(this.siloResponse[0].reinforcementOfHorizontalJoints);
    this.form.get(['loads', 'seismicLoads', 'hasSeismicEffectOnSheets']).patchValue(this.siloResponse[0].seismicEffectOnSheets);      

    this.form.get(['loads', 'specialLoads', 'numberOfCycles']).patchValue(this.siloResponse[0].fatigue); 
    this.form.get(['loads', 'specialLoads', 'overpressure']).patchValue(this.siloResponse[0].overpressure); 
    this.form.get(['loads', 'specialLoads', 'temperatureIncrement']).patchValue(this.siloResponse[0].temperatureIncrement); 

    this.form.get(['materials', 'numberOfRings']).patchValue(this.siloResponse[0].materialsNumberOfRings); 
    this.form.get(['materials', 'numberOfStiffeners']).patchValue(this.siloResponse[0].materialsNumberOfStiffeners); 
    this.form.get(['materials', 'numberOfSplices']).patchValue(this.siloResponse[0].materialsNumberOfSplices); 



    this.form.get(['materials', 'sheetsMaterials']).patchValue(this.siloResponse[0].wallSheetsGradeSteel); 

    this.form.get(['materials', 'sheetsFromRing']).patchValue(this.siloResponse[0].wallSheetsFromRing); 


    this.form.get(['materials', 'sheetsGalvanization']).patchValue(this.siloResponse[0].wallSheetsGalvanization);

    this.form.get(['materials', 'stiffenersMaterials']).patchValue(this.siloResponse[0].verticalStiffenersGradeSteel); 

    this.form.get(['materials', 'stiffenersFromRing']).patchValue(this.siloResponse[0].verticalStiffenersFromRing); 


    this.form.get(['materials', 'stiffenersGalvanization']).patchValue(this.siloResponse[0].verticalStiffenersGalvanization);

    this.form.get(['materials', 'splicesMaterials']).patchValue(this.siloResponse[0].materialJunctions); 

    this.form.get(['materials', 'splicesFromRing']).patchValue(this.siloResponse[0].materialJunctionsFromRing);



    this.form.get(['materials', 'roofMaterials']).patchValue(this.siloResponse[0].roofGradeSteel); 
    this.form.get(['materials', 'roofGalvanization']).patchValue(this.siloResponse[0].roofGalvanisation); 

    this.form.get(['materials', 'windRingsMaterials']).patchValue(this.siloResponse[0].windRingsMaterial); 

    this.form.get(['materials', 'ringBoltsMaterials']).patchValue(this.siloResponse[0].boltsGrade); 

    this.form.get(['materials', 'ringBoltsFromRing']).patchValue(this.siloResponse[0].boltsGradeFromRing); 


    this.form.get(['materials', 'ringBoltsGalvanization']).patchValue(this.siloResponse[0].boltsGalvanizationMethod); 

    this.form.get(['materials', 'roofBoltsMaterials']).patchValue(this.siloResponse[0].roofBoltsGrade); 
    this.form.get(['materials', 'roofBoltsGalvanization']).patchValue(this.siloResponse[0].roofBoltsGalvanization); 

    this.form.get(['materials', 'basePlatesMaterials']).patchValue(this.siloResponse[0].basePlatesMaterial); 
    this.form.get(['materials', 'anchorBoltsMaterials']).patchValue(this.siloResponse[0].anchorBoltsGrade);


    this.form.get(['materials', 'anchorBoltsFromRing']).patchValue(this.siloResponse[0].anchorBoltsType);
    this.form.get(['jobId']).patchValue(this.siloResponse[0].jobId);

    //console.log("job id in silo response:", this.siloResponse[0].jobId);

    this.siloResponse[0].inputForCalculation = this.form.getRawValue();
    this.siloResponse[0].inputSentToCIS = this.form.value;
    
    //console.log("form value before request selection update",this.form.value);

    this.cisService.initialConstitutionSelection(this.form.value).then(data => {
    
     // console.table(data.CalcConstitutionSelectionResult.constitutionSelectionRingsCalculated);
      this.siloConstitutionSelection.constitutionSelectionRingsStandard = data.CalcConstitutionSelectionResult.constitutionSelectionRingsStandard;
      this.siloConstitutionSelection.constitutionSelectionRingsCalculated = data.CalcConstitutionSelectionResult.constitutionSelectionRingsCalculated;

      let counter = 0;
      for(let ring of  data.CalcConstitutionSelectionResult.constitutionSelectionRingsCalculated){
        this.siloConstitutionSelection.constitutionSelectionRingsCalculated[counter][25]=  Number.parseFloat( ring[6] + ring[7] + ring[8]);
        counter++;
      }

      console.table(this.siloConstitutionSelection.constitutionSelectionRingsCalculated);

    });



    
  }

  siloConstitutionSelection: any = {
    constitutionSelectionRingsStandard: [],
    constitutionSelectionRingsCalculated: []
  }

  showStandardConstitutionSelection:boolean = false
  toggleStandardConstitutionSelection(){
    this.showStandardConstitutionSelection = !this.showStandardConstitutionSelection;
  }

  singleCalculation(){
    this.loadingSilo = true;
    this.siloResponse = [];

    this.cisService.calculate(this.form.value).then(data => {
    
      this.loadingSilo = false;
  
      this.siloResponse.push(data.CalcSingleSiloResult);

      if(data.CalcSingleSiloResult.hasError){
        return ;
      }
      
      this.loadCalcualtedDataInForm();    

    }).catch(error => {
      this.loadingSilo = false;
    });

  }

  singleCalculationSelection(){
    const selection = this.siloConstitutionSelection.constitutionSelectionRingsCalculated;
    const selectionArray = [];

    for(const ring of selection){
      const ringArray = [];
      ringArray.push(ring[1]); //wind ring
      ringArray.push(ring[5]); //steel type
      ringArray.push(ring[11]); //steel type
      ringArray.push(ring[12]); //stiffeners
      ringArray.push(ring[18]); //steel type
      ringArray.push(ring[19]); //stiffeners
      if(!_.isUndefined(ring[25])){
        ringArray.push(ring[25]); //total ticknes
      }
      else{
        ringArray.push(0); //total ticknes
      }
      

      selectionArray.push(ringArray);
    }

    this.siloConstitutionSelection.constitutionSelectionRingsCalculated =[];
  
    this.loadingSelection = true;

    this.cisService.calculateWithConstitutionSelection(this.form.value, selectionArray).then(data => {
    
      this.loadingSelection = false;
      this.siloResponse = [];
      this.siloResponse.push(data.CalcSingleSiloResult);

      if(data.CalcSingleSiloResult.hasError){
        return ;
      }
      
      this.loadCalcualtedDataInForm();   

    }).catch(error => {
      this.loadingSelection = false;
    });


    //console.table( selectionArray);
  }

  singleCalculationPartList(){
    this.downloadingPartList = true;


    this.cisService.calculatePartList(this.form.value)
      .map((res) => res.blob())
      .subscribe(data => {
        const blob = new Blob([data], { type: data.type });
        
        saveAs(blob, "PartList" + ".xlsx");
        this.downloadingPartList = false;
      },

      error =>  {
       
          this.downloadingPartList = false;
       
          //this.proforma.error = true;
        }
      )
  }

  runnerCalculationPartlist(silo:any){
    this.downloadingPartList = true;



    this.cisService.downloadPartList(silo.input)
      .map((res) => res.blob())
      .subscribe(data => {
        const blob = new Blob([data], { type: data.type });
        
        saveAs(blob, "PartList" + ".xlsx");
        this.downloadingPartList = false;
      },

      error =>  {
       
          this.downloadingPartList = false;
       
          //this.proforma.error = true;
        }
      )
  }

  downloadPartList(silo:any){
    if(_.has(silo, 'input')){
      this.runnerCalculationPartlist(silo);
    }
    else{
      this.singleCalculationPartList();
    }
  }

 constitutionsVariants = [];
 constituionsIndex = 0;

 runnerOn = false;
 openRunnerModal() {
   
    const modalRef = this.modalService.open(RunnerModalComponent, {  size: 'lg', windowClass: 'runner-modal-window' });
    
    modalRef.componentInstance.resultRunner.subscribe((simpleInputRunner: any) => {
      

      modalRef.close();
     
      this.siloResponse  = [];
      this.constitutionsVariants = simpleInputRunner;
      this.constituionsIndex= 0;
      
      this.runnerOn = true;
      this.playRunner();



    });

 
  }

  runnerEndOk = false;
  calcRunner(){

    this.loadingSilo = true;
    

    if(this.constituionsIndex == (this.constitutionsVariants.length)){
      this.runnerEndOk = true;
      this.runnerOn = false;
      this.loadingSilo = false;
      this.stopRunner();
    }
    else{
      this.cisService.calculateRunner(this.constitutionsVariants[this.constituionsIndex]).then(data => {
      
        this.loadingSilo = false;

        data.CalcSimplifiedSingleSiloResult["input"] =  this.constitutionsVariants[this.constituionsIndex];
      
        this.siloResponse.push(data.CalcSimplifiedSingleSiloResult);
        this.constituionsIndex++;
        
        if(this.runnerPlay){
          this.calcRunner();
  
        }
    
    
  
      }).catch(error => {
 
        this.loadingSilo = false;
        this.runnerOn = false;
      });
    }


  }

  runnerPlay = false;
  stopRunner(){
    this.runnerPlay = false;

  }

  playRunner(){
    this.runnerPlay = true;
    
    this.calcRunner();
  }

  

  trackByFn(index, item) {
    return index;
  }


  isSiloSaved(){
      return _.has(this.siloResponse[0], "saved") &&  this.siloResponse[0]["saved"];
  }

  ngOnDestroy() {
    this.densityForCalculationSubscription.unsubscribe();
    this.lowestRingSubscription.unsubscribe();
    this.cisService.inputFromOffer = undefined;
  }

  showWarningBox(){
   return  this.siloResponse.length > 0 && (this.siloResponse[0].error1!="" || this.siloResponse[0].error1!="" || this.siloResponse[0].error1!="" );
  }


  openSaveRecalculatedSiloModal(){

    const modal = this.modalService.open(SaveSiloConfirmationModalComponent, {
      backdrop: 'static',
      keyboard: false,
      // size: 'sm',
      windowClass: 'rmv-modal',
    });

    modal.componentInstance.silo = this.siloResponse[0];
    

    modal.result.then((result) => {
      if (result === 'Accept') {
        this.saveRecalculatedSilo(this.cisService.isInputFromOfferAvailable());
  
      } else { }
    });

    
  }


  savingSilo: boolean = false;
  saveRecalculatedSilo(returnBackToOffer: boolean){

    this.savingSilo = true;
    this.cisService.createCalculatedSilo(this.siloResponse[0]).then(recalculatedSilo => {
      
      this.savingSilo = false;
    
      this.siloResponse[0]["saved"] = true;
      this.siloResponse[0]["progressiveNumber"] = recalculatedSilo.progressiveNumber;
      this.siloResponse[0]["aggregationCode"] = recalculatedSilo.aggregationCode;
      //console.log("ksilo", recalculatedSilo);
      if(returnBackToOffer){

        this.openConfirmationSaveSiloOfferModal(recalculatedSilo);

      }
     
    }).catch(error => {

      this.savingSilo = false;

    });
  }

  openConfirmationSaveSiloOfferModal(recalculatedSilo: any) {
    const modal = this.modalService.open(SaveSiloOfferConfirmationModalComponent, {
      backdrop: 'static',
      keyboard: false,
      // size: 'sm',
      windowClass: 'rmv-modal',
    });
    modal.componentInstance.offerTitle = this.cisService.inputFromOffer.offerTitle;

    modal.componentInstance.silo =recalculatedSilo;
    modal.componentInstance.numberOfSilos =  this.form.get(['projectData','numberOfSilosStandard']).value;
    

    modal.result.then((result) => {
      if (result === 'Accept') {
        
        const inputFromCis = {
          attachSiloToOffer: true,
          recalculatedSilo: recalculatedSilo,
          silosQuantity: this.form.get(['projectData','numberOfSilosStandard']).value,
          originalOfferSilo: this.cisService.inputFromOffer.offerSilo
        }

        this.cisService.goToOffer(inputFromCis);
  
      } else { }
    });
  }

  openReturnToOfferModal(){
    const modal = this.modalService.open(UnSaveConfModalComponent, {
      backdrop: 'static',
      keyboard: false,
      // size: 'sm',
      windowClass: 'rmv-modal',
    });
    modal.componentInstance.offerTitle = this.cisService.inputFromOffer.offerTitle;

    modal.result.then((result) => {
      if (result === 'Accept') {

        const inputFromCis = undefined;
        this.cisService.goToOffer(inputFromCis);

      } else { }
    });

    
  }

}
