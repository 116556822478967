import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Injectable()
export class AuthenticateService {
  constructor(private router: Router, private location: Location) { }
  checkAuthentication(): boolean {
    let savedToken;
    savedToken = JSON.parse(localStorage.getItem('authentication'));
    if (!!savedToken && !!savedToken.access_token) {
      return true;
    }
    return false;
  }

  logout(): void {
    localStorage.removeItem('authentication');
   // this.router.navigate(['/login']);
    location.reload();
  }
}
