import { ComponentKeyValue } from '../data-types/components-relation';

export const StiffenersTypes: ComponentKeyValue[] = [
    {
        key: 'V250-1.5a',
        value: 'V250-1.5a'
    },
    {
        key: 'V250-1.5ab',
        value: 'V250-1.5ab'
    },
    {
        key: 'V250-1.5b',
        value: 'V250-1.5b'
    },
    {
        key: 'V250-1.5-Base',
        value: 'V250-1.5-Base'
    },
    {
        key: 'V250-2.0a',
        value: 'V250-2.0a'
    },
    {
        key: 'V250-2.0ab',
        value: 'V250-2.0ab'
    },
    {
        key: 'V250-2.0b',
        value: 'V250-2.0b'
    },
    {
        key: 'V250-2.0-Base',
        value: 'V250-2.0-Base'
    },
    {
        key: 'V250-3.0a',
        value: 'V250-3.0a'
    },
    {
        key: 'V250-3.0ab',
        value: 'V250-3.0ab'
    },
    {
        key: 'V250-3.0b',
        value: 'V250-3.0b'
    },
    {
        key: 'V250-3.0-Base',
        value: 'V250-3.0-Base'
    },
    {
        key: 'V250-3.5a',
        value: 'V250-3.5a'
    },
    {
        key: 'V250-3.5ab',
        value: 'V250-3.5ab'
    },
    {
        key: 'V250-3.5b',
        value: 'V250-3.5b'
    },
    {
        key: 'V250-3.5-Base',
        value: 'V250-3.5-Base'
    },
    {
        key: 'V250-4.0a',
        value: 'V250-4.0a'
    },
    {
        key: 'V250-4.0ab',
        value: 'V250-4.0ab'
    },
    {
        key: 'V250-4.0b',
        value: 'V250-4.0b'
    },
    {
        key: 'V250-4.0-Base',
        value: 'V250-4.0-Base'
    },
    {
        key: 'V330-4.0b',
        value: 'V330-4.0b'
    },
    {
        key: 'V330-4.0-Base',
        value: 'V330-4.0-Base'
    },
    {
        key: 'V330-6.0a',
        value: 'V330-6.0a'
    },
    {
        key: 'V330-6.0-Base',
        value: 'V330-6.0-Base'
    },
    {
        key: 'V330-7.0a',
        value: 'V330-7.0a'
    },
    {
        key: 'V330-7.0-Base',
        value: 'V330-7.0-Base'
    },
    {
        key: 'V330-8.0a',
        value: 'V330-8.0a'
    },
    {
        key: 'V330-8.0-Base',
        value: 'V330-8.0-Base'
    },
    {
        key: 'V330-10.0a',
        value: 'V330-10.0a'
    },
    {
        key: 'V330-10.0-Base',
        value: 'V330-10.0-Base'
    },
    {
        key: 'V330-11.0a',
        value: 'V330-11.0a'
    },
    {
        key: 'V330-11.0-Base',
        value: 'V330-11.0-Base'
    },
    {
        key: 'V330-12.0a',
        value: 'V330-12.0a'
    }
    ,
    {
        key: 'V330-12.0-Base',
        value: 'V330-12.0-Base'
    }

];