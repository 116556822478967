import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { Company } from '../company';
import { CompanyService } from '../company.service';
import { MiscService } from '../../../shared/misc.service';
import { NgForm, EmailValidator, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { CityService } from '../../../shared/city.service';
import { NgbActiveModal, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { badInputCityValidator } from '../../../shared/bad-input-city.directive';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';


@Component({
  selector: 'app-create-company-modal',
  templateUrl: './create-company-modal.component.html',
  styleUrls: ['./create-company.component.scss']
})
export class CreateCompanyModalComponent implements OnInit {

  @Output() newCompany = new EventEmitter();

	@ViewChild('newCompanyForm')
  @ViewChild('tabsCompany')  

  private tabsCompany:NgbTabset;

  public newCompanyForm: NgForm;
  public newCompanyForm2: NgForm;
  public newCompanyForm3: NgForm;

  public quickCustomerForm: FormGroup;

  public companyGroups: any[];
  public cityPlaceholder = 'Utebo';
  public selectedCountry: any;

  @Input() newCompanyData = {
    name: null,
    address: null,
    group: null,
    office_city: null,
    contact_name: null,
    contact_last_name: null,
    contact_email: null,
    contact_phone: null,
    zip: null,
    vat: null,
    idNavision: null
  };



  constructor(
  	private router: Router,
    private companyService: CompanyService,
    private cityService: CityService,
    private miscService: MiscService,
    public activeModal: NgbActiveModal,
    private translate: TranslateService

  ) { 

       this.getCompanyGroups();
  }

  ngOnInit(): void {
   
    this.quickCustomerForm = new FormGroup({
      
      'name': new FormControl(this.newCompanyData.name,Validators.required),
      'vat': new FormControl(this.newCompanyData.vat,Validators.required),
      'address': new FormControl(this.newCompanyData.address, Validators.required),
      'zip': new FormControl(this.newCompanyData.zip, Validators.required),
      'group': new FormControl(this.newCompanyData.group, Validators.required),
      'country': new FormControl(this.selectedCountry, [
        Validators.required,
        badInputCityValidator()
        ]),
      'office_city': new FormControl(this.newCompanyData.office_city, [
        Validators.required,
        badInputCityValidator()
      ]),
      'contact_name': new FormControl(this.newCompanyData.contact_name, Validators.required),
      'contact_last_name': new FormControl(this.newCompanyData.contact_last_name, Validators.required),
      'contact_email': new FormControl(this.newCompanyData.contact_email, Validators.required),
      'contact_phone': new FormControl(this.newCompanyData.contact_phone, Validators.required),
     
    });
  }

  get name() { return this.quickCustomerForm.get('name'); }
  
  get vat() { return this.quickCustomerForm.get('vat'); }

  get address() { return this.quickCustomerForm.get('address'); }

  get zip() { return this.quickCustomerForm.get('zip'); }
  
  get group() { return this.quickCustomerForm.get('group'); }
  
  set group(defualtGroup) { this.quickCustomerForm.patchValue({group: defualtGroup}); }
  
  get country() { return this.quickCustomerForm.get('country'); }
  
  get office_city() { return this.quickCustomerForm.get('office_city'); }
  
  get contact_name() { return this.quickCustomerForm.get('contact_name'); }
  
  get contact_last_name() { return this.quickCustomerForm.get('contact_last_name'); }
  
  get contact_email() { return this.quickCustomerForm.get('contact_email'); }

  get contact_phone() { return this.quickCustomerForm.get('contact_phone'); }
  
  getCompanyGroups(): any {
    this.miscService.getCompanyGroups()
      .then(companyGroups => {
        this.companyGroups = companyGroups;
        this.group = this.companyGroups[0];
      });
  }  
  onSubmit() {
   

  }


  searchCountry = (text$: Observable<string>) =>
    text$
      .debounceTime(300)
      .distinctUntilChanged()
      .switchMap(term => {
        return term.length <= 2 ? [] : this.cityService.searchCountries(term)
          .catch(() => Observable.of([]));
  })

  formatterCountry = (x: {name: string}) => x.name;

  searchSite = (text$: Observable<string>) =>
    text$
      .debounceTime(300)
      .distinctUntilChanged()
      .switchMap(term => {
       
        return Observable.fromPromise(this.cityService.getCitiesByCountry(this.country.value.id, term));
      })
      .catch(() => Observable.of([]));

  formatterSiteOffice = (x: { nameUtf8: string, state: { nameUtf8: string } }) => { return x.nameUtf8 + (x.state ? ', ' + x.state.nameUtf8 : '') };

  selectedCountryTrigger($country){

    this.cityPlaceholder = $country.item.capital;

  }

  closeModal(){
    this.activeModal.close();
  }

  onSubmitStep1(){
   
     this.switchTab(2);
  }

  onSubmitStep2(){

    this.switchTab(3);
  }

  creatingCustomer: boolean = false;
  errorCompanyVAT: string = "";
  onSubmitStep3(){
    this.creatingCustomer = true;
    this.companyService.createCompanySpec(this.quickCustomerForm.value)
      .then(company => {
        this.newCompanyData = {name: null, 
                               address: null, 
                               group: null,
                               office_city: null,
                               contact_name: null,
                               contact_last_name: null,
                               contact_email: null,
                               contact_phone: null,
                               zip: null,
                               vat: null,
                               idNavision: null
                               };

        this.creatingCustomer = false;
        this.errorCompanyVAT="";
        this.newCompany.emit(company);
      }).catch(error=> {
        this.creatingCustomer = false;

        console.log("error", error);

        const errorObject = JSON.parse(error._body);
    
        if(errorObject.error.code === 515){
          this.vat.reset();
        
          this.vat.markAsDirty();
          this.vat.markAsPending();
          this.errorCompanyVAT = "CIF debe ser Único";
          this.switchTab(1);
        }



      });
  }

  back(step){
    this.switchTab(step);
  }

  switchTab(tabNumber){
    this.tabsCompany.select('tab'+tabNumber);
  }


}
