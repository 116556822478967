/**
 * Created by juan on 27/06/17.
 */

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'myOfferAdvancedFilter',
  pure: false
})
export class OfferAdvancedFilter implements PipeTransform {
  transform(items: any[], filter: any): any {
    if (!items || !filter) {
      return items;
    }

    return items.filter(item => {
      const notMatchingField = Object.keys(filter)
        .find(key => {
            if (filter[key]) {
              return item[key] !== filter[key];
            }
            return false;
          });

      return !notMatchingField;
    });
  }
}
