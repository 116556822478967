import { ComponentKeyValue } from '../data-types/components-relation';

export const WindRingsTypes: ComponentKeyValue[] = [
    {
        key: '',
        value: ''
    },
    
    {
        key: '1 1/2"',
        value: '1 1/2"'
    },
    {
        key: '2"',
        value: '2"'
    },
    {
        key: '2 1/2"',
        value: '2 1/2"'
    },
    {
        key: '3"',
        value: '3"'
    },
    {
        key: '3 1/2"',
        value: '3 1/2"'
    },
    {
        key: '4"',
        value: '4"'
    },
    {
        key: '5"',
        value: '5"'
    }

];