import { Injectable } from '@angular/core';
import {Http} from '@angular/http';

const endpoint = '/cities';

@Injectable()
export class CityService {
	constructor(
		private http: Http
	) { }

	getCities(query?: string): Promise<any[]> {
			const q = query ? '?fuzzySearch=' + query : '';
			return this.http.get(endpoint + q)
      .toPromise()
      .then(cities => {  return cities.json(); })
      .catch(this.handleError);
	}

	getCitiesByCountry(country: number, query: string): Promise<any[]> {

		return this.http.get(endpoint + '?country=' + country + '&name=' + query)
			.toPromise()
			.then(cities => cities.json())
			.catch(this.handleError);
	}

	getCity(id): Promise<any> {
		return this.http.get(endpoint + '/' + id)
			.toPromise()
			.then(city => city.json())
			.catch(this.handleError);
	}

	searchCountries(query: string): Promise<any[]> {
		return this.http.get('/countries?name=' + query)
			.toPromise()
			.then(countries => countries.json())
			.catch(this.handleError);
	}

	private handleError(error: any): Promise<any> {
  
    return Promise.reject(error.message || error);
  }

}