/**
 * Created by juan on 21/06/17.
 */

import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/toPromise';
import {LoginService} from '../../login/login.service';
import * as _ from 'lodash';

import {IndexComponent} from '../../../dashboard/index/index.component';
import {CompanyDetailComponent} from '../../company/company-detail/company-detail.component';
import {EditCompanyComponent} from '../../company/edit-company/edit-company.component';
import {EditOfficeComponent} from '../../company/office/edit-office/edit-office.component';
import {CreateOfficeComponent} from '../../company/office/create-office/create-office.component';
import {PersonComponent} from '../../person/person.component';
import {PersonDetailComponent} from '../../person/person-detail/person-detail.component';
import {EditPersonComponent} from '../../person/edit-person/edit-person.component';
import {GroupComponent} from '../group.component';
import {EditGroupComponent} from '../edit-group/edit-group.component';
import {UserComponent} from '../../user/user.component';
import {User} from '../../user/user';
import {CompanyComponent} from '../../company/company.component';
import {UserDetailComponent} from '../../user/user-detail/user-detail.component';
import {EditUserComponent} from '../../user/edit-user/edit-user.component';

import {EditOfferComponent} from '../../../pss/offer/edit-offer/edit-offer.component';
import {ListOffersComponent} from '../../../pss/offer/list-offers/list-offers.component';
import {ConstitutionComponent} from '../../../scs/constitution/constitution.component';
import {StatisticsComponent} from '../../../mts/statistics/statistics.component';
import { CisComponent } from 'app/scs/cis/cis.component';


@Injectable()
export class GroupGuardService {
  constructor(private loginService: LoginService) {}

  canActivateRoute(component: any): Promise<any> {
    switch (component) {

      case EditCompanyComponent:
          return this.getUserData()
          .then(data => data.user.group.MTSGroupPermission.readCompany);
      case PersonComponent:
        return this.getUserData()
          .then(data => data.user.group.MTSGroupPermission.readPerson);
      case CompanyComponent:
        return this.getUserData()
          .then(data => data.user.group.MTSGroupPermission.readCompany);
      case ConstitutionComponent:
        return this.getUserData()
          .then(data => data.user.group.SCSGroupPermission.readConstitutions);
      case EditGroupComponent:
        return this.getUserData()
          .then(data => data.user.group.MTSGroupPermission.updateGroup);
      case UserComponent:
        return this.getUserData()
          .then(data => {
            return (User.hasRole(data.user, 'INTERNAL_ADMIN') || User.hasRole(data.user, 'SUPER_ADMIN'))

          });
      case StatisticsComponent:
        return this.getUserData()
        .then(data => {
          //recalcualteSilo
          return (User.hasRole(data.user, 'INTERNAL_ADMIN') || User.hasRole(data.user, 'SUPER_ADMIN') || data.PSSUserPermission.readStatistics)

        });
      case CisComponent:
        return this.getUserData()
          .then(data => {

            return User.hasRole(data.user, 'INTERNAL_ADMIN') || User.hasRole(data.user, 'SUPER_ADMIN') || data.PSSUserPermission.readCIS;
            //return data.PSSUserPermission.readCIS;

          });
      case UserDetailComponent:
        return this.getUserData()
          .then(data => data.user.group.MTSGroupPermission.readUser);
      case EditUserComponent:
        return this.getUserData()
          .then(data => data.user.group.MTSGroupPermission.updateUser);
      case ListOffersComponent:
         return this.getUserData()
          .then(data => {
            console.log("data",data);
            return data.user.group.PSSGroupPermission.readOffers;
          });
      case EditOfferComponent:

          return this.getUserData()
          .then(data => {
            return User.hasRole(data.user, 'INTERNAL_ADMIN') || User.hasRole(data.user,'EXTERNAL_AGENT') || User.hasRole(data.user,'INTERNAL_AGENT');
          });
      default:
        return this.getUserData()
          .then(data => true);
    }
  }

  getUserData(): Promise<any> {
    let userData: any;

    if (this.loginService.checkUser()) {
      return this.loginService.setUser()
        .then(user => { userData = user; return userData; });
    } else {
      userData = this.loginService.getUser();

      return new Promise(resolve => resolve(userData));
    }
  }

}
