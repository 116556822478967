import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import {AuthenticateService} from './authenticate.service';

@Injectable()
export class AuthenticateGuard implements CanActivateChild {

  constructor (private router: Router, private authService: AuthenticateService) { }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.checkAuthentication()) {
      return true;
    }
    this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
   
    return false;
  }

  checkAuthentication(): boolean {
    if (this.authService.checkAuthentication()) { return true; }

    return false;
  }
}
