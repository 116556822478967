import { Injectable } from '@angular/core';
import {Http, ResponseContentType} from '@angular/http';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Injectable()
export class MiscService {
	constructor(
    private http: Http
  ) { }

  getBanks(): Promise<any> {
  	return this.http.get('/bankdetails')
  		.toPromise()
  		.then(banks => banks.json())
  		.catch(this.handleError);
  }

  getExclusions(): Promise<any> {
    return this.http.get('/exclusions')
      .toPromise()
      .then(exclusions => exclusions.json())
      .catch(this.handleError);
  }

  getTypesOfSupply(): Promise<any> {
    return this.http.get('/typesofsupply')
      .toPromise()
      .then(typesOfSupply => typesOfSupply.json())
      .catch(this.handleError);
  }

  getCurrencies(): Promise<any> {
    return this.http.get('/currencies')
      .toPromise()
      .then(currencies => currencies.json())
      .catch(this.handleError);
  }

  getTermsOfDelivery(): Promise<any> {
    return this.http.get('/termsofdelivery')
      .toPromise()
      .then(terms => terms.json())
      .catch(this.handleError);
  }

  getPaymentTerms(): Promise<any> {
    return this.http.get('/paymentterms')
      .toPromise()
      .then(terms => terms.json())
      .catch(this.handleError);
  }

  getCompanyGroups(): Promise<any>{
    return this.http.get('/companygroups')
      .toPromise()
      .then(companyGroups => companyGroups.json())
      .catch(this.handleError);
  }

  downloadProforma(url) {
    return this.http.get(url, { responseType: ResponseContentType.Blob });
  }

  private handleError(error: any): Promise<any> {
  
    return Promise.reject(error.message || error);
  }

  //non web method
  getTranslationSteps(object,locale){


    let hasTranslation = object.translations.some(function(objectTranslation){

    
      return objectTranslation.locale === locale;

    });

    if(hasTranslation)
    {
      return this.findByMatchingProperties(object.translations,{"locale": locale})[0];
    }

    return null;

  
   
  }


  findByMatchingProperties(set, properties) {
    return set.filter(function (entry) {
        return Object.keys(properties).every(function (key) {
            return entry[key] === properties[key];
        });
    });
  }


}