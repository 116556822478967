import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/toPromise';
import {Person} from './person';

const endpoint = '/persons';

@Injectable()
export class PersonService {

  constructor(private http: Http) { }

  createPerson(person: Person): Promise<Person> {
    return this.http.post(endpoint, person)
      .toPromise()
      .then(response => response.json() as Person)
      .catch(this.handleError);
  }

  getPersons(query?: string): Promise<Person[]> {
    const q = query ? '?nameSurname=' + query : '';
    return this.http.get(endpoint + q)
      .toPromise()
      .then(persons => persons.json() as Person[])
      .catch(this.handleError);
  }

  getPersonById(id: number): Promise<Person> {
    return this.http.get(endpoint + '/' + id)
      .toPromise()
      .then(person => person.json() as Person)
      .catch(this.handleError);
  }

  updatePerson(person: Person): Promise<Response> {
   
    return this.http.put(endpoint + '/' + person.id, person)
      .toPromise()
      .then(response => response.json())
      .catch(this.handleError);
  }

  deletePerson(id: number): Promise<Response> {
    return this.http.delete(endpoint + '/' + id)
      .toPromise()
      .then(response => response.json())
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    
    return Promise.reject(error.message || error);
  }

}
