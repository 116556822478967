import { Component, OnInit } from '@angular/core';
import { ErrorBoxService } from './error-box.service';

@Component({
  selector: 'app-error-box',
  templateUrl: './error-box.component.html',
  styleUrls: ['./error-box.component.scss']
})
export class ErrorBoxComponent implements OnInit {

  message: any;

  constructor(private errorBoxService: ErrorBoxService) { }

  ngOnInit() {
    this.errorBoxService.getMessage().subscribe(message => {
      this.message = message;

      if (message && this.message.type === 'success') {
        let box = this;
        setTimeout(function() {
          box.close();
        }, 5000)
      }

      
      if (message && this.message.type === 'error') {
        let box = this;
        setTimeout(function() {
          box.close();
        }, 10000)
      }        

    });
  }

  close() {
    this.message = null;
  }
}
