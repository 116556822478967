import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticateGuard } from './mts/authenticate/authenticate.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'login',
        loadChildren: './mts/login/login.module#LoginModule',
      },
    ]
  },
  {
    path: '',
    canActivateChild: [AuthenticateGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
      },
      {
        path: '404',
        loadChildren: './not-found/not-found.module#NotFoundModule'
      },
      {
        path: '**',
        redirectTo: '404'
      }
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
})
export class AppRoutingModule {}
