import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';
import * as _ from 'lodash';

/** A hero's name can't match the given regular expression */
export function badInputCityValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    const isCityObject = _.isObject(control.value);
   
    return !isCityObject ? {'badInputCity': {value: control.value}} : null;
  };
}

@Directive({
  selector: '[appForbiddenName]',
  providers: [{provide: NG_VALIDATORS, useExisting: BadInputCityDirective, multi: true}]
})
export class BadInputCityDirective implements Validator {
  @Input() badInputCity: any;

  validate(control: AbstractControl): {[key: string]: any} {
    return this.badInputCity ? badInputCityValidator()(control)
                              : null;
  }
}