import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import {Group} from './group';
import 'rxjs/add/operator/toPromise';

const endpoint = '/groups';

@Injectable()
export class GroupService {

  constructor(private http: Http) { }

  createGroup(group: Group): Promise<Group> {
    return this.http.post(endpoint + '/new?', group)
      .toPromise()
      .then(response => response.json() as Group)
      .catch(this.handleError);
  }

  getGroups(): Promise<Group[]> {
    return this.http.get(endpoint)
      .toPromise()
      .then(groups => groups.json() as Group[])
      .catch(this.handleError);
  }

  getGroupById(id: number): Promise<Group> {
    return this.http.get(endpoint + '/' + id)
      .toPromise()
      .then(group => group.json() as Group)
      .catch(this.handleError);
  }

  updateGroup(group: Group): Promise<Response> {
    return this.http.put(endpoint + '/' + group.id + '/edit', group)
      .toPromise()
      .then(response => response.json())
      .catch(this.handleError);
  }

  deleteGroup(id: number): Promise<Response> {
    return this.http.delete(endpoint + '/' + id)
      .toPromise()
      .then(response => response.json())
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
}
