import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MTSComponent } from './mts.component';
import {AuthenticateService} from './authenticate/authenticate.service';
import {AuthenticateGuard} from './authenticate/authenticate.guard';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [MTSComponent],
  providers: [AuthenticateService, AuthenticateGuard]
})
export class MTSModule { }
