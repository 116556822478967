import {Injectable} from '@angular/core';
import { FormBuilder, ValidatorFn, AsyncValidatorFn, FormGroup, AbstractControl, FormControl, FormArray } from '@angular/forms';
import { SimezaFormControl } from './simeza-form-control';
import { AbstractControlOptions } from '@angular/forms/src/model';

@Injectable()
export class SimezaFormBuilder extends FormBuilder {
    control(formState: any, validatorOrOpts?: ValidatorFn | AbstractControlOptions | ValidatorFn[],
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]): SimezaFormControl {
            return new SimezaFormControl(formState, validatorOrOpts, asyncValidator);
        }
}
