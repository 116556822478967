import {Component, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import {NgForm} from '@angular/forms';
import {DelConfModalComponent} from '../../../shared/delete-confirmation-modal.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs/Observable';

import {LoginService} from '../../../mts/login/login.service';
import {ConstitutionService} from '../../constitution/constitution.service';
import { SimezaFormBuilder } from 'app/shared/simeza-form-builder';
import { CISService } from '../cis.service';
import { count } from 'rxjs/operator/count';

@Component({
  selector: 'app-runner-modal',
  templateUrl: './runner-modal.component.html',
  styleUrls: ['./runner-modal.component.scss']
})
export class RunnerModalComponent implements OnInit {

    siloModels = [
        
        
        ];

    wallRings = [

    ]; 

    sheetsRing = [

    ]; 

    stiffenersSheets= [

    ];

    siloStandards = [

    ];

    snowLoads = [

    ];

    seismics = [

    ];


    formDefaultValues = {
        siloModel: 'FBS',
        numberOfSilos: 1,
        wallRings: 'All',
        sheetsRing: 'All',
        stiffenersSheets: 'All',
        siloStandards: 'All',
        snowLoads: 'All',
        seismics: 'All'
    };

    form = this.fb.group({
        
        siloType: [this.formDefaultValues.siloModel],
        numberOfSilos:  [this.formDefaultValues.numberOfSilos],
        numberOfRings: [this.formDefaultValues.wallRings],
        numberSheetsPerRing: [this.formDefaultValues.sheetsRing],
        numberStiffenersPerSheet: [this.formDefaultValues.stiffenersSheets],
        loadsAndPressures: [this.formDefaultValues.siloStandards],
        snow: [this.formDefaultValues.snowLoads],
        basicAcceleration: [this.formDefaultValues.seismics]
    });

    @Output() resultRunner = new EventEmitter();

    constructor(
        private activeModal: NgbActiveModal,
        private loginService: LoginService,
        private siloService: ConstitutionService,
        private fb: SimezaFormBuilder,
        private cisService: CISService
        ) { }

    ngOnInit() {

        this.siloModels = ["FBS"];
        this.wallRings = this.siloService.getWallRings("FBS", true);
        this.sheetsRing  = this.siloService.getSheetsRingRunner("FBS", true);
        this.stiffenersSheets = this.siloService.getStiffenersSheets("FBS", true);
        this.siloStandards = this.siloService.getStandardsRunner();
        this.snowLoads = this.siloService.getSnowLoads(true);
        this.seismics =  this.siloService.getSeismics(true);
    }



    runConstitutions(){
        let calculationParameters = [];
        for (const property in this.form.value){
            if(this.form.value[property] == 'All'){
                switch(property){
                    case "numberOfRings":
                        calculationParameters.push({'numberOfRings': this.siloService.getWallRings("FBS", true, true)});
                    break;
                    case "numberSheetsPerRing":
                        calculationParameters.push({'numberSheetsPerRing': this.siloService.getSheetsRingRunner("FBS", true, true)});
                    break;
                    case "numberStiffenersPerSheet":
                        calculationParameters.push({'numberStiffenersPerSheet': this.siloService.getStiffenersSheets("FBS", true, true)});
                    break;
                    case "loadsAndPressures":
                        calculationParameters.push({'loadsAndPressures': this.siloService.getStandardsRunner(true)});
                    break;
                    case "snow":
                        calculationParameters.push({'snow': this.siloService.getSnowLoads(true, true)});
                    break;
                    case "basicAcceleration":
                        calculationParameters.push({'basicAcceleration': this.siloService.getSeismics(true, true)});
                    break;
                }
            }
            else{
                const obj = {};
                obj[property] = [this.form.value[property]] ;
                calculationParameters.push(obj) ;
            }
        }

        const result = this.cartesianProduct(calculationParameters);
   
        this.resultRunner.emit(result);

    }

    cartesianProduct(input, current?) {
        if (!input || !input.length) { return []; }
     
        var head = input[0];
        var tail = input.slice(1);
        var output = [];
     
         for (var key in head) {
           for (var i = 0; i < head[key].length; i++) {
                 var newCurrent = this.copy(current);         
                 newCurrent[key] = head[key][i];
                 if (tail.length) {
                      var productOfTail = 
                              this.cartesianProduct(tail, newCurrent);
                      output = output.concat(productOfTail);
                 } else output.push(newCurrent);
            }
          }    
         return output;
     }
     
    copy(obj) {
       var res = {};
       for (var p in obj) res[p] = obj[p];
       return res;
     }

    closeModal(){
        this.activeModal.close();
    }

}
