import { Injectable } from '@angular/core';
import {Http, ResponseContentType} from '@angular/http';

import { ErrorBoxService } from '../../shared/error-box/error-box.service';
import { LoginService } from '../../mts/login/login.service';


const endpoint = '/reports';

@Injectable()
export class StatisticsService {

  userData: any;

  constructor(
    private http: Http,
    private loginService: LoginService,
    private errorBox: ErrorBoxService
  ) {
    loginService.getUser()
      .then(userData => this.userData = userData);
  }

  getOffersOrdersReportByYears(years:any, filter:any): Promise<any> {
    const body = this.getBodyReports(years,filter);
    
    return this.http.post(endpoint + '/offers/general', body)
      .toPromise()
      .then(report => {
 
        return report.json();
      })
      .catch(this.handleError);
  }

  downloadBacklogOrders(years: any, filter) {
    const body = this.getBodyReports(years,filter);

    return this.http.post(endpoint + '/orders/general/excel', body,
      { responseType: ResponseContentType.Blob })
      .catch(this.handleError);
  }

  downloadBacklogOffers(years: any, filter) {
    const body = this.getBodyReports(years,filter);

    return this.http.post(endpoint + '/offers/general/excel', body,
      { responseType: ResponseContentType.Blob })
      .catch(this.handleError);
  }

  downloadExcel(years: any, filter) {
    const body = this.getBodyReports(years,filter);
  
    return this.http.post(endpoint + '/statistics/focus/view/excel', body,
      { responseType: ResponseContentType.Blob })
      .catch(this.handleError);
  }

  downloadPDF(years: any, filter) {
    const body = this.getBodyReports(years,filter);
  
    return this.http.post(endpoint + '/statistics/focus/view/pdf', body,
      { responseType: ResponseContentType.Blob })
      .catch(this.handleError);
  }

  private getBodyReports(years:any, filter:any){
    const body = {
      dates: years.map(year => year.toString()),
      filters: filter
    };


    return body;
  }

  private handleError(error: any): Promise<any> {
  
    return Promise.reject(error.message || error);
  }
}
