import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorBoxComponent } from './error-box.component';
import { ErrorBoxService } from './error-box.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ErrorBoxComponent],
  exports: [ ErrorBoxComponent ],
  providers: [ ErrorBoxService ]
})
export class ErrorBoxModule { }
