import {Component, Input, NgModule, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delconf-modal-content',
  template: `
    <div class="modal-header" style="background-color: #4dbd74;">
      <h4 class="modal-title">Silo <b>{{silo.aggregationCode}}</b> has been saved to Recalculated Archive. </h4>
    </div>
    <div class="modal-body">
      <p><b>Press YES to return with Recalcualted Silo or NO to return with the original</b></p> 
      <p>If yes, You are going to save {{numberOfSilos}} Recalculated Silo {{silo.completeModel}} & subsitute it into the offer {{offerTitle}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" (click)="activeModal.close('Accept')">Yes</button>
      <button type="button" class="btn btn-secondary" (click)="activeModal.close('Cancel')">No</button>
    </div> 
  `
})
export class SaveSiloOfferConfirmationModalComponent implements OnInit {

  @Input() offerTitle: string;
  @Input() numberOfSilos: number;
  @Input() silo: any;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
  }

}

@NgModule({
  declarations: [SaveSiloOfferConfirmationModalComponent],
  exports: [SaveSiloOfferConfirmationModalComponent]
})
export class SaveSiloOfferConfirmationModalModule { }
