import {Component, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import {User} from '../user';
import {Group} from '../../group/group';
import {UserService} from '../user.service';
import {GroupService} from '../../group/group.service';
import {NgForm} from '@angular/forms';
import {DelConfModalComponent} from '../../../shared/delete-confirmation-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoginService} from '../../login/login.service';
import {Observable} from 'rxjs/Observable';
import {CreatePersonModalComponent} from '../../person/create-person/create-person-modal.component';
import {Office} from '../../company/office/office';

@Component({
  selector: 'app-create-user-modal',
  templateUrl: './create-user-modal.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserModalComponent implements OnInit {

  groups: Group[];
  offices: Office[];
  messageError = false;

  @Output() newUser = new EventEmitter();

	extraBtns = [
    {
      html: `<i class="fa fa-plus-circle"></i> Add New`,
      fn: 'test'
    }
  ];

  @ViewChild('newUserForm')
  public newUserForm: NgForm;

  public newUserData = {
    name: null,
    surname: null,
    password: null,
    email: null,
    office: null,
    group: 5, //fix. remove harcoded ID
    discount: null,
    commission: null,
    kage: null,
    enabled: null,
  };

  constructor(
    private userService: UserService,
    private groupService: GroupService,
    private modalService: NgbModal,
    private loginService: LoginService,
  
	) { }

  ngOnInit() {
    setTimeout(() => {
      this.getGroups();
    });
  
  }

  getGroups(): void {
    this.groupService.getGroups()
      .then(groups => this.groups = groups);
  }

  onSubmit() {
    const newUser = { ...this.newUserData };
  
    this.userService.createUser(newUser)
      .then(user => {
        this.newUserData = {
            name: null,
            surname: null,
            password: null,
            email: null,
            office: null,
            group: 5, //fix. remove harcoded ID
            discount: null,
            commission: null,
            kage: null,
            enabled: null,
          };
        this.newUser.emit(user);
      });

  }


  extBtnFn(extBtn: any) {
    this[extBtn.fn]();
  }

  isUserGroup(group: Group): boolean {
    if (group.name === 'User') {
      return true;
    }
    return false;
  }

  isCustomerGroup(group: Group): boolean {
    
    if (group.name === 'Customer') { //fix. remove harcoded Customer group name. Default selected.
      return true;
    }
    return false;
  }

}
