import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import {isNull, isUndefined} from 'util';
import * as _ from 'lodash';
import { User } from '../../mts/user/user';

@Injectable()
export class LoginService {
  user: any;
  public retrieving = false;
  private currentSetUser = null;

  constructor (private http: Http) { }

  authenticate(email: string, password: string) {
    return this.http.post('/login?', {email: email, password: password})
      .map((response: Response) => {
        console.log(response);
        // Here comes our OAuth Token :D // recibimiento de codigo
        let bearer; bearer = response.json();
//
        if (bearer && bearer.access_token && bearer.refresh_token) {
          bearer.expireDateTime = new Date();
          bearer.expireDateTime.setSeconds(bearer.expireDateTime.getSeconds() + bearer.expires_in);
          localStorage.setItem('authentication', JSON.stringify(bearer));
          // se guarda en ordenador local el token
        }
      },
      (error) => {
        console.log(error);
        throw "";
      });
  }

  getUser(): Promise<any> {
    if (this.checkUser() && !this.retrieving) {
      return this.currentSetUser = this.setUser();
    } else if (this.retrieving) {
      return this.currentSetUser;
    }

    return new Promise(resolve => {
      resolve(this.user);
    });
  }

  setUser(): Promise<any> {
  //  console.warn('Getting user data');
    this.retrieving = true;
    return this.http.get('/me')
      .toPromise()
      .then(userData => {
        console.log("user /me", userData.json());
        this.retrieving = false;
        this.currentSetUser = null;
        this.user = userData.json();
        this.setFlags();


        return this.user;
      });
  }

  checkUser(): boolean {
    return (isNull(this.user) || isUndefined(this.user));
  }

  private setFlags(){
    this.user['user']['isAdmin'] = User.isAdmin(this.user.user);
    this.user['user']['isInternal'] = User.isInternal(this.user.user);
    this.user['user']['isInternalTechnical'] = User.isInternalTechnical(this.user.user);
    this.user['user']['isInternalAdministrative'] = User.isInternalAdministrative(this.user.user);
    this.user['user']['isInternalProduction'] = User.isInternalProduction(this.user.user);
    this.user['user']['isExternalAdmin'] = User.isExternalAdmin(this.user.user);
    this.user['user']['isExternal'] = User.isExternal(this.user.user);

  }

}
