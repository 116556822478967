import {Component, OnInit, ViewChild} from '@angular/core';
import {User} from './user';
import {Group} from '../group/group';
import {Router} from '@angular/router';
import {UserService} from './user.service';
import {GroupService} from '../group/group.service';
import {NgForm} from '@angular/forms';
import {DelConfModalComponent} from '../../shared/delete-confirmation-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {user} from '../authenticate/mock-user';
import {LoginService} from '../login/login.service';
import {Observable} from 'rxjs/Observable';
import {PersonService} from '../person/person.service';
import {CreatePersonModalComponent} from '../person/create-person/create-person-modal.component';
import {Person} from '../person/person';
import {LocaleCustomUnity} from '../../pipes/custom-locale.pipe';
import { TranslateService } from '@ngx-translate/core';
import { ErrorBoxService } from '../../shared/error-box/error-box.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  users: User[];
  userData: any;
  processingDeleteRequest: boolean = false;
  //dtOptions: DataTables.Settings = {};


  loadingUsers: boolean =false;

  constructor(
    private router: Router,
    private userService: UserService,
    private groupService: GroupService,
    private modalService: NgbModal,
    private loginService: LoginService,
    private localeCustomUnity: LocaleCustomUnity,
    private personService: PersonService,
    private translate: TranslateService,
    private errorBox: ErrorBoxService
  ) { }

  ngOnInit() {
    this.getUsers();
    this.getUserData();
  }

  getUsers(): void {


    this.loadingUsers = true;
    this.userService.getUsers()
      .then(users => {
        this.users = users;
        console.log("Hello");
        this.loadingUsers = false;

      }).catch(error => {

        this.loadingUsers = false;
      });


  }

  openDeleteModal(user) {
    const modal = this.modalService.open(DelConfModalComponent, {
      backdrop: 'static',
      keyboard: false,
      // size: 'sm',
      windowClass: 'rmv-modal',
    });
    modal.componentInstance.text = '<b>' + user.username + '</b>, ' + user.email;
    modal.componentInstance.type = 'user';
    modal.result.then((result) => {
      if (result === 'Accept') {
        this.removeUser(user.id);
      }
    });
  }

  removeUser(id: number): void {
    this.processingDeleteRequest = true;
    this.userService.deleteUser(id)
      .then(response => {
        this.processingDeleteRequest = false;
        this.errorBox.success('User removed successfully.');
        this.getUsers();

      }).catch(error => {
        this.processingDeleteRequest = false;
        this.errorBox.error('Eror, try again');

      });
  }

  getUserData() {

    this.loginService.getUser()
      .then(user => {
        this.userData = user;

      }).catch(error => {

      });
  }

  canManupulateUsers(){
    if (!_.isNull(this.userData) && !_.isUndefined(this.userData) ){
      return User.hasRole(this.userData.user, 'SUPER_ADMIN');
    }
    
    return false;
  }

  // funcion ordenar
  sort(colName) {
    console.log(colName);
    this.users.sort((a, b) => a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0)
  }



}
