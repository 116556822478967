/**
 * Created by juan on 4/07/17.
 */

export class Silo {
  [key: string]: any


  public static isWTT(silo){
 
    switch(silo.model){
      case "DPT":
      case "DP":
        return true;
    }

    return false;

  }

  public static isFBS(silo){
 
    switch(silo.model){
      case "FBS":
    
        return true;
    }

    return false;

  }

  public static isSG(silo){
      switch(silo.model){
      case "SGC":
      case "SGL":
      case "SGC-A":
      case "SGL-A":
        return true;
    }

    return false;

  }

  public static isDP(silo){
    
    switch(silo.model){
      case "DP":
        return true;
    }

    return false;

  }
}