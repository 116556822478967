import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'lineJump'})
export class LineJumpPipe implements PipeTransform {
  public transform(input: string): string {
    if (!input) {
      return '';
    } else {
      return input.replace(/\n/g, '<br>');
    }
  }
}
