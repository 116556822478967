import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {UserService} from '../user.service';
import {User} from '../user';
import {PersonService} from '../../person/person.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {
  user: User = null;
  notFound = false;
  personName: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private userService: UserService,
    private personService: PersonService
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.route.params
      .switchMap((params: Params) => this.userService.getUserById(params['id']))
      .subscribe(
        user => {
          this.user = user;

          this.getPersonName();
          this.titleService.setTitle('User: ' + this.user.username + ' - Simeza');
        },
        error => {
          if (error.status === 404) {
            this.notFound = true;
          }
        });
  }

  getPersonName() {
    const personId = this.user.person ? this.user.person.id : null;
    if (!_.isNull(personId)) {
      this.personService.getPersonById(personId)
        .then(person => {
          this.personName = person.name;
        });
    }
  }

  enableDisableUser() {
    this.user.enabled = !this.user.enabled;
    this.userService.updateUser(this.user)
      .then(response => this.getUser());
  }

}
