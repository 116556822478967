import { ComponentKeyValue, ComponentsRelation, generateRelations } from '../data-types/components-relation';

export const LoadPressuresTypes: ComponentKeyValue[] = [
    {
        key: 'EUROCODE',
        value: 'EUROCODE'
    },
    {
        key: 'ANSI-ASAE',
        value: 'ANSI-ASAE'
    }
];

export const DensitiesForCalculation: ComponentKeyValue[] = [
    {
        key: '9',
        value: 9
    },
    {
        key: '8.18',
        value: 8.18
    },

];

// ComponentRelations<K, T> is optional as it defaults to: K = string | T = ComponentKeyValue[]
export const LoadPressuresDensityCalculation: ComponentsRelation = generateRelations(
    {
        value: LoadPressuresTypes[0],
        relations: [DensitiesForCalculation[0]]
    },
    {
        value: LoadPressuresTypes[1],
        relations: [DensitiesForCalculation[1]]
    }
)
