import { PipeTransform, Pipe } from "@angular/core";

@Pipe({name: 'mapToArray'})
export class MapToArray implements PipeTransform {
  transform(value: any, args: string[]): any {
    const arr = [];
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        arr.push({key: key, value: value[key]});
      }
    }
    return arr;
  }
}