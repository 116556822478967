import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule, HttpClient} from '@angular/common/http';
import { RequestOptions, XHRBackend, Http, HttpModule} from '@angular/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';

// MTS AND PSS
import { MTSModule } from './mts/mts.module';
import { PSSModule } from './pss/pss.module';

import {httpFactory} from './shared/http.factory';
import {AuthenticateService} from './mts/authenticate/authenticate.service';
import {LoginService} from './mts/login/login.service';
import {GroupGuard} from './mts/group/guard/group.guard';
import {GroupGuardService} from './mts/group/guard/group-guard.service';
import {CustomPipesModule} from './pipes/custom-pipes.module';
import {LangService} from './mts/lang/lang.service';
import { AppErrorHandler } from './shared/app-error-handler.service';
import { ErrorBoxModule } from './shared/error-box/error-box.module';
import { BadInputCityDirective } from './shared/bad-input-city.directive';
import {TranslateModule,TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';

//importado a nivel de pagina y componente individual?
//import { DataTablesModule } from "angular-datatables";

import { YearSelectorModule } from './shared/year-selector/year-selector.module';
import { CISService } from './scs/cis/cis.service';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}



export class MyMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        return '*Traduction Pending*';
    }
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    MTSModule,
    PSSModule,
    NgbModule.forRoot(),
    TranslateModule.forRoot({
        loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
    }),
    CustomPipesModule,
    ErrorBoxModule,
    YearSelectorModule
  ],
  declarations: [
    AppComponent


  ],

  providers: [

    HttpClientModule,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    },
    {
      provide: Http,
      useFactory: httpFactory,
      deps: [XHRBackend, RequestOptions, AuthenticateService, ErrorHandler]
    },
    LoginService,
    GroupGuard,
    GroupGuardService,
    LangService,
    CISService
  ],
  bootstrap: [ AppComponent ],
})
export class AppModule { }
