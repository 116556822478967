export class User {
  id: number;
  person: any;
  username: string;
  password: string;
  email: string;
  group: any;
  roles: any[];
  office: number;
  father: any;
  discount: number;
  commission: number;
  agent_number: number;
  enabled: boolean;
  is_deleted: boolean;
  name: string;
  surname:string;

  static hasRole(user,role){


    let hasRole = user.roles.some(function(userRole){

      

      return role === userRole;

    });

    return hasRole;

  }

  static isAdmin(user){

    return (User.hasRole(user, 'INTERNAL_ADMIN') || User.hasRole(user, 'SUPER_ADMIN'))


  }

  static isInternal(user){

    return (User.hasRole(user, 'INTERNAL_AGENT') )
    
  }

  static isInternalTechnical(user){

    return (User.hasRole(user, 'INTERNAL_AGENT_TECHNICAL') )
    
  }

  static isInternalAdministrative(user){

    return (User.hasRole(user, 'INTERNAL_AGENT_ADMINISTRATIVE') )
    
  }

  static isInternalProduction(user){

    return (User.hasRole(user, 'INTERNAL_AGENT_PRODUCTION') )
    
  }

  static isExternalAdmin(user){
     return ( User.hasRole(user, 'EXTERNAL_ADMIN'))
  }

  static isExternal(user){
    return User.hasRole(user, 'EXTERNAL_AGENT') 
  }

}
