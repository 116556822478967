import {Injectable, ErrorHandler} from '@angular/core';
import { ConnectionBackend, RequestOptions, Request, RequestOptionsArgs, Response, Http, Headers} from '@angular/http';
import {Observable} from 'rxjs/Rx';
import {environment} from '../../environments/environment';
import {AuthenticateService} from '../mts/authenticate/authenticate.service';

@Injectable()
export class InterceptedHttp extends Http {
  private currentRequests = 0;

  constructor(
    backend: ConnectionBackend,
    defaultOptions: RequestOptions,
    private authService: AuthenticateService,
    private appErrorHandler: ErrorHandler
  ) {
    super(backend, defaultOptions);
  }

  request(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {
    // this.incrementReqCount();
    return super.request(url, options)
      .catch((error: Response) => {
        if (error.status === 401) {
          this.authService.logout();
        }
        this.appErrorHandler.handleError(error)
        return Observable.of(error);
      });
  }

  get(url: string, options?: RequestOptionsArgs): Observable<Response> {
    url = this.updateUrl(url);
    this.incrementReqCount();
    let resp;
    resp = super.get(url, this.getRequestOptionArgs(options)).share()
      /*.catch((error: Response) => {
        if (error.status === 401) {
          this.authService.logout();
        }
        return Observable.throw(error);
      });*/
    resp.subscribe(null, error => {
      this.decrementReqCount();
    }, () => {
      this.decrementReqCount();
    });
    return resp;
  }

  post(url: string, body: string, options?: RequestOptionsArgs): Observable<Response> {
    url = this.updateUrl(url);
    this.incrementReqCount();
    let resp;
    resp = super.post(url, body, this.getRequestOptionArgs(options)).share()
      .catch((error: Response) => {
        if (error.status === 401) {
          this.authService.logout();
        }
        return Observable.throw(error);
      });
    resp.subscribe(null, error => {
      this.decrementReqCount();
    }, () => {
      this.decrementReqCount();
    });
    return resp;
  }

  put(url: string, body: string, options?: RequestOptionsArgs): Observable<Response> {
    url = this.updateUrl(url);
    this.incrementReqCount();
    let resp;
    resp = super.put(url, body, this.getRequestOptionArgs(options)).share()
      .catch((error: Response) => {
        if (error.status === 401) {
          this.authService.logout();
        }
        return Observable.throw(error);
      });
    resp.subscribe(null, error => {
      this.decrementReqCount();
    }, () => {
      this.decrementReqCount();
    });
    return resp;
  }

  patch(url: string, body: string, options?: RequestOptionsArgs): Observable<Response> {
    url = this.updateUrl(url);
    this.incrementReqCount();
    let resp;
    resp = super.patch(url, body, this.getRequestOptionArgs(options)).share()
      .catch((error: Response) => {
        if (error.status === 401) {
          this.authService.logout();
        }
        return Observable.throw(error);
      });
    resp.subscribe(null, error => {
      this.decrementReqCount();
    }, () => {
      this.decrementReqCount();
    });
    return resp;
  }

  delete(url: string, options?: RequestOptionsArgs): Observable<Response> {
    url = this.updateUrl(url);
    this.incrementReqCount();
    let resp;
    resp = super.delete(url, this.getRequestOptionArgs(options)).share()
      .catch((error: Response) => {
        if (error.status === 401) {
          this.authService.logout();
        }
        return Observable.throw(error);
      });
    resp.subscribe(null, error => {
      this.decrementReqCount();
    }, () => {
      this.decrementReqCount();
    });
    return resp;
  }

  private updateUrl(req: string) {
    return  environment.apiPath + req;
  }

  private getRequestOptionArgs(options?: RequestOptionsArgs): RequestOptionsArgs {
    if (options == null) {
      options = new RequestOptions();
    }
    if (options.headers == null) {
      options.headers = new Headers();
    }
    //options.headers.append('Content-Type', 'application/json');
    if (localStorage.getItem('authentication')) {
      options.headers.append('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('authentication')).access_token);
    }
    return options;
  }

  private incrementReqCount() {
    /*if (this.currentRequests++ === 0) {
      this.slimService.start();
    }*/
  }

  private decrementReqCount() {
    /*if (--this.currentRequests === 0) {
      this.slimService.complete();
    }*/
  }
}
