import {Component, OnInit, ViewChild, Input} from '@angular/core';
import {Person} from './person';
import {Router} from '@angular/router';
import {PersonService} from './person.service';
import {NgForm} from '@angular/forms';
import {DelConfModalComponent} from '../../shared/delete-confirmation-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {LoginService} from '../login/login.service';
import {LocaleCustomUnity} from '../../pipes/custom-locale.pipe';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss']
})
export class PersonComponent implements OnInit {
  @Input() persons: any[];
  @Input() officeName: String;

  userData: any;

  @ViewChild('newPersonForm')
  public newPersonForm: NgForm;

  public newPersonData = {
    name: null,
    surname: null,
    email: null,
    address: null,
    phone: null,
    office: null
  };

  constructor(
    private router: Router,
    private personService: PersonService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private loginService: LoginService,
     private localeCustomUnity: LocaleCustomUnity,
  ) { }

  ngOnInit() {
   
   this.getUserData();
  }

  getPersons(): void {
    this.personService.getPersons()
      .then(persons => this.persons = persons);
  }

  openDeleteModal(person) {
    const modal = this.modalService.open(DelConfModalComponent, {
      backdrop: 'static',
      keyboard: false,
      // size: 'sm',
      windowClass: 'rmv-modal',
    });
    modal.componentInstance.text = '<b>' + person.name + '</b>, ';
    modal.componentInstance.type = 'person';
    modal.result.then((result) => {
      if (result === 'Accept') {
        this.removePerson(person.id);
      } else { }
    });
  }

  removePerson(id: number): void {
    this.personService.deletePerson(id)
      .then(response => this.getPersons());
  }

  getUserData() {
    this.loginService.getUser()
      .then(user => {
        this.userData = user;
   
      });
  }
}
