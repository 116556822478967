/**
 * Created by juan on 6/07/17.
 */

import { Injectable } from '@angular/core';
import {Http} from '@angular/http';
import {Lang} from "./lang";

const endpoint = '/lang';

@Injectable()
export class LangService {

  constructor(
    private http: Http
  ) { }

  createLang(lang: Lang): Promise<Lang> {
    return this.http.post(endpoint + '/new', lang)
      .toPromise()
      .then(newLang => newLang.json() as Lang)
      .catch(this.handleError);
  } 

  getLangs(): Promise<Lang[]> {
    return this.http.get(endpoint)
      .toPromise()
      .then(langs => langs.json() as Lang[])
      .catch(this.handleError);
  }

  getLangById(id: number): Promise<Lang> {
    return this.http.get(endpoint + '/' + id)
      .toPromise()
      .then(lang => lang.json() as Lang)
      .catch(this.handleError);
  }

  updateLang(lang: Lang): Promise<Lang> {
    return this.http.put(endpoint + '/' + lang.id + '/edit', lang)
      .toPromise()
      .then(updatedLang => updatedLang.json() as Lang)
      .catch(this.handleError);
  }

  deleteLang(id: number): Promise<Response> {
    return this.http.delete(endpoint + '/' + id)
      .toPromise()
      .then(response => response.json() as Response)
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    
    return Promise.reject(error.message || error);
  }

}
