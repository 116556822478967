import {Component, Input, NgModule, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delconf-modal-content',
  template: `
    <div class="modal-header" style="background-color: #4dbd74;">
      <h4 class="modal-title"> You are going to save Recalculated Silo <b>{{silo.aggregationCode}}</b> to the Recalculated Archive</h4>
    </div>
    <div class="modal-body">
      <p>Are you sure you want to continue?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" (click)="activeModal.close('Accept')">Yes</button>
      <button type="button" class="btn btn-secondary" (click)="activeModal.close('Cancel')">No</button>
    </div> 
  `
})
export class SaveSiloConfirmationModalComponent implements OnInit {
  @Input() silo: any;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
  }

}

@NgModule({
  declarations: [SaveSiloConfirmationModalComponent],
  exports: [SaveSiloConfirmationModalComponent]
})
export class SaveSiloConfirmationModalModule { }
