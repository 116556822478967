import { ComponentKeyValue } from '../data-types/components-relation';

export const TiknessTypes: ComponentKeyValue[] = [
    {
        key: '',
        value: ''
    },
    {
        key: '0,80',
        value: 0.80
    },
    {
        key: '1,00',
        value: 1.00
    },
    {
        key: '1,20',
        value: 1.20
    },
    {
        key: '1,50',
        value: 1.50
    },
    {
        key: '1,80',
        value: 1.80
    },
    {
        key: '2,00',
        value: 2.00
    },
    {
        key: '2,25',
        value: 2.25
    },
    {
        key: '2,50',
        value: 2.50
    },
    {
        key: '2,75',
        value: 2.75
    },
    {
        key: '3,00',
        value: 3.00
    },
    {
        key: '3,60',
        value: 3.60
    },
    {
        key: '3,80',
        value: 3.80
    },
    {
        key: '4,00',
        value: 4.00
    },
    {
        key: '4,25',
        value: 4.25
    },
    {
        key: '4,50',
        value: 4.50
    },
    {
        key: '4,75',
        value: 4.75
    },
    {
        key: '5,00',
        value: 5.00
    },
    {
        key: '5,25',
        value: 5.25
    },
    {
        key: '5,50',
        value: 5.50
    },
    {
        key: '5,75',
        value: 5.75
    },
    {
        key: '6,00',
        value: 6.00
    },
    {
        key: '6,25',
        value: 6.25
    },
    {
        key: '6,50',
        value: 6.50
    },
    {
        key: '6,75',
        value: 6.75
    },
    {
        key: '7,00',
        value: 7.00
    },
    {
        key: '7,25',
        value: 7.25
    },
    {
        key: '7,50',
        value: 7.50
    },
    {
        key: '7,75',
        value: 7.75
    },
    {
        key: '8,00',
        value: 8.00
    },
    {
        key: '8,25',
        value: 8.25
    },
    {
        key: '8,50',
        value: 8.50
    },
    {
        key: '8,75',
        value: 8.75
    },
    {
        key: '9,00',
        value: 9.00
    }

];