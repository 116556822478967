import {Component, Input, NgModule, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delconf-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Are you sure...</h4>
    </div>
    <div class="modal-body">
      <p>Are you sure you want to delete the following {{type}}:</p>
      <p [innerHtml]="text"></p>
      <!--<p *ngIf="type == 'company' || type == 'office'"><b>{{myObj.name}}</b>, {{myObj.address}}, {{myObj.cif}}</p>-->
      <!--<p *ngIf="type == 'user'"><b>{{myObj.username}}</b>, {{myObj.email}}</p>-->
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="activeModal.close('Accept')">Accept</button>
      <button type="button" class="btn btn-secondary" (click)="activeModal.close('Cancel')">Cancel</button>
    </div> 
  `
})
export class DelConfModalComponent implements OnInit {
  @Input() text: string;
  @Input() type: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
  }

}

@NgModule({
  declarations: [DelConfModalComponent],
  exports: [DelConfModalComponent]
})
export class DelConfModalModule { }
