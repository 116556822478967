import { Injectable } from '@angular/core';
import {Http, ResponseContentType} from '@angular/http';
import {Offer} from './offer';
import {OfferRevisionSilo} from './offer-revision-silo';
import {OfferRevisionProduct} from './offer-revision-product';

import { ErrorBoxService } from '../../shared/error-box/error-box.service';
import { LoginService } from '../../mts/login/login.service';
import { Product } from '../../scs/constitution/productRevision';

const endpoint = '/offers';
const endpointSilos = '/offers';
const proformaEndpoint = '/proforma';
const proformaWeightEndpoint = '/proforma-weight';
const proformaCodesEndpoint = '/proforma-codes';
const endpointCoefficients = '/coefficients';

@Injectable()
export class OfferService {

  userData: any;
  changedOffer: {id: number, revision: number};

  constructor(
    private http: Http,
    private loginService: LoginService,
    private errorBox: ErrorBoxService
  ) {
    loginService.getUser()
      .then(userData => this.userData = userData);
  }


  createOfferRevision(offerId: number, offerRevision: number, currencyUpdate: any): Promise<Offer> {
    return this.http.post(endpoint + '/' + offerId + '/revision/' + offerRevision, currencyUpdate)
      .toPromise()
      .then(offerRev => {
        this.errorBox.success('Offer revision created successfully.', true);
        return offerRev.json() as Offer;
      })
      .catch(this.handleError);
  }

  createOffer(offer: Offer): Promise<Offer> {
    return this.http.post(endpoint, offer)
      .toPromise()
      .then(response => response.json().data as Offer)
      .catch(this.handleError);
  }

  getOfferSilos(offer: Offer): Promise<OfferRevisionSilo[]> {

    return this.http.get(endpointSilos + '/' + offer.offer.id + '/revision/' + offer.revision + '/silo')
      .toPromise()
      .then(offerSilos => offerSilos.json().data)
      .catch(this.handleError);
  }

  getOfferSiloOriginalSilo(offer: Offer,offerSilo: OfferRevisionSilo): Promise<any>{

    return this.http.get(endpointSilos + '/' + offer.offer.id + '/revision/' + offer.revision + '/silo/' +  offerSilo.id + '/' + offerSilo.model + '/original', offerSilo)
      .toPromise()
      .then(response => response.json().data as any)
      .catch(this.handleError);

  }

  createOfferSilo(offer: Offer,offerSilo: OfferRevisionSilo): Promise<OfferRevisionSilo> {


    return this.http.post(endpointSilos + '/' + offer.offer.id + '/revision/' + offer.revision + '/silo/' + offerSilo.model, offerSilo)
      .toPromise()
      .then(response => response.json().data as OfferRevisionSilo)
      .catch(this.handleError);
  }

  createOfferSiloFromRecalcualtedSilo(offer: Offer,offerSilo: OfferRevisionSilo, originalOfferSiloId: number): Promise<OfferRevisionSilo> {


    return this.http.post(endpointSilos + '/' + offer.offer.id + '/revision/' + offer.revision + '/silo/' + offerSilo.model + '/from-offer-silo/' + originalOfferSiloId , offerSilo)
      .toPromise()
      .then(response => response.json().data as OfferRevisionSilo)
      .catch(this.handleError);
  }

  editOfferSilo( offer: Offer,offerSilo: OfferRevisionSilo): Promise<OfferRevisionSilo> {

    return this.http.put(endpointSilos + '/' + offer.offer.id + '/revision/' + offer.revision + '/silo/' +  offerSilo.id + '/' + offerSilo.model, offerSilo)
      .toPromise()
      .then(response =>
        response.json().data as OfferRevisionSilo)
      .catch(this.handleError);
  }

  hideShowOfferSiloPriceWeight( offer: Offer, offerSilo: OfferRevisionSilo, type: string, state: boolean): Promise<OfferRevisionSilo> {

    const payload = {
      type: type,
      state: state,
    };

    let url = endpointSilos + '/' + offer.offer.id + '/revision/' + offer.revision + '/silo/' +  offerSilo.id + '/' + offerSilo.model + '/hide';

    return this.http.post(url, payload)
      .toPromise()
      .then(response =>
        response.json().data as OfferRevisionSilo)
      .catch(this.handleError);
  }

  hideShowOfferSiloPriceWeightProduct( offer: Offer, offerRevisionProduct: OfferRevisionProduct, type: string, state: boolean): Promise<OfferRevisionProduct> {

    const payload = {
      type: type,
      state: state,
    };

    let url = endpointSilos + '/' + offer.offer.id + '/revision/' + offer.revision + '/product/category/' +  offerRevisionProduct.id + '/hide';

    return this.http.post(url, payload)
      .toPromise()
      .then(response =>
        response.json().data as OfferRevisionProduct)
      .catch(this.handleError);
  }

  createOfferSiloProduct( id_silo: number, offerProduct: any, model:string): Promise<any> {

    return this.http.post(endpoint + '/silo/' + id_silo + '/product?model=' + model , offerProduct)
      .toPromise()
      .then(response => response.json().data as any)
      .catch(this.handleError);
  }

  getOfferProductCategories(offer: any): Promise<any[]>  {

      return this.http.get(endpoint + '/' + offer.offer.id + '/revision/' + offer.revision +  '/product/category')
      .toPromise()
      .then(ProductCategory => ProductCategory.json().data as any[])
      .catch(this.handleError);
  }

  createOfferProduct( offer: any, offerProduct: any): Promise<any> {

    return this.http.post(endpoint + '/' + offer.offer.id + '/revision/' + offer.revision +  '/product' , offerProduct)
      .toPromise()
      .then(response => response.json().data as any)
      .catch(this.handleError);
  }

  createOfferSection( offer: any, section: any): Promise<any> {
    //   return this.http.post(endpoint + '/' + offer.offer.id + '/revision/' + offer.revision +  '/product/category/' + section.id)
    return this.http.post(endpoint + '/' + offer.offer.id + '/revision/' + offer.revision +  '/product/category/' + section.id, section)
      .toPromise()
      .then(response => {
      
        response.json().data as any})
      .catch(this.handleError);
  }

  createOfferCommission( offer: any, offerCommission: any): Promise<any> {
    //   return this.http.post(endpoint + '/' + id + '/revision/' + rev + '/product', offerProduct)
    

    return this.http.post(endpoint + '/' + offer.offer.id + '/revision/' + offer.revision +  '/commission' , offerCommission)
      .toPromise()
      .then(response => response.json().data as Response)
      .catch(this.handleError);
  }

  editOfferSiloProduct( id_silo: number, offerProduct: any, model:string): Promise<any> {

   
    return this.http.put(endpoint + '/silo/' + id_silo + '/product/' + offerProduct.id + '?model=' + model, offerProduct)
      .toPromise()
      .then(response => {
     
        return response.json().data as any})
      .catch(this.handleError);
  }

  editOfferProduct( offer: any, offerProduct: any): Promise<any> {

   
    return this.http.put(endpoint + '/' + offer.offer.id + '/revision/' + offer.revision +  '/product/'  + offerProduct.id , offerProduct)
      .toPromise()
      .then(response => {
        
        return response.json().data as any})
      .catch(this.handleError);
  }

  editOfferCommission( offer: any, offerCommission: any): Promise<any> {

    return this.http.put(endpoint + '/' + offer.offer.id + '/revision/' + offer.revision +  '/commission/' + offerCommission.id , offerCommission)
      .toPromise()
      .then(response => {
        
        response.json().data as any})
      .catch(this.handleError);
  }

  editOfferSection( offer: any, section: any): Promise<any> {

    return this.http.put(endpoint + '/' + offer.offer.id + '/revision/' + offer.revision +  '/product/category/' + section.id, section)
      .toPromise()
      .then(response => {
        
        response.json().data as any})
      .catch(this.handleError);
  }

  deleteOfferSiloProduct( id_silo: number, id_offer_product: number, model:string): Promise<any> {

    return this.http.delete(endpoint + '/silo/' + id_silo + '/product/' + id_offer_product + '?model=' + model)
      .toPromise()
      .then(response => {
        
        response.json().data as any

      })
      .catch(this.handleError);
  }

  deleteOfferProduct( offer: any, id_offer_product: number): Promise<any> {

    return this.http.delete(endpoint + '/' + offer.offer.id + '/revision/' + offer.revision +  '/product/' + id_offer_product )
      .toPromise()
      .then(response => {
        
        response.json().data as any

      })
      .catch(this.handleError);
  }

  deleteOfferProductBulk(offer: any, basket_product_ids: number[]): Promise<any> {
    let ids_string = '';
    let i = 0;
    for (const item of basket_product_ids) {
      if (i + 1 === basket_product_ids.length) {
        ids_string += item;
      } else {
        ids_string += item + ',';
      }
      i++;
    }
    return this.http.delete(endpoint + '/' + offer.offer.id + '/revision/' + offer.revision +  '/product?offerRevisionProducts=' + ids_string )
      .toPromise()
      .then(response => {
        
        response.json().data as any

      })
      .catch(this.handleError);
  }

  deleteOfferSection( offer: any, section: any): Promise<any> {

    return this.http.delete(endpoint + '/' + offer.offer.id + '/revision/' + offer.revision +  '/product/category/' + section.id )
      .toPromise()
      .then(response => {
        
        response.json().data as any

      })
      .catch(this.handleError);
  }

  deleteOfferCommission( offer: any, offerCommission: any): Promise<any> {

    return this.http.delete(endpoint + '/' + offer.offer.id + '/revision/' + offer.revision +  '/commission/' + offerCommission.id )
      .toPromise()
      .then(response => {
        
        response.json().data as any

      })
      .catch(this.handleError);
  }

  deleteOfferRevisionSilo(offer: Offer, offerRevisionSiloId: number, model: string){

    return this.http.delete(endpoint + '/' + offer.offer.id + '/revision/' + offer.revision + '/silo/' + offerRevisionSiloId+ '?model='+ model)
        .toPromise()
        .then(response => {
          
          response.json().data as any

        })
        .catch(this.handleError);
  }

  duplicateOfferRevisionSilo(offer: Offer, offerRevisionSiloId: number, model: string){

    return this.http.post(endpoint + '/' + offer.offer.id + '/revision/' + offer.revision + '/silo/' + offerRevisionSiloId +  '/duplicate' + '?model='+ model, {})
        .toPromise()
        .then(response => {

          return response.json().data as any

        })
        .catch(this.handleError);

  }

  getOfferSiloProductByCategory(id_silo: number, id: number, model: string): Promise<any[]> {
 
    return this.http.get(endpoint + '/silo/' + id_silo + '/product' + '/by-category/' + id + '?model=' + model)
      .toPromise()
      .then(Product => Product.json().data as any[])
      .catch(this.handleError);
  }

  getLastKPriceCoeffcient(): Promise<any> {
    return this.http.get(endpointCoefficients + '/kprice/last')
      .toPromise()
      .then(response => response.json().data as any)
      .catch(this.handleError);
  }

  geKtPriceCoeffcientByDate(date: any): Promise<any> {
    return this.http.get(endpointCoefficients + '/kprice/date' + this.getDateQueryString(date))
      .toPromise()
      .then(response => response.json().data as any)
      .catch(this.handleError);
  }


  getDateQueryString(date){
    return '?date' + '=' + date.year + "-" + date.month + "-" + date.day;
  }

  getOfferProductByCategory(offer, id: number): Promise<any[]> {
   
    return this.http.get(endpoint + '/' + offer.offer.id + '/revision/' + offer.revision +  '/product' + '/by-category/' + id )
      .toPromise()
      .then(Product => Product.json().data as any[])
      .catch(this.handleError);
  }

  getOfferProducts(offer): Promise<any[]> {

    return this.http.get(endpoint + '/' + offer.offer.id + '/revision/' + offer.revision +  '/product' )
      .toPromise()
      .then(Product => Product.json().data as any[])
      .catch(this.handleError);
  }

  updateOffer(offer: Offer): Promise<Response> {
    return this.http.patch(endpoint + '/' + offer.offer.id + '/revision/' + offer.revision, offer)
      .toPromise()
      .then(response => response.json() as Response)
      .catch(this.handleError);
  }

  getOffers(queryString): Promise<any> {

    return this.http.get(endpoint + queryString)
      .toPromise()
      .then(offers => offers.json())
      .catch(this.handleError);
  }

  getOffersCount(queryString): Promise<any> {

    return this.http.get(endpoint + '/count'+ queryString)
        .toPromise()
        .then(offers => offers.json())
        .catch(this.handleError);

  }

  getOfferById(id: number): Promise<Offer> {
    return this.http.get(endpoint + '/' + id)
      .toPromise()
      .then(offer => offer.json() as Offer)
      .catch(this.handleError);
  }

  getOfferByIdAndRev(id: number, rev: number): Promise<Offer> {
    return this.http.get(endpoint + '/' + id + '/revision/' + rev)
      .toPromise()
      .then(offer => offer.json() as Offer)
      .catch(this.handleError);
  }

  getPreviousOfferByIdAndRev(id: number, rev: number): Promise<Offer> {
    return this.http.get(endpoint + '/' + id + '/revision/' + rev + '/previous')
      .toPromise()
      .then(offer => offer.json() as Offer)
      .catch(this.handleError);
  }

  getOfferRevisionPrice(id: number, rev: number): Promise<number> {
    return this.http.get(endpoint + '/' + id + '/revision/' + rev + '/price')
      .toPromise()
      .then(offer => offer.json().data as number)
      .catch(this.handleError);
  }

  getOfferRevisionCapacity(id: number, rev: number): Promise<number> {
    return this.http.get(endpoint + '/' + id + '/revision/' + rev + '/capacity')
      .toPromise()
      .then(offer => offer.json().data as number)
      .catch(this.handleError);
  }

  getOfferRevisionSegmentedPrice(id: number, rev: number): Promise<any> {
    return this.http.get(endpoint + '/' + id + '/revision/' + rev + '/price/segmented')
      .toPromise()
      .then(offer => offer.json().data as any)
      .catch(this.handleError);
  }

  getOfferRevisionScopeOfSupply(id: number, rev: number): Promise<string> {
    return this.http.get(endpoint + '/' + id + '/revision/' + rev + '/scopeofsupply')
      .toPromise()
      .then(offer => offer.json().data as string)
      .catch(this.handleError);
  }

  getOfferSiloByIdAndRev(id: number, rev: number): Promise<Offer> {
    return this.http.get(endpoint + '/' + id + '/revision/' + rev + '/silo')
      .toPromise()
      .then(offer => offer.json() as Offer)
      .catch(this.handleError);
  }

  getRevisionsOfOffer(id: number): Promise<Offer[]> {
    return this.http.get(endpoint + '/' + id + '/revisions')
      .toPromise()
      .then(revisions => revisions.json() as Offer[])
      .catch(this.handleError);
  }



  deleteOffer(id: number, rev: number): Promise<number> {
    return this.http.delete(endpoint + '/' + id + '/revision/' + rev)
      .toPromise()
      .then(response => {
        this.errorBox.success('Offer removed successfully.', true);
        return response.json().data as number;
      })
      .catch(this.handleError);
  }


  duplicateOffer(offerId: number, offerRevision: number, newCurrency?: any): Promise<Offer> {

    return this.http.post(endpoint + '/' + offerId + '/revision/' + offerRevision + '/duplicate', newCurrency)
      .toPromise()
      .then(offerDup => {
        this.errorBox.success('Offer duplicated successfully.', true);
        return offerDup.json() as Offer;
      })
      .catch(this.handleError);
  }

  adquireOffer(offerId: number, offerRevision: number): Promise<any> {

    return this.http.put(endpoint + '/' + offerId + '/revision/' + offerRevision + '/adquire', {})
      .toPromise()
      .then(offer => {
        this.errorBox.success('Offer Adquired successfully.', true);
        return offer.json().data as any;
      })
      .catch(this.handleError);

  }

  sendToNextState(offerId: number, offerRevision: number): Promise<Offer> {
    return this.http.put(endpoint + '/' + offerId + '/revision/' + offerRevision + '/next', {})
      .toPromise()
      .then(offer => {
        // this.errorBox.success('Offer status changed successfully.', true);
        return offer.json() as Offer;
      })
      .catch(this.handleError);
  }

  sendToBackState(offerId: number, offerRevision: number): Promise<Offer> {
    return this.http.put(endpoint + '/' + offerId + '/revision/' + offerRevision + '/back', {})
      .toPromise()
      .then(offer => {
        // this.errorBox.success('Offer status changed successfully.', true);
        return offer.json() as Offer;
      })
      .catch(this.handleError);
  }

  rejectNext(offerId: number, offerRevision: number): Promise<Offer> {
    return this.http.put(endpoint + '/' + offerId + '/revision/' + offerRevision + '/reject', {})
      .toPromise()
      .then(offer => {
        this.errorBox.success('Offer status change rejected successfully.', true);
        return offer.json() as Offer;
      })
      .catch(this.handleError);
  }

  setChangedOffer(id: number, revision: number): void {
    this.changedOffer = {id: id, revision: revision};
  }

  getChangedOffer(): {id: number, revision: number} {
    let changedOffer = this.changedOffer;
    this.clearChangedOffer();
    return changedOffer;
  }

  downloadDocument(docType: string, offer: Offer, locale: string) {


    let endpointCustom = "";
    if(docType == "equipment-list-weight"){
      endpointCustom = proformaWeightEndpoint;
      docType = "equipment-list";
    }
    else if(docType == "equipment-list-codes"){
      endpointCustom = proformaCodesEndpoint;
      docType = "equipment-list";
    }
    else{
       endpointCustom = proformaEndpoint;
    }

    return this.http.get(`${endpointCustom}/${docType}/${offer.offer.id}/revision/${offer.revision}/${locale}`, { responseType: ResponseContentType.Blob })
      .catch(this.handleErrorProforma);
  }



  downloadOfferDocument(offer: Offer, doc: string, locale: string){

    let endpointCustom = "";
    if(doc == "equipment-list-weight"){
      endpointCustom = proformaWeightEndpoint;
      doc = "equipment-list";
    }
    else if(doc == "equipment-list-codes"){
      endpointCustom = proformaCodesEndpoint;
      doc = "equipment-list";
    }
    else{
       endpointCustom = proformaEndpoint;
    }


    return this.http.get(endpointCustom + '/'+ doc + '/' + offer.offer.id + '/revision/' + offer.revision + '/' + locale,
                      { responseType: ResponseContentType.Blob })
    .catch(this.handleErrorProforma);
  }

  downloadExcelPrice(offer: Offer, lang: string){
    return this.http.get(endpoint + '/'+ offer.offer.id + '/revision/' + offer.revision + "/price/excel/" + lang,
                      { responseType: ResponseContentType.Blob })
    .catch(this.handleErrorProforma);
  }

  downloadExcel(queryString){
    return this.http.get(endpoint + '/excel' + queryString,
                      { responseType: ResponseContentType.Blob })
    .catch(this.handleErrorProforma);
  }

  downloadSinglePivot(offer: Offer, offerRevisionSilo: any, doc: string, locale: string) {

    return this.http.get(proformaEndpoint + '/'+ locale + '/' + doc + '/' + offer.offer.id + '/revision/' + offer.revision + '/silo/' + offerRevisionSilo.id + '/' + offerRevisionSilo.model,
                      { responseType: ResponseContentType.Blob })
    .catch(this.handleErrorProforma);
  }

  downloadEquipmentPreview(offer: Offer, offerRevisionSilo: number, model: string, locale:string){
    return this.http.get(proformaEndpoint  + '/' +  locale + '/equipment-list/' + offer.offer.id + '/revision/' + offer.revision + '/silo/' + offerRevisionSilo + '/' + model +'/html')
      .toPromise()
      .then(html => {
        return html;
      })
      .catch(this.handleError);
  }


  downloadEquipmentPDF(offer: Offer, offerRevisionSilo: number, model: string, locale: string){
    return this.http.get(proformaEndpoint + '/' +  locale + '/equipment-list/' + offer.offer.id + '/revision/' + offer.revision + '/silo/' + offerRevisionSilo + '/' + model +'/pdf',
    {
      responseType: ResponseContentType.Blob
    })

      .catch(this.handleError);
  }

  updateOfferPrices(offerId: number, offerRevision: number,body: any): Promise<any> {

    return this.http.post(endpoint + '/' + offerId + '/revision/' + offerRevision + '/update/prices', body)
      .toPromise()
      .then(offer => {
        this.errorBox.success('Offer Prices updated successfully.', true);
        return offer.json() as any;
      })
      .catch(this.handleError);

  }

  getCostQueryString(date){
    return '?date' + '=' + date.year + "-" + date.month + "-" + date.day;
  }
  clearChangedOffer(): void {
    this.changedOffer = null;
  }

  offerCanBe(offer: Offer, action: string, syncBack?): boolean {
    let canBe = false;
    if (offer && this.userData) {
      let byRole = false;
      switch (action) {
        case 'saved':
          for (let role of this.userData.user.roles) {
            if (offer.state === 0) {
              if (role !== 'CUSTOMER') {
                byRole = true;
              }
            } else if (offer.state === 1 || offer.state === 3) {
              if (role === 'INTERNAL_AGENT' || role === 'INTERNAL_ADMIN') {
                byRole = true;
              }
            } else {
              if (role === 'INTERNAL_ADMIN') {
                byRole = true;
              }
            }
          }
          canBe = byRole;
          break;


        case 'sendToNext':
          for (let role of this.userData.user.roles) {
            if (offer.state === 0) {
              if (role !== 'CUSTOMER') {
                byRole = true;
              }
            } else if (offer.state === 1 || offer.state === 3) {
              if (role === 'INTERNAL_AGENT' || role === 'INTERNAL_ADMIN') {
                byRole = true;
              }
            } else {
              if (role === 'INTERNAL_ADMIN') {
                byRole = true;
              }
            }
          }

          canBe = byRole;
          break;

        case 'sendBack':
          for (let role of this.userData.user.roles) {
            if (offer.state === 1 || offer.state === 3) {
              if (role === 'INTERNAL_AGENT' || role === 'INTERNAL_ADMIN') {
                byRole = true;
              }
            } else if (offer.state === 2) {
              if (role === 'INTERNAL_ADMIN') {
                byRole = true;
              }
            }
            canBe = byRole;
          }

          break;

        case 'duplicate':
          for (let role of this.userData.user.roles) {
            if (offer.state === 0) {
              if (role !== 'CUSTOMER') {
                byRole = true;
              }
            } else if (offer.state === 1 || offer.state === 4) {
              if (role === 'INTERNAL_ADMIN' || role === 'INTERNAL_AGENT') {
                byRole = true;
              }
            } else if (offer.state === 3 ) {
              if (role === 'INTERNAL_ADMIN') {
                byRole = true;
              }
            }
          }
          canBe = byRole;
          break;

        case 'remove':
          for (let role of this.userData.user.roles) {
            if (offer.state === 0) {
              if (role !== 'CUSTOMER') {
                byRole = true;
              }
            } else if (offer.state === 1) {
              if (role === 'INTERNAL_ADMIN' || role === 'INTERNAL_AGENT') {
                byRole = true;
              }
            } else if (offer.state === 2 || offer.state === 3) {
              if (role === 'INTERNAL_ADMIN') {
                byRole = true;
              }
            }
          }
          canBe = byRole;
          break;

        case 'revision':
          for (let role of this.userData.user.roles) {
            if (offer.state === 4 && offer.adquired === false) {
              if ( (role === 'INTERNAL_AGENT' || role === 'INTERNAL_ADMIN') &&
              offer.status != "DR") {
                byRole = true;
              }
            }
          }
          canBe = byRole;
          break;

        case 'createJob':
          for (let role of this.userData.user.roles) {
            if (offer.state === 4) {
              if (role === 'INTERNAL_ADMIN') {
                byRole = true;
              }
            }
          }
          canBe = byRole;
          break;

        case 'adquired':
          for (let role of this.userData.user.roles) {
            if (offer.state === 4 && offer.adquired === false) {
              if (role === 'INTERNAL_ADMIN' || role === 'INTERNAL_AGENT') {
                byRole = true;
              }
            }
          }
          canBe = byRole;
          break;

        case 'acceptReject':
          for (let role of this.userData.user.roles) {
            if (offer.state === 0) {
              if (role === 'INTERNAL_AGENT' || role === 'INTERNAL_ADMIN') {
                byRole = true;
              }
            } else {
              if (role === 'INTERNAL_ADMIN') {
                byRole = true;
              }
            }
          }
          canBe = byRole && offer.stateChangePending;
          break;

        default:
          canBe = false;
          break;
      }
    }

    return canBe;
  }

  canRecalcualteOfferSilo(offerSilo:any){
    if(   offerSilo.model == "FBS"){
      return true;
    }

    return false;
  }

  addHistorynote(id: number, rev: number, historyNote: any): Promise<any> {
    return this.http.post(endpoint + '/' + id + '/revision/' + rev + '/historynote', historyNote)
      .toPromise()
      .then(response => {
        this.errorBox.success('Note added successfully.', true);
        return response.json().data as any;
      })
      .catch(this.handleError);
  }


  private handleErrorProforma(error: any): Promise<any>{
    throw new Error("received unexpected...");
  }

  private handleError(error: any): Promise<any> {

    return Promise.reject(error.message || error);
  }



  uploadFile(id: number, rev: number, file: any, docType: string): Promise<any> {
    return this.http.post(endpoint + '/' + id + '/revision/' + rev + '/upload-file/' + docType, file)
      .toPromise()
      .then(response => {
        this.errorBox.success('File uploaded successfully.', true);
        return response.json().data as any;
      })
      .catch(this.handleError);
  }

  deleteFile(id: number, rev: number, fileId: number): Promise<number> {
    return this.http.delete(endpoint + '/' + id + '/revision/' + rev + '/delete-file/' + fileId)
      .toPromise()
      .then(response => {
        this.errorBox.success('Document removed successfully.', true);
        return response.json().data as number;
      })
      .catch(this.handleError);
  }

  getDocuments(id: number, rev: number, docType: string){


    return this.http.get(endpoint + '/' + id + '/revision/' + rev + '/documents/' + docType)
      .toPromise()
      .then(response => {

        return response.json().data as any;
      })
      .catch(this.handleError);
  }

  getDocument(id: number, rev: number, document: any){
    return this.http.get(endpoint + '/' + id + '/revision/' + rev + '/documents/' + document.id,
                        { responseType: ResponseContentType.Blob })

      .catch(this.handleError);
  }


}
