import {Component, OnInit} from '@angular/core';
import {
  ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart,
  Router
} from '@angular/router';
import {Title} from '@angular/platform-browser';

import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from './mts/login/login.service';
import * as _ from 'lodash';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<app-error-box></app-error-box>'+

  '<router-outlet></router-outlet>',
  styles: []
})
export class AppComponent implements OnInit {
  private sub: any;
  constructor (
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translate: TranslateService,
    private loginService: LoginService
  ) {



  }

  ngOnInit() {

//    this.getUserData();
//

   let userLang = null;
    if(_.isUndefined(userLang) || _.isNull(userLang) || !this.isLangSupported(userLang)) //prevent js bad detection, move to server detection
         {
           userLang = this.translate.getBrowserLang();

            if(_.isUndefined(userLang) || _.isNull(userLang) || !this.isLangSupported(userLang)){
                userLang = "en";
            }
         }

         this.translate.setDefaultLang(userLang);
         this.translate.use(userLang);

    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .map(() => this.activatedRoute)
      .map(route => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      })
      .filter(route => route.outlet === 'primary')
      .mergeMap(route => route.data)
      .subscribe((event) => (this.titleService.setTitle(event['title'] + ' - Simeza')));
  }

  isLangSupported(lang){

    return lang === "es" || lang === "en"

  }

  getUserData() {
    this.loginService.getUser()
      .then(user =>   {

         let userLang = user.user.lang.isoCode;

         if(_.isUndefined(userLang) || _.isNull(userLang) || !this.isLangSupported(userLang)) //prevent js bad detection, move to server detection
         {
           userLang = this.translate.getBrowserLang();

            if(_.isUndefined(userLang) || _.isNull(userLang) || !this.isLangSupported(userLang)){
                userLang = "en";
            }
         }

         this.translate.setDefaultLang(userLang);
         this.translate.use(userLang);

      });
  }
}
