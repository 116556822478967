import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Silo } from './silo';
import { Product } from './productRevision';
import { ProductCategory } from './productCategory';

const endpoint = '/silos';
const recalculatedEndpoint = '/recalculatedsilos';
const endpointProducts = '/products';
const endpointStoredProducts = '/storedproduct';
const endpointCoefficients = '/coefficients';
const endpointProductCategory = '/categories';
const endpointProductCategorySilo = '/categories-silo';
const endpointProductSingleCategory = '/category';
const endpointProductSingleSiloCategory = '/category-silo';
const endpointProductCategoryDefault = '/categories/default';
const endpointProductsByCategory = '/products/by-category';
const endpointProductQuantityByCategory = '/products/quantity/by-category';

@Injectable()
export class ConstitutionService {

  constructor(
    private http: Http
  ) { }

  createSilo(silo: Silo): Promise<Silo> {
    return this.http.post(endpoint, silo)
      .toPromise()
      .then(response => response.json() as Silo)
      .catch(this.handleError);
  }

  getSilos(queryString: string, pricesDate?: string): Promise<Silo[]> {


    if(pricesDate){
      queryString+= '&pricesDate=' + pricesDate;
    }

    return this.http.get("/" + queryString)
      .toPromise()
      .then(Silos => Silos.json() as Silo[])
      .catch(this.handleError);
  }

  getStoredProducts(): Promise<Silo[]> {

    return this.http.get(endpointStoredProducts)
      .toPromise()
      .then(Silos => Silos.json() as Silo[])
      .catch(this.handleError);
  }

  getSiloCostByDate(siloId, model, date): Promise<any>{



    return this.http.get(endpoint + '/'+ siloId + '/' + 'cost' + this.getCostQueryStringSilo(model,date))
    .toPromise()
    .then(Silos => Silos.json())
    .catch(this.handleError);

  }

  getCostQueryStringSilo(model, date){
    return '?date' + '=' + date.year + "-" + date.month + "-" + date.day + '&' + 'model' + '=' + model;
  }


  getProductCostByDate(productCode, date): Promise<any>{


    return this.http.get(endpointProducts + '/'+ productCode + '/' + 'cost' + this.getDateQueryString(date))
    .toPromise()
    .then(Products => Products.json())
    .catch(this.handleError);
  }

  getDateQueryString(date){
    return '?date' + '=' + date.year + "-" + date.month + "-" + date.day;
  }

  getProdcutsLastRevision(): Promise<Product[]> {
    return this.http.get(endpointProducts + '/revisions/latests')
      .toPromise()
      .then(Product => Product.json().data as Product[])
      .catch(this.handleError);
  }

  getLastKPriceCoeffcient(): Promise<any> {
    return this.http.get(endpointCoefficients + '/kprice/last')
      .toPromise()
      .then(response => response.json().data as any)
      .catch(this.handleError);
  }

  getProductBy(aggregation_code: string, pricesDate: string): Promise<Product> {



    return this.http.get(endpointProducts + '/' + aggregation_code + '?pricesDate=' + pricesDate)
      .toPromise()
      .then(Product => Product.json().data as Product)
      .catch(this.handleError);
  }

  getProdcutsByCategory(id: number,pricesDate: string, filter?: boolean, offerSilo?: any, page?: number, silos?: number ): Promise<Product[]> {

   let endpoint = "" ;

   if(!filter){
      endpoint = endpointProductsByCategory + '/' + id + '?page=' + page;

   }
   else{
      endpoint = endpointProductsByCategory + '/' + id + '/silo/' + offerSilo.silo.id ;
      if(silos){
        endpoint = endpoint + '?silosqty=' + silos + '&' + 'model=' + offerSilo.silo.model;
      }
   }

   endpoint+="&pricesDate=" + pricesDate;
   const isRecalculated = offerSilo.isRecalculated ? '1': '0';
   endpoint+="&isRecalculated=" + isRecalculated;



    return this.http.get(endpoint)
      .toPromise()
      .then(Product => Product.json().data as Product[])
      .catch(this.handleError);
  }


  // function service to api
  getProdcutsOnlyByCategoryBySearch(id: number, pricesDate: string, text: String, diameter: String , page?: number): Promise<Product[]> {

   console.log("Diametro en service " + diameter);

   let endpoint = endpointProductsByCategory + '/' + id + '?page=' + page + '&pricesDate=' + pricesDate + '&text=' + text+ '&diameter=' + diameter;

    return this.http.get(endpoint)
      .toPromise()
      .then(Product => Product.json().data as Product[])
      .catch(this.handleError);
  }


  getProdcutsOnlyByCategory(id: number, pricesDate: string, page?: number): Promise<Product[]> {

   let endpoint = endpointProductsByCategory + '/' + id + '?page=' + page + '&pricesDate=' + pricesDate;



    return this.http.get(endpoint)
      .toPromise()
      .then(Product => Product.json().data as Product[])
      .catch(this.handleError);
  }

  getProdcutQuantityByCategory(id: number,  offerSilo?: any): Promise<number> {


    let endpoint = endpointProductQuantityByCategory + '/' + id + '/silo/' + offerSilo.silo.id + '?model=' + offerSilo.silo.model;

    const isRecalculated = offerSilo.isRecalculated ? '1': '0';
    endpoint+="&isRecalculated=" + isRecalculated;


    return this.http.get(endpoint)
      .toPromise()
      .then(Product => Product.json().data as number)
      .catch(this.handleError);
  }

  getNormalizedDate(pricesDate){
    const pricesDateTime = new Date(pricesDate);

    const dateString =   pricesDateTime.getFullYear() + '-' + (pricesDateTime.getMonth() + 1)+ '-' + pricesDateTime.getDate();

    return dateString;
  }

  getProductCategories(pricesDate?: string): Promise<ProductCategory>  {

      const finalEndpoint = endpointProductCategory + '?date=' + this.getNormalizedDate(pricesDate);

      return this.http.get(finalEndpoint)
      .toPromise()
      .then(ProductCategory => ProductCategory.json().data as ProductCategory)
      .catch(this.handleError);
  }

  getProductCategoryChilds(category: any, pricesDate): Promise<ProductCategory[]> {

    const finalEndpoint = endpointProductSingleCategory + '/' + category.id + '?date=' + this.getNormalizedDate(pricesDate);


    return this.http.get(finalEndpoint)
    .toPromise()
    .then(ProductCategory => ProductCategory.json().data as ProductCategory[])
    .catch(this.handleError);
}

  getProductParentCategories(): Promise<ProductCategory[]>  {
      return this.http.get(endpointProductCategory + '/' + 'parents')
      .toPromise()
      .then(ProductCategory => ProductCategory.json().data as ProductCategory[])
      .catch(this.handleError);
  }

  getProductCategoriesSilo(): Promise<ProductCategory>  {

    return this.http.get(endpointProductCategorySilo)
    .toPromise()
    .then(ProductCategory => ProductCategory.json().data as ProductCategory)
    .catch(this.handleError);
  }


  getProductDefaultCategories(siloModel): Promise<ProductCategory[]>  {
      return this.http.get(endpointProductCategoryDefault + '?model=' + siloModel)
      .toPromise()
      .then(ProductCategory => ProductCategory.json().data as ProductCategory[])
      .catch(this.handleError);
  }



  getSiloById(id: number,model: string, pricesDate: string): Promise<any> {
    return this.http.get(endpoint + '/' + id + '?model=' + model + '&pricesDate=' + pricesDate)
      .toPromise()
      .then(silo => silo.json().data)
      .catch(this.handleError);
  }

  updateSilo(silo: Silo): Promise<Response> {
    return this.http.put(endpoint + '/' + silo.id + '/edit?', silo)
      .toPromise()
      .then(response => response.json() as Response)
      .catch(this.handleError);
  }



  deleteSilo(id: number): Promise<Response> {
    return this.http.delete(endpoint + '/' + id)
      .toPromise()
      .then(response => response.json() as Response)
      .catch(this.handleError);
  }

  isRecalculatedSilo(silo: any){

  }

  updateCategoryUserPermission(user: any, category: any, value): Promise<any> {


    let body = {
      "read": value
    }

    return this.http.put(endpointProductSingleCategory + '/' + category.id + '/user/' + user.id, body)
      .toPromise()
      .then(response => response.json().data as any)
      .catch(this.handleError);
  }

  getCategoryUserPermission(user: any, category: any): Promise<any>  {
      return this.http.get(endpointProductSingleCategory + '/' + category.id + '/user/' + user.id)
      .toPromise()
      .then(response => response.json().data as any)
      .catch(this.handleError);
  }

  updateCategorySiloUserPermission(user: any, category: any, value): Promise<any> {


    let body = {
      "read": value
    }

    return this.http.put(endpointProductSingleSiloCategory + '/' + category.id + '/user/' + user.id, body)
      .toPromise()
      .then(response => response.json().data as any)
      .catch(this.handleError);
  }

  getCategorySiloUserPermission(user: any, category: any): Promise<any>  {
      return this.http.get(endpointProductSingleSiloCategory + '/' + category.id + '/user/' + user.id)
      .toPromise()
      .then(response => response.json().data as any)
      .catch(this.handleError);
  }

  getCategoriesSiloUserPermissionByUser(user: any, pricesDate?: string): Promise<any>  {

    let finalEndpoint = endpointProductCategorySilo + '/user/' + user.id;

    if(pricesDate){
      finalEndpoint+= '?date=' + this.getNormalizedDate(pricesDate);
    }

    return this.http.get(finalEndpoint)
    .toPromise()
    .then(response => response.json().data as any)
    .catch(this.handleError);
  }

  getStandards(runner: boolean = false){

    let standards = [
      {
        value: "ANSI/ASAE EP433 (1988 R2011)"
      },
      {
        value: "EN 1991-4:2006"
      }
    ];

    if(runner){
      standards.unshift({
        value: "All"
      });

    }else{
      standards.unshift({
        value: null
      });

    }

    return standards;
  }

  getStandardsRunner( plainArray: boolean = false){

    let standards = [
      {
        value: "ANSI-ASAE"
      },
      {
        value: "EUROCODE"
      }
    ];

    if(plainArray){
      let standardsPlain = [];
      for(const standard of standards){
        standardsPlain.push(standard.value);
      }

      return standardsPlain;
    }

    standards.unshift({
      value: "All"
    });


    return standards;
  }

  getStiffenersSheets(siloModel: String, runner: boolean = false, plainArray: boolean = false){


    let sheetsRing = [];

    switch(siloModel){
      case "FBS":
      case "HBS 45":
      case "HBS 60":
      case "HBS-S 45":
      case "HBS-S 60":
      case "TLS 45":
      case "TLS 60":
      case "TLS-S 45":
      case "TLS-S 60":
        sheetsRing = [
              {
                value: 2,
                valueDistance: "1200"
              },
              {
                value: 3,
                valueDistance: "800"
              }];
        break;
      case "DP":
        sheetsRing = [
              {
                value: 0,
                valueDistance: "0"
              }];
        break;
      case "DPT":
        sheetsRing = [
              {
                value: 1,
                valueDistance: "2400"
              }];
        break;

    }

    if(plainArray){
      let stiffenerSheetArray = [];
      for(const stiffenerSheet of sheetsRing){
        stiffenerSheetArray.push(stiffenerSheet.value);
      }

      return stiffenerSheetArray;
    }

    if(runner){
      sheetsRing.unshift({
        value: "All",
        valueDistance: null
      });
    }else{
      sheetsRing.unshift({
        value: null,
        valueDistance: null
      });
    }

    return sheetsRing;

  }

  getKSectionWeightAnsi(){
    return 1.15;
  }

  getKSectionWeight(){
    return 1.15;
  }

  getKSectionWeightEuro(){
    return 1.10;
  }

  getGlobalDisscount(){
    return 0;
  }

  getDefaultDisscount(){
    return 0;
  }

  getWallRings(siloModel, runner: boolean = false, plainArray: boolean = false){

    let wallRings = [];

    let init = 4;
    let limit = 35;

    switch(siloModel){
        case "DP":
        case "DPT":
            init = 1;
            limit = 5;
          break;
        case "SGC":
        case "SGL":
        case "SGC-A":
        case "SGL-A":
            init = 1;
            limit = 4;
          break;
        case "HBS 45":
        case "HBS 60":
        case "HBS-S 45":
        case "HBS-S 60":
            init = 1;
            limit = 35;
          break;
        case "TLS 45":
        case "TLS 60":
        case "TLS-S 45":
        case "TLS-S 60":
            init = 1;
            limit = 8;
          break;
    }

    if(plainArray){
      for(let i=init; i<=limit; i++){

        wallRings.push(i);

     }

     return wallRings;
    }

    if(runner){
      wallRings.push({value: "All"});
    }else{
      wallRings.push({value: null});
    }

    for(let i=init; i<=limit; i++){

       wallRings.push({value: i});

    }


    return wallRings;

  }

  getDefaultCategoriesByModel(silo){

    switch(silo.model){
      case "HBS":
      case "HBS-S":

        if(silo.hopperAngle == 45){
          return [4,17,33,35,73,74,75,192];
        }
        else if(silo.hopperAngle == 60){
          return [4,17,33,35,73,74,75,192];
        }
      case "FBS":
        return [4,17,32,35,73,74,75,192];
      case "DP":
        return  [133,135,136,137,138,139,140,141,142,175,176,177,178,198,199,200,201];
      case "DPT":
        return  [133,135,136,137,138,139,140,141,142,175,177,178,198,199,200,201];
      case "SGC":
      case "SGL":
      case "SGC-A":
      case "SGL-A":
        return [17,33,34,134];
      case "TLS":
        return [4,17,185,186,187,188,48,35,33, 192];
      case "TLS-S":
        return  [17,35,33,192];

    }

  }

  getHiddenCategoriesForModel(silo){

    let hiddenCategories = [];

    switch(silo.model){
      case "HBS":
        hiddenCategories = [13,18,32,41,42,43,44,47,64,65,76,87,88, 89, 94, 133, 134, 183, 187,188,190];
        break;
      case "HBS-S":
        hiddenCategories = [13,18,32,41,43,44,47,64,65,76,87,88,89, 94, 133, 134, 183, 187,188,190];
        break;
      case "FBS":
        hiddenCategories = [43,44,45,46, 47, 64,65,66,67,76, 133, 134, 135, 150, 151, 152,183,187,188, 189, 190, 191];
        break;
      case "DP":
        hiddenCategories =
[15, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30, 31, 36, 37, 38, 40, 41, 42, 43, 44, 45, 46, 47, 62, 63, 64, 65, 66, 67,
70, 71, 72, 73, 74, 75, 134,143,144,145,146,147,148,149, 150, 151, 152,183,187,188, 193,194,195,196,197 ];

        if(silo.wallSheetsGalvanization == "PL2"){
          hiddenCategories.push(139);
          hiddenCategories.push(141);
        }
        else if(silo.wallSheetsGalvanization == "Z6"){
          hiddenCategories.push(140);
          hiddenCategories.push(142);
        }
        break;


      case "DPT":
        hiddenCategories = [15, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30, 37, 38, 40, 41, 42, 43, 44, 45, 46, 47, 62,
        63, 64, 65, 66, 67, 70, 71, 72, 73, 74, 75, 134,143, 150, 151, 152, 183,187, 188, 193,194,195,196,197];

        if(silo.wallSheetsGalvanization == "PL2"){
          hiddenCategories.push(139);
          hiddenCategories.push(141);
        }
        else if(silo.wallSheetsGalvanization == "Z6"){
          hiddenCategories.push(140);
          hiddenCategories.push(142);
        }
        break;

      case "SGC":
      case "SGC-A":
        hiddenCategories = [15, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30, 31, 32, 35, 37, 38, 40, 41, 42, 43, 44, 45, 46, 47, 62, 63, 64, 65, 66,
         67, 70, 71, 72, 73, 74, 75, 76, 133, 135, 143, 150, 151, 152, 183,187,188, 193,194,195,196,197];
        break;

      case "SGL":
      case "SGL-A":
         hiddenCategories = [15, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30, 31, 32, 35, 37, 38, 40, 41, 42, 43, 44,
         45, 46, 47, 62, 63, 64, 65, 66, 67, 70, 71, 72, 73, 74, 75, 76, 133, 135, 143, 150, 151, 152,183,187,188, 193,194,195,196,197];
         break;
      case "TLS":
      case "TLS-S":
         hiddenCategories =  [135,139,178,45,46,76,64,65,66,133,134,18,19,20,21,22,23,24,25,26,37,179,181,62,63, 193,194,195,196,197,89,190];
         break;

    }

    return hiddenCategories;

  }

  getCategoriesInUploadingProcess(){
      return [30,150, 151, 152, 100,104,105,106,107,108,109,110,111,112,113,115,117,131,132];
  }



  getSheetsRingRunner(siloModel: String, runner: boolean = false, plainArray: boolean = false){

    let sheetsRing = [];

    switch(siloModel){
      default:
      case "FBS":
        sheetsRing = [

          {valueDiameter: "3,10",value: 3},
          {valueDiameter: "3,60",value: 4},
          {valueDiameter: "3,80",value: 5},
          {valueDiameter: "4,60",value: 6},
          {valueDiameter: "5,30",value: 7},
          {valueDiameter: "6,10",value: 8},
          {valueDiameter: "6,90",value: 9},
          {valueDiameter: "7,60",value: 10},
          {valueDiameter: "8,40",value: 11},
          {valueDiameter: "9,20",value: 12},
          {valueDiameter: "9,90",value: 13},
          {valueDiameter: "10,70",value:14},
          {valueDiameter: "11,50",value: 15},
          {valueDiameter: "12,20",value: 16},
          {valueDiameter: "13,00",value: 17},
          {valueDiameter: "13,80",value: 18},
          {valueDiameter: "14,50",value: 19},
          {valueDiameter: "15,30",value: 20},
          {valueDiameter: "16,00",value: 21},
          {valueDiameter: "16,80",value: 22},
          {valueDiameter: "17,60",value: 23},
          {valueDiameter: "18,30",value: 24},
          {valueDiameter: "19,10",value: 25},
          {valueDiameter: "19,90",value: 26},
          {valueDiameter: "20,60",value: 27},
          {valueDiameter: "21,40",value: 28},
          {valueDiameter: "22,20",value: 29},
          {valueDiameter: "22,90",value: 30},
          {valueDiameter: "23,70",value: 31},
          {valueDiameter: "24,40",value: 32},
          {valueDiameter: "25,20",value: 33},
          {valueDiameter: "26,00",value: 34},
          {valueDiameter: "26,70",value: 35},
          {valueDiameter: "27,50",value: 36},
          {valueDiameter: "28,30",value: 37},
          {valueDiameter: "29,00",value: 38},
          {valueDiameter: "29,80",value: 39},
          {valueDiameter: "30,60",value: 40}
        ];

        break;
      }

      if(plainArray){
        let sheetsRingPlain = [];
        for(const sheetRing of sheetsRing){
          sheetsRingPlain.push(sheetRing.value);
        }

        return sheetsRingPlain;
      }

      sheetsRing.unshift({valueDiameter: null,value: "All"});

      return sheetsRing;
  }
  getSheetsRing(siloModel: String, runner: boolean = false){

    let sheetsRing = [];

    switch(siloModel){
      case "FBS":
        sheetsRing = [

          {valueDiameter: "3,10",value: "3"},
          {valueDiameter: "3,60",value: "4"},
          {valueDiameter: "3,80",value: "5"},
          {valueDiameter: "4,60",value: "6"},
          {valueDiameter: "5,30",value: "7"},
          {valueDiameter: "6,10",value: "8"},
          {valueDiameter: "6,90",value: "9"},
          {valueDiameter: "7,60",value: "10"},
          {valueDiameter: "8,40",value: "11"},
          {valueDiameter: "9,20",value: "12"},
          {valueDiameter: "9,90",value: "13"},
          {valueDiameter: "10,70",value: "14"},
          {valueDiameter: "11,50",value: "15"},
          {valueDiameter: "12,20",value: "16"},
          {valueDiameter: "13,00",value: "17"},
          {valueDiameter: "13,80",value: "18"},
          {valueDiameter: "14,50",value: "19"},
          {valueDiameter: "15,30",value: "20"},
          {valueDiameter: "16,00",value: "21"},
          {valueDiameter: "16,80",value: "22"},
          {valueDiameter: "17,60",value: "23"},
          {valueDiameter: "18,30",value: "24"},
          {valueDiameter: "19,10",value: "25"},
          {valueDiameter: "19,90",value: "26"},
          {valueDiameter: "20,60",value: "27"},
          {valueDiameter: "21,40",value: "28"},
          {valueDiameter: "22,20",value: "29"},
          {valueDiameter: "22,90",value: "30"},
          {valueDiameter: "23,70",value: "31"},
          {valueDiameter: "24,40",value: "32"},
          {valueDiameter: "25,20",value: "33"},
          {valueDiameter: "26,00",value: "34"},
          {valueDiameter: "26,70",value: "35"},
          {valueDiameter: "27,50",value: "36"},
          {valueDiameter: "28,30",value: "37"},
          {valueDiameter: "29,00",value: "38"},
          {valueDiameter: "29,80",value: "39"},
          {valueDiameter: "30,60",value: "40"}
        ];

        break;

      case "HBS 45":
        sheetsRing = [

          {valueDiameter:"3,10",value: "3"},
          {valueDiameter:"3,60",value: "4"},
          {valueDiameter:"3,80",value: "5"},
          {valueDiameter: "4,60",value: "6"},
          {valueDiameter: "5,30",value: "7"},
          {valueDiameter: "6,10",value: "8"},
          {valueDiameter: "6,90",value: "9"},
          {valueDiameter: "7,60",value: "10"},
          {valueDiameter: "8,40",value: "11"},
          {valueDiameter: "9,20",value: "12"},
          {valueDiameter: "9,90",value: "13"},
          {valueDiameter:"10,70",value: "14"},
          {valueDiameter:"11,50",value: "15"},
          {valueDiameter:"12,20",value: "16"},
          {valueDiameter:"13,00",value: "17"},
          {valueDiameter:"13,80",value: "18"},
          {valueDiameter:"14,50",value: "19"},
        ];

        break;

      case "HBS 60":
        sheetsRing = [

          {valueDiameter:"3,10",value: "3"},
          {valueDiameter:"3,60",value: "4"},
          {valueDiameter:"3,80",value: "5"},
          {valueDiameter: "4,60",value: "6"},
          {valueDiameter: "5,30",value: "7"},
          {valueDiameter: "6,10",value: "8"},
          {valueDiameter: "6,90",value: "9"},
          {valueDiameter: "7,60",value: "10"},
          {valueDiameter: "8,40",value: "11"},
          {valueDiameter: "9,20",value: "12"},
          {valueDiameter: "9,90",value: "13"},
        ];

        break;


      case "HBS-S 60":
          sheetsRing = [

            {valueDiameter:"3,10",value: "3"},
            {valueDiameter:"3,60",value: "4"},
            {valueDiameter:"3,80",value: "5"},
            {valueDiameter: "4,60",value: "6"},
            {valueDiameter: "5,30",value: "7"},
            {valueDiameter: "6,10",value: "8"},
            {valueDiameter: "6,90",value: "9"},
            {valueDiameter: "7,60",value: "10"},
            {valueDiameter: "8,40",value: "11"}
          ];

          break;
      case "HBS-S 45":
          sheetsRing = [

            {valueDiameter:"3,10",value: "3"},
            {valueDiameter:"3,60",value: "4"},
            {valueDiameter:"3,80",value: "5"},
            {valueDiameter: "4,60",value: "6"},
            {valueDiameter: "5,30",value: "7"},
            {valueDiameter: "6,10",value: "8"},
            {valueDiameter: "6,90",value: "9"},
            {valueDiameter: "7,60",value: "10"},
            {valueDiameter: "8,40",value: "11"},
            {valueDiameter: "9,20",value: "12"},
            {valueDiameter: "9,90",value: "13"},
            {valueDiameter:"10,70",value: "14"},
            {valueDiameter:"11,50",value: "15"},
            {valueDiameter:"12,20",value: "16"}
          ];

          break;
      case "DP":
        sheetsRing = [

          {valueDiameter: "1,50",value:  "2"},
          {valueDiameter: "2,30",value:  "3"},
          {valueDiameter: "3,10",value:  "4"},
          {valueDiameter: "3,80",value:  "5"},
          {valueDiameter: "4,60",value:  "6"},
          {valueDiameter: "5,30",value:  "7"},
          {valueDiameter: "6,10",value:  "8"},
          {valueDiameter: "6,90",value:  "9"},
          {valueDiameter: "7,60",value:  "10"},
          {valueDiameter: "8,40" ,value: "11"},
          {valueDiameter: "9,20",value:  "12"},
          {valueDiameter: "9,90",value:  "13"},
          {valueDiameter: "10,70",value:  "14"},
          {valueDiameter: "11,50",value:  "15"},
          {valueDiameter: "12,20",value:  "16"},
          {valueDiameter: "13,00",value:  "17"},
          {valueDiameter: "13,80",value:  "18"},
          {valueDiameter: "14,50",value:  "19"},
          {valueDiameter: "15,30",value:  "20"},
          {valueDiameter: "16,00",value:  "21"},
          {valueDiameter: "16,80",value:  "22"},
          {valueDiameter: "17,60",value:  "23"},
          {valueDiameter: "18,30",value:  "24" },
          {valueDiameter: "19,10",value:  "25"},
          {valueDiameter: "19,90",value:  "26"},
          {valueDiameter: "20,60",value:  "27" },
          {valueDiameter: "21,40",value:  "28"},
          {valueDiameter: "22,20",value:  "29"},
          {valueDiameter: "22,90",value:  "30"},
          {valueDiameter: "23,70",value:  "31"},
          {valueDiameter: "24,40",value:  "32"},
          {valueDiameter: "25,20",value:  "33" },
          {valueDiameter: "26,00",value:  "34"},
          {valueDiameter: "26,70",value:  "35"},
          {valueDiameter: "27,50",value:  "36"},
          {valueDiameter: "28,30",value:  "37"},
          {valueDiameter: "29,00",value:  "38"},
          {valueDiameter: "29,80",value:  "39"},
          {valueDiameter: "30,60",value:  "40"},
          {valueDiameter: "31,30",value:  "41"},
          {valueDiameter: "32,10",value:  "42"},
          {valueDiameter: "32,80",value:  "43"},
          {valueDiameter: "33,60",value:  "44"},
          {valueDiameter: "34,40",value:  "45"},
          {valueDiameter: "35,10",value:  "46"},
          {valueDiameter: "35,90",value:  "47"},
          {valueDiameter: "36,70",value:  "48"},
          {valueDiameter: "37,40",value:  "49"},
          {valueDiameter: "38,20",value:  "50"},
          {valueDiameter: "39,00",value:  "51"},
          {valueDiameter: "39,70",value:  "52"},
          {valueDiameter: "40,50",value:  "53"},
          {valueDiameter: "41,30",value:  "54"},
          {valueDiameter: "42,00",value:  "55"},
          {valueDiameter: "42,80",value:  "56"}
        ];
        break;
      case "DPT":
        sheetsRing = [

          {valueDiameter: "2,30",value:  "3"},
          {valueDiameter: "3,10",value:  "4"},
          {valueDiameter: "3,80",value:  "5"},
          {valueDiameter: "4,60",value:  "6"},
          {valueDiameter: "5,30",value:  "7"},
          {valueDiameter: "6,10",value:  "8"},
          {valueDiameter: "6,90",value:  "9"},
          {valueDiameter: "7,60",value:  "10"},
          {valueDiameter: "8,40" ,value: "11"},
          {valueDiameter: "9,20",value:  "12"},
          {valueDiameter: "9,90",value:  "13"},
          {valueDiameter: "10,70",value:  "14"},
          {valueDiameter: "11,50",value:  "15"},
          {valueDiameter: "12,20",value:  "16"},
          {valueDiameter: "13,00",value:  "17"},
          {valueDiameter: "13,80",value:  "18"},
          {valueDiameter: "14,50",value:  "19"},
          {valueDiameter: "15,30",value:  "20"},
          {valueDiameter: "16,00",value:  "21"},
          {valueDiameter: "16,80",value:  "22"},
          {valueDiameter: "17,60",value:  "23"},
          {valueDiameter: "18,30",value:  "24" },
          {valueDiameter: "19,10",value:  "25"},
          {valueDiameter: "19,90",value:  "26"},
          {valueDiameter: "20,60",value:  "27" },
          {valueDiameter: "21,40",value:  "28"},
          {valueDiameter: "22,20",value:  "29"},
          {valueDiameter: "22,90",value:  "30"},
          {valueDiameter: "23,70",value:  "31"},
          {valueDiameter: "24,40",value:  "32"},
          {valueDiameter: "25,20",value:  "33" },
          {valueDiameter: "26,00",value:  "34"},
          {valueDiameter: "26,70",value:  "35"},
          {valueDiameter: "27,50",value:  "36"},
          {valueDiameter: "28,30",value:  "37"},
          {valueDiameter: "29,00",value:  "38"},
          {valueDiameter: "29,80",value:  "39"},
          {valueDiameter: "30,60",value:  "40"},

        ];
        break;
      case "TLS 45":
      case "TLS 60":
      case "TLS-S 45":
      case "TLS-S 60":
        sheetsRing = [
          {valueDiameter: "3,10", value:  "3"},
          {valueDiameter:"3,60",value: "4"},
          {valueDiameter: "3,80", value:  "5"},
          {valueDiameter: "4,60", value:  "6"},
          {valueDiameter: "5,30",  value: "7"},
      ];
      break;
      case "TLS-S 53-80":
        sheetsRing = [
          {valueDiameter: "2,55", value:  "3"}
      ];
      break;
    }

    if(runner){
      sheetsRing.unshift({valueDiameter: null,value: "All"});
    }else{
      sheetsRing.unshift({valueDiameter: null,value: null});
    }


    return sheetsRing;

  }

  getSheetsRingNoAdmin(siloModel: String){

    switch(siloModel){
      case "FBS":
        return [
          {valueDiameter: null,value: null},
          {valueDiameter: "4,60",value: "6"},
          {valueDiameter: "5,30",value: "7"},
          {valueDiameter: "6,10",value: "8"},
          {valueDiameter: "6,90",value: "9"},
          {valueDiameter: "7,60",value: "10"},
          {valueDiameter: "8,40",value: "11"},
          {valueDiameter: "9,20",value: "12"},
          {valueDiameter: "9,90",value: "13"},
          {valueDiameter: "10,70",value: "14"},
          {valueDiameter: "11,50",value: "15"},
          {valueDiameter: "12,20",value: "16"},
          {valueDiameter: "13,00",value: "17"},
          {valueDiameter: "13,80",value: "18"},
          {valueDiameter: "14,50",value: "19"},
          {valueDiameter: "15,30",value: "20"},
          {valueDiameter: "16,80",value: "22"},
          {valueDiameter: "18,30",value: "24"},
          {valueDiameter: "19,90",value: "26"},
          {valueDiameter: "21,40",value: "28"},
          {valueDiameter: "22,90",value: "30"},
          {valueDiameter: "24,40",value: "32"},
          {valueDiameter: "26,00",value: "34" },
          {valueDiameter: "27,50",value: "36"},
          {valueDiameter: "30,60",value: "40"}
        ];

      case "HBS 45":
      case "HBS-S 45":
        return [
          {valueDiameter: null,value: null},
          {valueDiameter: "4,60",value: "6"},
          {valueDiameter: "5,30",value: "7"},
          {valueDiameter: "6,10",value: "8"},
          {valueDiameter: "6,90",value: "9"},
          {valueDiameter: "7,60",value: "10"},
          {valueDiameter: "8,40",value: "11"},
          {valueDiameter: "9,20",value: "12"},
          {valueDiameter: "9,90",value: "13"},
          {valueDiameter:"10,70",value: "14"},
          {valueDiameter:"11,50",value: "15"},
          {valueDiameter:"12,20",value: "16"}
        ];

      case "HBS 60":
      case "HBS-S 60":
        return [
          {valueDiameter: null,value: null},
          {valueDiameter: "4,60",value: "6"},
          {valueDiameter: "5,30",value: "7"},
          {valueDiameter: "6,10",value: "8"},
          {valueDiameter: "6,90",value: "9"},
          {valueDiameter: "7,60",value: "10"},
          {valueDiameter: "8,40",value: "11"},
        ];

      case "DP":
        return [
          {valueDiameter: null,value: null},
          {valueDiameter: "1,50",value:  "2"},
          {valueDiameter: "2,30",value:  "3"},
          {valueDiameter: "3,10",value:  "4"},
          {valueDiameter: "3,80",value:  "5"},
          {valueDiameter: "4,60",value:  "6"},
          {valueDiameter: "5,30",value:  "7"},
          {valueDiameter: "6,10",value:  "8"},
          {valueDiameter: "6,90",value:  "9"},
          {valueDiameter: "7,60",value:  "10"},
          {valueDiameter: "8,40" ,value: "11"},
          {valueDiameter: "9,20",value:  "12"},
          {valueDiameter: "9,90",value:  "13"},
          {valueDiameter: "10,70",value:  "14"},
          {valueDiameter: "11,50",value:  "15"},
          {valueDiameter: "12,20",value:  "16"},
          {valueDiameter: "13,00",value:  "17"},
          {valueDiameter: "13,80",value:  "18"},
          {valueDiameter: "14,50",value:  "19"},
          {valueDiameter: "15,30",value:  "20"},
          {valueDiameter: "16,00",value:  "21"},
          {valueDiameter: "16,80",value:  "22"},
          {valueDiameter: "17.60",value:  "23"},
          {valueDiameter: "18,30",value:  "24" },
          {valueDiameter: "19,10",value:  "25"},
          {valueDiameter: "19,90",value:  "26"},
          {valueDiameter: "20,60",value:  "27" },
          {valueDiameter: "21,40",value:  "28"},
          {valueDiameter: "22,20",value:  "29"},
          {valueDiameter: "22,90",value:  "30"},
          {valueDiameter: "23,70",value:  "31"},
          {valueDiameter: "24,40",value:  "32"},
          {valueDiameter: "25,20",value:  "33" },
          {valueDiameter: "26,00",value:  "34"},
          {valueDiameter: "26,70",value:  "35"},
          {valueDiameter: "27,50",value:  "36"},
          {valueDiameter: "28,30",value:  "37"},
          {valueDiameter: "29,00",value:  "38"},
          {valueDiameter: "29,80",value:  "39"},
          {valueDiameter: "30,60",value:  "40"},
          {valueDiameter: "31,30",value:  "41"},
          {valueDiameter: "32,10",value:  "42"},
          {valueDiameter: "32,80",value:  "43"},
          {valueDiameter: "33,60",value:  "44"},
          {valueDiameter: "34,40",value:  "45"},
          {valueDiameter: "35,10",value:  "46"},
          {valueDiameter: "35,90",value:  "47"},
          {valueDiameter: "36,70",value:  "48"},
          {valueDiameter: "37,40",value:  "49"},
          {valueDiameter: "38,20",value:  "50"},
          {valueDiameter: "39,00",value:  "51"},
          {valueDiameter: "39,70",value:  "52"},
          {valueDiameter: "40,50",value:  "53"},
          {valueDiameter: "41,30",value:  "54"},
          {valueDiameter: "42,00",value:  "55"},
          {valueDiameter: "42,80",value:  "56"}
        ];
      case "DPT":
        return [
          {valueDiameter: null,value: null},
          {valueDiameter: "2,30",value:  "3"},
          {valueDiameter: "3,10",value:  "4"},
          {valueDiameter: "3,80",value:  "5"},
          {valueDiameter: "4,60",value:  "6"},
          {valueDiameter: "5,30",value:  "7"},
          {valueDiameter: "6,10",value:  "8"},
          {valueDiameter: "6,90",value:  "9"},
          {valueDiameter: "7,60",value:  "10"},
          {valueDiameter: "8,40" ,value: "11"},
          {valueDiameter: "9,20",value:  "12"},
          {valueDiameter: "9,90",value:  "13"},
          {valueDiameter: "10,70",value:  "14"},
          {valueDiameter: "11,50",value:  "15"},
          {valueDiameter: "12,20",value:  "16"},
          {valueDiameter: "13,00",value:  "17"},
          {valueDiameter: "13,80",value:  "18"},
          {valueDiameter: "14,50",value:  "19"},
          {valueDiameter: "15,30",value:  "20"},
          {valueDiameter: "16,00",value:  "21"},
          {valueDiameter: "16,80",value:  "22"},
          {valueDiameter: "17,60",value:  "23"},
          {valueDiameter: "18,30",value:  "24" },
          {valueDiameter: "19,10",value:  "25"},
          {valueDiameter: "19,90",value:  "26"},
          {valueDiameter: "20,60",value:  "27" },
          {valueDiameter: "21,40",value:  "28"},
          {valueDiameter: "22,20",value:  "29"},
          {valueDiameter: "22,90",value:  "30"},
          {valueDiameter: "23,70",value:  "31"},
          {valueDiameter: "24,40",value:  "32"},
          {valueDiameter: "25,20",value:  "33" },
          {valueDiameter: "26,00",value:  "34"},
          {valueDiameter: "26,70",value:  "35"},
          {valueDiameter: "27,50",value:  "36"},
          {valueDiameter: "28,30",value:  "37"},
          {valueDiameter: "29,00",value:  "38"},
          {valueDiameter: "29,80",value:  "39"},
          {valueDiameter: "30,60",value:  "40"},

        ];
      case "TLS 45":
      case "TLS 60":
      case "TLS-S 45":
      case "TLS-S 60":
        return [
          {valueDiameter: "3,10", value: "3"},
          {valueDiameter: "3,60", value: "4"},
          {valueDiameter: "3,80", value: "5"},
          {valueDiameter: "4,60", value: "6"},
          {valueDiameter: "5,30", value: "7"},
      ];
      case "TLS-S 53-80":
        return [
          {valueDiameter: "2,55", value: "3"}
      ];
    }



  }


  getDiameters(siloModel: String){

    switch(siloModel){
      case "FBS":
        return [
          {value: null},
          {value:"3,10"},
          {value:"3,60"},
          {value:"3,80"},
          {value:"4,60"},
          {value:"5,30"},
          {value:"6,10"},
          {value:"6,90"},
          {value:"7,60"},
          {value:"8,40"},
          {value:"9,20"},
          {value:"9,90"},
          {value:"10,70"},
          {value:"11,50"},
          {value:"12,20"},
          {value:"13,00"},
          {value:"13,80"},
          {value:"14,50"},
          {value:"15,30"},
          {value:"16,00"},
          {value:"16,80"},
          {value:"17,60"},
          {value:"18,30"},
          {value:"19,10"},
          {value:"19,90"},
          {value:"20,60"},
          {value:"21,40"},
          {value:"22,20"},
          {value:"22,90"},
          {value:"23,70"},
          {value:"24,40"},
          {value:"25,20"},
          {value:"26,00"},
          {value:"26,70"},
          {value:"27,50"},
          {value:"28,30"},
          {value:"29,00"},
          {value:"29,80"},
          {value:"30,60"}
        ];

      case "HBS 45":
        return [
          {value:null},
          {value:"3,10"},
          {value:"3,60"},
          {value:"3,80"},
          {value:"4,60"},
          {value:"5,30"},
          {value:"6,10"},
          {value:"6,90"},
          {value:"7,60"},
          {value:"8,40"},
          {value:"9,20"},
          {value:"9,90"},
          {value:"10,70"},
          {value:"11,50"},
          {value:"12,20"},
          {value:"13,00"},
          {value:"13,80"},
          {value:"14,50"},
        ];

      case "HBS 60":
        return [
          {value:null},
          {value:"3,10"},
          {value:"3,60"},
          {value:"3,80"},
          {value:"4,60"},
          {value:"5,30"},
          {value:"6,10"},
          {value:"6,90"},
          {value:"7,60"},
          {value:"8,40"},
          {value:"9,20"},
          {value:"9,90"},
        ];

      case "HBS-S 45":
          return [
            {value:null},
            {value:"3,10"},
            {value:"3,60"},
            {value:"3,80"},
            {value:"4,60"},
            {value:"5,30"},
            {value:"6,10"},
            {value:"6,90"},
            {value:"7,60"},
            {value:"8,40"},
            {value:"9,20"},
            {value:"9,90"},
            {value:"10,70"},
            {value:"11,50"},
            {value:"12,20"}
          ];
      case "HBS-S 60":
          return [
            {value:null},
            {value:"3,10"},
            {value:"3,60"},
            {value:"3,80"},
            {value:"4,60"},
            {value:"5,30"},
            {value:"6,10"},
            {value:"6,90"},
            {value:"7,60"},
            {value:"8,40"}
          ];
      case "DP":
        return [
          {value: null},
          {value: "1,50"},
          {value: "2,30"},
          {value: "3,10"},
          {value: "3,80"},
          {value: "4,60"},
          {value: "5,30"},
          {value: "6,10"},
          {value: "6,90"},
          {value: "7,60"},
          {value: "8,40"},
          {value: "9,20"},
          {value: "9,90"},
          {value: "10,70"},
          {value: "11,50"},
          {value: "12,20"},
          {value: "13,00"},
          {value: "13,80"},
          {value: "14,50"},
          {value: "15,30"},
          {value: "16,00"},
          {value: "16,80"},
          {value: "17,60"},
          {value: "18,30"},
          {value: "19,10"},
          {value: "19,90"},
          {value: "20,60"},
          {value: "21,40"},
          {value: "22,20"},
          {value: "22,90"},
          {value: "23,70"},
          {value: "24,40"},
          {value: "25,20"},
          {value: "26,00"},
          {value: "26,70"},
          {value: "27,50"},
          {value: "28,30"},
          {value: "29,00"},
          {value: "29,80"},
          {value: "30,60"},
          {value: "31,30"},
          {value: "32,10"},
          {value: "32,80"},
          {value: "33,60"},
          {value: "34,40"},
          {value: "35,10"},
          {value: "35,90"},
          {value: "36,70"},
          {value: "37,40"},
          {value: "38,20"},
          {value: "39,00"},
          {value: "39,70"},
          {value: "40,50"},
          {value: "41,30"},
          {value: "42,00"},
          {value: "42,80"}
        ];
      case "DPT":
        return [
          {value: null},
          {value: "2,30"},
          {value: "3,10"},
          {value: "3,80"},
          {value: "4,60"},
          {value: "5,30"},
          {value: "6,10"},
          {value: "6,90"},
          {value: "7,60"},
          {value: "8,40"},
          {value: "9,20"},
          {value: "9,90"},
          {value: "10,70"},
          {value: "11,50"},
          {value: "12,20"},
          {value: "13,00"},
          {value: "13,80"},
          {value: "14,50"},
          {value: "15,30"},
          {value: "16,00"},
          {value: "16,80"},
          {value: "17,60"},
          {value: "18,30" },
          {value: "19,10"},
          {value: "19,90"},
          {value: "20,60" },
          {value: "21,40"},
          {value: "22,20"},
          {value: "22,90"},
          {value: "23,70"},
          {value: "24,40"},
          {value: "25,20" },
          {value: "26,00"},
          {value: "26,70"},
          {value: "27,50"},
          {value: "28,30"},
          {value: "29,00"},
          {value: "29,80"},
          {value: "30,60"}

        ];
      case "SGC":
      case "SGC-A":
        return [
          {value: null},
          {value: "2,30"},
          {value: "2,50"},
          {value: "3,10"},
          {value: "3,50"}
        ];
      case "SGL":
      case "SGL-A":
        return [
          {value: null},
          {value: "2,30"},
          {value: "2,50"}
        ];
      case "TLS 45":
      case "TLS 60":
      case "TLS-S 45":
      case "TLS-S 60":
        return [
          {value: "3,10"},
          {value: "3,60"},
          {value: "3,80"},
          {value: "4,60"},
          {value: "5,30"},
      ];
      case "TLS-S 53-80":
        return [
          {value: "2,55"}
      ];
    }


  }

  getDiametersNotAdmin(siloModel: String)
  {
    switch(siloModel){
      case "FBS":
        return [
          {value:null},
          {value:"4,60"},
          {value:"5,30"},
          {value:"6,10"},
          {value:"6,90"},
          {value:"7,60"},
          {value:"8,40"},
          {value:"9,20"},
          {value:"9,90"},
          {value:"10,70"},
          {value:"11,50"},
          {value:"12,20"},
          {value:"13,00"},
          {value:"13,80"},
          {value:"14,50"},
          {value:"15,30"},
          {value:"16,80"},
          {value:"18,30"},
          {value:"19,90"},
          {value:"21,40"},
          {value:"22,90"},
          {value:"24,40"},
          {value:"26,00"},
          {value:"27,50"},
          {value:"30,60"}
        ];

      case "HBS 45":
      case "HBS-S 45":
        return [
          {value:null},
          {value:"4,60"},
          {value:"5,30"},
          {value:"6,10"},
          {value:"6,90"},
          {value:"7,60"},
          {value:"8,40"},
          {value:"9,20"},
          {value:"9,90"},
          {value:"10,70"},
          {value:"11,50"},
          {value:"12,20"}
        ];

      case "HBS 60":
      case "HBS-S 60":
        return [
          {value:null},
          {value:"4,60"},
          {value:"5,30"},
          {value:"6,10"},
          {value:"6,90"},
          {value:"7,60"},
          {value:"8,40"},
        ];

      case "DP":
        return [
          {value: null},
          {value: "1,50"},
          {value: "2,30"},
          {value: "3,10"},
          {value: "3,80"},
          {value: "4,60"},
          {value: "5,30"},
          {value: "6,10"},
          {value: "6,90"},
          {value: "7,60"},
          {value: "8,40"},
          {value: "9,20"},
          {value: "9,90"},
          {value: "10,70"},
          {value: "11,50"},
          {value: "12,20"},
          {value: "13,00"},
          {value: "13,80"},
          {value: "14,50"},
          {value: "15,30"},
          {value: "16,00"},
          {value: "16,80"},
          {value: "17,60"},
          {value: "18,30"},
          {value: "19,10"},
          {value: "19,90"},
          {value: "20,60"},
          {value: "21,40"},
          {value: "22,20"},
          {value: "22,90"},
          {value: "23,70"},
          {value: "24,40"},
          {value: "25,20"},
          {value: "26,00"},
          {value: "26,70"},
          {value: "27,50"},
          {value: "28,30"},
          {value: "29,00"},
          {value: "29,80"},
          {value: "30,60"},
          {value: "31,30"},
          {value: "32,10"},
          {value: "32,80"},
          {value: "33,60"},
          {value: "34,40"},
          {value: "35,10"},
          {value: "35,90"},
          {value: "36,70"},
          {value: "37,40"},
          {value: "38,20"},
          {value: "39,00"},
          {value: "39,70"},
          {value: "40,50"},
          {value: "41,30"},
          {value: "42,00"},
          {value: "42,80"}
        ];
      case "DPT":
        return [
          {value: null},
          {value: "2,30"},
          {value: "3,10"},
          {value: "3,80"},
          {value: "4,60"},
          {value: "5,30"},
          {value: "6,10"},
          {value: "6,90"},
          {value: "7,60"},
          {value: "8,40"},
          {value: "9,20"},
          {value: "9,90"},
          {value: "10,70"},
          {value: "11,50"},
          {value: "12,20"},
          {value: "13,00"},
          {value: "13,80"},
          {value: "14,50"},
          {value: "15,30"},
          {value: "16,00"},
          {value: "16,80"},
          {value: "17,60"},
          {value: "18,30" },
          {value: "19,10"},
          {value: "19,90"},
          {value: "20,60" },
          {value: "21,40"},
          {value: "22,20"},
          {value: "22,90"},
          {value: "23,70"},
          {value: "24,40"},
          {value: "25,20" },
          {value: "26,00"},
          {value: "26,70"},
          {value: "27,50"},
          {value: "28,30"},
          {value: "29,00"},
          {value: "29,80"},
          {value: "30,60"}

        ];
      case "SGC":
      case "SGC-A":
          return [
            {value: null},
            {value: "2,50"},
            {value: "3,50"}
          ];
      case "SGL":
      case "SGL-A":
          return [
            {value: null},
            {value: "2,50"}
          ];
      case "TLS 45":
      case "TLS 60":
      case "TLS-S 45":
      case "TLS-S 60":
        return [
          {value: "3,10"},
          {value: "3,60"},
          {value: "3,80"},
          {value: "4,60"},
          {value: "5,30"},
      ];
      case "TLS-S 53-80":
        return [
          {value: "2,55"}
      ];

    }


  }

  getModels(){
    return [
            {
              value: "FBS"
            },
             {
              value: "HBS 45"
            },
             {
              value: "HBS 60"
            },
            {
              value: "HBS-S 45"
            },
            {
              value: "HBS-S 60"
            },
            {
              value: "DP"
            },
            {
              value: "DPT"
            },
            {
              value: "SGC"
            },
            {
              value: "SGL"
            },
            {
              value: "TLS"
            }

      ];
  }
  
  getSeismics(runner: boolean = false,  plainArray: boolean = false){

    let seismics  = [];

    if(plainArray){
      return [0.00, 0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40];
    }


    if(runner){
      seismics =  [
        {
           value: "All"
        },
        {
           value: 0.00
        },
        {
          value: 0.10
        }
         ,{
            value: 0.15
         },
         {
           value: 0.20
         },
         {
           value: 0.25
         },
         {
           value: 0.30
         },
         {
           value: 0.35
         },
         {
           value: 0.40
         }
       ];
    }else{
      seismics = [
        {
           value: null
        },
        {
           value: 0.00
        },
        {
          value: 0.10
        }
         ,{
            value: 0.15
         },
         {
           value: 0.20
         },
         {
           value: 0.25
         },
         {
           value: 0.30
         },
         {
           value: 0.35
         },
         {
           value: 0.40
         }
       ];
    }



    return seismics;
  }

  getSeismicZones(){
      return [
               "0" ,
               "0,10",
               "0,15",
               "0,20",
               "0,25",
               "0,30",
               "0,35",
               "0,40"

           ];
  }

  getDistances(siloModel: String){



      switch(siloModel){
        case "FBS":
        case "HBS 45":
        case "HBS 60":
        case "HBS-S 45":
        case "HBS-S 60":
        case "TLS 45":
        case "TLS 60":
        case "TLS-S 45":
        case "TLS-S 60":
          return [{value: null},
                  {value: "800"},
                  {value: "1200"}
                 ];
        case "DP":
          return [{value: null},
                  {value: "0"}

                 ];
        case "DPT":
          return [{value: null},
                  {value: "2400"}
                 ];
     }



  }

  getGrainLoads(){
    return [
             "EN 1991-4:2006" ,
             "ANSI/ASAE EP433 (1988 R2011)"
          ];
  }

  getDesignDensities(){
    return [
        "8,18",
        "9"
    ];
  }


  getSnowLoad(){
    return [
        "60",
        "80",
        "120",
        "150",
        "180",
        "240"
    ];
  }

  getSnowLoads(runner: boolean = false,  plainArray: boolean = false){

    let snowLoads = [];

    if(plainArray){
      return [60,80,120,180,240];
    }

    if(runner){

      snowLoads = [

        { value: 60},
        { value: 80},
        { value: 120},
        { value: 180},
        { value: 240}
      ];

      snowLoads.unshift({value: "All"});

    }else{

      snowLoads = [

        { value: 60},
        { value: 80},
        { value: 120},
        { value: 180},
        { value: 240}
      ];

      snowLoads.unshift({value: null});

    }


     return snowLoads;
  }

  getDischargeModes(locale){
    switch(locale){
      case "en":
        return [
           "Central discharge" ,
           "Lateral discharge"
              ];
      case "es":
        return [
           "Central por gravedad" ,
           "Lateral"
        ];
      case "de":
        return [
          "Entladung durch zentral angeordnete Entleerungsöffnung" ,
          "Seitliche Entleerung"
       ];
      case "ru":
        return [
          "Разгрузка через центральное выгрузное отверстие" ,
          "Боковая разгрузка"
       ];
    }

  }

  getFinishes(siloModel,locale){

    switch(locale){
      case "en":
        return [
          {value: "Galvanized", finalValue: "Z6" },
          {value: "Prelaquered", finalValue: "PL2" },

        ];
      case "es":
        return [
          {value: "Galvanizado", finalValue: "Z6" },
          {value: "Prelacado", finalValue: "PL2" },

        ];
    }

  }

  getDistanceBetweenStiffeners(){
    return [
        "800",
        "1200"
    ];
  }

  getGalvanisationRoof(){
    return [
        "Z600",
        "Prelaquered RAL 5014 Blue Petkus",
        "Prelaquered RAL 6005 Green Navarra",
    ];
  }

  getGalvanisationWallSheets(){
    return [
        "Z600",
        "Prelaquered RAL 5014 Blue Petkus",
        "Prelaquered RAL 6005 Green Navarra",
    ];
  }

  getHoppers(){
    return [
        "Z600",
        "Prelaquered RAL 5014 Blue Petkus",
        "Prelaquered RAL 6005 Green Navarra",
    ];
  }

  getTypeOfFundation(foundation, locale){
    switch(foundation){
        case "Flat":
          switch(locale){
            case "en":
              return foundation;
            case "es":
              return "Plano"
            case "de":
              return "Flach";
            case "ru":
              return "Плоский";
          }

    }

    return foundation;

  }
  getJointSealing(locale){
    switch(locale){
      case "en":
        return "Sealant tape (rubber/polybutene blend)";
      case "es":
        return "Cinta selladora (mezcla caucho/polibuteno)"
      case "de":
        return "Dichtungsband (Mischung von Gummi und Polybuten)";
      case "ru":
        return "Уплотнительная лента (смесь резины / полибутена)";
    }
  }

  getBoltGalvanizationMethod(method,locale){
    switch(method){
      case "Spun galvanised":
        switch(locale){
          case "en":
            return method;
          case "es":
            return "Galvanizado centrifugado";
          case "de":
            return "Feuerverzinkt";
          case "ru":
            return "Оцинкованные по методу центрифугирования";

        }
    }
  }

  getDischargeMode(mode,locale){


    switch(mode){
      case 'Central per gravity':
        switch(locale){
          case "en":
            return 'Central per gravity';
          case "es":
            return "Galvanizado centrifugado";
          case "de":
            return 'Central per gravity';
          case "ru":
            return 'Central per gravity';

        }

      case 'Lateral discharge':
        switch(locale){
          case "en":
            return 'Sidewall by gravity';
          case "es":
            return "Lateral por graveded";
          case "de":
            return 'Sidewall by gravity';
          case "ru":
            return 'Sidewall by gravity';
        }
    }

  }


  decimalCasting(_numberString){

    let designDensity =  _numberString.replace(".","")
                                       .replace(",",".");

    designDensity = parseFloat(designDensity.toString());

    return designDensity;
  }

  decimalCastingReverse(_number){


    return _number.toString().replace(".",",");



  }

  private handleError(error: any): Promise<any> {

    return Promise.reject(error.message || error);
  }

}
