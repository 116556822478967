import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../mts/login/login.service';
import { User } from '../../../mts/user/user';

import { OfferService } from '../offer.service';
import { Offer } from '../offer';

import { ErrorBoxService } from '../../../shared/error-box/error-box.service';
import * as _ from 'lodash';

import {TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-list-offers',
  templateUrl: './list-offers.component.html',
  styleUrls: ['./list-offers.component.scss']
})
export class ListOffersComponent implements OnInit {

	userData;
  processingReq = false;
  offerData = new Offer();

  constructor(private router: Router,
              private loginService: LoginService,
              private offerService: OfferService,
              private errorBox: ErrorBoxService,
              private translate: TranslateService) {

     
  }

  ngOnInit() {
  	this.loginService.getUser()
  		.then(user => this.userData = user);
  }

  canCreateOffer() {

  	if (this.userData) {

      if( User.hasRole(this.userData.user,'SUPER_ADMIN') || User.hasRole(this.userData.user,'INTERNAL_AGENT') || User.hasRole(this.userData.user,'EXTERNAL_AGENT') ){
        return true;
      }
  
  	}

    return false;
  }

  createTemporaryOffer(){
    this.createEmptyOffer();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }


  createEmptyOffer() {
    let finalOffer = _.cloneDeep(this.offerData);
    finalOffer.customer =  null;
    finalOffer.offerRevisionSilos = [];
    finalOffer.cityOfInstallation = null;
    finalOffer.placeOfDelivery = null;
    delete finalOffer.exclusions;

    this.processingReq = true;
    this.offerService.createOffer(finalOffer)
      .then(offer => {
        this.processingReq = false;
        this.errorBox.success("Offer created successfully.", true)
        this.router.navigate(['/dashboard/offers/edit/' + offer.offer.id + '/revision/' + offer.revision]);
      }, error => {
        this.processingReq = false;
      });
  }

}
