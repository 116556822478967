export class Report {
    yearOne: Month[];
    yearTwo: Month[];
    comparison: MonthComparison[];

    constructor() {
        this.comparison = [];
    }
}

class Month {
    month: string;
    offers: ReportData;
    revisedOffers: ReportData;
    totalOffers: ReportData;
    orders: ReportData;
    revisedOrders: ReportData;
    totalOrders: ReportData;
    orderOfferPercent: ReportData;
}

class MonthComparison {
    offers: ReportData;
    orders: ReportData;
}

class ReportData {
    count: number | string;
    value: number | string;
}
