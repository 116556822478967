import * as _ from 'lodash';

export class StatisticsFilter {

	filter:{

    user: any;
    typeOfSupply: any;
    storage: any;
    customerGroup: any;
    area: string;
    country: any;
    customer: any;
    site: any;
	}

	queryString: string;

  storages: any[];

  public clean(property){
    this.filter[property] = null;
  }

	public getUser(): any { return this.filter.user; }
	public setUser(user: any): StatisticsFilter { this.filter.user = user; return this; }
  
  public getTypeOfSupply(): any { return this.filter.typeOfSupply; }
  public setTypeOfSupply(typeOfSupply: any): StatisticsFilter { this.filter.typeOfSupply = typeOfSupply; return this; }

  public getStorage(): any { return this.filter.storage; }
  public setStorage(storage: any): any { this.filter.storage = storage; return storage; }

  public getCustomerGroup(): string { return this.filter.customerGroup; }
  public setCustomerGroup(customerGroup: string): string { this.filter.customerGroup = customerGroup; return customerGroup;}

  public getArea(): any { return this.filter.area; }
  public setArea(area: any): any { this.filter.area = area; return area; }

  public getCountry(): any { return this.filter.country; }
  public setCountry(country: any): any { this.filter.country = country; return country;}

  public getCustomer(): any { return this.filter.customer; }
  public setCustomer(customer: any): any { this.filter.customer = customer; return customer; }

  public getSite(): any { return this.filter.site; }
  public setSite(site: any): any { this.filter.site = site; return site; }

  public initFilterObject(){
    this.filter = {

      user  :  null,
      typeOfSupply: null,
      storage: null,
      customerGroup: null,
      area: null,
      country: null,
      customer: null,
      site: null

    }


    this.storages = [

      {value: "A", text: "- 1.000 [T]", minCapacity: null, maxCapacity: 1000 },
      {value: "B", text: "1.000 [T] - 5.000 [T]" , minCapacity: 1000, maxCapacity: 5000  },
      {value: "C", text: "5.000 [T] - 10.000 [T]" , minCapacity: 5000, maxCapacity: 10000 },
      {value: "D", text: "10.000 [T] - 30.000 [T]" , minCapacity: 10000, maxCapacity: 30000 },
      {value: "E", text: "30.000 [T] - 50.000 [T]" , minCapacity: 30000, maxCapacity: 50000 },
      {value: "F", text: "50.000 [T] - 80.000 [T]" , minCapacity: 50000, maxCapacity: 80000 },
      {value: "G", text: "80.000 [T] - 120.000 [T]" , minCapacity: 80000, maxCapacity: 120000 },
      {value: "H", text: "+ 120.000 [T]", minCapacity: 120000, maxCapacity: null }
    
    ];

    this.queryString = '';

  }
	public constructor() {

  	this.initFilterObject();

	}

  public getFilter(){
    const applyedFilters = { ...this.filter };

    if(!_.isNull(applyedFilters.user)){
      applyedFilters.user = applyedFilters.user.id;
    }

    if(!_.isNull(applyedFilters.typeOfSupply)){
      applyedFilters.typeOfSupply = applyedFilters.typeOfSupply.id;
    }

    if(!_.isNull(applyedFilters.customerGroup)){
      applyedFilters.customerGroup = applyedFilters.customerGroup.id;
    }

    if(!_.isNull(applyedFilters.country)){
      applyedFilters.country = applyedFilters.country.id;
    }


    if(!_.isNull(applyedFilters.site)){
      applyedFilters.site = applyedFilters.site.id;
    }

    if(!_.isNull(applyedFilters.customer)){
      applyedFilters.customer = applyedFilters.customer.id;
    }

    if(!_.isNull(applyedFilters.storage)){
      applyedFilters["minCapacity"] = applyedFilters.storage.minCapacity;
      applyedFilters["maxCapacity"] = applyedFilters.storage.maxCapacity;
    }

    return applyedFilters;
  }
  public getQueryString(){
       
      this.queryString="";
      let applyedFilters = { ...this.filter };

      if(applyedFilters.user){
      	applyedFilters.user = applyedFilters.user.id;
      }
   
      for (const property in applyedFilters) {
        if (applyedFilters.hasOwnProperty(property) && !_.isNull(applyedFilters[property])) {

          switch(property){

            default:
                if(_.isNumber(applyedFilters[property]) || applyedFilters[property]!=''){

                  this.queryString+= this.getSeparatorQueryString() + property + '=' + applyedFilters[property];
                }
              
              break;

          }
             
        }
      }


     return  this.queryString;
  }

  private getSeparatorQueryString(){

    if(this.queryString != '' && this.queryString){
      return "&"
    }
    else{
      return "?"
    }

  }

  public toString = () : string => {
 
    return this.getQueryString();
  }


  private  findByMatchingProperties(set, properties) {
    return set.filter(function (entry) {
        return Object.keys(properties).every(function (key) {
            return entry[key] === properties[key];
        });
    });
  }

}