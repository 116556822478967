import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';


@Pipe({
  name: 'localeCustomFormat'
})
export class LocaleCustomFormat implements PipeTransform {
  public transform(value: number, format?: string): string {

    if(format){

      switch(format){
        case "currency":
          return  _.isNull(value) || _.isUndefined(value) ? '' : value.toLocaleString('es-ES', { style: format, currency: 'EUR' });

        case "percent":
          return  _.isNull(value) || _.isUndefined(value) ? '' : value.toLocaleString('es-ES', { style: format, minimumFractionDigits:5 });

      }

    }

    return  _.isNull(value) || _.isUndefined(value) ? '' : value.toLocaleString('es-ES', { style: 'currency', currency: 'EUR'});
   
  }
}

@Pipe({
  name: 'localeCustomUnity'
})
export class LocaleCustomUnity implements PipeTransform {

  private checkCurrencyRate(value: number, currencyRate?: number){

    let rate = 1;

    if(currencyRate && _.isNumber(currencyRate) && _.isFinite(currencyRate) ){
      rate = currencyRate;
    }

    if(_.isNumber(value) && _.isFinite(value)){

        value*=rate;

    }

    return value;

  }

  private checkCurrencyRateInverse(value: number, currencyRate?: number){

    let rate = 1;

    if(currencyRate && _.isNumber(currencyRate) && _.isFinite(currencyRate) ){
      rate = currencyRate;
    }

    if(_.isNumber(value) && _.isFinite(value)){

        value/=rate;

    }

    return value;

  }

  public transform(value: number, format?: string, currencyRate?: number): string {


    if(format){

      switch(format){
        case "currency":
          value = this.checkCurrencyRate(value, currencyRate);
          return _.isNull(value) || _.isUndefined(value) ? '' : value.toLocaleString('de-DE', {minimumFractionDigits:2 , maximumFractionDigits:2});
        
        case "currency-inverse":
          value = this.checkCurrencyRateInverse(value, currencyRate);
          return _.isNull(value) || _.isUndefined(value) ? '' : value.toLocaleString('de-DE', {minimumFractionDigits:2 , maximumFractionDigits:2});
        
        case "currency-long":
          
          value = this.checkCurrencyRate(value, currencyRate);
          return _.isNull(value) || _.isUndefined(value) ? '' : value.toLocaleString('de-DE', {minimumFractionDigits:5 , maximumFractionDigits:5});        

        case "percent":
     
          return  _.isNull(value) || _.isUndefined(value) ? '' : value.toLocaleString('de-DE', { style: format, minimumFractionDigits:2 });

        case "percentClean":
     
          return  _.isNull(value) || _.isUndefined(value) ? '' : value.toLocaleString('de-DE', { style: 'percent', minimumFractionDigits:0, maximumFractionDigits:0 });

        case "percentPrice":
     
          return  _.isNull(value) || _.isUndefined(value) ? '' : value.toLocaleString('de-DE', { style: 'percent', minimumFractionDigits:5 ,maximumFractionDigits:5 });

        case "weight":

          return _.isNull(value) || _.isUndefined(value) ? '' : value.toLocaleString('de-DE',{minimumFractionDigits:1,maximumFractionDigits:1});

        case "weight-round":

          return _.isNull(value) || _.isUndefined(value) ? '' : value.toLocaleString('de-DE',{minimumFractionDigits:0,maximumFractionDigits:0});

        case "kweight":
          return _.isNull(value) || _.isUndefined(value) ? '' : value.toLocaleString('de-DE',{minimumFractionDigits:3,maximumFractionDigits:3});

        case "kprice":
          return _.isNull(value) || _.isUndefined(value) ? '' : value.toLocaleString('de-DE',{minimumFractionDigits:5,maximumFractionDigits:5});

        case "capacity":
          return _.isNull(value) || _.isUndefined(value) ? '' : value.toLocaleString('de-DE',{minimumFractionDigits:2,maximumFractionDigits:2});
          
      }

    }
    
    return  _.isNull(value) || _.isUndefined(value) ? '' : value.toLocaleString('de-DE', {minimumFractionDigits:2 , maximumFractionDigits:2});
   
  }
}