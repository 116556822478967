import { ErrorHandler } from '@angular/core';
import {XHRBackend, Http, RequestOptions} from '@angular/http';
import {InterceptedHttp} from './http.interceptor';
import {AuthenticateService} from '../mts/authenticate/authenticate.service';

import { AppErrorHandler } from './app-error-handler.service';


export function httpFactory(xhrBackend: XHRBackend, requestOptions: RequestOptions, authService: AuthenticateService,
                            appErrorHandler: ErrorHandler): Http {
  return new InterceptedHttp(xhrBackend, requestOptions, authService, appErrorHandler);
}
