import { ComponentKeyValue } from '../data-types/components-relation';

export const SteelTypeTypes: ComponentKeyValue[] = [
    {
        key: 'S350GD',
        value: 'S350GD'
    },
    {
        key: 'HX420LAD',
        value: 'HX420LAD'
    },
    {
        key: 'HX460LAD',
        value: 'HX460LAD'
    },
    {
        key: 'HX500LAD',
        value: 'HX500LAD'
    }

];